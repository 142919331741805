import { useEffect, useState } from "react";
import { ActivatorDropdown } from "./styles";
import SelectItem from "./SelectItem";
import iconLupa from "./assets/searchIcon.png";
import CustomDropDown, { classCustomDropDown } from "../CustomDropdown";

export default function CustomSelectItemsLoad({
  options, // [{id,name,isSelected}]
  showSearchBar, // boolean
  placeHolder = "Buscar departamento",
  valueInputSearch,
  onChangeValueInputSearch,
  selectLabel = "",
  customSelectId = "defaultSelectId",
  onClickItem,
  icon,
  iconClassName,
  isShowSelect,
  isShowModal,
  componentFooter, // comp que va despues de la lista de items
}) {
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    isShowSelect && isShowSelect(showContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showContent]);

  return (
    <CustomDropDown
      onClickAway={() => {
        if (!isShowModal) setShowContent(false);
      }}
      show={showContent}
      position={classCustomDropDown.POSITION_BOTTOM_START}
      className={"dropdown customSelectItemsLoad"}
      hiddenComponent={
        <div className="content-container">
          {/* input de busqueda */}
          {showSearchBar && (
            <div className="search-bar-filter">
              <img src={iconLupa} alt="search icon" />
              <input
                type="text"
                placeholder={placeHolder}
                value={valueInputSearch}
                onChange={(event) =>
                  onChangeValueInputSearch(event.target.value)
                }
              />
            </div>
          )}

          {/* container de los items */}
          <div className="items-container">
            {options?.map((option) => {
              return (
                <SelectItem
                  key={customSelectId + option.id}
                  option={option}
                  customSelectId={customSelectId}
                  onClickItem={onClickItem}
                />
              );
            })}
          </div>

          {/* container de botones inferiores */}
          {componentFooter && componentFooter}
        </div>
      }
    >
      {/* activador del select */}
      <ActivatorDropdown
        className="activatorDropdown customSelectItemsLoad"
        onClick={(e) => {
          setShowContent((prev) => !prev);
        }}
      >
        {selectLabel && <p>{selectLabel}</p>}
        {icon ? (
          <div className={iconClassName ? "icon " + iconClassName : "icon"}>
            {icon}
          </div>
        ) : (
          <div className="arrow-item">&#9664;</div>
        )}
      </ActivatorDropdown>
    </CustomDropDown>
  );
}
