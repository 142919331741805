import React, { useState, useEffect, useCallback } from "react";
import {
  ODTText,
  Row,
  Text10,
  ContainerMap,
  Text14,
  ContainerInfo,
  ContainerDatos,
  Text12,
  BotonEnviarCorreo,
  Text11,
  ContainerSelect,
  CustomSelect,
  ContainerCalendar,
  ContainerDate,
  DateButton,
  InputCalle,
  InputEstado,
  InputCP,
  InputNombre,
  InputTelefono,
} from "../../products/listProducts/stepTwo/styles";
import { Calendar } from "react-datepicker2";
import moment from "moment";
import Leaflet from "./map";
import IconEmailPink from "../../../assets/IconComponents/IconEmailPink";
import style from "./styleCalendarOveride.css";

function Map(props) {
  const latlngOffice = { lat: 19.4363992, lng: -99.196269 };
  const latlngOfficeMTY = { lat: 25.6599448, lng: -100.3109336 };
  const today = new Date();
  const [date, setDate] = useState(new Date(new Date().valueOf()));
  const [select, setSelect] = useState("En oficina");
  const [selectSede, setSelectSede] = useState("CDMX");
  const [inputType, setInputType] = useState("Ficha");
  const [requestBy, setRequestBy] = useState("provider");
  const [orderType, setOrderType] = useState("new");
  const [dateString, setDateString] = useState(
    moment(date).format("DD/MM/YYYY")
  );
  const [collectedName, setCollectedName] = useState("");
  const [collectedPhone, setCollectedPhone] = useState("");
  const [firstTime, setFirstTime] = useState(false);

  const [street, setStreet] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState(null);
  const [latlng, setLatlng] = useState(
    props.includeTHD ? latlngOfficeMTY : latlngOffice
  );

  const [dir, setDir] = useState({
    CDMX: {
      address: "Eugenio Sue #316 Col. Polanco CDMX, México.",
      cel1: "+52 (55) 4165-1497",
      cel2: "01-800-26-91-338",
      latlng: { lat: 19.4363992, lng: -99.196269 },
    },
    MTY: {
      address: "Av. Revolución 2096, Villa Estadio, 64830 Monterrey, N.L.",
      cel1: "81 8369 4400",
      cel2: "",
      latlng: { lat: 25.6558509, lng: -100.2805023 },
    },
  });

  const onChange = useCallback(() => {
    const valueStreet = document.getElementById("street").value;
    const valueCity = document.getElementById("city").value;
    const valueZipCode = document.getElementById("zipCode").value;
    const valueCollectedName = document.getElementById("collectedName").value;
    const valueCollectedPhone = document.getElementById("collectedPhone").value;
    setStreet(valueStreet);
    setCity(valueCity);
    setZipCode(valueZipCode);
    setCollectedName(valueCollectedName);
    setCollectedPhone(valueCollectedPhone);
    const data = {
      street: select === "En oficina" ? "" : valueStreet,
      city: select === "En oficina" ? "" : valueCity,
      zipCode: select === "En oficina" ? "" : valueZipCode,
      latlng: select === "En oficina" ? "" : latlng,
      date: dateString,
      collectedName: collectedName,
      collectedPhone: collectedPhone,
      type: select,
    };
    props.setAppointment(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectedName, collectedPhone, dateString, latlng, select]);

  useEffect(() => {
    const data = {
      street: select === "En oficina" ? "" : street,
      city: select === "En oficina" ? "" : city,
      zipCode: select === "En oficina" ? "" : zipCode,
      latlng: select === "En oficina" ? "" : latlng,
      date: dateString,
      collectedName: select === "En oficina" ? "" : collectedName,
      collectedPhone: select === "En oficina" ? "" : collectedPhone,
      type: select,
    };
    props.setAppointment && props.setAppointment(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    city,
    collectedName,
    collectedPhone,
    dateString,
    latlng,
    select,
    street,
    zipCode,
  ]);

  useEffect(() => {
    if (props.cita && props.cita.id_cita) {
      setDate(new Date(props.cita.date));
      setSelect(props.cita.type);
      setCollectedName(props.cita.collectedName);
      setCollectedPhone(props.cita.collectedPhone);
      setStreet(props.cita.street);
      setZipCode(props.cita.zipCode);
      setCity(props.cita.city);
      if (props.cita.type === "En oficina") {
        setLatlng(latlngOffice);
      } else {
        setLatlng({ lat: props.cita.lat, lng: props.cita.lng });
        document.getElementById("street") &&
          (document.getElementById("street").value = props.cita.street);
        document.getElementById("street") &&
          (document.getElementById("city").value = props.cita.city);
        document.getElementById("street") &&
          (document.getElementById("zipCode").value = props.cita.zipCode);
        document.getElementById("street") &&
          (document.getElementById("collectedName").value =
            props.cita.collectedName);
        document.getElementById("street") &&
          (document.getElementById("collectedPhone").value =
            props.cita.collectedPhone);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    if (firstTime) {
      setDateString(
        new Intl.DateTimeFormat("es-ES", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).format(date)
      );
    }
  }, [date, firstTime]);

  useEffect(() => {
    if (street && city && zipCode) setAddress(`${street} ${city} ${zipCode}`);
    else setAddress(null);
  }, [street, city, zipCode]);

  useEffect(() => {
    const data = {
      street: dir[selectSede].address,
      city: selectSede,
      zipCode: "",
      latlng: dir[selectSede].latlng,
      date: dateString,
      collectedName: "",
      collectedPhone: "",
      type: select,
      inputType: inputType,
    };
    //props.setAppointment(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContainerMap width={props.width}>
      {!props.includeTHD && (
        <ContainerSelect>
          <ODTText>Entrega del producto</ODTText>
          <CustomSelect
            defaultValue={select}
            onChange={(e) => {
              setLatlng(latlngOffice);
              setSelect(e.target.value);
            }}
          >
            <option
              value="En oficina"
              selected={select === "En oficina" && "selected"}
            >
              En oficina
            </option>
            <option
              value="Pick up"
              selected={select === "Pick up" && "selected"}
            >
              Pick up
            </option>
            <option value="Insitu" selected={select === "Insitu" && "selected"}>
              Insitu
            </option>
          </CustomSelect>
        </ContainerSelect>
      )}
      <ContainerInfo>
        <Text10>
          Tú nos entregas tus productos en nuestras instalaciones.
        </Text10>
        {select !== "En oficina" && <Text14>Por definir</Text14>}
      </ContainerInfo>
      <hr />
      <Text14 className="text-map">
        Elige la ubicación en el mapa y agrega la dirección de forma escrita.
      </Text14>
      <ContainerInfo>
        <Leaflet
          draggable={select !== "En oficina"}
          address={address}
          latlng={select === "En oficina" ? dir[selectSede].latlng : latlng}
          setLatlng={(e) => {
            if (props.includeTHD) {
              const dirTemp = { ...dir };
              dirTemp[selectSede].latlng = e;
              setDir(dirTemp);
            } else {
              setLatlng(e);
            }
          }}
        />
        <ContainerDatos>
          {select === "En oficina" ? (
            <>
              {props.includeTHD && (
                <>
                  <ContainerSelect>
                    <ODTText>Selecciona Sede</ODTText>
                    <CustomSelect
                      defaultValue={selectSede}
                      onChange={(e) => {
                        setSelectSede(e.target.value);
                        const data = {
                          street: dir[e.target.value].address,
                          city: e.target.value,
                          zipCode: "",
                          latlng: dir[e.target.value].latlng,
                          date: dateString,
                          collectedName: "",
                          collectedPhone: "",
                          type: select,
                          inputType: inputType,
                        };
                        props.setAppointment(data);
                      }}
                    >
                      <option value="CDMX" selected={selectSede === "CDMX"}>
                        CDMX
                      </option>
                      <option value="MTY" selected={selectSede === "MTY"}>
                        MTY
                      </option>
                    </CustomSelect>
                  </ContainerSelect>
                  <ContainerSelect>
                    <ODTText>Tipo de input</ODTText>
                    <CustomSelect
                      defaultValue={inputType}
                      onChange={(e) => {
                        setInputType(e.target.value);
                        const data = {
                          street: dir[selectSede].address,
                          city: selectSede,
                          zipCode: "",
                          latlng: dir[selectSede].latlng,
                          date: dateString,
                          collectedName: "",
                          collectedPhone: "",
                          type: select,
                          inputType: e.target.value,
                        };
                        props.setAppointment(data);
                      }}
                    >
                      <option value="Ficha" selected={inputType === "Ficha"}>
                        Ficha
                      </option>
                      <option
                        value="Picking"
                        selected={inputType === "Picking"}
                      >
                        Picking
                      </option>
                      <option
                        value="Muestra"
                        selected={inputType === "Muestra"}
                      >
                        Muestra
                      </option>
                      <option
                        value="Complemento"
                        selected={inputType === "Complemento"}
                      >
                        Complemento
                      </option>
                    </CustomSelect>
                  </ContainerSelect>
                  <ContainerSelect>
                    <ODTText>Solicitado por:</ODTText>
                    <CustomSelect
                      defaultValue={requestBy}
                      onChange={(e) => {
                        setRequestBy(e.target.value);
                        const data = {
                          street: dir[selectSede].address,
                          city: selectSede,
                          zipCode: "",
                          latlng: dir[selectSede].latlng,
                          date: dateString,
                          collectedName: "",
                          collectedPhone: "",
                          type: select,
                          inputType: inputType,
                          requestBy: e.target.value,
                          orderType: orderType,
                        };
                        props.setAppointment(data);
                      }}
                    >
                      <option
                        value="provider"
                        selected={requestBy === "provider"}
                      >
                        Proveedor
                      </option>
                      <option
                        value="retailer"
                        selected={requestBy === "retailer"}
                      >
                        Cadena
                      </option>
                    </CustomSelect>
                  </ContainerSelect>
                  <ContainerSelect>
                    <ODTText>Tipo:</ODTText>
                    <CustomSelect
                      defaultValue={orderType}
                      onChange={(e) => {
                        setOrderType(e.target.value);
                        const data = {
                          street: dir[selectSede].address,
                          city: selectSede,
                          zipCode: "",
                          latlng: dir[selectSede].latlng,
                          date: dateString,
                          collectedName: "",
                          collectedPhone: "",
                          type: select,
                          inputType: inputType,
                          requestBy: requestBy,
                          orderType: e.target.value,
                        };
                        props.setAppointment(data);
                      }}
                    >
                      <option value="new" selected={orderType === "new"}>
                        Alta
                      </option>
                      <option
                        value="maintenance"
                        selected={orderType === "maintenance"}
                      >
                        Mantenimiento
                      </option>
                    </CustomSelect>
                  </ContainerSelect>
                </>
              )}
              <div>
                <Text12>Dirección de la entrega en nuestras oficinas</Text12>
                <hr />
                <Text12 className="txt-address">
                  {dir[selectSede].address}
                </Text12>
                <BotonEnviarCorreo>
                  Enviar por correo <IconEmailPink />
                </BotonEnviarCorreo>
                <hr />
                <Text12 className="office-contact">Contacto de oficina</Text12>
                <Text11>{dir[selectSede].cel1}</Text11>
                <Text11>{dir[selectSede].cel2}</Text11>
                <hr />
              </div>
            </>
          ) : (
            <div>
              <Text12>Otra dirección o elige el la ubicación en el mapa</Text12>
              <InputCalle
                id="street"
                placeholder="Calle"
                autoComplete="off"
                onBlur={onChange}
                defaultValue={street}
              />
              <Row>
                <InputEstado
                  id="city"
                  placeholder="Estado"
                  autoComplete="off"
                  onBlur={onChange}
                  defaultValue={city}
                />
                <InputCP
                  id="zipCode"
                  placeholder="CP"
                  autoComplete="off"
                  onBlur={onChange}
                  defaultValue={zipCode}
                />
              </Row>
              <hr />
              <Text12>Contacto de recolección</Text12>
              <InputNombre
                id="collectedName"
                placeholder="Nombre"
                onChange={onChange}
                defaultValue={collectedName}
              />
              <Row>
                <p className="pNumber">+52 </p>
                <InputTelefono
                  id="collectedPhone"
                  placeholder="Teléfono"
                  onChange={onChange}
                  defaultValue={collectedPhone}
                />
              </Row>
              <hr />
            </div>
          )}
          {!props.includeTHD && (
            <ContainerCalendar>
              <ContainerDate>
                <Text12>Asigna el día de tu visita</Text12>
                <DateButton>{dateString}</DateButton>
              </ContainerDate>
              <Calendar
                style={style}
                id="calendar"
                isGregorian={true}
                min={today.toDateString()}
                onChange={(value) => {
                  setDate(value._d);
                  setFirstTime(true);
                }}
              />
            </ContainerCalendar>
          )}
        </ContainerDatos>
      </ContainerInfo>
    </ContainerMap>
  );
}
export default Map;
