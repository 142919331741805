import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  RequestModal,
  CheckboxItem,
  ModalContainer,
  CloseButton,
  ModalImage,
  Title,
  CheckboxContainer,
  ButtonContainer,
  FormActiveContainer,
  Row,
} from "./RequestModal";

// imports SVG
import requestmodal from "../../../assets/IconComponents/contentohRequesModal.svg";

// Functions
import { addToCart } from "../../_utils/data";
import Loading from "../../general/loading/index";

export default function ContentohRequestModal(props) {
  const [retailers, setRetailers] = useState([]);
  const [addingToCart, setAddingToCart] = useState(false);
  const [datasheetChecked, setDatasheetChecked] = useState(true);
  const [descriptionChecked, setDescriptionChecked] = useState(true);
  const [imagesChecked, setImagesChecked] = useState(true);
  const [allChecked, setAllChecked] = useState(false);

  const [product, setProduct] = useState(() => {
    const storedProductEdit = sessionStorage.getItem("productEdit");
    if (storedProductEdit) {
      const parsedProductEdit = JSON.parse(storedProductEdit);
      if (parsedProductEdit && parsedProductEdit.product) {
        return parsedProductEdit.product;
      }
    }
    return [];
  });

  const [selectedRetailers, setSelectedRetailers] = useState(() => {
    if (product.length > 0 && product[0].retailersAvailable) {
      return product[0].retailersAvailable.map((e) => e.id);
    }
    return [];
  });

  const checkUncheckFunction = () => {
    if (!allChecked) {
      setImagesChecked(true);
      setDescriptionChecked(true);
      setDatasheetChecked(true);
      let tempArr = [...selectedRetailers];
      for (let i = 0; i < retailers.length; i++) {
        if (selectedRetailers.indexOf(retailers[i].id) === -1) {
          tempArr.push(parseInt(retailers[i].id, 10));
        }
      }
      setSelectedRetailers(tempArr);
    } else {
      setImagesChecked(false);
      setDescriptionChecked(false);
      setDatasheetChecked(false);
      setSelectedRetailers([]);
    }
  };

  const prepareOrder = async () => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    const version = JSON.parse(sessionStorage.getItem("version")) || (product[0] && product[0].version);
    const data = [
      {
        articleId: product[0] ? product[0].id_article : null,
        version: version,
        userId: user.id_user,
        discount: 0,
        subtotal: 0,
        companyId: user.id_company,
        datasheet: datasheetChecked ? JSON.stringify(selectedRetailers) : "[]",
        description: descriptionChecked ? JSON.stringify(selectedRetailers) : "[]",
        image: imagesChecked ? JSON.stringify(selectedRetailers) : "[]",
        attributeTranslations: false,
        descriptionTranslations: false,
        build: "[]",
        manual: "[]",
        userCreated: user.id_user,
      },
    ];
    const arr = JSON.parse(sessionStorage.getItem("inCart")) || [];
    data.forEach((e) => {
      arr.push(e.articleId);
    });
    sessionStorage.setItem("inCart", JSON.stringify(arr));

    props.setShowContentohRequestModal(false);

    const res = await addToCart({ data });
    if (res.data.statusCode === 200) {
      props.setShowContentohRequestModal(false);
      props.setInCart(true);
    }
  };

  const manageRetailers = (checked, retailer) => {
    if (checked) {
      if (selectedRetailers.indexOf(retailer.id) === -1) {
        setSelectedRetailers([...selectedRetailers, parseInt(retailer.id, 10)]);
      }
    } else {
      let tempRetailers = selectedRetailers.slice();
      const index = tempRetailers.indexOf(parseInt(retailer.id, 10));
      if (index !== -1) {
        tempRetailers.splice(index, 1);
      }
      setSelectedRetailers(tempRetailers);
    }
  };

  useEffect(() => {
    const company = sessionStorage.getItem("company");
    if (company) {
      const financedRetailers = JSON.parse(company).financedRetailers;
      if (financedRetailers) {
        setRetailers(financedRetailers);
        return;
      }
    }
    if (product.length > 0) {
      axios
        .get(
          `${process.env.REACT_APP_RETAILER_ENDPOINT}?category=${encodeURIComponent(product[0].categoryName)}`,
          {
            headers: {
              Authorization: sessionStorage.getItem("jwt"),
            },
          }
        )
        .then((r) => {
          setRetailers(Object.values(JSON.parse(r.data.body).data));
        });
    }
  }, [product]);

  return (
    <RequestModal>
      <div className="transparent-background">
        <ModalContainer>
          <CloseButton>
            <button
              className="close"
              onClick={() => props.setShowContentohRequestModal(false)}
            ></button>
          </CloseButton>

          <ModalImage>
            <div className="image-container">
              <img src={requestmodal} alt="" />
            </div>
          </ModalImage>

          <Title>
            <h2>
              Solicitud de <span>{props.concept}</span> a Content-oh!
            </h2>
            <p>¿Desea agregar los demás activos?</p>
          </Title>

          <FormActiveContainer>
            <form>
              <p>Selecciona los activos</p>
              <CheckboxContainer className="active-options">
                <CheckboxItem top>
                  <input
                    checked={datasheetChecked}
                    defaultChecked={datasheetChecked}
                    onClick={(evt) => {
                      setDatasheetChecked(!datasheetChecked);
                    }}
                    type="checkbox"
                    style={{ cursor: "pointer" }}
                  />
                  <label>Ficha técnica</label>
                </CheckboxItem>

                <CheckboxItem top>
                  <input
                    checked={descriptionChecked}
                    defaultChecked={descriptionChecked}
                    onClick={(evt) => {
                      setDescriptionChecked(!descriptionChecked);
                    }}
                    type="checkbox"
                    style={{ cursor: "pointer" }}
                  />
                  <label>Descripción</label>
                </CheckboxItem>

                <CheckboxItem top>
                  <input
                    checked={imagesChecked}
                    defaultChecked={imagesChecked}
                    onClick={(evt) => {
                      setImagesChecked(!imagesChecked);
                    }}
                    type="checkbox"
                    style={{ cursor: "pointer" }}
                  />
                  <label>Imágenes</label>
                </CheckboxItem>
              </CheckboxContainer>
              <Row>
                <p>Cadenas ligadas a este producto</p>
                <CheckboxItem top>
                  <input
                    checked={allChecked}
                    defaultChecked={allChecked}
                    onClick={() => {
                      setAllChecked(!allChecked);
                      checkUncheckFunction();
                    }}
                    type="checkbox"
                    style={{ cursor: "pointer" }}
                  />
                  <label>Seleccionar todo</label>
                </CheckboxItem>
              </Row>

              <CheckboxContainer className="linked-companies">
                {retailers.length === 0 ? (
                  <Loading />
                ) : (
                  retailers.map((retailer, i) => (
                    <CheckboxItem key={i}>
                      <input
                        checked={
                          selectedRetailers.includes(retailer.id)
                        }
                        defaultChecked={
                          product.length > 0 && product[0].retailersAvailable.some(
                            (ret) => ret.id === retailer.id
                          )
                        }
                        key={`check-${retailer.name}-${retailer.id}`}
                        id={retailer.id}
                        type="checkbox"
                        onChange={(evt) => {
                          manageRetailers(evt.target.checked, retailer);
                        }}
                        style={{ cursor: "pointer" }}
                      />
                      <label>{retailer.name}</label>
                    </CheckboxItem>
                  ))
                )}
              </CheckboxContainer>

              {addingToCart ? (
                <ButtonContainer>
                  <Loading />
                </ButtonContainer>
              ) : (
                <ButtonContainer>
                  <button
                    onClick={(evt) => {
                      evt.preventDefault();
                      props.setShowContentohRequestModal(false);
                    }}
                    className="back-button"
                  >
                    Regresar
                  </button>
                  <button
                    onClick={(evt) => {
                      evt.preventDefault();
                      prepareOrder();
                      setAddingToCart(true);
                    }}
                    className="add-button"
                  >
                    Agregar Orden
                  </button>
                </ButtonContainer>
              )}
            </form>
          </FormActiveContainer>
        </ModalContainer>
      </div>
    </RequestModal>
  );
}
