import styled from "styled-components";
import {
  grayS2,
  grayS6,
  grayS7,
  greenS2,
  greenS3,
  pinkS1,
  pinkS2,
  redS2,
  redS3,
} from "../../variables";

export const Container = styled.div`
  padding: 25px 20px 0 0;
  flex-grow: 2;
  overflow: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  //border: 1px solid red;
  .full-size {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 30px;
      height: 30px;
    }
  }

  .inputAddList {
    width: 100%;
    padding: 9px 8px;
    border-radius: 6px;
    border: 1px solid ${grayS7};
    font-family: "Roboto Regular", "Segoe UI", sans-serif;
    font-size: 13px;
    color: #262626;
    font-weight: 500;
    line-height: 1.1;
    //border: 1px solid red;
  }

  .table-products {
    flex-grow: 2;
    //border: 2px solid blue;
    overflow: auto;

    .table-column {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      box-sizing: border-box;

      &:first-child {
        div {
          width: 30px;
          height: 30px;
          padding: 0;

          input {
            margin: auto;
          }
        }
      }

      & + .table-column {
        border-left: 1px solid #f0eef2;
      }
    }
    .table-row {
      cursor: default;

      &:nth-child(odd) {
        background-color: #fafafa;
      }

      &:hover {
        background-color: #d4d1d7;
      }
    }

    .ReactVirtualized__Grid {
      height: auto !important;
      width: auto !important;
      overflow: unset !important;
      will-change: auto !important;

      .ReactVirtualized__Grid__innerScrollContainer {
        overflow: unset !important;
      }
    }
  }

  // renderizar activador del customSelectItemsLoad
  .activatorDropdown.customSelectItemsLoad {
    .icon.iconNormal {
      background-color: white;
      border: 1px solid ${grayS7};
      color: ${grayS6};

      &:hover {
        border: 1px solid ${grayS6};
        background-color: ${grayS7};
      }
    }

    .icon.iconClick {
      background-color: ${grayS2};
      border: 1px solid ${grayS7};
      color: ${pinkS1};
    }

    .icon.iconSelected {
      background-color: ${pinkS1};
      color: white;

      &:hover {
        background-color: ${pinkS2};
      }
    }
  }

  // renderizar hiddenComponent del customSelectItemsLoad
  .dropdown.customSelectItemsLoad {
    box-shadow: 0px 2px 4px #00000040;
    border-radius: 5px;
    background-color: #fff;
    padding: 0px 0px 10px 0px;
    max-width: 400px;
    cursor: default;

    .content-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: inherit;
      cursor: inherit;

      .search-bar-filter {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        flex-wrap: nowrap;
        border-bottom: 1px solid #f0f0f0;
        padding: 10px 8px;

        img {
          width: 15px;
          height: 15px;
        }

        input {
          font-family: "Roboto Medium", "Segoe UI", sans-serif;
          color: #262626;
          font-size: 13px;
          line-height: 1.1;
          background-color: transparent;
          outline: none;
          border: none;
          margin: 0px;
        }
      }

      .items-container {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        overflow: auto;
        max-height: 230px;
        padding: 5px 0px;
        width: 100%;

        .option-container,
        .default-option {
          cursor: pointer;
          padding: 10px 10px;

          .main-item {
            width: 100%;
            display: flex;
          }

          &:hover {
            background-color: #f0f0f0;
          }

          label {
            white-space: nowrap;
          }
        }
      }

      .btnAddList {
        border: none;
        width: calc(100% - 12px);
        max-width: 150px;
        background-color: #e33aa9;
        color: white;
        padding: 6px 10px;
        border-radius: 10px;
        font-family: "Roboto Medium", "Segoe UI", sans-serif;
        font-size: 13px;
        cursor: pointer;
        white-space: pre-wrap;
        line-height: 1.4;
        margin-top: 5px;

        &:disabled {
          cursor: not-allowed;
          background-color: ${grayS2} !important;
          border: 2px solid ${grayS6} !important;
          color: ${grayS6} !important;
        }

        // icon loading
        i {
          font-size: 15px;
        }

        &:hover {
          background: #ad2b80;
        }
      }
    }
  }

  //NOTIFICACIONES CUSTOM
  // contenedor de la noti flotante
  .notification-container {
    top: 100px;
    right: 10px;
    margin-left: 10px;
    left: auto;
    align-items: flex-start;
    z-index: 1100002 !important;
    //border: 1px solid red;

    // contenedor de las alertas
    .notification-content {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 6px 10px;
      justify-content: flex-start;
      overflow: auto;
      //border: 1px solid blue;

      // contenedor de una alerta
      .alert-container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        gap: 8px;
        border-radius: 10px;
        padding: 5px 12px;
        //border: 1px solid green;

        .MuiAlert-icon {
          margin: 0px;
          padding: 0px;
          font-size: 30px;
        }
        .MuiAlert-message {
          font-size: 17px;
          white-space: nowrap;
          font-weight: 600;
        }
        .MuiAlert-action {
          padding: 0px;
        }

        // alerta success
        &.MuiAlert-standardSuccess {
          background-color: ${greenS2};

          .MuiAlert-icon {
            color: ${greenS3};
          }
          .MuiAlert-message {
            color: white;
          }
          .MuiAlert-action button {
            color: ${greenS3};
            &:hover {
              color: ${greenS2};
              background-color: ${greenS3};
            }
          }
        }

        // alerta error
        &.MuiAlert-standardError {
          background-color: ${redS2};

          .MuiAlert-icon {
            color: ${redS3};
          }
          .MuiAlert-message {
            color: white;
          }
          .MuiAlert-action button {
            color: ${redS3};
            &:hover {
              color: ${redS2};
              background-color: ${redS3};
            }
          }
        }
      }
    }
  }
`;

export const ContainerFilters = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 20px;
  padding: 0px 4px 0px 0px;
  gap: 9px 13px;
  //border: 1px solid green;

  // container main
  .containerMain {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    flex-grow: 2;
    justify-content: flex-start;
    gap: 14px 10px;
    //border: 1px solid red;

    // componente filterInput
    > .filter-input {
      margin: 0px 0px 0px 0px;
      min-width: 300px;
      //border: 1px solid red;

      > .container-InputSection {
        //border: 1px solid black;
        flex-grow: 2;
        width: auto;
        gap: 10px;

        > .input-container {
          width: 100%;
          height: 35px;
        }
      }
    }

    // container de los buttons
    .container-buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: nowrap;
      gap: 8px;
      //border: 1px solid blue;
    }
  }

  .containerEnd-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    gap: 8px;
    //border: 1px solid black;
  }

  // botones
  // circle button con hover gris por default
  .buttonCircle {
    width: 34px;
    height: 34px;
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: background 0.25s;
    border-radius: 100%;
    //border: 1px solid blue;

    &.pink {
      background-color: ${pinkS1};
    }

    img {
      width: 100%;
      height: 100%;
    }

    .icon-cart {
      width: 24px;
      height: 24px;
    }

    &:hover {
      background-color: ${grayS7};
    }

    &.pink:hover {
      background-color: ${pinkS2};
    }
  }
`;

export const TableWarning = styled.div`
  font-family: Avenir Next;
  box-sizing: border-box;
  font-size: 20px;
  color: #603888;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5%;
`;

export const RowColumn = styled.div`
  font-family: "Avenir Next";
  font-size: 13px;
  box-sizing: border-box;
  display: flex;
  color: #817393;
  padding: 0 10px;
  width: 100%;

  p {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
  }
`;

export const ContainerGrid = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 2;
  overflow: auto;
  //border: 1px solid red;
`;

export const PrincipalGrid = styled.div`
  width: 100%;
  display: grid;
  //grid-template-columns: repeat(auto-fill, minmax(auto, 200px));
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  justify-content: flex-start;
  gap: 20px;
  padding: 6px;
  background-color: white !important;
  //border: 1px solid blue;
`;

export const GridRow = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.1);
  /*@media (max-width: 890px) {
    width: 90%;
    max-width: 100%;
  }
  @media (min-width: 1250px) {
    width: 30%;
  }*/
  transition: margin 0.4s ease-in;
  transition: opacity 0.4s ease-in;
  transition-delay: 0.7s;
`;

export const Notification = styled.div`
  width: 100%;
  height: 30px;
  background: #b12d84;
  position: fixed;
  bottom: 0;
  z-index: 999999;
  margin-left: -30px;
  color: white;
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  visibility: ${(props) => (props.show === "showAlert" ? "visible" : "hidden")};
  opacity: ${(props) => (props.show === "showAlert" ? "1" : "0")};
  transition: ${(props) =>
    props.show === "showAlert"
      ? "transition: opacity 2s linear;"
      : "visibility 0s 2s, opacity 2s linear"};

  p {
    position: absolute;
    top: 100%;
    left: 45%;
    margin: -23px 0 0 -25px;
  }
`;
