export const updateMaxIndex = (currentSettings, productList) => {
  const newListMaxIndex = productList.length - 1;
  const settings = currentSettings;
  settings.maxIndex = newListMaxIndex;
  return settings;
};

/**
 *
 * @param {object} flags -Object which contains the previous states for error handling and filter states
 * @param {boolean} flags.isLoading
 * @param {boolean} flags.showProductDetail
 * @param {boolean} flags.loadingExportables
 * @param {boolean} flags.redirectMultipleEdition
 * @param {boolean} flags.addedToCart
 * @param {boolean} flags.firstFilterExecuted
 * @param {boolean} flags.errorCatched
 * @param {boolean} flags.NoProductsFound
 * @param {boolean} flags.FilteredNotFound
 * @param {boolean} flags.warningMessage
 * @param {string} flags.UpdateName -String that describes which case should be handled to update the required flags
 * @returns {object}
 */
export const updateFlags = (flags, UpdateName) => {
  switch (UpdateName) {
    case "RequestError":
      flags.errorCatched = true;
      flags.NoProductsFound = false;
      flags.isLoading = false;
      flags.warningMessage =
        "Hubo un problema al recuperar los productos para tu cadena";
      break;
    case "EmptyList":
      flags.errorCatched = false;
      flags.NoProductsFound = true;
      flags.isLoading = false;
      flags.warningMessage = "No se encontraron productos para tu cadena";
      break;
    default:
      break;
  }

  return flags;
};
