import { Container } from "./styles";
import spinner from "../../../../assets/defaultImages/Spinner.gif";

export default function Percentage({ percentage }) {
  if ([null, undefined, "---", <p>----</p>].includes(percentage))
    return <img src={spinner} alt="Loading" width="20px" height="20px" />;
  return (
    <Container percentage={percentage}>
      <p>{percentage}%</p>
    </Container>
  );
}
