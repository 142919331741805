import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  useMap,
  ZoomControl,
} from "react-leaflet";
import axios from "axios";
import * as L from "leaflet";
import "leaflet/dist/leaflet.css";
// import icon from 'leaflet/dist/images/marker-icon.png';
import icon from "../../../assets/IconComponents/pin.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

function Leaflet(props) {
  const [points, setPoints] = useState([props.latlng.lat, props.latlng.lng]);
  const [movePoint, setMovePoint] = useState(false);

  const ResizeMap = () => {
    const map = useMap();
    map._onResize();
    map.setView(points, map.getZoom());
    map.on("zoom", function () {
      map.setView(points, map.getZoom());
    });
    return null;
  };

  L.Marker.prototype.options.icon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
  });

  const getWithAddress = useCallback(async () => {
    if (props.address !== null) {
      const address = props.address.replaceAll(" ", "+");
      const temp = await axios.get(
        `https://nominatim.openstreetmap.org/search.php?q=${address}&format=jsonv2`
      );
      if (temp.data.length > 0) {
        setPoints([temp.data[0].lat, temp.data[0].lon]);
      }
    }
  }, [props.address]);

  useEffect(() => {
    // validación para evitar un renderizado infinito
    if (props.latlng.lat !== points[0]) {
      props.setLatlng(L.latLng(points[0], points[1]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [points]);

  useEffect(() => {
    if (props.latlng.lat !== points[0]) {
      setPoints([props.latlng.lat, props.latlng.lng]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.latlng]);

  useEffect(() => {
    if (movePoint) {
      getWithAddress();
    }
  }, [getWithAddress, movePoint]);

  function DraggableMarker(props) {
    const [position, setPosition] = useState(props.latlng);
    const markerRef = useRef(null);
    const eventHandlers = useMemo(
      () => ({
        dragend() {
          const marker = markerRef.current;
          if (marker !== null) {
            setPosition(marker.getLatLng());
          }
        },
      }),
      []
    );
    if (props.latlng.lat !== points[0]) {
      props.setLatlng(position);
    }
    setMovePoint(true);
    return (
      <Marker
        draggable={props.draggable}
        eventHandlers={eventHandlers}
        position={position}
        ref={markerRef}
      />
    );
  }

  return (
    <MapContainer
      id="map"
      style={{ width: "100%", height: "0", paddingBottom: "50%" }}
      center={points}
      zoom={15}
      zoomControl={false}
      scrollWheelZoom={false}
    >
      <ResizeMap />
      <DraggableMarker
        latlng={props.latlng}
        setLatlng={props.setLatlng}
        draggable={props.draggable}
      />
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <ZoomControl />
    </MapContainer>
  );
}

export default Leaflet;
