import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import GenericModal from "../../general/GenericModal/index";

// SVG assets
import edit from "../../../assets/IconComponents/edit.svg";
import billingIcon from "../../../assets/IconComponents/billing.svg";
import radiobuttonchecked from "../../../assets/IconComponents/radiobuttonchecked.svg";
import radiobuttonunchecked from "../../../assets/IconComponents/radiobuttonunchecked.svg";
import IconMasterdCard from "../../../assets/IconComponents/IconMasterdCard";
import add from "../../../assets/IconComponents/addIconVersion.svg";
import trash from "../../../assets/IconComponents/trash.svg";
import errorModal from "../../../assets/IconComponents/errorModal.svg";

import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { SubmmitButton, Input } from "./CompanyData";

const stripePromise = loadStripe(`${process.env.REACT_APP_KEY_STRIPE}`);

const MainContainer = styled.div`
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .sectionLabel {
    padding-top: 20px;
  }
`;

const Section = styled.div`
  width: 95%;
  border-bottom: 1px solid #e2e2e2;
  padding: 14px 0px;
  p {
    font-family: "Avenir Next";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #707070;
  }

  .title {
    figure {
      & + p {
        margin-left: 12px;
      }
    }

    & + * {
      margin-top: 22px;
    }
  }
`;

const Header = styled(Section)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  p {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #817393;
  }
`;

const BasicData = styled(Section)`
  div {
    display: flex;
    align-items: center;
    .title {
      width: 35%;
      font-family: Avenir Next;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.015em;
    }
    .title,
    .item {
      width: 35%;
      font-family: "Avenir Next";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.015em;
      color: #503d66;

      & + p {
        color: #817393;
      }
    }
    h4 {
      font-family: Avenir Next;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: -0.015em;
    }
    .btnText {
      color: #fff;
      font-family: Avenir Next;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
    }
  }
`;

const TitleIcon = styled.figure`
  margin: 0 15px 0 0;
  align-items: center;
`;

const DataCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  padding: 0 14px;
  box-sizing: border-box;
  width: 360px;
  height: 30px;
  border-radius: 15px;
  background: #f4f4f7;
  input {
    margin-right: 10px;
    margin-top: 0;
    position: relative;
    color: transparent;
    outline: none;
    box-sizing: border-box;

    &:before,
    &:checked:before {
      box-sizing: border-box;
      content: "";
      width: 17px;
      height: 17px;
      background-color: #f4f4f7;
      background-image: url(${radiobuttonunchecked});
      background-size: cover;
      position: absolute;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
    }

    &:checked:before {
      background-image: url(${radiobuttonchecked});
    }
  }
  label {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #707070;
  }
`;

const InputCardNombre = styled.input`
  background: #fafafa;
  border-radius: 3px;
  width: 190px;
  height: 30px;
  border: none;

  &::placeholder {
    color: #d4d1d7;
  }

  & + * {
    margin-left: 9px;
  }
`;

const RowSpaceBetwen = styled.div`
  display: flex;
  justify-content: space-between;

  & + * {
    margin-top: 5px;
  }

  .icons-share {
    width: 50px;
    display: flex;
    justify-content: space-between;
  }

  &.pay-transfer {
    & + * {
      margin-top: 9px;
    }
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;

  p.title {
    font-weight: 500;
    color: #503d66;
  }

  .cardExpand {
    height: 220px !important;
  }

  &.label-heading {
    & + * {
      margin-top: 6px;
    }
  }
`;

export const ContainerCards = styled.div`
  border: 1px solid #f0eef2;
  box-sizing: border-box;
  border-radius: 7px;
  width: 370px;
  height: 160px;
  padding: 15px;
  margin-top: 20px;
  margin-bottom: 20px;

  .title-container {
    display: flex;
    align-items: center;

    svg {
      & + p.title {
        margin-left: 11px;
      }
    }

    & + * {
      margin-top: 26px;
    }
  }

  .title {
    font-family: Avenir Next;
    color: #503d66;
    font-size: 14px;
  }
  .title2 {
    font-family: Avenir Next;
    color: #503d66;
    font-size: 14px;
    margin-bottom: 5px;
  }
  .label {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 21px;
    color: #817393;
  }
  .labelName {
    font-family: Avenir Next;
    color: #817393;
    font-size: 12px;
    width: 200px;
  }
  .labelExpira {
    font-family: Avenir Next;
    color: #817393;
    font-size: 12px;
    width: 85px;
  }
  .labelCVV {
    font-family: Avenir Next;
    color: #817393;
    font-size: 12px;
    width: 45px;
  }

  .not-cards {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #817393;
  }

  & + * {
    margin-left: 35px;
  }
  .inputCardNumber {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 21px;
    background: #fafafa;
    border-radius: 3px;
    width: 320px;
    height: 23px;
    border: none;

    &::placeholder {
      color: #d4d1d7;
    }

    padding-left: 10px;
    padding-top: 7px;

    & + * {
      margin-top: 4px;
    }

    div {
      width: 100%;
    }
  }
  .inputCardExpira {
    background: #fafafa;
    border-radius: 3px;
    width: 71px;
    height: 23px;
    border: none;

    &::placeholder {
      color: #d4d1d7;
    }

    padding-left: 7px;
    padding-top: 7px;

    & + * {
      margin-left: 9px;
    }
    div {
      width: 100%;
    }
  }

  .inputCardCVV {
    background: #fafafa;
    border-radius: 3px;
    width: 40px;
    height: 23px;
    border: none;

    input {
      &.InputElement {
        &::placeholder {
          color: #d4d1d7;
        }
      }
    }
    div {
      width: 100%;
    }

    padding-left: 7px;
    padding-top: 7px;
  }

  .btnAdd {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
`;

const Icon = styled.figure`
  cursor: pointer;
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  & + p {
    margin-left: 17px;
  }
`;

export default function Billing(props) {
  const [modalErrorCard, setModalErrorCard] = useState(false);
  const [billing, setBilling] = useState([]);
  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem("user")));
  const [show, setShow] = useState(false);
  const [msj, setMsj] = useState("");
  const [company, setCompany] = useState(
    JSON.parse(sessionStorage.getItem("company"))
  );

  const getAllCards = async () => {
    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_CARDS_ENDPOINT}`,
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    });
    const billing = JSON.parse(response.data.body).data;
    setBilling(billing);
  };

  const deleteCard = async (id) => {
    await axios({
      method: "delete",
      url: `${process.env.REACT_APP_CARDS_ENDPOINT}?idCard=${id}`,
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    });
    getAllCards();
  };

  useEffect(() => {
    getAllCards();
  }, []);

  const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const handleSubmit = async (e) => {
      e.preventDefault();
      /* isNew values
      1: is new
      0: use card existing
      -1: use other payment metoh */
      let token = "";
      token = await stripe.createToken(elements.getElement(CardNumberElement));

      if (token.token) {
        token = token.token.id;
      } else {
        setModalErrorCard(true);
        setMsj(token.error.message);
      }

      await axios({
        method: "post",
        url: `${process.env.REACT_APP_CARDS_ENDPOINT}`,
        data: {
          token,
        },
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      });
      getAllCards();
    };

    return (
      <form onSubmit={handleSubmit} id="my-form">
        <RowSpaceBetwen>
          <div>
            <p className="title2">Agregar tarjeta de crédito</p>
          </div>
          <IconMasterdCard />
        </RowSpaceBetwen>

        <label className="label">Número de tarjeta de crédito</label>
        <CardNumberElement className="inputCardNumber" />
        <Row className="label-heading">
          <label className="labelName">Nombre del titular</label>
          <label className="labelExpira">Expira</label>
          <label className="labelCVV">CVV</label>
        </Row>
        <Row>
          <InputCardNombre placeholder="Brooklyn Simmons" />
          <CardExpiryElement className="inputCardExpira" />
          <CardCvcElement className="inputCardCVV" />
        </Row>
        <button className="btnAdd" type="submit">
          <img src={add} alt="iconAdd" />
        </button>
      </form>
    );
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let newValues = {};
    document.querySelector("#nameInput").value !== "" &&
      (newValues.name = document.querySelector("#nameInput").value);
    document.querySelector("#addressInput").value !== "" &&
      (newValues.address = document.querySelector("#addressInput").value);
    document.querySelector("#zipCodeInput").value !== "" &&
      (newValues.zip_code = document.querySelector("#zipCodeInput").value);
    document.querySelector("#telephoneInput").value !== "" &&
      (newValues.telephone = document.querySelector("#telephoneInput").value);
    setUser(newValues);

    let newValuesCompany = {};
    document.querySelector("#rfcInput").value !== "" &&
      (newValuesCompany.rfc = document.querySelector("#rfcInput").value);

    try {
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_USER_ENDPOINT}?boolCompany=1`,
        data: newValuesCompany,
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      });

      await axios.put(
        `${process.env.REACT_APP_USER_ENDPOINT}?boolUser=1`,
        newValues,
        {
          headers: {
            Authorization: sessionStorage.getItem("jwt"),
          },
        }
      );
      let response = await axios.get(process.env.REACT_APP_USER_ENDPOINT, {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      });
      const newUserInfo = JSON.parse(response.data.body).data[0];
      const newCompanyInfo = JSON.parse(response.data.body).data[1];
      sessionStorage.setItem("user", JSON.stringify(newUserInfo));
      sessionStorage.setItem("company", JSON.stringify(newCompanyInfo));
      setUser(newUserInfo);
      setCompany(newCompanyInfo);
      setShow(!show);
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <MainContainer>
      <Header>
        <div>
          <TitleIcon>
            <img src={billingIcon} alt="Section icon" />
          </TitleIcon>
          <p>Datos de facturación</p>
        </div>
        {!show && (
          <TitleIcon>
            <img
              src={edit}
              alt="Edit"
              onClick={() => {
                setShow(!show);
              }}
            />
          </TitleIcon>
        )}
      </Header>

      <BasicData>
        <div className="sectionLabel">
          <p className="item">Nombre</p>
          {show ? (
            <Input
              id="nameInput"
              placeholder="Nombre"
              defaultValue={user.name ? user.name : ""}
            />
          ) : (
            <p>{user.name ? user.name : "---"}</p>
          )}
        </div>
        <div className="sectionLabel">
          <p className="item">RFC</p>
          {show ? (
            <Input
              id="rfcInput"
              placeholder="RFC"
              defaultValue={company.rfc ? company.rfc : ""}
            />
          ) : (
            <p>{company.rfc ? company.rfc : "---"}</p>
          )}
        </div>
      </BasicData>

      <BasicData>
        <div className="sectionLabel">
          <p className="item">Dirección fiscal</p>
          {show ? (
            <Input
              id="addressInput"
              placeholder="Agrega tu dirección"
              defaultValue={user.address ? user.address : ""}
            />
          ) : (
            <p>{user.address ? user.address : "---"}</p>
          )}
        </div>
        <div className="sectionLabel">
          <p className="item">Código postal</p>
          {show ? (
            <Input
              id="zipCodeInput"
              placeholder="Código postal"
              defaultValue={user.zip_code ? user.zip_code : ""}
            />
          ) : (
            <p>{user.zip_code ? user.zip_code : "---"}</p>
          )}
        </div>
        <div className="sectionLabel">
          <p className="item">Teléfono</p>
          {show ? (
            <Input
              id="telephoneInput"
              placeholder="Teléfono"
              defaultValue={user.telephone ? user.telephone : ""}
            />
          ) : (
            <p>{user.telephone ? user.telephone : "---"}</p>
          )}
        </div>
        <div className="sectionLabel">
          <p className="item">Correo electrónico</p>
          <p>{JSON.parse(sessionStorage.getItem("user")).email}</p>
        </div>
      </BasicData>

      <BasicData>
        <div className="title">
          <figure>
            <img src={billingIcon} alt="Section icon" />
          </figure>
          <p>Tarjetas</p>
        </div>
        {billing && billing.length > 0 && (
          <>
            {billing.map((e, i) => (
              <div key={i}>
                <DataCard>
                  <div>
                    <input type="radio" name="Card" id="card1" />
                    <label for="card1">
                      {e.brand} Tarjeta de{" "}
                      {e.funding === "credit" ? "crédito" : "débito"}
                    </label>
                  </div>
                  <p>*****{e.last4}</p>
                </DataCard>
                {show && (
                  <Icon onClick={() => deleteCard(e.id)}>
                    <img src={trash} alt="Eliminar" />
                  </Icon>
                )}
              </div>
            ))}
          </>
        )}
        {show && (
          <ContainerCards>
            <Elements stripe={stripePromise}>
              <CheckoutForm />
            </Elements>
          </ContainerCards>
        )}
      </BasicData>
      {show && (
        <BasicData>
          <SubmmitButton
            onClick={(e) => {
              onSubmit(e);
            }}
          >
            <p className="btnText">Guardar cambios</p>
          </SubmmitButton>
        </BasicData>
      )}

      {modalErrorCard && (
        <GenericModal
          close={() => setModalErrorCard(false)}
          message={`Error al agregar Tarjeta`}
          detail={msj}
          button1={{
            name: "Cerrar",
            action: () => setModalErrorCard(false),
          }}
          textArea={false}
          img={errorModal}
        />
      )}
    </MainContainer>
  );
}
