import styled from "styled-components";

export const MainContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 10px;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
`;
export const SectionThreeRight = styled.div`
  width: 100%;
  max-width: 554px;

  .color-list {
    ul {
      padding-left: 20px;

      li {
        font-family: Avenir Next;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 21px;
        color: #817393;
        position: relative;

        &::before {
          content: "";
          width: 10px;
          height: 10px;
          position: absolute;
          left: -13px;
          top: 50%;
          transform: translateY(-50%);
        }

        &.blue {
          &::before {
            background-color: #18a0fb;
          }
        }

        &.pink {
          &::before {
            background-color: #e33aa9;
          }
        }

        &.yellow {
          &::before {
            background-color: #ffc107;
          }
        }

        &.orange {
          &::before {
            background-color: #ed9a4d;
          }
        }

        &.purple {
          &::before {
            background-color: #603888;
          }
        }
      }
    }
  }
`;

export const SectionOne = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  & + * {
    margin-top: 15px;
  }
`;
export const Container = styled.div`
  display: flex;
  width: 70%;
  height: 50%;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-around;
  > div {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 11px;
    letter-spacing: -0.015em;
    color: #503d66;
  }
`;
export const Container75 = styled.div`
  width: 70%;

  .title30 {
    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 35px;
    color: #503d66;
  }
  .text14 {
    margin-top: 5px;
    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.015em;

    /* Gray S4 */

    color: #817393;
  }
  .text18 {
    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: -0.015em;
    color: #503d66;
  }

  & + * {
    margin-left: 50px;
  }
`;

export const Container25 = styled.div`
  width: 25%;
  right: 0;
  position: relative;

  .color-list {
    ul {
      padding-left: 20px;

      li {
        font-family: Avenir Next;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 21px;
        color: #817393;
        position: relative;

        &::before {
          content: "";
          width: 10px;
          height: 10px;
          position: absolute;
          left: -13px;
          top: 50%;
          transform: translateY(-50%);
        }

        &.blue {
          &::before {
            background-color: #18a0fb;
          }
        }

        &.green {
          &::before {
            background-color: #71de56;
          }
        }

        &.yellow {
          &::before {
            background-color: #ffc107;
          }
        }

        &.orange {
          &::before {
            background-color: #ed9a4d;
          }
        }

        &.purple {
          &::before {
            background-color: #d74ded;
          }
        }
      }
    }
  }
`;

export const Flex = styled.div`
  display: flex;

  .notification-cards {
    max-width: 250px;

    & + * {
      margin-left: 70px;
    }
  }
`;
export const FlexCard = styled.div`
  display: flex;
  padding : 1rem 0;
  justify-content:space-between;

  &.contentoh-dashboard {
    > div {
      width: 155px;
    }
  }
`;

export const SectionTwo = styled.div`
  width: 100%;
  height: 255px;
  overflow: auto;

  .timeLine {
    .timeLine-side-main {
      width: auto !important;
      min-width: 75px !important;
      flex: auto;
      display: block;
      border-right: 1px solid #cdcdcd;

      .timeLine-side {
        border: none;

        .timeLine-side-title {
          align-items: flex-end;
          padding-bottom: 5px;
          border-bottom: 1px solid #cdcdcd !important;
          position: relative;

          &:before {
            content: "Calendario";
            position: absolute;
            font-family: Raleway;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 38px;
            height: 38px;
            top: 0;
            left: 20px;
            z-index: 10;
            background-color: #fff;
          }
        }

        .timeLine-side-task-viewPort {
          .timeLine-side-task-container {
            .timeLine-side-task-row {
              border: none;
            }
          }
        }
      }
    }

    .timeLine-main {
      .timeLine-main-header-viewPort {
        .timeLine-main-header-container {
          .header-top {
            height: 40px;

            div {
              font-family: Avenir Next;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              height: 100%;
              line-height: 40px;
            }
          }

          .header-bottom {
            padding: 0 !important;
            height: 20px !important;
          }
        }
      }
    }
  }
`;

export const SectionThree = styled.div`
  display: flex;
  margin: 17px auto;

  .rdrDateRangePickerWrapper {
    max-width: 259px;
    height: 250px;

    .rdrCalendarWrapper {
      .rdrMonthAndYearWrapper {
        margin: 0;

        .rdrNextPrevButton {
          margin: 0;

          i {
            margin: auto;
          }
        }

        .rdrMonthAndYearPickers {
          justify-content: center;
        }
      }

      .rdrMonths {
        margin: 0;

        .rdrMonth {
          width: auto;
          padding: 7px 15px;

          .rdrWeekDays {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
          }

          .rdrDays {
            button {
              font-family: Roboto;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 34px;
              height: 34px;
            }
          }
        }
      }
    }

    & + * {
      margin-left: 26px;
    }
  }
`;
