import IconLoading from "../../../../assets/defaultImages/Spinner.gif";
import { Container } from "./styles";

export default function CheckBox({ id, label, onChange, checked }) {
  return (
    <Container key={`check-${id}`}>
      {checked === undefined ? (
        <img
          className="iconLoading"
          src={IconLoading}
          alt={"loading"}
          onClick={(event) => {
            event.stopPropagation();
          }}
        />
      ) : (
        <input
          type="checkbox"
          name={id}
          id={id}
          onClick={(event) => {
            event.stopPropagation();
          }}
          checked={checked}
          onChange={onChange}
        />
      )}

      {checked === undefined ? (
        <label
          className="label-loading"
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {label && <p className={"filter-text"}>{label}</p>}
        </label>
      ) : (
        <label
          className="label-input"
          htmlFor={id}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {label && <p className={"filter-text"}> {label} </p>}
        </label>
      )}
    </Container>
  );
}
