import moment from "moment";

export const getMonth = (month) => {
  const Months = {
    1: "Enero",
    2: "Febrero",
    3: "Marzo",
    4: "Abril",
    5: "Mayo",
    6: "Junio",
    7: "Julio",
    8: "Agosto",
    9: "Septiembre",
    10: "Octubre",
    11: "Noviembre",
    12: "Diciembre",
  };
  return Months[month];
};

export const estatusOptions = [
  { name: "Pendiente", value: "CREATED" },
  { name: "Recepción", value: "R" },
  { name: "En progreso", value: "CA" },
  { name: "Aprobado", value: "AP" },
  { name: "Rechazada", value: "REJECTED" },
];

export const monthsOptions = [
  { name: "Enero", value: "Enero" },
  { name: "Febrero", value: "Febrero" },
  { name: "Marzo", value: "Marzo" },
  { name: "Abril", value: "Abril" },
  { name: "Mayo", value: "Mayo" },
  { name: "Junio", value: "Junio" },
  { name: "Julio", value: "Julio" },
  { name: "Agosto", value: "Agosto" },
  { name: "Septiembre", value: "Septiempre" },
  { name: "Octubre", value: "Octubre" },
  { name: "Noviembre", value: "Noviembre" },
  { name: "Diciembre", value: "Diciembre" },
];

const filterByMonth = (orderList, filterValues) => {
  return orderList.filter((orderItem) =>
    filterValues.includes(
      getMonth(moment(orderItem.order.timestamp).format("M"))
    )
  );
};

const filterByOrder = (orderList, filterValues) => {
  return orderList.filter((orderItem) =>
    filterValues.includes(orderItem.order.id_order.toString())
  );
};

export const filterOrders = (filters, updateList, initialList) => {
  const userCheckedProducts = initialList.filter((item) => item.checked);
  let listToFilter = userCheckedProducts
    ? initialList.map((item) => {
        item.checked = false;
        return item;
      })
    : initialList;

  filters?.forEach((filterParams) => {
    const monthSelected = filterParams.filter === "timestamp";
    const orderTyped = filterParams.filter === "upc";
    if (monthSelected)
      listToFilter = filterByMonth(listToFilter, filterParams.values);
    else if (orderTyped)
      listToFilter = filterByOrder(listToFilter, filterParams.values);
    else {
      const filterKey = handleFilterKey(filterParams.filter);
      listToFilter = listToFilter.filter((product) =>
        filterParams.values.includes(product.order[filterKey])
      );
    }
  });
  updateList(listToFilter);
};

export const updateSelected = (checked, orderItem, ordersSelected) => {
  if (checked) {
    ordersSelected.push(orderItem.order.id_order);
  } else {
    const index = ordersSelected.indexOf(orderItem.order.id_order);
    const indexFound = index !== -1;
    if (indexFound) ordersSelected.splice(index, 1);
  }
  return ordersSelected;
};

export const markChecked = (ordersList, orderId, checked) => {
  const itemFound = findItem(ordersList, orderId);
  if (itemFound) {
    const orderIndex = findIndex(ordersList, itemFound);
    const indexFound = orderIndex !== -1;
    if (indexFound) {
      ordersList[orderIndex].checked = checked;
    }
  }
  return ordersList;
};

export const markAll = (ordersList, checked) => {
  return ordersList.map((orderItem) => ({ ...orderItem, checked: checked }));
};

const findItem = (list, id) => {
  return list.find((item) => item.order.id_order === id);
};

const findIndex = (list, item) => {
  return list.indexOf(item);
};

export const handleFilterKey = (currentKey) => {
  switch (currentKey) {
    case "Estatus":
      return "status";
    default:
      return currentKey;
  }
};
