import { useState } from "react";
import { Container } from "./styles.js";
import { VisibilityOutlined, VisibilityOffOutlined } from "@mui/icons-material";
import { updateVisibility } from "../../../_utils/data";
import {
  NOTIFICATION_SUCCESSFUL,
  NOTIFICATION_ERROR,
} from "../../../custom/CustomNotification";

export default function Visibility(props) {
  const [password, setPassword] = useState("");
  const closeModal = () => {
    props.setModalAlert({ show: false });
    setPassword("");
  };

  const setVisibility = (setIsVisible = [], setIsNotVisible = []) => {
    const promises = [];
    if (setIsVisible.length > 0) {
      promises.push(
        updateVisibility({ body: { articlesId: setIsVisible, isVisible: 1 } })
      );
      props.updateVisibility(setIsVisible, 1);
      props.showNotification(
        props.articleId,
        NOTIFICATION_SUCCESSFUL,
        `El producto se encuentra visible`
      );
    }
    if (setIsNotVisible.length > 0) {
      promises.push(
        updateVisibility({
          body: { articlesId: setIsNotVisible, isVisible: 0 },
        })
      );
      props.updateVisibility(setIsNotVisible, 0);
      props.showNotification(
        props.articleId,
        NOTIFICATION_SUCCESSFUL,
        `Se ha quitado la visibilidad al producto`
      );
    }
    closeModal();
    return Promise.all(promises);
  };

  const onClick = async () => {
    const button1 = {
      type: "btnTransparent",
      name: "Cancelar",
      action: closeModal,
    };
    const button2 = {
      type: "btnPink",
      name: "Cambiar",
    };
    const params = [
      "Cambiar visibilidad",
      <div>
        <p>Para cambiar la visibilidad ingresa la clave de aprobación</p>
        <br></br>
        <input
          id={props.articleId}
          autoFocus
          className="inputAddList"
          type={"password"}
          placeholder={"Clave de aprobación"}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
      </div>,
      closeModal,
      button1,
      button2,
      undefined,
    ];

    let productList = [];
    if (props.productsSelected.length > 0) {
      productList = props.productsSelected;
    } else if (props.articleId) {
      productList = [props.articleId];
    }

    if (productList.length > 0) {
      const articlesInclued = props.articlesList.filter(({ article }) =>
        productList.includes(article.id_article)
      );
      const setIsVisible = [];
      const setIsNotVisible = [];
      articlesInclued.forEach(({ article }) => {
        const { isVisible, id_article } = article;
        if (isVisible) setIsNotVisible.push(id_article);
        else setIsVisible.push(id_article);
      });

      button2.action = async () => {
        if (document.getElementById(props.articleId).value === "melocoton") {
          await setVisibility(setIsVisible, setIsNotVisible);
        } else {
          props.showNotification(
            props.articleId,
            NOTIFICATION_ERROR,
            `La contraseña ingresada es incorrecta`
          );
        }
      };
      params[4] = button2;
    } else {
      params[0] = "No hay productos seleccionados";
      params[1] = undefined;
      params[4] = undefined;
      button1.name = "Continuar";
    }

    props.showModalAlert(...params);
  };

  return (
    <Container isVisible={props.isVisible} onClick={onClick}>
      {props.isVisible === 0 ? (
        <VisibilityOffOutlined sx={{ color: "#FFF", fontSize: 18 }} />
      ) : (
        <VisibilityOutlined sx={{ fontSize: 18 }} />
      )}
    </Container>
  );
}
