import axios from "axios";
import {
  initError,
  noResultsFromDB,
  noError,
} from "../../../_utils/errorTemplates";
export const getRetailerTasks = async () => {
  let retailerTasks = {};
  try {
    await axios
      .get(`${process.env.REACT_APP_RETAILER_TASKS}`, {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      })
      .then((response) => {
        retailerTasks.taskList = JSON.parse(response.data.body).data ?? [];
      });
    return retailerTasks;
  } catch (err) {
    console.log("Unable to retrieve retailer tasks from DB", err);
    retailerTasks.taskList = [];
    retailerTasks.err = true;
    return retailerTasks;
  }
};

export const checkInitResults = (tasksResponse) => {
  if (tasksResponse.err) return initError;
  const noTasksFound = tasksResponse.taskList.length === 0;
  if (noTasksFound) return noResultsFromDB;
  return noError;
};

export const initTasks = async (
  setError,
  setTaskList,
  setOriginalTaskList,
  setLoading
) => {
  const tasks = await getRetailerTasks();
  setError(checkInitResults(tasks));
  setTaskList(tasks.taskList);
  setOriginalTaskList(tasks.taskList);
  setLoading(false);
};

export const articleChecked = (
  taskList,
  checked,
  selectedArticles,
  setSelectedArticles,
  setTaskList,
  selectedTask
) => {
  const itemInList = findItem(taskList, selectedTask);
  const index = taskList.indexOf(itemInList);
  if (index !== -1) taskList[index].checked = checked;
  setTaskList(taskList);
  if (checked) selectArticle(selectedTask, setSelectedArticles);
  else unselectArticle(selectedTask, selectedArticles, setSelectedArticles);
};

export const allChecked = (
  taskList,
  setTaskList,
  checked,
  setSelectedArticles
) => {
  if (checked) setSelectedArticles(taskList);
  else setSelectedArticles([]);
  setTaskList((prev) => prev.map((task) => ({ ...task, checked: checked })));
};

const matchParams = (iterationParam, selectedParam) => {
  return selectedParam === iterationParam;
};

const selectArticle = (article, setSelectedArticles) => {
  setSelectedArticles((prev) => [...prev, article]);
};

const unselectArticle = (article, selectedArticles, setSelectedArticles) => {
  const itemInList = findItem(selectedArticles, article);
  const index = selectedArticles.indexOf(itemInList);
  if (index !== -1) {
    selectedArticles.splice(index, 1);
    setSelectedArticles(selectedArticles);
  }
};

const findItem = (list, selectedTask) => {
  return list.find(
    (task) =>
      matchParams(task.orderId, selectedTask.orderId) &&
      matchParams(task.article.id_article, selectedTask.article.id_article)
  );
};

export const rowClicked = (taskClicked, setRedirect) => {
  taskClicked.id_article = taskClicked.article.id_article;
  taskClicked.retailersAvailable = taskClicked.retailers;
  sessionStorage.setItem("revisionRetailer", true);
  sessionStorage.setItem("productSelected", JSON.stringify(taskClicked));
  sessionStorage.setItem(
    "productEdit",
    JSON.stringify({
      ArticleId: taskClicked.article.id_article,
      idCategory: taskClicked.article.id_category,
      product: taskClicked,
    })
  );
  setRedirect({
    path: `/products/${taskClicked.article.id_article}/datasheet`,
    origin: "RequestWithContentoh",
  });
};
