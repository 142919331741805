import React, { useState, useEffect } from "react";
import { getContentohTasks } from "../../_utils/data";
import { Redirect } from "react-router-dom";
import { FilterInput } from "../../filterInput/index";
import { priorityOptions } from "../../_utils/helper";
import { handleFilterKey } from "../utils/handleFiltrKey";
import { GridProducts } from "contentoh-components-library";
import {
  getProductsToTable,
  chkOnChange,
  chkChecked,
  checkAll,
  chkCheckedAll,
  renderColumns,
} from "./TasksGridUtils";

// Styled components
import { MainContainer, Title, SubTitle, TableWarning } from "./styles.js";
// Components
import Loading from "../../general/loading/index";
import GenericModal from "../../general/GenericModal";

const TaskList = () => {
  const [productSelected, setProductSelected] = useState();
  const [contentohTasks, setContentohTasks] = useState([]);
  const [contentohTasksOriginal, setContentohTasksOriginal] = useState([]);
  const [selectedArticles, setSelectedArticles] = useState([]);
  const [filtersArray, setFiltersArray] = useState({});
  const [filterArray, setFilterArray] = useState([]);
  const [tasksLoaded, setTasksLoaded] = useState(false);
  const [tableSettings, setTableSettings] = useState({});
  const [showMessage, setShowMessage] = useState(false);
  const [redirect, setRedirect] = useState();
  const [warningMessage, setWarningMessage] = useState("");
  const [images, setImages] = useState({});
  const [gridView, setGridView] = useState(false);
  const [gridProducts, setGridProducts] = useState([]);

  useEffect(() => {
    const products = getProductsToTable(contentohTasks, images);
    setGridProducts(products);
  }, [contentohTasks, images]);

  const multipleEditionRedirect = () => {
    if (selectedArticles.length > 0) {
      let getDataProducts = selectedArticles.slice();
      let getDataProduct = getDataProducts.map((prod) => {
        prod.name = prod.article.name;
        prod.categoryName = prod.article.category;
        return prod;
      });

      let orderedArray = getDataProduct.sort(
        (a, b) => a.id_article - b.id_article
      );

      sessionStorage.setItem(
        "multipleEditionList",
        JSON.stringify(orderedArray)
      );
      sessionStorage.setItem("task", true);
      setRedirect(`/products/multipleEdition`);
    }
  };

  const filterInputFunct = async (filtersObject) => {
    if (tasksLoaded) {
      try {
        const userCheckedProducts = selectedArticles.length > 0;
        const filters = Object.values(filtersObject);

        if (filters.length > 0) {
          let listToFilter = userCheckedProducts
            ? contentohTasksOriginal.slice().map((item) => {
                item.checked = false;
                return item;
              })
            : contentohTasksOriginal.slice();

          filters?.forEach((filterParams) => {
            if (filterParams.filter === "Alcance") {
              const filterScope = filterParams.values;
              listToFilter = listToFilter.filter((product) => {
                const filterAttr = filterScope.includes("datasheet_status")
                  ? product.datasheet_status !== "NS"
                  : product.datasheet_status === "NS";

                const filterDesc = filterScope.includes("description_status")
                  ? product.description_status !== "NS"
                  : product.description_status === "NS";

                const filterImages = filterScope.includes("images_status")
                  ? product.images_status !== "NS"
                  : product.images_status === "NS";

                return filterAttr && filterDesc && filterImages;
              });
            } else {
              const filterKey = handleFilterKey(filterParams.filter);
              listToFilter = listToFilter.filter((product) => {
                let arrayCategories = product.article.category.split("|");
                switch (filterParams.filter) {
                  case "Departamentos":
                    return filterParams.values.includes(arrayCategories[0]);
                  case "Categorías":
                    return filterParams.values.includes(
                      `${arrayCategories[1]}|${arrayCategories[2]}`
                    );
                  case "retailers":
                    const temp = product.retailers.filter((element) =>
                      filterParams.values.includes(element.id)
                    );
                    if (temp.length) return product;
                    break;
                  default:
                    return filterParams.values.includes(
                      product[filterKey] || product.article[filterKey]
                    );
                }
              });
            }
          });
          if (listToFilter.length === 0)
            setWarningMessage(
              "No se encontraron tareas con esas características"
            );
          setContentohTasks(listToFilter);
        } else {
          setContentohTasks(
            contentohTasksOriginal.map((item) => {
              item.checked = false;
              return item;
            })
          );
        }
        setTableSettings((prev) => {
          prev.startIndex = 0;
          prev.scrollTop = 0;
          return prev;
        });
        setSelectedArticles([]);
      } catch (err) {
        console.log(err, "Unable to filter data, please repor this to IT");
        setContentohTasks(contentohTasksOriginal);
      }
    }
  };

  const checkAllItems = (checked) => {
    if (checked) {
      setContentohTasks((prev) =>
        prev.map((item) => {
          item.checked = true;
          return item;
        })
      );
      setSelectedArticles(
        contentohTasks.map((item) => {
          item.retailersAvailable = item.retailers;
          item.categoryName = item.article.category;
          item.upc = item.article.upc;
          item.id_article = item.article.id_article;
          item.id_order = item.orderId;
          return item;
        })
      );
    } else {
      setContentohTasks((prev) =>
        prev.map((item) => {
          item.checked = false;
          return item;
        })
      );
      setSelectedArticles([]);
    }
  };

  const rowClicked = (product, setProductSelected) => {
    try {
      const membership = JSON.parse(sessionStorage.getItem("user")).membership;
      const canEdit = membership.planID !== 1;
      if (!canEdit) {
        setShowMessage(true);
        return true;
      }
      let prod = product;
      prod.retailersAvailable = prod.retailers;
      prod.categoryName = product.article.category;
      prod.upc = product.article.upc;
      prod.id_article = product.article.id_article;
      prod.id_order = product.orderId;
      sessionStorage.setItem("productSelected", JSON.stringify(prod));
      setProductSelected(prod);
      if (prod?.orderId) {
        sessionStorage.setItem("revision", true);
        sessionStorage.setItem("version", prod.version);
        sessionStorage.setItem(
          "productEdit",
          JSON.stringify({
            ArticleId: prod?.article?.id_article,
            idCategory: prod?.article?.id_category,
            product: prod,
          })
        );
      }
      setRedirect(`products/${product?.article?.id_article}/datasheet`);
    } catch (err) {
      console.log(
        err,
        "Unable to set session item and redirect, please report this to IT"
      );
    }
  };

  async function initialRequest() {
    try {
      const { products, images, err } = await getContentohTasks();
      if (err) {
        setWarningMessage("Hubo un problema al obtener las tareas");
        setContentohTasks(products.taskList);
        setContentohTasksOriginal(products);
      } else {
        const responseList = Object.values(products);
        setContentohTasks(responseList);
        setContentohTasksOriginal(responseList);
        setImages(images);
      }

      setTasksLoaded(true);
      let uniqueOrders = [...new Set(products.map((item) => item.orderId))];
      uniqueOrders = uniqueOrders.map((order) => ({
        name: order,
        value: order,
      }));

      let uniqueBrands = [...new Set(products.map((item) => item.brand))];
      let listCategories = products.map((item) =>
        item.article.category.split("|")
      );
      let uniqueDepartments;
      let uniqueCategories;
      const objsRetailers = {};

      products.forEach((item) =>
        item.retailers.forEach((ret) => {
          objsRetailers[ret.id] = {
            name: ret.name,
            value: ret.id,
          };
        })
      );
      const uniqueRetailers = Object.values(objsRetailers);

      uniqueDepartments = [...new Set(listCategories.map((e) => `${e[0]}`))];
      uniqueDepartments = uniqueDepartments.map((department) => ({
        name: department,
        value: department,
      }));
      uniqueCategories = [
        ...new Set(listCategories.map((e) => `${e[1]}|${e[2]}`)),
      ];
      uniqueCategories = uniqueCategories.map((category) => ({
        name: category,
        value: category,
      }));

      const servicesOptions = [
        { name: "Datos", value: "datasheet_status" },
        { name: "Descripción", value: "description_status" },
        { name: "Imagenes", value: "images_status" },
      ];

      uniqueBrands = uniqueBrands.filter((brand) => !!brand);
      uniqueBrands = uniqueBrands.map((brand) => ({
        name: brand,
        value: brand,
      }));

      setFiltersArray({
        Prioridad: priorityOptions,
        Orden: uniqueOrders,
        Marca: uniqueBrands,
        Alcance: servicesOptions,
        Departamentos: uniqueDepartments,
        Categorías: uniqueCategories,
        Alcances: uniqueRetailers,
      });
      const tempArray = [];
      tempArray.push(
        { name: "Orden", active: false },
        { name: "Marca", active: false },
        { name: "Prioridad", active: false },
        { name: "Alcance", active: false },
        { name: "Departamentos", active: false },
        { name: "Categorías", active: false },
        { name: "Alcances", active: false }
      );
      setFilterArray(tempArray);
    } catch (err) {
      console.log(
        err,
        "unable to mount initial data, please report this to IT"
      );
    }
  }

  useEffect(() => {
    initialRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const articleSelected = (checked, product) => {
  //   try {
  //     if (checked) {
  //       let prod = product;
  //       prod.retailersAvailable = prod.retailers;
  //       prod.categoryName = product.article.category;
  //       prod.upc = product.article.upc;
  //       prod.id_article = product.article.id_article;
  //       prod.id_order = product.orderId;
  //       setSelectedArticles((prev) => [...prev, prod]);
  //     } else {
  //       setSelectedArticles((prev) => {
  //         const temp = prev;
  //         temp.splice(temp.indexOf(product), 1);
  //         return temp;
  //       });
  //     }
  //     setContentohTasks((prev) =>
  //       prev.map((item) => {
  //         item.orderId === product.orderId &&
  //           item.article?.id_article === product.article?.id_article &&
  //           (item.checked = checked);
  //         return item;
  //       })
  //     );
  //   } catch (err) {
  //     console.log(
  //       err,
  //       "Unable to add clicked article to selected products, please report this to IT"
  //     );
  //   }
  // };
  const renderTable = () => {
    if (contentohTasks.length === 0)
      return <TableWarning>{warningMessage}</TableWarning>;
    if (tasksLoaded) {
      return (
        <GridProducts
          products={gridProducts}
          gridView={gridView}
          selected={productSelected}
          columnsArray={renderColumns()}
          chkOnChange={(e, product) =>
            chkOnChange(e, product, selectedArticles, setSelectedArticles)
          }
          chkChecked={(product) => {
            return chkChecked(product, selectedArticles);
          }}
          checkAll={(e) => checkAll(e, setSelectedArticles, contentohTasks)}
          chkCheckedAll={(e) => {
            return chkCheckedAll(selectedArticles, contentohTasks);
          }}
          onGridClick={(e, product) => {
            rowClicked(product, setProductSelected);
          }}
        />
      );
    }
  };

  return (
    <>
      <MainContainer>
        <Title>Productos por realizar</Title>

        <SubTitle>
          En esta sección aparecerán todas las tareas que necesitas revisar para
          que el flujo de trabajo continúe.
        </SubTitle>
        <div className="container-grid">
          <FilterInput
            filterInputFunct={filterInputFunct}
            charged={contentohTasks.length}
            total={contentohTasksOriginal.length}
            setProductsList={setContentohTasks}
            productsListCopy={contentohTasks}
            editProducts={multipleEditionRedirect}
            taskFilter={true}
            filtersArray={filtersArray}
            filterArray={filterArray}
            gridView={gridView}
            setGridView={setGridView}
          ></FilterInput>

          {!tasksLoaded && <Loading />}
          {renderTable()}
        </div>

        {redirect && (
          <Redirect
            to={{
              pathname: redirect,
              state: { origin: "Contentoh" },
            }}
          />
        )}
        {showMessage && (
          <GenericModal
            close={() => setShowMessage(false)}
            message="Tu plan actual no te permite utilizar esta función"
            detail="¿Quieres actualizarlo?"
            button1={{ name: "Cancelar", action: () => setShowMessage(false) }}
            button2={{
              name: "Actualizar",
              action: () => {
                setShowMessage(false);
                setRedirect("upgradeplan");
              },
            }}
          />
        )}
      </MainContainer>
    </>
  );
};

export default TaskList;
