import styled from "styled-components";

export const TextUnderImage = styled.div`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "24px")};
  font-family: Raleway;
  color: ${({ color }) => (color ? color : "#fbfbfb")};
  display: flex;
  line-height: 36px;
  justify-content: center;
  align-items: center;

  > p {
    text-align: center;
    width: 90%;
    margin: 0;
  }
`;

export const MainContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`;

export const VersionFooter = styled.div`
  position: fixed;
  top: 97%;
  left: 96%;
  z-index: 15;
  height: 90px;
  width: 100%;
  pointer-events: none;
`;

export const Button = styled.button`
  font-family: Raleway;
  font-size: 15px;
  background: ${({ background }) => (background ? background : "#b12d84")};
  border-radius: 30px;
  box-sizing: border-box;
  border: none;
  width: 158.54px;
  height: 43px;
  color: ${({ color }) => (color ? color : "white")};
  :hover {
    cursor: pointer;
  }
  ${({ isActive, canDisabled }) =>
    !isActive &&
    canDisabled &&
    `
    background: #FFF;
    color: #D4D1D7;
    border: 1px solid #D4D1D7;
    box-sizing: border-box;
    border-radius: 30px;
  `}
`;

export const Title = styled.h2`
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "36px")};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : "36px")};
  text-align: center;
  letter-spacing: -0.015em;
  color: ${({ color }) => (color ? color : "#000000")};
`;

export const Panel = styled.div`
  width: 50%;
  height: 100vh;
  display: flex;
  padding: 25px 50px;
  box-sizing: border-box;
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "space-around"};
  align-items: center;
  flex-direction: column;
  background: ${({ color }) =>
    color ? color : "linear-gradient(180deg, #e33aa9 0%, #3b1366 100%)"};
`;

export const TextContainer = styled.div`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "24px")};
  font-family: Raleway;
  color: ${({ color }) => (color ? color : "#fbfbfb")};
  display: flex;
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : "36px")};
  justify-content: center;
  align-items: center;
  margin: ${({ margin }) => (margin ? margin : "default")};

  > p {
    text-align: center;
    width: 90%;
  }

  > ul {
    margin-top: 40px;
    > li {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 40px;
      letter-spacing: -0.015em;
      list-style-type: disc;
    }
  }
`;

export const ContainerGeneral = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;

  .inputCardNumber {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 21px;
    background: #fafafa;
    border-radius: 3px;
    width: 320px;
    height: 23px;
    border: none;

    &::placeholder {
      color: #d4d1d7;
    }

    padding-left: 10px;
    padding-top: 7px;

    & + * {
      margin-top: 4px;
    }
  }
  .inputCardExpira {
    background: #fafafa;
    border-radius: 3px;
    width: 71px;
    height: 23px;
    border: none;

    &::placeholder {
      color: #d4d1d7;
    }

    padding-left: 7px;
    padding-top: 7px;

    & + * {
      margin-left: 9px;
    }
  }

  .inputCardCVV {
    background: #fafafa;
    border-radius: 3px;
    width: 40px;
    height: 23px;
    border: none;

    input {
      &.InputElement {
        &::placeholder {
          color: #d4d1d7;
        }
      }
    }

    padding-left: 7px;
    padding-top: 7px;
  }
`;

export const BoxOption = styled.div`
  width: ${({ width }) => (width ? width : "auto")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({ padding }) => (padding ? padding : "30px 20px")};
  border: ${({ isSelected }) =>
    isSelected ? "1px solid #e33aa9" : "1px solid #d4d1d7"};
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: 40px;
  > p {
    font-family: Raleway;
    font-style: normal;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #817393;
    &:first-child {
      font-weight: normal;
      font-size: 36px;
      line-height: 42px;
      color: #603888;
    }
  }
  &:hover {
    border: 1px solid #e33aa9;
  }
`;

export const Figure = styled.figure`
  position: fixed;
  margin: 0;
  top: 40px;
  left: calc(50% + 30px);
`;

export const RowSpaceBetwen = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  & + * {
    margin-top: 5px;
  }

  .icons-share {
    width: 50px;
    display: flex;
    justify-content: space-between;
  }

  &.pay-transfer {
    & + * {
      margin-top: 9px;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;

  p.title {
    font-weight: 500;
    color: #503d66;
  }

  .cardExpand {
    height: 220px !important;
  }

  &.label-heading {
    & + * {
      margin-top: 6px;
    }
  }
`;

export const InputCardNombre = styled.input`
  background: #fafafa;
  border-radius: 3px;
  width: 190px;
  height: 30px;
  border: none;

  &::placeholder {
    color: #d4d1d7;
  }

  & + * {
    margin-left: 9px;
  }
`;

export const ContainerCards = styled.div`
  border: 1px solid #f0eef2;
  box-sizing: border-box;
  border-radius: 7px;
  width: 370px;
  height: 160px;
  padding: 15px;
  margin-top: 50px;

  .title-container {
    display: flex;
    align-items: center;

    svg {
      & + p.title {
        margin-left: 11px;
      }
    }

    & + * {
      margin-top: 26px;
    }
  }

  .title {
    font-family: Avenir Next;
    color: #503d66;
    font-size: 14px;
  }
  .title2 {
    font-family: Avenir Next;
    color: #503d66;
    font-size: 14px;
    margin-bottom: 5px;
  }
  .label {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 21px;
    color: #817393;
  }
  .labelName {
    font-family: Avenir Next;
    color: #817393;
    font-size: 12px;
    width: 200px;
  }
  .labelExpira {
    font-family: Avenir Next;
    color: #817393;
    font-size: 12px;
    width: 85px;
  }
  .labelCVV {
    font-family: Avenir Next;
    color: #817393;
    font-size: 12px;
    width: 45px;
  }

  .not-cards {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #817393;
  }

  @media screen and (max-width: 1406px) {
    & + * {
      margin-top: ºº5px;
    }
  }
`;
