import styled from "styled-components";

export const NotifyMenu = styled.button`
  width: 381px;
  height: calc(100% - 100px);
  position: fixed;
  border: none;
  box-sizing: border-box;
  background-color: #fafafa;
  right: 0;
  bottom: 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 10px;
  display: flex;
  flex-direction: column;
  z-index: 10;
  overflow: auto;

  .containerHead {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .icon-close-container {
      height: fit-content;
      padding: 0;
      background-color: transparent;
      border: none;
    }
  }
  .containerText {
    margin: 5px;
    margin-left: 0;
    display: flex;
  }
  .box {
    margin: 5px;
    margin-left: 5px;
    display: flex;
    flex-direction: column;
  }
  .text24 {
    font-size: 24px;
    font-family: Raleway;
    color: #867586;
  }
  .text14 {
    font-size: 14px;
    font-family: Raleway;
    color: #867586;
  }
  .hidden {
    display: none;
  }
  .textDate {
    margin-left: auto;
    font-family: Raleway;
    color: #6a5880;
  }
  .containerDate {
    display: flex;
    margin: 20px;
  }

  &.notification-bar {
    transition: right 0.7s;
    right: -381px;

    &:focus {
      right: 0;
    }
  }
`;

export const Notification = styled.div`
  border: ${(props) =>
    props.read === 0
      ? "1px solid #E33AA9"
      : "1px solid rgba(149, 147, 173, 0.3)"};
  box-sizing: border-box;
  border-radius: 50px;
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .container {
    display: flex;
    align-items: center;
  }
  .image {
    height: 36px;
    width: 36px;
    border-radius: 50%;
  }
  .text {
    font-size: 12px;
    font-family: Raleway;
    color: #342b3f;
    margin-left: 10px;
    text-align: left;
  }
  .containerPercentage {
    background: #ed9a4d;
    border-radius: 848px;
    width: 93.5px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .textPercentage {
    font-size: 13px;
    font-family: Raleway;
    color: #ffffff;
  }
  :hover {
    cursor: pointer;
  }
`;
