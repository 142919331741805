import React from 'react';
import { useHistory } from 'react-router-dom';
import { TopSide, BoxBtn } from "./styles";
import bags from "../../../../assets/IconComponents/bags.svg";

export default function Top({ isRetailer }) {
  const history = useHistory();

  const handleClick = () => {
    history.push('/profile/invite-providers');
  };

  return (
    <TopSide>
      <p className="title">Agrega tus productos</p>
      {isRetailer && (
        <BoxBtn onClick={handleClick}>
          <figure>
            <img src={bags} alt="" />
          </figure>
          <div>
            <p>Proveedor nuevo</p>
            <p>Agrega a tu nuevo proveedor</p>
          </div>
        </BoxBtn>
      )}
    </TopSide>
  );
}
