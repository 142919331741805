import axios from "axios";
export const setFinancedRetailers = (
  financedRetailers,
  setRetailers,
  currentRetailers
) => {
  let selectedRetailers;
  if (currentRetailers) selectedRetailers = currentRetailers.map((cr) => cr.id);
  setRetailers(
    [...financedRetailers, ...currentRetailers].map((ret) => ({
      name: ret.name,
      id: ret.id,
      checked: selectedRetailers ? selectedRetailers.includes(ret.id) : false,
    }))
  );
};

export const setRegularRetailers = async (
  currentRetailers,
  category,
  setRetailers
) => {
  try {
    const result = await axios({
      method: "get",
      url: `${
        process.env.REACT_APP_RETAILER_ENDPOINT
      }?category=${encodeURIComponent(category)}`,
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    });
    const categoryRetailers = Object.values(JSON.parse(result.data.body).data);
    currentRetailers = currentRetailers.map((item) => item.id);
    const retailerOptions = categoryRetailers.map((retailerOption) => ({
      name: retailerOption.name,
      checked: currentRetailers.includes(retailerOption.id),
      id: retailerOption.id,
    }));

    setRetailers(retailerOptions);
  } catch (err) {
    setRetailers([]);
    console.log(err, "err");
  }
};
