import { NotifyMenu, Notification } from "./styles";

import React, { useRef, useState } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";

import IconBell from "../../../assets/IconComponents/IconBell";
import IconClose from "../../../assets/IconComponents/IconClose";
import notifyDefaultIcon from "../../../assets/defaultImages/notifyDefaultIcon.png";
import { useEffect } from "react";

export default function NotifyBar(props) {
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();
  const months = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];
  let dia = "";

  const [notificationsProp, setNotificationsProp] = useState([]);
  const [closeNotifyBar, setCloseNotifyBar] = useState(false);
  const [pathRedirect, setPathRedirect] = useState();

  const getNotifications = async () => {
    const notificationsResponse = await axios.get(
      process.env.REACT_APP_NOTIFICATIONS_ENDPOINT,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    const notif = JSON.parse(notificationsResponse?.data?.body)?.data;
    setNotificationsProp(notif);
  };

  useEffect(() => {
    getNotifications();
  }, []);

  const notificationClicked = async (id, route) => {
    const data = {
      idNotification: id,
    };
    const notifications = notificationsProp.slice();
    const itemNotification = notifications.find((not) => not.id === id);
    const indexNotification = notifications.indexOf(itemNotification);
    const routeValues = route.split("/");
    const path = window.location.pathname;

    notifications[indexNotification].read = 1;
    props.setNotifications(notifications);
    await axios.put(process.env.REACT_APP_NOTIFICATIONS_ENDPOINT, data, {
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    });

    let redirect;
    const pathLength = path.split("/").length;
    switch (routeValues[0]) {
      case "orders":
        if (path.startsWith("/orders")) {
          if (pathLength === 3) {
            redirect = `${routeValues[1]}`;
          } else if (pathLength === 2) {
            redirect = `orders/${routeValues[1]}`;
          }
        } else {
          redirect = `/${route}`;
        }
        break;
      case "products":
        if (path.startsWith("/products")) {
          if (routeValues.length === 3 && pathLength === 4) {
            redirect = `${routeValues[2]}`;
          } else if (routeValues.length === 3 && pathLength === 3) {
            redirect = `${routeValues[1]}/${routeValues[2]}`;
          }
        } else {
          redirect = `/${route}`;
        }
        break;
      case "tasks":
        redirect = `/tasks`;
        break;
      case "merchants":
        redirect = `merchants`;
        break;
      default:
        break;
    }
    setTimeout(() => {
      props.setCount(false);
    }, 500);
    setPathRedirect(redirect);
  };

  const notifyContainer = useRef();

  useEffect(() => {
    if (notifyContainer?.current || props.notifyMenu)
      notifyContainer?.current?.focus();
  }, [notifyContainer, props.notifyMenu]);

  useEffect(() => {
    if (!closeNotifyBar) return;
    const processID = setTimeout(() => props.setCount(false), 500);
    return () => {
      clearTimeout(processID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeNotifyBar]);

  if (pathRedirect) return <Redirect to={pathRedirect} />;

  return (
    <NotifyMenu
      type="button"
      className="notification-bar"
      ref={notifyContainer}
      onBlur={() => setCloseNotifyBar(!closeNotifyBar)}
    >
      <div className="containerHead">
        <div className="containerText">
          <IconBell />
          <div className="box">
            <p className="text24">Notificaciones</p>
            <p className="text14">
              {day} de {months[month]} {year}
            </p>
          </div>
        </div>
        <button className="icon-close-container">
          <IconClose />
        </button>
      </div>
      {notificationsProp.map((notify, i) => (
        <div
          onClick={() => notificationClicked(notify.id, notify.route)}
          key={i}
        >
          {dia !== notify.date && (
            <div className="containerDate">
              <b className="textDate">{notify.date}</b>
            </div>
          )}
          <div className="hidden">{(dia = notify.date)}</div>
          <Notification read={notify.read}>
            <div className="container">
              <img src={notifyDefaultIcon} className="image" alt="" />
              <p className="text">{notify.message}</p>
            </div>
          </Notification>
        </div>
      ))}
    </NotifyMenu>
  );
}
