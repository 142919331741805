import axios from "axios";
import { useState, useEffect } from "react";
import ReactImageFallback from "react-image-fallback";
import {
  Modal,
  CardSelector,
  StripeCardForm,
} from "contentoh-components-library";
import {
  Container,
  TwoColumns,
  PaymentCard,
  Input,
  RSShoppingTable,
} from "./styles.js";
import { getRetailerImage, currencyFormatter } from "../../../_utils/helper";
import { getUser } from "../../../_utils/data";
import defaultRetailer from "../../../../assets/defaultImages/notifyDefaultIcon.png";
import check from "../../../../assets/IconCheckout/check_circle.svg";
import { icons } from "./utils";

const InputLabel = ({ label, left = "25%", right = "75%", ...props }) => {
  return (
    <TwoColumns left={left} right={right}>
      <label>{label}</label>
      <Input {...props} />
    </TwoColumns>
  );
};

const Third = (props) => {
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [cardSelected, setCardSelected] = useState(null);
  const [cards, setCarts] = useState([]);
  const [modalData, setModalData] = useState({ icon: "warning" });
  const [, setStripe] = useState(null);
  const [card, setCard] = useState({});
  const [token, setToken] = useState("");

  const closeModal = () => setModalData((prev) => ({ ...prev, show: false }));

  const onChange = (evt) => {
    if (evt?.target?.name && evt?.target?.value) {
      const { name, value } = evt.target;
      props.setPaymentData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const setPayment = (type) => {
    if (type !== "card") {
      setCardSelected(null);
      props.setPaymentData((prev) => ({ ...prev, id: null }));
    }
    setPaymentMethod(type);
    props.setPaymentData((prev) => ({ ...prev, paymentMethod: type }));
  };

  useEffect(() => {
    getUser({ query: "isCards=1" })
      .then((r) => {
        const cards = JSON.parse(r.data.body)?.data[3] ?? [];
        setCarts(cards);
      })
      .catch((err) => console.log(err));
  }, []);

  const canCreateOrder = () => {
    const canCreateOT = props.createOT;
    if (!canCreateOT) {
      props.resetPayButton();
      return false;
    }
    if (paymentMethod === null) {
      setModalData((prev) => ({
        ...prev,
        show: true,
        title: "No selecionó ningún método de pago",
        onClickBtnDefault: () => closeModal(),
      }));
      props.resetPayButton();
      return false;
    }
    return true;
  };

  const createPayment = async (paymentRegistration) => {
    const paymentSucceded = await axios.post(
      process.env.REACT_APP_PAYMENTS,
      { paymentRegistration },
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    return JSON.parse(paymentSucceded.data.body).data;
  };

  const createOrderByCard = async () => {
    if (!cardSelected) {
      setModalData((prev) => ({
        ...prev,
        show: true,
        title: "No selecionó ninguna tarjeta",
        onClickBtnDefault: () => closeModal(),
      }));
      props.resetPayButton();
      return;
    }
    const amount = Math.floor(props.generalData.totalPrice * 100);
    const amount_decimal = props.generalData.totalPrice * 100 - amount;
    const { id, customer } = cardSelected;
    const paymentRegistration = {
      id,
      customer,
      amount,
      amount_decimal,
    };
    const isSucceded = await createPayment(paymentRegistration);
    if (isSucceded) {
      props.setPaymentData((prev) => ({
        ...prev,
        ...paymentRegistration,
      }));
      const data = { ...paymentRegistration, amount };
      props.handleSubmit(data, "paymentData");
    } else {
      setModalData((prev) => ({
        ...prev,
        show: true,
        title: "Algo salio mal con el pago...",
        onClickBtnDefault: () => closeModal(),
      }));
      props.resetPayButton();
    }
  };

  const createOrderByNewCard = async () => {
    if (!card.id) {
      setModalData((prev) => ({
        ...prev,
        show: true,
        title: "Datos de la tarjeta invalidos",
        onClickBtnDefault: () => closeModal(),
      }));
      props.resetPayButton();
      return;
    }
    const amount = Math.floor(props.generalData.totalPrice * 100);
    const amount_decimal = props.generalData.totalPrice * 100 - amount;
    const paymentRegistration = {
      id: card.id,
      token,
      amount,
      amount_decimal,
      isNew: 1,
    };
    const isSucceded = createPayment(paymentRegistration);
    if (isSucceded) {
      props.setPaymentData((prev) => ({
        ...prev,
        ...paymentRegistration,
      }));
      const data = { ...paymentRegistration, amount, isNew: 1 };
      props.handleSubmit(data, "paymentData");
    } else {
      setModalData((prev) => ({
        ...prev,
        show: true,
        title: "Algo salio mal con el pago...",
        onClickBtnDefault: () => closeModal(),
      }));
      props.resetPayButton();
    }
  };

  const createOrderByContract = async () => {
    const { company, contractNumber } = props.paymentData;
    const hasCompany = company && company.trim() !== "";
    const hasContractNumber = contractNumber && contractNumber.trim() !== "";
    if (!hasCompany || !hasContractNumber) {
      setModalData((prev) => ({
        ...prev,
        show: true,
        title: "Faltan de llenar algunos datos del contrato",
        onClickBtnDefault: () => closeModal(),
      }));
      props.resetPayButton();
      return;
    }
    props.handleSubmit();
  };

  useEffect(() => {
    if (!canCreateOrder()) return;
    if (paymentMethod === "card") createOrderByCard();
    else if (paymentMethod === "newCard") createOrderByNewCard();
    else if (paymentMethod === "contract") createOrderByContract();
    else props.handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.createOT]);

  return (
    <Container>
      <Modal {...modalData} />
      <div className="payment">
        {/* <PaymentCard>
          <p>Promociones y descuentos</p>
          <InputLabel label="Código de cupón" />
        </PaymentCard> */}
        <PaymentCard
          isSelected={paymentMethod === "contract"}
          onClick={() => setPayment("contract")}
        >
          {paymentMethod === "contract" ? (
            <img src={check} alt="Seleccionado" />
          ) : null}
          <p>Pago por contrato asignado</p>
          <InputLabel onChange={onChange} name="company" label="Empresa" />
          <InputLabel
            onChange={onChange}
            name="contractNumber"
            label="No. de contrato"
            type="number"
          />
        </PaymentCard>
        {cards?.length > 0 ? (
          <CardSelector
            jwt={sessionStorage.getItem("jwt")}
            setCard={setCardSelected}
            isSelected={paymentMethod === "card"}
            onClick={() => setPayment("card")}
          />
        ) : null}
        <StripeCardForm
          isSelected={paymentMethod === "newCard"}
          onClick={() => setPayment("newCard")}
          setCard={setCard}
          setToken={setToken}
          setStripe={setStripe}
        />
        <PaymentCard
          isSelected={paymentMethod === "transfer"}
          onClick={() => setPayment("transfer")}
        >
          {paymentMethod === "transfer" ? (
            <img src={check} alt="Seleccionado" />
          ) : null}
          <p>Pago con transferencia</p>
          <TwoColumns left="35%" right="65%">
            <label>Nombre del banco</label>
            <label className="info">Grupo Financiero Banorte</label>
          </TwoColumns>
          <TwoColumns left="35%" right="65%">
            <label>CLABE</label>
            <label className="info">072180002624585886</label>
          </TwoColumns>
          <TwoColumns left="35%" right="65%">
            <label>Referencia</label>
            <label className="info">14/4/2023 Content-oh!</label>
          </TwoColumns>
        </PaymentCard>
        <PaymentCard
          isSelected={paymentMethod === "cashPickup"}
          onClick={() => setPayment("cashPickup")}
        >
          {paymentMethod === "cashPickup" ? (
            <img src={check} alt="Seleccionado" />
          ) : null}
          <p>Pago en ventanilla</p>
          <TwoColumns left="35%" right="65%">
            <label>Nombre del banco</label>
            <label className="info">Grupo Financiero Banorte</label>
          </TwoColumns>
          <TwoColumns left="35%" right="65%">
            <label>CLABE</label>
            <label className="info">072180002624585886</label>
          </TwoColumns>
          <TwoColumns left="35%" right="65%">
            <label>Referencia</label>
            <label className="info">14/4/2023 Content-oh!</label>
          </TwoColumns>
        </PaymentCard>
      </div>
      <div className="articles">
        <RSShoppingTable>
          <table>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Cadenas</th>
                <th>Servicios</th>
                <th>Precio</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(props.articles).map(([articleId, product]) => {
                const retailersId =
                  Object.keys(product?.retailers)?.map((id) =>
                    id.replace(/.*-/g, "")
                  ) ?? [];
                const services = [];
                Object.values(product?.retailers).forEach((values) =>
                  services.push(...values)
                );
                return (
                  <tr key={articleId}>
                    <td>
                      <p className="product_name">{product.name}</p>
                      {product.upc}
                    </td>
                    <td className="retailers-images">
                      {retailersId.length > 4 && `+${retailersId.length - 4}`}
                      {retailersId.map((id, i) =>
                        i < 4 ? (
                          <ReactImageFallback
                            key={id}
                            alt={props.retailers[id]}
                            src={getRetailerImage(id)}
                            fallbackImage={defaultRetailer}
                            title={props.retailers[id]}
                          />
                        ) : null
                      )}
                    </td>
                    <td className="services-icons">
                      {services.includes("datasheet") && icons("datasheet")}
                      {services.includes("description") && icons("description")}
                      {services.includes("image") && icons("images")}
                    </td>
                    <td>{currencyFormatter().format(product.price.general)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </RSShoppingTable>
      </div>
    </Container>
  );
};

export default Third;
