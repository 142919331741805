import React, { useState } from "react";
import styled from "styled-components";
import { Auth } from "aws-amplify";

// SVG assets
import edit from "../../../assets/IconComponents/edit.svg";
import password from "../../../assets/IconComponents/password.svg";

import {
  Row,
  PasswordStrenght,
  ItemPasswordStrength,
} from "../../Login/styles";

const MainContainer = styled.div`
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .p-5 {
    width: 90%;
    margin-top: 20px;
  }
`;

const Section = styled.div`
  width: 95%;
  border-bottom: 1px solid #e2e2e2;
  padding: 14px 0px;
  p {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #707070;
  }
`;

const Header = styled(Section)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  p {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #817393;
  }
`;

const BasicData = styled(Section)`
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  box-sizing: border-box;
  div {
    display: flex;
    flex-direction: column;
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: -0.015em;
    color: #969696;
    input {
      width: 244px;
      height: 30px;
      padding: 6px;
      border: 1px solid #c4c4c4;
      box-sizing: border-box;
      border-radius: 7px;
      margin-right: 50px;
      ::placeholder {
        color: #cdcdcd;
      }

      & + * {
        margin-top: 5px;
      }
    }
    p:first-child {
      font-size: 12px;
      line-height: 16px;
      color: #707070;
      font-weight: 400;

      & + * {
        margin-top: 5px;
      }
    }
    a {
      text-decoration: none;
      color: #d43594;
      :hover {
        text-decoration: underline;
      }
    }
    button {
      font-size: 18px;
      line-height: 25px;
      color: #ffffff;
      width: 200px;
      height: 40px;
      margin: 15px 0;
      background: #d43594;
      border-radius: 30px;
      border-width: 0;
      :hover {
        cursor: pointer;
      }
    }
    label {
      font-size: 10px;
      color: red;
    }
  }
  .align_right {
    width: 100%;
    display: flex;
    align-items: flex-end;
  }
`;

const TitleIcon = styled.figure`
  margin: 0 15px 0 0;
  align-items: center;
`;

export default function ChangePassword(props) {
  const [passwordMatches, setPasswordMatches] = useState(false);
  const [passwordToShort, setPasswordToShort] = useState(false);
  const [emptyOldPassword, setEmptyOldPassword] = useState(true);
  const [invalidOldPassword, setInvalidOldPassword] = useState(false);
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const [limitExceeded, setLimitExceeded] = useState(false);
  const [errorAws, setErrorAws] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);

  let upperCaseLetters = /[A-Z]/g;
  let numbers = /[0-9]/g;
  let specialChar = /['!','@','#','$','%','^','&','*']/g;

  const updateInfo = (e, newValue) => {
    setPasswordStrength(0);
    if (newValue.length >= 8) {
      setPasswordStrength((passwordStrength) => passwordStrength + 1);
    }
    //Verificar si la contraseña contiene mayuscula, numeros y un caracter especial
    newValue.match(upperCaseLetters) &&
      setPasswordStrength((passwordStrength) => passwordStrength + 1);
    newValue.match(numbers) &&
      setPasswordStrength((passwordStrength) => passwordStrength + 1);
    newValue.match(specialChar) &&
      setPasswordStrength((passwordStrength) => passwordStrength + 1);
  };

  const onSubmit = async (e) => {
    setShowErrors(true);
    setInvalidOldPassword(false);
    setInvalidCredentials(false);
    setErrorAws(false);
    setLimitExceeded(false);

    let valid = true;
    e.preventDefault();

    let oldPassword = document.querySelector("#oldPasswordInput").value;
    let newPassword = document.querySelector("#newPasswordInput").value;
    let confirmPassword = document.querySelector("#confirmPasswordInput").value;

    newPassword.length < 8
      ? setPasswordToShort(true)
      : setPasswordToShort(false);
    confirmPassword !== newPassword
      ? setPasswordMatches(false)
      : setPasswordMatches(true);
    oldPassword === "" ? setEmptyOldPassword(true) : setEmptyOldPassword(false);

    passwordToShort && (valid = false);
    !passwordMatches && (valid = false);
    emptyOldPassword && (valid = false);

    let user = await Auth.currentAuthenticatedUser();
    if (valid) {
      try {
        await Auth.changePassword(user, oldPassword, newPassword);
        await Auth.signOut({ global: true });
        sessionStorage.clear();
        props.history.push({
          pathname: "/login",
        });
        window.location.reload();
      } catch (err) {
        console.log(err);
        if (err.code === "InvalidParameterException") {
          setInvalidOldPassword(true);
        } else if (err.code === "NotAuthorizedException") {
          setInvalidCredentials(true);
        } else if (err.code === "LimitExceededException") {
          setLimitExceeded(true);
        } else {
          setErrorAws(true);
        }
      }
    }
  };
  return (
    <MainContainer>
      <Header>
        <div>
          <TitleIcon>
            <img src={password} alt="Section icon" />
          </TitleIcon>
          <p>Cambiar contraseña </p>
        </div>
      </Header>

      <BasicData>
        <div>
          <p className="title">Contraseña actual</p>
          <input
            type="password"
            placeholder="Ingresa tu contraseña actual"
            id="oldPasswordInput"
          ></input>
          {showErrors && invalidCredentials && (
            <label>Contraseña incorrecta</label>
          )}
          {showErrors && invalidOldPassword && (
            <label>Las contraseñas deben ser de minimo 8 caracteres</label>
          )}
          {showErrors && emptyOldPassword && (
            <label>Ingrese su contraseña actual</label>
          )}
          <p>
            ¿Olvidaste tu contraseña?{" "}
            <a href="#forgot_password">Da click aquí</a>
          </p>
        </div>
      </BasicData>

      <BasicData>
        <div>
          <p className="title">Contraseña nueva</p>
          <input
            onChange={(e) => updateInfo(e, e.target.value)}
            id="newPasswordInput"
            type="password"
            placeholder="Ingresa la contraseña nueva"
          ></input>
          {showErrors && passwordToShort && (
            <label>La contraseña debe ser minimo de 8 caracteres</label>
          )}
        </div>
        <div>
          <p className="title">Confirmar contraseña</p>
          <input
            id="confirmPasswordInput"
            type="password"
            placeholder="Ingresa la contraseña nueva"
          ></input>
          {showErrors && !passwordMatches && (
            <label>La contraseña no coincide</label>
          )}
        </div>
      </BasicData>
      <Row className="passwod-strength p-5">
        <ItemPasswordStrength paso={1}>
          <PasswordStrenght level={1} passwordStrength={passwordStrength} />
          {passwordStrength === 1 && <p>Baja</p>}
        </ItemPasswordStrength>

        <ItemPasswordStrength paso={2}>
          <PasswordStrenght level={2} passwordStrength={passwordStrength} />
          {passwordStrength === 2 && <p>Regular</p>}
        </ItemPasswordStrength>

        <ItemPasswordStrength paso={3}>
          <PasswordStrenght level={3} passwordStrength={passwordStrength} />
          {passwordStrength === 3 && <p>Segura</p>}
        </ItemPasswordStrength>

        <ItemPasswordStrength paso={4}>
          <PasswordStrenght level={4} passwordStrength={passwordStrength} />
          {passwordStrength === 4 && <p>Fuerte</p>}
        </ItemPasswordStrength>
      </Row>

      <BasicData>
        <div className="align_right">
          {showErrors && limitExceeded && (
            <label>Demasiados intentos fallidos, intente más tarde</label>
          )}
          {showErrors && errorAws && (
            <label>Algo ha salido mal, intente más tarde</label>
          )}
          <button onClick={(e) => onSubmit(e)}>Cambiar contraseña</button>
        </div>
      </BasicData>
    </MainContainer>
  );
}
