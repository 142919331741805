import { memo } from "react";
import { CheckBox, Tooltip } from "contentoh-components-library";
import { Container, TwoColumns, ContentTooltipDiscount } from "./styles";
import info from "../../../assets/IconCheckout/info.svg";

const discountMessage = (discount) => {
  let message = {
    0: (
      <p>
        Accede a descuentos por selección de tipos de servicios.
        <br />
        <br />
        10% al seleccionar 2 servicios.
        <br />
        20% al seleccionar 3 servicios
      </p>
    ),
    10: (
      <p>
        Tienes un descuento del 10% por haber seleccionado 2 tipos de servicios.
        <br />
        <br />
        Agrega un servicio más para obtener un 20% de descuento.
      </p>
    ),
    20: (
      <p>
        Tienes un descuento del 20% por haber seleccionado 3 tipos de servicios.
      </p>
    ),
  };
  return (
    <ContentTooltipDiscount>
      <p className="title">Descuento por servicios</p>
      {message[discount]}
    </ContentTooltipDiscount>
  );
};

const RetailerItem = ({ product, articleId, ...props }) => {
  const hasDiscount = product.full > product.price;
  return (
    <Container>
      <TwoColumns>
        <div className="product__info">
          {props.selectArticle ? (
            <CheckBox
              name={`select-${articleId}`}
              id={`select-${articleId}`}
              onChange={props.selectArticle}
              label={
                <>
                  <span className="name">{product.name}</span>
                  <p className="remove__margin">
                    <span className="upc">UPC: {product.upc}</span>
                    <span className="upc">
                      {product.totalRetailers} cadenas
                    </span>
                  </p>
                </>
              }
              checked={props.isSelected}
            />
          ) : (
            <label>
              <p>
                <span className="name">{product.name}</span>
              </p>
              <p className="remove__margin">
                <span className="upc">UPC: {product.upc}</span>
                <span className="upc">{product.totalRetailers} cadenas</span>
              </p>
            </label>
          )}
        </div>
        <div className="price">
          <Tooltip
            componentTooltip={discountMessage(product.discount)}
            children={<img src={info} alt="Info" />}
            classNameTooltip="container-tooltip"
            position="topCenter"
          />
          {product.discount > 0 ? (
            <div className="discount">{product.discount}%</div>
          ) : null}
          <div className="group__price">
            {hasDiscount ? (
              <span className="full">
                {new Intl.NumberFormat("es-MX", {
                  style: "currency",
                  currency: "MXN",
                }).format(product.full)}
              </span>
            ) : null}
            <span className={hasDiscount ? "discountText" : ""}>
              {new Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(product.price)}
            </span>
          </div>
        </div>
      </TwoColumns>
    </Container>
  );
};

export default memo(RetailerItem);
