import styled from "styled-components";
import closeIcon from "../images/surveyPopUp/genericModalClose.svg";

export const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(59, 59, 59, 0.53);
  backdrop-filter: blur(4px);
  z-index: 15;
  //background: #281f3366;
  .global-styles {
    background-color: #503d66;
    height: auto;
    width: 400px;
    position: relative;
    border-radius: 20px;
    padding: 40px 40px;
    text-align: center;
    .close-button {
      background-image: url(${closeIcon});
      background-color: transparent;
      width: 14px;
      height: 14px;
      cursor: pointer;
      border: none;
      position: absolute;
      right: 20px;
      top: 20px;
    }
    .title {
      font-family: "Raleway";
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 42px;
      color: white;
      & + * {
        margin-top: 30px;
      }
    }
    p {
      font-family: "Raleway";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: white;
      & + * {
        margin-top: 25px;
      }
    }
    .emojis-experiences {
      display: flex;
      justify-content: space-between;
      button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        opacity: 0.4;
        img {
          width: 75px;
        }
        &.emoji-select {
          opacity: 1;
        }
      }
      & + * {
        margin-top: 27px;
      }
    }
    textarea {
      display: block;
      width: 95%;
      height: 80px;
      border-radius: 10px;
      font-family: "Raleway";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 17px;
      color: #d4d1d7;
      border: solid 1px #d4d1d7;
      padding: 10px;
      outline: none;
      background-color: transparent;
      resize: none;
      ::-webkit-scrollbar-track {
        background: transparent; /* color of the tracking area */
      }
      ::placeholder {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: #d4d1d7;
      }
      :focus {
        border: 1px solid #e33aa9;
        background-color: #817393;
      }
      & + * {
        margin-top: 30px;
      }
    }
    #send-button {
      background: transparent;
      border: solid 1px #d4d1d7;
      color: #d4d1d7;
      border-radius: 30px;
      padding: 10px 18px;
      font-family: "Avenir Next";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;
    }
    .send-button-enable {
      background: #e33aa9 !important;
      border: none !important;
      color: white !important;
      cursor: pointer;
    }
  }
`;
