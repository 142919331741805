import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ProfileImg = styled.figure`
  height: 24px;
  width: 24px;
  background: #f0eef2;
  border-radius: 50%;
  img {
    border: 0;
    height: 24px;
    width: 24px;
    border-radius: 50%;
  }

  & + p {
    margin-left: 10px;
  }
`;

export const UsersList = styled.div`
  position: absolute;
  top: 10px;
  left: 100%;
  z-index: 2;
  white-space: normal;
  font-family: Avenir Next;
  font-style: normal;
  overflow-y: auto;
  width: 175px;
  background: #f0eef2;
  border-radius: 3px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;

  .users,
  .send {
    overflow-y: auto;
    max-height: 90px;

    .assignation {
      display: flex;
      align-items: center;
      padding: 10px;
      box-sizing: border-box;

      &:hover {
        background-color: lightgray;
      }
    }
  }

  .send {
    max-height: initial;
    border-top: 1px solid #d4d1d7;
    p {
      text-align: left;
      span {
        color: #503d66;
        display: block;
      }
    }
    img {
      margin-left: auto;
      margin-right: 0;
    }
  }
`;
