import CheckBox from "../CheckBox/index";

export default function SelecItem({
  option, // {id , name , isSelected}
  customSelectId,
  onClickItem,
}) {
  return (
    <div className="option-container" key={customSelectId + option.id}>
      <div className="main-item">
        <CheckBox
          id={"main-item-" + option.id}
          label={option.name}
          checked={option.isSelected}
          onChange={(e) => {
            //e.preventDefault() // evitar el checked auto
            if (onClickItem) onClickItem(e.target.checked, option.id);
          }}
        />
      </div>
    </div>
  );
}
