import { ButtonsContainer } from "./styles";

// iconos
import editIcon from "../iconsFolder/edit-circle.svg"; // editar
import downloadIcon from "../iconsFolder/DownloadIcon.svg"; // download
import MoreDataIcon from "../iconsFolder/LoadProducts.svg"; // load
import TrashIcon from "../iconsFolder/IconTrash.svg"; // delete
import AddToCartIcon from "../../../assets/IconComponents/carrito.svg"; //add carrito
import spinner from "../iconsFolder/Spinner.gif"; // animacion spinner
import { faPlus as addIcon } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import gridLayout from "../../../assets/Layouts/gridLayout.svg";
import rowLayout from "../../../assets/Layouts/rowLayout.svg";

import { useState, useEffect, useRef } from "react";
import GenericModal from "../../general/GenericModal";
import { Redirect } from "react-router-dom";
import CustomDropDown, {
  classCustomDropDown,
} from "../../custom/CustomDropdown";
import Features from "../../Features";

export const OptionsButtons = (props) => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const membership = user.membership;

  const canExport = membership.planID !== 1;
  const canEdit = membership.planID !== 1;
  const taskFilterOff = !props.taskFilter;
  const [showMessage, setShowMessage] = useState(false);
  const [redirect, setRedirect] = useState();
  const handleGridToggle = () => {
    props.setGridView((prev) => {
      const newView = !prev;
      return newView;
    });
  };
  const [isModalOpen, setModalOpen] = useState(true);
  const buttonRef = useRef(null);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return redirect ? (
    <Redirect to="upgradeplan" />
  ) : (
    <ButtonsContainer>
      {/* boton agregar */}
      {props.buttonAdd && (
        <CustomDropDown
          show={props.buttonAdd.showHiddenComponent ?? false}
          position={classCustomDropDown.POSITION_BOTTOM_START}
          className={"tooltip-btnAdd"}
          onClickAway={
            props.buttonAdd.onClickAway && props.buttonAdd.onClickAway
          }
          hiddenComponent={
            props.buttonAdd.hiddenComponent && props.buttonAdd.hiddenComponent
          }
        >
          <button
            className="circleIcon"
            onClick={(event) => {
              if (props.buttonAdd.onClick) props.buttonAdd.onClick(event);
            }}
          >
            <i>
              <Icon icon={addIcon} />
            </i>
          </button>
        </CustomDropDown>
      )}

      {/* boton editar */}
      {props.editProducts && (
        <button
          className="circle"
          onClick={() => {
            if (canEdit) {
              // funcionalidad del boton
              props.editProducts && props.editProducts();
            } else setShowMessage(true);
          }}
        >
          <img src={editIcon} alt="boton editar" />
        </button>
      )}

      {/* boton eliminar */}
      {props.deleteArticles && (
        <button
          className="circle redHoverButton"
          onClick={() => props.deleteArticles()}
        >
          <img src={TrashIcon} alt="boton eliminar" />
        </button>
      )}

      {/* boton cargar mas datos */}
      {props.loadingLoadMore && taskFilterOff ? (
        <button className="circle">
          <img src={spinner} alt="boton descargar" />
        </button>
      ) : (
        props.loadMoreProducts &&
        taskFilterOff && (
          <button className="circle" onClick={() => props.loadMoreProducts()}>
            <img src={MoreDataIcon} alt="cargar mas datos" />
          </button>
        )
      )}

      {/* boton descargar */}
      {props.download &&
        (props.loadingExportables && taskFilterOff ? (
          <button className="circle">
            <img src={spinner} alt="Download products" />
          </button>
        ) : (
          taskFilterOff && (
            <button className="circle">
              <img
                src={downloadIcon}
                alt="Download products"
                onClick={() => props.download()}
              />
            </button>
          )
        ))}

      {props.addToCart && (
        <button
          className="circle pink"
          onClick={(evt) => {
            evt.preventDefault();
            props.addToCart();
          }}
        >
          <img src={AddToCartIcon} alt="boton agregar al carrito" />
        </button>
      )}

      <button
        className="circleIcon grid"
        onClick={() => props.setGridView(!props.gridView)}
      >
        <img
        className="grid-icon"
          src={!props.gridView ? gridLayout : rowLayout}
          alt="Edit products"
        />
      </button>

      {/* boton exportar */}
      {taskFilterOff && !props.hideExportButton && (
        <button
          className="export-button"
          onClick={() => {
            if (canExport) props.exportProducts();
            else setShowMessage(true);
          }}
        >
          Exportar
        </button>
      )}

      {/* modal informativo con 2 botones de accion */}
      {showMessage && (
        <GenericModal
          close={() => setShowMessage(false)}
          message="Tu plan actual no te permite utilizar esta función"
          detail="¿Quieres actualizarlo?"
          button1={{ name: "Cancelar", action: () => setShowMessage(false) }}
          button2={{
            name: "Actualizar",
            action: () => {
              setShowMessage(false);
              setRedirect(true);
            },
          }}
        />
      )}
    </ButtonsContainer>
  );
};
