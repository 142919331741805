import React, { useEffect, useState, useRef, useReducer } from "react";
import GeneralProducts from "./tables/GeneralProducts";
import { useLocation } from "react-router-dom";
import Loading from "../../general/loading";
import { FilterInput } from "../../filterInput/index";
import ProductDetail from "../productDetail/index";
import { TableWarning } from "../retailerProducts/styles";
import { Container, ContainerFilters } from "./styles";
import {
  fetchAddArticlesList,
  fetchDeleteArticlesList,
  getProducts,
  getUsers,
  updateSessionStorageProduct,
} from "./utils/productsHandler";
import { getPercentage } from "../../_utils/data";

import ContainerMain from "./filtersSelect";
import IconGridViewWhite from "./icons/iconGridViewWhite.svg";
import IconGridViewGray from "./icons/iconGridViewGray.svg";
import axios from "axios";

import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faSpinner as LoadingIcon } from "@fortawesome/free-solid-svg-icons";
import ErrorModalIcon from "../../../assets/IconComponents/errorModal.svg";
import OkModalIcon from "../../../assets/IconComponents/genericModalCheck.svg";
import Modal from "../../merchantsLists/Modal/Modal";

import {
  NOTIFICATION_SUCCESSFUL,
  NOTIFICATION_ERROR,
} from "../../custom/CustomNotification";
import CustomNotification from "../../custom/CustomNotification";
import CustomSelectListMultiple from "./customSelectListMultiple";
import { fetchPOST } from "../../_utils/handle_http";
import DropdownScenary from "./DropdownScenary";
import Visibility from "./Visibility";
const articleListReducer = (state, action) => {
  const tempState = { ...state };
  switch (action.action) {
    case "init":
      return action.value;
    case "addList": {
      const { articleId, list } = action;
      if (!tempState[articleId].lists.some((l) => l.id === list.id)) {
        tempState[articleId].lists.push(list);
      }
      return tempState;
    }
    case "addMultipleArtilceList": {
      const { articlesId, list } = action;
      articlesId.forEach((articleId) => {
        if (!tempState[articleId].lists.some((l) => l.id === list.id)) {
          tempState[articleId].lists.push(list);
        }
      });
      return tempState;
    }
    case "removeList": {
      const { articleId, listId } = action;
      tempState[articleId].lists = tempState[articleId].lists.filter(
        (l) => l.id !== listId
      );
      return tempState;
    }
    case "removeMultipleArtilceList": {
      const { articlesId, listId } = action;
      articlesId.forEach((articleId) => {
        tempState[articleId].lists = tempState[articleId].lists.filter(
          (l) => l.id !== listId
        );
      });
      return tempState;
    }
    case "updateVisibility": {
      const { articlesId, isVisible } = action;
      articlesId.forEach((articleId) => {
        tempState[articleId].article.isVisible = isVisible;
      });
      return tempState;
    }
    case "updateScenary": {
      const { articlesId, stage } = action;
      articlesId.forEach((articleId) => {
        tempState[articleId].stage = stage;
      });
      return tempState;
    }
    default:
      return tempState;
  }
};

export default function MerchantsProducts(props) {
  window.history.replaceState({}, document.title);
  const isRetailer = JSON.parse(sessionStorage.getItem("user")).is_retailer;
  const dataCompany = JSON.parse(sessionStorage.getItem("company"));
  const [listType] = useState(props.type);
  const [articlesList, setArticlesList] = useState();
  const [originalList, setOriginalList] = useReducer(articleListReducer, {});

  const [productSelected, setProductSelected] = useState([]);
  const [productsSelected, setProductsSelected] = useState([]);

  const [modalClass, setModalClass] = useState(null);
  const [addedPopUp, setAddedPopUp] = useState(null);
  const wrapperRef = useRef(null);
  const [stateFlags, setStateFlags] = useState({
    isLoading: true,
    showProductDetail: false,
    productsLoaded: false,
    addedToCart: false,
    loadFilters: false,
    warningMessage: "No hay productos disponibles",
  });
  const [showGridView, setShowGridView] = useState(true); // modo vista grid/table
  const [lists, setLists] = useState([]);
  const [providersList, setProvidersList] = useState([]);
  const [departmentsList, setDepartmentsList] = useState([]);
  const [priceRange, setPriceRange] = useState({
    min: undefined,
    max: undefined,
  });
  const [filters, setFilters] = useState({
    upc: [],
    list: [],
    provider: [],
    department: [],
    price: { min: undefined, max: undefined },
    percentage: { min: undefined, max: undefined },
  });
  const [isVisibleModalInputAddList, setVisibleModalInputAddList] =
    useState(false); // config del modal editar nombre lista
  const [valueInputAddList, setValueInputAddList] = useState("");
  const [isLoadingBtnAddList, setIsLoadingBtnAddList] = useState(false); // ver comp cargando?
  const [modalAlert, setModalAlert] = useState({
    // config del modal mensaje/preguntas
    show: false,
    title: "",
    message: "",
    onClickBtnClose: () => {},
    button1: { type: "btnTransparent", name: "Entendido", action: () => {} },
    button2: undefined,
    icon: undefined,
  });
  const locationRedirect = useLocation();

  /*==================================================
  MANEJO DE LAS CUSTOM NOTIFICATIONS
  ==================================================== */
  const [notifications, setNotifications] = useState([]);
  const [delete_notificationId, setDelete_notificationId] = useState("");
  const [deleteNotifications, setDeleteNotifications] = useState([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const removeNotification = (notificationId) => {
    const refreshNotifications = notifications.filter((n) => {
      return !deleteNotifications.includes(n.id) && n.id !== notificationId;
    });
    let deleteNotis = deleteNotifications.slice();
    if (deleteNotis.findIndex((id) => id === notificationId) === -1)
      deleteNotis.push(notificationId);
    setDeleteNotifications(deleteNotis);
    setNotifications(refreshNotifications);
  };
  useEffect(() => {
    if (delete_notificationId === "") return;
    removeNotification(delete_notificationId);
    setDelete_notificationId("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delete_notificationId]);

  const showNotification = (id, type, message) => {
    notifications.push({ id, type, message });
    setNotifications(notifications.slice());
    setTimeout(() => {
      setDelete_notificationId(id);
    }, 6500);
  };
  /*==================================================
  	FIN DEL MANEJO DE LAS CUSTOM NOTIFICATIONS
  ==================================================== */

  const calcPercentage = async (initialList) => {
    const data = [];
    const obj = {};
    const hasOnboarding = dataCompany?.financedRetailers?.find((item) =>
      [68, 74].includes(item.id)
    );
    const retailersAvailable = hasOnboarding
      ? [hasOnboarding.id]
      : dataCompany.financedRetailers.map(({ id }) => id);

    initialList.forEach((e) => {
      data.push({
        ...e,
        id_article: e.article.id_article,
        id_category: e.article.id_category,
        upc: e.article.upc,
        name: e.article.name,
        retailersAvailable,
      });
      obj[e.article.id_article] = e;
    });
    const query = { onBoarding: true };
    const [res] = await getPercentage({ data, query });

    Object.entries(res).forEach(([articleID, percentages]) => {
      retailersAvailable.forEach((id) => {
        if (!percentages[id]) return;
        const { percentageGeneral, percentageRequired } = percentages[id];
        obj[articleID].percentage = Number(percentageGeneral);
        obj[articleID].percentageRequired = Number(percentageRequired);
      });
    });

    const articleList = { ...obj };
    if (isRetailer) {
      Object.entries(obj).forEach(([key, value]) => {
        if (value.percentageRequired !== 100) delete articleList[key];
      });
    }
    setOriginalList({ action: "init", value: articleList });
    return articleList;
  };

  // obtener productos
  useEffect(() => {
    const fetchProducts = async () => {
      const bodyBack = await getProducts();
      // obtener la lista de productos
      let productsList = [];
      if (bodyBack?.objArticles)
        productsList = Object.values(bodyBack.objArticles);
      calcPercentage(productsList);

      // obtener todas las listas merchants
      let listsMerchants = bodyBack?.list ?? [];
      listsMerchants = listsMerchants.sort((listA, listB) => {
        if (listA.name.toLowerCase() <= listB.name.toLowerCase()) return -1;
        else return 1;
      });
      setLists(listsMerchants);

      // obtener los proveedores
      const providers = {};
      productsList.forEach(({ article }) => {
        const id = article.company_id;
        if (!providers[id]) providers[id] = { id, name: article.company_name };
      });
      setProvidersList(Object.values(providers));

      // obtener los departamentos  (v2)
      const categories = await getCategories();
      categories.sort((catA, catB) => {
        let nameA = catA.name.toLowerCase();
        let nameB = catB.name.toLowerCase();
        if (nameA < nameB) return -1;
        else return 1;
      });

      const userDepartments = await fetchUsers();
      userDepartments.sort((catA, catB) => {
        let nameA = catA.name.toLowerCase();
        let nameB = catB.name.toLowerCase();
        if (nameA < nameB) return -1;
        else return 1;
      });

      if (userDepartments.length > 0) {
        setDepartmentsList(userDepartments);
      } else {
        setDepartmentsList(categories);
      }

      // obtener el precio min y max de los productos
      let prices = { min: 0, max: undefined };
      productsList.forEach((prod) => {
        let priceProd = undefined;
        if (!isNaN(Number(prod.data?.price)))
          priceProd = Number(prod.data.price);
        if (priceProd !== undefined) {
          //if(prices.min === undefined) prices.min = priceProd
          //else if(priceProd < prices.min) prices.min = priceProd

          if (prices.max === undefined) prices.max = priceProd;
          else if (priceProd > prices.max) prices.max = priceProd;
        }
      });
      setPriceRange({ min: prices.min, max: prices.max });
    };
    fetchProducts();
    if (props?.state?.listId) {
      setFilters((prev) => ({ ...prev, list: [props.state.listId] }));
      props.setLocation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchUsers = async () => {
    const dataUsers = await getUsers();
    const categoriesKeys = Object.keys(dataUsers || {});
    const subCategories = categoriesKeys?.map(
      (key) => dataUsers[key].sub_category
    );
    const finalArray = [];
    const arrayCategories = [];
    let index = 0;
    subCategories?.forEach((element) => {
      element &&
        Object.keys(element || {}).forEach((key) => {
          const subCategory = element[key]?.sub_category || {};
          const subOptions = [];
          Object.keys(subCategory)?.forEach((subKey) => {
            arrayCategories.push(subCategory[subKey].id_category);
            subOptions.push({
              id: subCategory[subKey].id_category,
              name: subKey,
            });
          });
          finalArray.push({ id: index++, name: key, subOptions });
        });
    });
    return finalArray;
  };

  // modal ProductDetail
  useEffect(() => {
    if (stateFlags.showProductDetail) {
      setTimeout(() => {
        setModalClass("modal-active");
        document.addEventListener("click", closeModal, false);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateFlags.showProductDetail]);

  // cuando cambia algun filtro o se cargan los productos
  useEffect(() => {
    if (!originalList) return;
    const articlesInitial = Object.values(originalList ?? {});
    if (articlesInitial.length === 0) {
      setStateFlags((prev) => ({ ...prev, isLoading: false }));
      return;
    }
    setStateFlags((prev) => ({
      ...prev,
      isLoading: true,
      warningMessage: "Ningun producto coincide con los filtros aplicados",
    }));

    // aplicar filtros
    const tempList = articlesInitial.filter((prod) => {
      // ver si pasa el filtro por UPC
      if (filters.upc.length > 0) {
        if (!filters.upc.includes(prod.article?.upc)) return false;
      }
      // ver si pasa el filtro por listas
      if (filters.list.length > 0) {
        const prodLists = prod.lists.map(({ id }) => id) ?? [];
        return filters.list.some((l) => prodLists.includes(Number(l)));
      }

      // ver si pasa el filtro por proveedores
      if (filters.provider.length > 0) {
        const providerID = prod.article?.company_id ?? -1;
        let filterProvider = filters.provider.some(
          (p) => providerID === Number(p)
        );
        if (!filterProvider) return false;
      }

      // ver si pasa el filtro por departamentos
      if (filters.department.length > 0) {
        const departmentID = prod.article?.id_category ?? -1;
        let filterDepartment = filters.department.some((d) => {
          if (departmentID === d) return true;
          return false;
        });
        if (!filterDepartment) return false;
      }

      // ver si pasa el filtro por precios
      let priceProd = 0;
      if (!isNaN(Number(prod.data?.price))) priceProd = Number(prod.data.price);
      if (filters.price.min !== undefined && priceProd < filters.price.min)
        return false;
      if (filters.price.max !== undefined && priceProd > filters.price.max)
        return false;

      // ver si pasa el filtro por porcentajes
      let percentageProd = 0;
      if (!isNaN(Number(prod.percentage)))
        percentageProd = Number(prod.percentage);
      if (
        filters.percentage.min !== undefined &&
        percentageProd < filters.percentage.min
      )
        return false;
      if (
        filters.percentage.max !== undefined &&
        percentageProd > filters.percentage.max
      )
        return false;

      // se filtra si pasa todos los filtros
      return true;
    });
    console.log({ tempList });
    setArticlesList(tempList);
    setStateFlags((prev) => ({ ...prev, isLoading: false }));
  }, [filters, originalList]);

  // funcion que se ejecuta al dar clic en un item lista de una tarjeta producto
  // para agregar o eliminar una lista de un producto
  const onClickItemListArticle = async (
    isChecked,
    articleId,
    listId,
    setIsSeletedItem,
    countUnique
  ) => {
    let namelist = "";
    const IDnoti = "noti-" + articleId + "-" + countUnique;
    lists.forEach((l) => {
      if (l.id === listId) namelist = l.name;
    });
    if (isChecked) {
      // add list
      const response = await fetchAddArticlesList(listId, [articleId]);
      if (response.data) {
        // successful
        // buscar la info de la lista
        let list = lists.find((l) => l.id === listId);
        // actualizar array
        setOriginalList({ action: "addList", articleId, list });
        showNotification(
          IDnoti,
          NOTIFICATION_SUCCESSFUL,
          `Producto agregado a la lista "${namelist}"`
        );
      } else {
        setIsSeletedItem(listId, false);
        showNotification(
          IDnoti,
          NOTIFICATION_ERROR,
          `El producto NO pudo ser agregado a la lista "${namelist}"`
        );
      }
    } else {
      // delete list
      const response = await fetchDeleteArticlesList(listId, [articleId]);
      if (response.data) {
        // actualizar array
        setOriginalList({ action: "removeList", articleId, listId });
        showNotification(
          IDnoti,
          NOTIFICATION_SUCCESSFUL,
          `Producto eliminado de la lista "${namelist}"`
        );
      } else {
        setIsSeletedItem(listId, true);
        showNotification(
          IDnoti,
          NOTIFICATION_ERROR,
          `El producto NO pudo ser eliminado de la lista "${namelist}"`
        );
      }
    }
  };

  // funcion que elimina una lista del array de listas de un articulo
  const delete_multipleArticlesLists = async (listsId, unique, selectID) => {
    const productsList = productsSelected.slice();
    let valueUnique = unique;

    // recorrer cada lista seleccionada
    for (let listId of listsId) {
      let namelist = lists.find((l) => l.id === listId)?.name;
      const response = await fetchDeleteArticlesList(listId, productsList);
      if (response.data) {
        // successful
        // eliminar lista en los prods
        setOriginalList({
          action: "removeMultipleArtilceList",
          articlesId: productsList,
          listId,
        });
        showNotification(
          `${selectID}-${valueUnique++}`,
          NOTIFICATION_SUCCESSFUL,
          `Productos eliminados de la lista "${namelist}"`
        );
      } else {
        //setIsSeletedItem(listId , true)
        showNotification(
          `${selectID}-${valueUnique++}`,
          NOTIFICATION_ERROR,
          `Los productos NO pudieron ser eliminados de la lista "${namelist}"`
        );
      }
    }
    setProductsSelected([]);
  };

  // funcion que agrega una lista al array de listas de un articulo
  const add_multipleArticlesLists = async (listsId, unique, selectID) => {
    const productsList = productsSelected.slice();
    let valueUnique = unique;

    // recorrer cada lista seleccionada
    for (let listId of listsId) {
      let list = lists.find((l) => l.id === listId);
      const response = await fetchAddArticlesList(listId, productsList);
      if (response.data) {
        // add lista en los prods
        setOriginalList({
          action: "addMultipleArtilceList",
          articlesId: productsList,
          list,
        });
        showNotification(
          selectID + "-" + valueUnique++,
          NOTIFICATION_SUCCESSFUL,
          `Productos agregados a la lista "${list.name}"`
        );
      } else {
        //setIsSeletedItem(listId , true)
        showNotification(
          selectID + "-" + valueUnique++,
          NOTIFICATION_ERROR,
          `Los productos NO pudieron ser agregados a la lista "${list.name}"`
        );
      }
    }
    // actualizar array
    setProductsSelected([]);
  };

  // funcion que selecciona o desmarca todos los productos en modo tabla
  const onClickSelectAllProducts = (isChecked) => {
    if (isChecked) {
      // selected all
      const productsID = articlesList.map((prod) => prod.article.id_article);
      setProductsSelected(productsID);
    } else {
      setProductsSelected([]);
    }
  };

  const getCategories = async () => {
    const company = JSON.parse(sessionStorage.getItem("company"));
    const key = company.financedRetailers ? "financedRetailers" : "retailers";

    const query =
      isRetailer || key === "financedRetailers"
        ? `?id=${encodeURIComponent(
            company[key].map((ret) => ret.id).join(",")
          )}`
        : "";
    const response = await axios.get(
      `${process.env.REACT_APP_CATEGORY_ENDPOINT}${query}`,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    const categories = JSON.parse(response.data.body)?.data;
    const categoriesKeys = Object.keys(categories || {});
    const subCategories = categoriesKeys?.map(
      (key) => categories[key].sub_category
    );
    const finalArray = [];
    let index = 0;
    subCategories?.forEach((element) => {
      element &&
        Object.keys(element || {}).forEach((key) => {
          const subCategory = element[key]?.sub_category || {};
          const subOptions = [];
          Object.keys(subCategory)?.forEach((subKey) => {
            subOptions.push({
              id: subCategory[subKey].id_category,
              name: subKey,
            });
          });
          finalArray.push({ id: index++, name: key, subOptions });
        });
    });
    return finalArray;
  };

  const rowClicked = (product) => {
    const formattedProduct = updateSessionStorageProduct(product);
    setProductSelected(formattedProduct);
    setStateFlags((prev) => ({ ...prev, showProductDetail: true }));
  };

  const renderTable = () => {
    const articles = articlesList?.slice();
    if (articles === undefined)
      return (
        <div className="full-size">
          <Icon className="fa-pulse" icon={LoadingIcon} />
        </div>
      );
    if (articles.length === 0)
      return <TableWarning>{stateFlags.warningMessage}</TableWarning>;

    const tableProps = {
      eventsFunctions: {
        rowClicked: rowClicked,
        onClickSelectAllProducts: onClickSelectAllProducts,
        onClickItemList: onClickItemListArticle,
        setProductsSelected: setProductsSelected,
        setLists: setLists,
      },
      articlesList: articles,
      viewMode: showGridView,
      productsSelected: productsSelected,
      allLists: lists,
      section: "merchants",
      showNotification: showNotification,
      setProductsSelected: setProductsSelected,
      isShowModal: modalAlert.show || isVisibleModalInputAddList,
      showModalAlert,
      setModalAlert,
      updateVisibility: (articlesId, isVisible) => {
        setOriginalList({ action: "updateVisibility", articlesId, isVisible });
      },
      updateScenary: (articlesId, stage) => {
        setOriginalList({ action: "updateScenary", articlesId, stage });
      },
      listsMenu_componentFooter: (
        <button
          disabled={isLoadingBtnAddList}
          className="btnAddList"
          name="favorito"
          type="button"
          onClick={() => {
            setValueInputAddList("");
            setVisibleModalInputAddList(true);
          }}
        >
          {isLoadingBtnAddList ? (
            <i>
              <Icon className="fa-pulse" icon={LoadingIcon} />
            </i>
          ) : (
            "Agregar nueva lista"
          )}
        </button>
      ),
      section: "merchants",
    };
    return <GeneralProducts {...tableProps} />;
  };

  const closeModal = (e) => {
    if (
      (!e.target.closest("#product-detail") && stateFlags.showProductDetail) ||
      e.target.closest(".close-button")
    ) {
      setModalClass(null);
      document.removeEventListener("click", closeModal, false);
      setTimeout(
        () => setStateFlags((prev) => ({ ...prev, showProductDetail: false })),
        500
      );
    }
  };

  const compareArrayValuesFilter = (nameFilter, newValue = []) => {
    let current = filters[nameFilter].slice();
    let a = [];
    let b = [];
    if (newValue.length >= current.length) {
      a = newValue;
      b = current;
    } else {
      a = current;
      b = newValue.slice();
    }
    let equals = true;
    for (let aItem of a) {
      const index = b.findIndex((itemB) => itemB === aItem);
      if (index === -1) {
        equals = false;
        break;
      }
    }
    return equals;
  };

  // quitar items repetidos de un array
  const removeItemsRepeat = (values = []) => {
    let obj = {};
    values.forEach((v) => {
      if (!obj[`${v}`]) obj[`${v}`] = {};
    });
    let VALUES = Object.keys(obj);
    return VALUES;
  };

  // funcion que actualiza un filtro al cumplir las siguientes condiciones:
  //	--el nombre del filtro existe como key
  //	--los values son diferentes
  const updateFilters = (nameFilter, newValues) => {
    if (Object.keys(filters).includes(nameFilter)) {
      if (Array.isArray(newValues)) {
        !compareArrayValuesFilter(nameFilter, newValues) &&
          setFilters({
            ...filters,
            [nameFilter]: removeItemsRepeat(newValues),
          });
      } else {
        setFilters({ ...filters, [nameFilter]: newValues });
      }
    }
  };

  //======================================================================
  // MANEJO DEL MODAL PARA CREAR NUEVA LISTA
  //=======================================================================
  const onClickAddList = async () => {
    const showModalInputAddList = () => {
      hideModalAlert();
      setVisibleModalInputAddList(true);
    };
    setVisibleModalInputAddList(false);

    const newNameList = valueInputAddList.trim();
    setValueInputAddList(newNameList);
    // cuando el new name list esta vacio
    if (newNameList === "") {
      showModalAlert(
        "Nuevo nombre de la lista esta vacio",
        "Por favor ingrese un nombre valido o haga click en Cancelar",
        showModalInputAddList,
        {
          type: "btnTransparent",
          name: "Entendido",
          action: showModalInputAddList,
        },
        undefined,
        ErrorModalIcon
      );
      return;
    }

    // mandamos a crear la lista
    setIsLoadingBtnAddList(true);
    const paramsBody = {
      nameList: newNameList,
    };
    const paramsHeaders = {
      Authorization: sessionStorage.getItem("jwt"),
    };
    const respCreate = await fetchPOST(
      process.env.REACT_APP_LIST_MERCHANTS_ENDPOINT,
      paramsBody,
      paramsHeaders
    );

    //esto sucede cuando se crea en la BD
    if (respCreate.body) {
      const refreshLists = async () => {
        setValueInputAddList(""); // limpiar el input
        hideModalAlert(); // ocultar modal
        // agregar la nueva lista al array original
        let currentLists = lists.map((list) => ({ ...list }));
        currentLists.push({
          id: respCreate.body.listId,
          name: newNameList,
          is_editable: 1,
        });
        // ordenar las listas alfabeticamente
        currentLists = currentLists.sort((listA, listB) => {
          if (listA.name.toLowerCase() < listB.name.toLowerCase()) return -1;
          else return 1;
        });
        setLists(currentLists);
      };

      // indicar que se creo
      setIsLoadingBtnAddList(false);
      showModalAlert(
        "Tu lista ha sido creada",
        respCreate.message ? respCreate.message : "",
        refreshLists,
        { type: "btnTransparent", name: "Entendido", action: refreshLists },
        undefined,
        OkModalIcon
      );
    } else {
      // cuando ocurrio un error y no se creo
      setIsLoadingBtnAddList(false);
      showModalAlert(
        respCreate.message,
        respCreate.errorDetail,
        hideModalAlert,
        { type: "btnTransparent", name: "Entendido", action: hideModalAlert },
        undefined,
        ErrorModalIcon
      );
    }
  };

  const hideModalAlert = (event) => {
    setModalAlert({
      show: false,
      title: "",
      message: "",
      onClickBtnClose: () => {},
      button1: undefined,
      button2: undefined,
      icon: undefined,
    });
  };

  const showModalAlert = (
    title,
    message,
    onClickBtnClose,
    button1 = {
      type: "btnTransparent",
      name: "Entendido",
      action: hideModalAlert,
    },
    button2 = undefined,
    icon = undefined
  ) => {
    const buttons = [];
    if (button1)
      buttons.push(
        <button
          key={"modalAlert-btn1"}
          className={button1.type}
          onClick={(event) => {
            event.stopPropagation();
            button1.action();
          }}
        >
          {button1.name}
        </button>
      );
    if (button2)
      buttons.push(
        <button
          key={"modalAlert-btn2"}
          className={button2.type}
          onClick={(event) => {
            event.stopPropagation();
            button2.action();
          }}
        >
          {button2.name}
        </button>
      );
    setModalAlert({
      show: true,
      title,
      message,
      onClickBtnClose,
      buttons,
      icon,
    });
  };

  return (
    <Container>
      {stateFlags.addedToCart && addedPopUp}

      <CustomNotification
        className="notification-container"
        open={notifications.length !== 0}
        deleteNotification={setDelete_notificationId}
        alerts={notifications.map((n) => ({ ...n }))}
      />

      {/* container filters */}
      <ContainerFilters>
        <ContainerMain
          className="containerMain"
          componentFilterInput={
            <FilterInput
              className="filter-input"
              classNameFilterContainer="container-InputSection"
              placeHolderInput="Buscar producto"
              filterInputFunct={(filtersInput) => {
                updateFilters("upc", filtersInput?.UPC?.values ?? []);
              }}
              taskFilter={listType !== "general"}
              filtersArray={[{}]}
              filterArray={[{}]}
              hiddenLabelProductsLoaded
              hideButtons
            />
          }
          containerButtons={
            <div className="container-buttons">
              {/* insertar/eliminar multiples productos a listas  */}
              {productsSelected.length !== 0 && (
                <CustomSelectListMultiple
                  idSelect={"selectProdListsMultiple"}
                  allLists={lists ?? []}
                  onClickBtnDelete={delete_multipleArticlesLists}
                  onClickBtnAdd={add_multipleArticlesLists}
                  isShowModal={modalAlert.show || isVisibleModalInputAddList}
                />
              )}
            </div>
          }
          lists={lists ?? []}
          locationRedirect={locationRedirect}
          providersList={providersList ?? []}
          departmentsList={departmentsList ?? []}
          updateFilters={updateFilters}
          priceRange={priceRange}
          currentPriceRange={filters.price}
          currentPercentageRange={filters.percentage}
          productsSelected={productsSelected}
        />

        <div className="containerEnd-buttons">
          {isRetailer === 1 ? (
            <DropdownScenary
              id="DropdownScenary"
              articlesList={articlesList}
              productsSelected={productsSelected}
              setModalAlert={setModalAlert}
              showModalAlert={showModalAlert}
              updateScenary={(articlesId, stage) => {
                setOriginalList({ action: "updateScenary", articlesId, stage });
              }}
            />
          ) : (
            <Visibility
              id="Visibility"
              articlesList={articlesList}
              productsSelected={productsSelected}
              setModalAlert={setModalAlert}
              showModalAlert={showModalAlert}
              updateVisibility={(articlesId, isVisible) => {
                setOriginalList({
                  action: "updateVisibility",
                  articlesId,
                  isVisible,
                });
              }}
            />
          )}
          {/*switch entre buttons (mode grid / mode table ) */}
          <button
            className={showGridView ? "buttonCircle pink" : "buttonCircle"}
            onClick={() => {
              setShowGridView(!showGridView);
            }}
          >
            <img
              src={showGridView ? IconGridViewWhite : IconGridViewGray}
              alt="Grid View"
            />
          </button>
        </div>
      </ContainerFilters>

      {/* aqui se rendiriza la tabla */}
      {stateFlags.isLoading ? <Loading /> : renderTable()}

      {stateFlags.showProductDetail && (
        <ProductDetail
          modalRef={wrapperRef}
          selectedArticles={productsSelected}
          option={"Productos"}
          className={modalClass}
          productSelected={productSelected}
          setAddedToCart={(value) =>
            setStateFlags((prev) => ({ ...prev, addedToCart: value }))
          }
          setAddedPopUp={setAddedPopUp}
          setModalClass={setModalClass}
          setSidebool={(value) =>
            setStateFlags((prev) => ({ ...prev, showProductDetail: value }))
          }
          setLoading={(value) =>
            setStateFlags((prev) => ({ ...prev, isLoading: value }))
          }
          getProducts={getProducts}
        />
      )}

      {/* modal para mensajes o preguntas */}
      {modalAlert.show && (
        <Modal
          icon={modalAlert.icon}
          onClickBtnClose={(event) => {
            event.stopPropagation();
            modalAlert.onClickBtnClose();
          }}
          title={modalAlert.title}
          message={modalAlert.message}
          buttons={modalAlert.buttons}
        />
      )}

      {/* modal para editar nombre de lista */}
      {isVisibleModalInputAddList && (
        <Modal
          onClickBtnClose={(event) => {
            event.stopPropagation();
            setVisibleModalInputAddList(false);
          }}
          title={"Agregar nueva lista"}
          customComponent={
            <input
              autoFocus
              className="inputAddList"
              type={"text"}
              placeholder={"Nombre de la lista"}
              value={valueInputAddList}
              onChange={(event) => {
                setValueInputAddList(event.target.value);
              }}
              onKeyDown={async (event) => {
                if (event.key === "Enter") {
                  //modalInputEditBtnGuardar();
                  onClickAddList();
                }
              }}
              onClick={(event) => {
                event.stopPropagation();
              }}
            />
          }
          buttons={[
            <button
              key={"modalInputAddlist-btnCancelar"}
              className="btnTransparent"
              onClick={(event) => {
                event.stopPropagation();
                setVisibleModalInputAddList(false);
              }}
            >
              Cancelar
            </button>,
            <button
              key={"modalInputAddlist-btnGuardar"}
              className="btnPink"
              onClick={async (event) => {
                event.stopPropagation();
                onClickAddList();
              }}
            >
              Guardar
            </button>,
          ]}
        />
      )}
    </Container>
  );
}
