import {
  RetailerCatalog,
  //   UserCatalog,
  Status,
} from "contentoh-components-library";
import moment from "moment";

import { servicesTranslation } from "../../_utils/helper";

export const renderColumns = () => {
  const columnsArray = [
    { name: "Orden", flex: 1, minWidth: 100 },
    { name: "Nombre", flex: 2, minWidth: 200 },
    { name: "Categoría", flex: 3, minWidth: 300 },
    { name: "Cadenas", flex: 1, minWidth: 100 },
    { name: "Estatus", flex: 1, minWidth: 60 },
    { name: "Descripción del servicio", flex: 3, minWidth: 300 },
    { name: "F.T.", flex: 1, minWidth: 60 },
    { name: "D.C.", flex: 1, minWidth: 60 },
    { name: "Imgs.", flex: 1, minWidth: 60 },
    { name: "Fecha", flex: 1, minWidth: 100 },
  ];

  return columnsArray;
};

export const getProductsToTable = (products = [], images) => {
  const productsTableArray = [];

  products.forEach((product) => {
    const { orderId, article, version, status, retailers, services, missing } =
      product;
    const id = article?.id_article + "-" + orderId;
    const imgArray = images[article?.id_article + "-" + version];
    const element = {
      id,
      cols: [
        { name: orderId || "-", flex: 1, minWidth: 100 },
        { name: <p>{article?.name}</p> || "-", flex: 2, minWidth: 200 },
        { name: <p>{article?.category}</p> || "-", flex: 3, minWidth: 300 },
        {
          name:
            (
              <RetailerCatalog
                id={"retailers-" + id}
                limit={2}
                article={product}
                retailers={retailers}
              />
            ) || "-",
          flex: 1,
          minWidth: 100,
        },
        { name: <Status statusType={status} />, flex: 1, minWidth: 60 },
        { name: servicesTranslation(services), flex: 3, minWidth: 300 },
        { name: missing.datasheet, flex: 1, minWidth: 60 },
        { name: missing.descriptions, flex: 1, minWidth: 60 },
        { name: missing.images, flex: 1, minWidth: 60 },
        {
          name: moment(article.timestamp).format("DD/MM/YYYY"),
          flex: 1,
          minWidth: 100,
        },
      ],
      gridElement: {
        images: imgArray,
        info: [
          { title: "name", value: article?.name || "-" },
          { title: "category", value: article?.category || "-" },
          { title: "order", value: orderId || "-" },
        ],
        status: [
          { title: "general", value: status || "-" },
          { title: "datasheet", value: product.datasheet_status || "-" },
          { title: "description", value: product.description_status || "-" },
          { title: "images", value: product.images_status || "-" },
        ],
        catalogs: {
          leftSide:
            (
              <RetailerCatalog
                id={"retailers-" + id}
                limit={2}
                article={product}
                retailers={retailers}
              />
            ) || "-",
        },
      },
      showBottom: true,
      product,
    };

    productsTableArray.push(element);
  });

  return productsTableArray;
};

export const chkOnChange = (e, product, state, setState) => {
  const tempArray = state.slice();
  if (e.target.checked) {
    tempArray.push(product);
    setState(tempArray);
  } else {
    const filteredArray = tempArray.filter(
      (item) =>
        !(
          item.orderId === product.orderId &&
          item.id_article === product.id_article
        )
    );
    setState(filteredArray);
  }
};

export const chkChecked = (product, state) => {
  const productSelected = [];
  state.forEach((prod) => {
    prod.orderId === product.orderId &&
      prod.article.id_article === product.article.id_article &&
      productSelected.push(prod);
  });
  return productSelected?.length > 0;
};

export const checkAll = (e, setState, products) => {
  const chks = document.querySelectorAll(".chk-item > input");
  e.target.checked ? setState(products) : setState([]);
  chks?.forEach((chk) => (chk.checked = e.target.checked));
};

export const chkCheckedAll = (selected, products) => {
  return selected.length === products.length;
};
