import React, { useState, useEffect } from "react";
import { ContainerDashboard } from "./ContainerDashboard/index";

import dashboard from "../../assets/IconComponents/menuContentOh/iconDashboard.svg";
import thddashboard from "../../assets/IconComponents/menuContentOh/thddashboard.svg";
import products from "../../assets/IconComponents/menuContentOh/iconProducts.svg";
import merchants from "../../assets/IconComponents/menuContentOh/iconMerchants.svg";
import contentoh from "../../assets/IconComponents/menuContentOh/iconContentoh.svg";
import task from "../../assets/IconComponents/menuContentOh/iconTask.svg";
import { VerticalSideMenuMainPage } from "contentoh-components-library";

export default function Main() {
  const [user] = useState(JSON.parse(sessionStorage.getItem("user")));
  const [company] = useState(JSON.parse(sessionStorage.getItem("company")));
  const [display, setDisplay] = useState(false);
  const [menuOptions, setMenuOptions] = useState([]);

  const [title, setTitle] = useState({
    title: sessionStorage.getItem("title"),
    info: sessionStorage.getItem("description"),
  });

  useEffect(() => {
    setTitle({
      title: sessionStorage.getItem("title"),
      info: sessionStorage.getItem("description"),
    });
  }, []);

  const openMenuUser = (e) => {
    if (
      !e.target.closest("#menu-user") &&
      display &&
      window.location.pathname !== "/DashboardContentoh"
    ) {
      document.removeEventListener("click", openMenuUser, false);
      setDisplay(false);
    }
  };

  const getMenuOption = () => {
    const isRetailer = user?.is_retailer === 1;
    const idsFinanced = company?.financedRetailers?.map(({ id }) => id) ?? [];

    const menuOptions = [
      { icon: dashboard, path: "/Dashboard", title: "Dashboard" },
    ];

    const ids = JSON.parse(sessionStorage.getItem("company"))?.retailers?.map(
      ({ id }) => id
    );
    const isTHDFinanced = [58, 59].some((id) => ids?.includes(id));

    if (user.id_user !== 59) {
      let productSubOptions = [];

      if (user.is_onboarding === 1) {
        productSubOptions.push(
          { path: "/allacquired", title: "General" },
          { path: "/products", title: "Tareas" }
        );
      } else {
        productSubOptions.push({ path: "/products", title: "General" });
      }

      productSubOptions.push({ path: "/AddProducts", title: "Agregar Producto" });

      menuOptions.push(
        {
          icon: products,
          path: "/products",
          title: "Productos",
          suboption: productSubOptions,
        },
        {
          icon: contentoh,
          path: "/ContentohProducts",
          title: "Content-oh!",
          suboption: [
            { path: "/ContentohProducts", title: "Productos" },
            { path: "/orders", title: "Órdenes" },
            { path: "/Quotes", title: "Citas" },
          ],
        }
      );

      const isAdmin = user?.id_role === 0;
      const optionMerchants = {
        icon: merchants,
        path: "/merchants",
        title: "Onboarding",
        suboption: [
          { path: "/merchants", title: "Productos" },
          { path: "/merchantsLists", title: "Listas" },
          { path: "/acquired", title: "Adquiridos" },
        ],
      };
      const merchantsRetailers = [68, 74];
      const hasMerchantsOption = idsFinanced.some((id) =>
        merchantsRetailers.includes(id)
      );

      if (isRetailer) {
        if (user?.id_role === 12) {
          if (hasMerchantsOption) {
            menuOptions.splice(2, 0, optionMerchants);
          }
        } else {
          if(user.is_onboarding !== 1){
            menuOptions.push({
              icon: task,
              path: `/tasks/${company?.id_company}`,
              title: "Tareas",
            });
          }
          
          if (isAdmin && hasMerchantsOption) {
            menuOptions.splice(2, 0, optionMerchants);
          }
        }
      } else {
        menuOptions.push({ icon: task, path: "/tasks", title: "Tareas" });
        if (isAdmin && hasMerchantsOption) {
          menuOptions.splice(2, 0, optionMerchants);
        }
      }
    }


    setMenuOptions(menuOptions);
  };

  useEffect(() => {
    getMenuOption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (display) {
      document.addEventListener("click", openMenuUser, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [display]);

  return (
    <VerticalSideMenuMainPage
      menuoption={menuOptions}
      containerdash={<ContainerDashboard />}
    />
  );
}
