import styled from "styled-components";

export const ContainerBar = styled.div`
  width: 94px;
  height: 20px;
  background-color: #fafafa;
  border-radius: 3px;
  margin: auto;

  & + * {
    margin-left: 50px;
  }
`;
export const Bar = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  height: 20px;
  color: #fafafa;
  border-radius: 3px;
  ${({ percentage }) =>
    percentage.percentage <= 25 && `background-color: #E57432;`};
  ${({ percentage }) =>
    percentage.percentage <= 50 &&
    percentage.percentage > 25 &&
    `background-color: #ED9A4D;`};
  ${({ percentage }) =>
    percentage.percentage <= 75 &&
    percentage.percentage > 50 &&
    `background-color: #18A0FB;`};
  ${({ percentage }) =>
    percentage.percentage <= 100 &&
    percentage.percentage > 75 &&
    `background-color: #71DE56;`};
`;
