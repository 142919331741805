export const tableStyle = {
  className: "table-products",
  rowClassName: "table-row",
  headerStyle: {
    display: "flex",
    justifyContent: "center",
    fontFamily: "Avenir Next",
    fontWeight: 600,
    fontSize: "12px",
    color: "#503D66",
    margin: 0,
  },
  height: "550",
  width: "1440",
  headerHeight: 20,
  rowHeight: 30,

  tableStyle: {
    overflow: "unset",
  },
  rowStyle: {
    display: "flex",
    overflow: "unset",
    textAlign: "center",
    fontFamily: "Avenir Next",
    fontSize: 13,
    width: "100%",
    color: "#817393",
  },
};

export const MonthColumn = {
  className: "table-column",
  flexGrow: 1,
  minWidth: "7%",
  maxWidth: "7%",
  dataKey: "checked",
  style: {
    textAlign: "center",
    margin: "0",
  },
};

export const idColumn = {
  className: "table-column",
  flexGrow: 1,
  maxWidth: "15%",
  minWidth: "15%",
  dataKey: "id_order",
  style: {
    textAlign: "center",
    margin: "0",
  },
};

export const quantityColumn = {
  className: "table-column",
  flexGrow: 1,
  maxWidth: "14%",
  minWidth: "14%",
  dataKey: "id_order",
  style: {
    textAlign: "center",
    margin: "0",
  },
};

export const checkboxColumn = {
  className: "table-column",
  flexGrow: 1,
  minWidth: "3.5%",
  maxWidth: "3.5%",
  dataKey: "checked",
  style: {
    textAlign: "center",
    margin: "0",
  },
};

export const estatusColumn = {
  className: "table-column",
  flexGrow: 1,
  maxWidth: "10%",
  minWidth: "10%",
  dataKey: "status",
  style: {
    textAlign: "center",
    margin: "0",
    overflow: "unset",
  },
};

export const dateColumn = {
  className: "table-column",
  flexGrow: 1,
  maxWidth: "15%",
  minWidth: "15%",
  style: {
    textAlign: "center",
    margin: "0",
    overflow: "unset",
  },
};

export const downloadColumn = {
  className: "table-column",
  flexGrow: 1,
  minWidth: "5%",
  maxWidth: "5%",
  dataKey: "checked",
  style: {
    textAlign: "center",
    margin: "0",
  },
};
