import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  align-items: center;
  width: ${(props) => (props.width ? props.width : "150px")};
  height: 20px;
  padding: 0 5px;
  box-sizing: border-box;
  background: #fafafa;
  border-radius: 2px;
  border: ${(props) => props.error && "1px solid red"};
`;

export const InputCustom = styled.input`
  border-width: 0;
  width: 100%;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  border-width: 0;
  background-color: rgba(0, 0, 0, 0);
  &::placeholder {
    font-size: 12px;
    color: #d4d1d7;
  }
`;
