/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
//SVG
import LoginImage from "../../assets/IconComponents/LoginImage.svg";
import Login2 from "../../assets/IconComponents/Login2.svg";
import Login3 from "../../assets/IconComponents/Login3.svg";
import retailerLogo from "../../assets/Login/retailer_logo.svg";
import { Auth } from "aws-amplify";
import axios from "axios";
// Styled-Components
import {
  MainContainer,
  GraphicPanel,
  TextUnderImage,
  VersionFooter,
} from "./styles";
import { Panel, Title } from "./selectPlanStyles";
//Componentes
//import Loading from "../general/loading/index.js";
import ChangePassword from "./ChangePassword.js";
import SignIn from "./SignIn.js";
import {
  LeftChoiceProviders,
  RightChoiceProviders,
  LeftChoiceProducts,
  RightChoiceProducts,
  LeftChoiceCard,
  RightChoiceCard,
} from "./selectPlan";
import BaseForm from "./BaseForm";
import { CarouselImagesLogin } from "contentoh-components-library";
import { SignInLogin } from "contentoh-components-library";
import { RegistrationFirstStep } from "contentoh-components-library";
import { RegistrationSecondStep } from "contentoh-components-library";
import { RegistrationThirdStep } from "contentoh-components-library";
import { EmailResetPasswordLogin } from "contentoh-components-library";
import { VerificationCodeResetPasswordLogin } from "contentoh-components-library";
//import { ChangePassword } from "contentoh-components-library";
import { LogoLoading } from "contentoh-components-library";
const Login = (props) => {
  const { userType } = props;
  //Variables para el flujo del componente
  const [paso, setPaso] = useState(7);
  const [image, setImage] = useState(LoginImage);
  const [text, seTtext] = useState(
    "Elige la mejor plataforma que conecta proveedores y retailers"
  );
  const [instruccion, setInstruccion] = useState("");
  const [confirmationError, setConfirmationError] = useState("");
  const [user, setUser] = useState("");
  const [rightSide, setRightSide] = useState(null);

  const setChild = useCallback(
    (child) => {
      return (
        <BaseForm
          paso={paso}
          instruccion={instruccion}
          PasoRegistro={child}
          setPaso={setPaso}
        />
      );
    },
    [instruccion, paso]
  );

  //ComponentDidMount => Array para guardar la información (usuario y empresa)
  useEffect(() => {
    sessionStorage.setItem(
      "nuevoRegistro",
      JSON.stringify({
        name: "",
        lastName: "",
        email: "",
        position: "",
        country: "",
        phone: "",
        commercialName: "",
        companyName: "",
        rfc: "",
        adress: "",
      })
    );
    sessionStorage.setItem("countryCode", JSON.stringify("+52"));

    return () => {
      sessionStorage.removeItem("nuevoRegistro");
      sessionStorage.getItem("confirmationCode") &&
        sessionStorage.removeItem("confirmationCode");
      sessionStorage.getItem("registrationError") &&
        sessionStorage.removeItem("registrationError");
      sessionStorage.getItem("countryCode") &&
        sessionStorage.removeItem("countryCode");
    };
  }, []);

  const pasoGraph = useCallback(() => {
    const data = { paso, setPaso, userType };
    switch (paso) {
      case 1:
        //Montar PrimerPaso con todas las variables necesarias para validar
        setRightSide(<RegistrationFirstStep {...data} />);
        break;

      case 2:
        //Montar PasswordCreation con todas las variables necesarias para validar */
        setRightSide(<RegistrationSecondStep {...data} />);
        break;

      case 3:
        /*  setImage(Login3);
        setInstruccion("Registra tu empresa"); */
        setRightSide(<RegistrationThirdStep {...data} />);
        break;

      case 4:
        setRightSide(null);
        //Registrar en AWS Cognito
        signUp();
        break;

      case 5:
        setRightSide(
          <VerificationCodeResetPasswordLogin
            confirmationError={confirmationError}
            setConfirmationError={setConfirmationError}
            {...data}
            Auth={Auth}
          />
        );
        break;

      case 6:
        confirmSignUp();
        break;

      case 7:
        setRightSide(
          <SignInLogin
            setUser={setUser}
            {...data}
            Auth={Auth}
            registration={true}
            users={"usuario_contentoh"}
          />
        );
        break;

      case 8:
        setRightSide(setChild(<ChangePassword {...data} />));
        break;

      case 9:
        confirmPasswordChange();
        break;

      case 10:
        setRightSide(<EmailResetPasswordLogin {...data} Auth={Auth} />);
        break;

      case 11:
        forgotPassword();
        break;

      case 12:
        setInstruccion(null);
        setRightSide(<RightChoiceProviders {...data} />);
        setLeftSide(<LeftChoiceProviders />);
        break;

      case 13:
        setRightSide(<RightChoiceProducts {...data} />);
        setLeftSide(<LeftChoiceProducts />);
        break;

      case 14:
        setRightSide(<RightChoiceCard {...data} />);
        setLeftSide(<LeftChoiceCard />);
        break;
      default:
        setImage(LoginImage);
        break;
    }
  }, [
    confirmPasswordChange,
    confirmSignUp,
    confirmationError,
    forgotPassword,
    paso,
    setChild,
    signUp,
    userType,
  ]);

  //on variable paso update
  useEffect(() => {
    pasoGraph();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paso]);

  async function signUp() {
    let userData = JSON.parse(sessionStorage.getItem("nuevoRegistro"));
    let newUser = userData;
    let username = newUser.email;
    let password = newUser.password;
    let email = newUser.email;
    let phone_number = newUser.phone;
    let name = `${newUser.name} ${newUser.lastName}`;

    try {
      setRightSide(<LogoLoading />);
      const user = await Auth.signUp({
        username,
        password,
        attributes: {
          email,
          phone_number,
          name,
        },
      });
      newUser.userSub = user.userSub;
      newUser.userType = userType;
      userData = newUser;
      if (user.userSub !== undefined) {
        await axios.post(
          `${process.env.REACT_APP_USER_ENDPOINT}?isUser=1`,
          userData
        );
        setPaso(5);
      }
    } catch (err) {
      setPaso(3);
      switch (err.code) {
        case "UsernameExistsException":
          sessionStorage.setItem(
            "registrationError",
            JSON.stringify("emailExists")
          );
          setPaso(1);
          break;
        case "InvalidParameterException":
          sessionStorage.setItem(
            "registrationError",
            JSON.stringify("phoneFormatError")
          );
          setPaso(1);
          break;
        default:
          sessionStorage.setItem("registrationError", JSON.stringify("error"));
          break;
      }
    }
  }

  async function confirmSignUp() {
    let userEmail;
    if (
      !sessionStorage.getItem("email") ||
      JSON.parse(sessionStorage.getItem("email")) === ""
    ) {
      userEmail = JSON.parse(sessionStorage.getItem("nuevoRegistro")).email;
    } else {
      userEmail = JSON.parse(sessionStorage.getItem("email"));
    }
    let confirmationCode = JSON.parse(
      sessionStorage.getItem("confirmationCode")
    );
    try {
      setRightSide(<LogoLoading />);
      await Auth.confirmSignUp(userEmail, confirmationCode);
      sessionStorage.removeItem("email");
      setPaso(7);
    } catch (err) {
      switch (err.code) {
        default:
          sessionStorage.setItem("registrationError", JSON.stringify("error"));
          break;
      }
      setConfirmationError(err.code);
      setPaso(5);
    }
  }

  async function forgotPassword() {
    let username = JSON.parse(sessionStorage.getItem("email"));
    let code = JSON.parse(sessionStorage.getItem("confirmationCode"));
    let new_password = JSON.parse(sessionStorage.getItem("newPassword"));
    try {
      setRightSide(<LogoLoading />);
      await Auth.forgotPasswordSubmit(username, code, new_password);
      sessionStorage.removeItem("resetPasswordProcess");
      sessionStorage.removeItem("email");
      sessionStorage.removeItem("confirmationCode");
      sessionStorage.removeItem("newPassword");
      sessionStorage.getItem("resetError") &&
        sessionStorage.removeItem("resetError");
      setPaso(7);
    } catch (err) {
      setPaso(5);
      console.log(err);
      switch (err.code) {
        case "CodeMismatchException":
          sessionStorage.setItem(
            "resetError",
            JSON.stringify("Código de confirmación incorrecto")
          );
          setPaso(5);
          break;
        case "LimitExceededException":
          sessionStorage.setItem(
            "resetError",
            JSON.stringify("Has intentado demasiadas veces, intentalo despues")
          );
          break;
        case "ExpiredCodeException":
          sessionStorage.setItem(
            "resetError",
            JSON.stringify("Código de confirmación expirado")
          );
          sessionStorage.setItem("expired", true);
          break;
        default:
          sessionStorage.setItem("registrationError", JSON.stringify("error"));
          break;
      }
    }
  }

  async function confirmPasswordChange() {
    let newPassword = JSON.parse(sessionStorage.getItem("newPassword"));
    let name = "Colaborador";
    let phone_number = "+521111";
    try {
      setRightSide(<LogoLoading />);
      await Auth.completeNewPassword(user, newPassword, {
        name,
        phone_number,
      }).then((response) => {
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("newPassword");
        sessionStorage.getItem("resetPasswordProcess") &&
          sessionStorage.removeItem("resetPasswordProcess");
        setPaso(7);
      });
    } catch (err) {
      console.log(err);
      switch (err.code) {
        case "InvalidParameterValue":
          sessionStorage.setItem(
            "changePasswordError",
            JSON.stringify("invalidPassword")
          );
          break;
        default:
          sessionStorage.setItem("registrationError", JSON.stringify("error"));
          break;
      }
      setPaso(8);
      console.log(err.message);
    }
  }

  const LeftSideProviderInit = useCallback(() => {
    return (
      <GraphicPanel>
        <CarouselImagesLogin
          panelText={"Elige la plataforma que conecta proveedores y retailers"}
          panelImg={[LoginImage, Login2, Login3]}
        />
      </GraphicPanel>
    );
  }, [image, paso, text]);

  const LeftSideRetailerInit = useCallback(() => {
    return (
      <Panel>
        <div className="image-container">
          <Title color="#FAFAFA">Bienvenido a Cadena</Title>
          <TextUnderImage>
            <p>La plataforma que conecta cadenas retailers con proveedores</p>
          </TextUnderImage>
          <figure>
            <img src={retailerLogo} alt="Cadena" />
          </figure>
        </div>
      </Panel>
    );
  }, []);

  const [leftSide, setLeftSide] = useState(
    userType === "provider" ? LeftSideProviderInit : LeftSideRetailerInit
  );

  return (
    <MainContainer>
      {leftSide}
      {rightSide}
      <VersionFooter>v.2.7</VersionFooter>
    </MainContainer>
  );
};
export default Login;
