import { Auth } from "aws-amplify";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, NavLink, useHistory } from "react-router-dom";
import ReactImageFallback from "react-image-fallback";
import {
  MainContainer,
  LeftContainer,
  RoleBadge,
  Header,
  MainData,
  Navbar,
  RightContainer,
  Alert,
} from "./styles.js";

// Own components
import PersonalData from "./PersonalData";
import CompanyData from "./CompanyData";
import Billing from "./Billing";
import ChangePassword from "./ChangePassword";
import AddPeople from "./AddPeople";
import InviteProviders from "./InviteProviders";
import GenericModal from "../../general/GenericModal";
import Plan from "./planSection/index";
import GroupsUsers from './GroupsUsers.js';

// SVG assets
import defaultProfile from "../../../assets/defaultImages/defaultProfile.png";
import spinner from "../../../assets/defaultImages/Spinner.gif";
import addPeople from "../../../assets/IconComponents/addPeople.svg";
import billing from "../../../assets/IconComponents/billing.svg";
import company from "../../../assets/IconComponents/company.svg";
import logout from "../../../assets/IconComponents/logout.svg";
import password from "../../../assets/IconComponents/password.svg";
import profile from "../../../assets/IconComponents/profile.svg";
import closeWhite from "../../../assets/IconComponents/closeWhite.svg";
import infoWhite from "../../../assets/IconComponents/infoWhite.svg";
import group from "../../../assets/IconComponents/group.svg";
import person from "../../../assets/IconComponents/person.svg";
import lock from "../../../assets/IconComponents/lock.svg";
import work from "../../../assets/IconComponents/work.svg";
import providers from "../../../assets/IconComponents/package_2.svg";
import enterprise from "../../../assets/IconComponents/compare_arrows.svg";
import arrow from "../../../assets/IconComponents/arrow_right.svg";

import { getRoleUser } from "../../_utils/helper";
import Flows from "./Flows.js";
import AttributesGroup from "./AttributesGroup.js";
import axios from "axios";

export default function Profile() {
  const history = useHistory();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [companyData, setCompany] = useState(
    sessionStorage.getItem("company")
      ? JSON.parse(sessionStorage.getItem("company"))
      : {}
  );
  const [child, setChild] = useState(<PersonalData history={history}/>);
  const [show, setShow] = useState(false);
  const [tabActive, setTabActive] = useState("Proveedores activos");
  const [isOnboarding, setIsOnboarding] = useState(false);
  const [loading, setLoading] = useState(false);

  // FIXME: Cuando se resuelva lo de los hardcodeos de onboarding quitar este

  const getCompany = async () => {
    const response = await axios.get(process.env.REACT_APP_USER_ENDPOINT, {
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    });
    sessionStorage.setItem(
      "company",
      JSON.stringify(JSON.parse(response.data.body).data[1])
    );
    const retailers = JSON.parse(response.data.body).data[1]?.retailers;
    retailers.forEach((retailer) => {
      const retailerId = retailer.id;
      if (retailerId === 74) {
        setIsOnboarding(true);
      }
    });
    setCompany(JSON.parse(response.data.body).data[1]);
  };

  useEffect(() => getCompany(), []);

  useEffect(() => {}, [tabActive]);
  useEffect(() => {
    switch (window.location.pathname) {
      case "/profile/plan":
        setChild(null);
        break;
      case "/profile/company-data":
        setChild(<CompanyData />);
        break;
      case "/profile/billing-data":
        setChild(<Billing />);
        break;
      case "/profile/change-pass":
        setChild(<ChangePassword history={history} />);
        break;
      case "/profile/add-people":
        setChild(<AddPeople history={history} />);
        break;
      case "/profile/invite-providers":
        setChild(
          <InviteProviders
            history={history}
            tabActive={tabActive}
            user={user}
          />
        );
        break;
        case "/profile/groups-users":
          setChild(
            <GroupsUsers
              history={history}
            />
          );
      break;
      case "/profile/attributes-group":
          setChild(
            <AttributesGroup
              history={history}
              tabActive={tabActive}
              user={user}
            />
          );
      break;
      case "/profile/flows":
        setChild(
          <Flows
            history={history}
            tabActive={tabActive}
            user={user}
          />
        );
    break;
      default:
        setChild(<PersonalData  history={history}/>);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname, tabActive]);
  return (
    <Router>
      <MainContainer>
        {show && (
          <GenericModal
            close={() => setShow(!show)}
            message="¿Estas seguro que quieres cerrar sesión?"
            button1={{ name: "Cancelar", action: () => setShow(!show) }}
            button2={{
              name: "Cerrar Sesión",
              action: async () => {
                try {
                  await Auth.signOut({ global: true });
                  sessionStorage.clear();
                  history.push({
                    pathname: "/login",
                  });
                  window.location.reload();
                } catch (error) {}
              },
            }}
          />
        )}
        <LeftContainer>
          <Header>
            <ReactImageFallback
              src={user.src}
              fallbackImage={defaultProfile}
              initialImage={spinner}
              alt="avatar"
              className="avatar"
            />
            <div>
              <p id="title">{`${user.name} ${user.last_name}`}</p>
              <p>{user.position}</p>
              <RoleBadge>{getRoleUser(user.id_role)}</RoleBadge>
            </div>
          </Header>

          <MainData>
          {isOnboarding === true && user.is_retailer ? ( 
              <Navbar>
                <NavbarItem
                  img={{ src: person, alt: "menu-icon" }}
                  title="Información personal"
                  path="/profile"
                  history={history}
                  uKey={1}
                />
                <NavbarItem
                  img={{ src: work, alt: "menu-icon" }}
                  title="Información de la empresa"
                  path="/profile/company-data"
                  history={history}
                  uKey={2}
                />
                <NavbarItem
                  img={{ src: lock, alt: "menu-icon" }}
                  title="Cambiar contraseña"
                  path="/profile/change-pass"
                  history={history}
                  uKey={3}
                />
                <NavbarItem
                  img={{ src: group, alt: "menu-icon" }}
                  title="Grupos y usuarios"
                  path="/profile/groups-users"
                  history={history}
                  uKey={4}
                />
                <NavbarItem
                  img={{ src: arrow, alt: "menu-icon" }}
                  title="Atributos por grupo"
                  path="/profile/attributes-group"
                  history={history}
                  uKey={5}
                />
                <NavbarItem
                  img={{ src: arrow, alt: "menu-icon" }}
                  title="Flujos de revisión"
                  path="/profile/flows"
                  history={history}
                  uKey={6}
                />
                {user.is_retailer === 1 && (
                  <NavbarItem
                    img={{ src: providers, alt: "menu-icon" }}
                    title="Proveedores"
                    path="/profile/invite-providers"
                    history={history}
                    uKey={6}
                    subMenu={["Proveedores activos", "Invitaciones pendientes"]}
                    setTabActive={setTabActive}
                  />
                )}
                <NavbarItem
                  img={{ src: logout, alt: "menu-icon" }}
                  title="Cerrar sesión"
                  notMenu
                  path="/logout"
                  setShow={setShow}
                  history={history}
                  uKey={8}
                />
              </Navbar>
            ):( 
              <Navbar>
                <NavbarItem
                  img={{ src: profile, alt: "menu-icon" }}
                  title="Información personal"
                  path="/profile"
                  history={history}
                  uKey={1}
                />
                <NavbarItem
                  img={{ src: company, alt: "menu-icon" }}
                  title="Información de la empresa"
                  path="/profile/company-data"
                  history={history}
                  uKey={2}
                />
                <NavbarItem
                  img={{ src: billing, alt: "menu-icon" }}
                  title="Datos de facturación"
                  path="/profile/billing-data"
                  history={history}
                  uKey={3}
                />
                <NavbarItem
                  img={{ src: password, alt: "menu-icon" }}
                  title="Cambiar contraseña"
                  path="/profile/change-pass"
                  history={history}
                  uKey={4}
                />
                <NavbarItem
                  img={{ src: addPeople, alt: "menu-icon" }}
                  title="Usuarios"
                  path="/profile/add-people"
                  history={history}
                  uKey={5}
                />
                {user.is_retailer === 1 && (
                  <NavbarItem
                    img={{ src: addPeople, alt: "menu-icon" }}
                    title="Proveedores"
                    path="/profile/invite-providers"
                    history={history}
                    uKey={5}
                    subMenu={["Proveedores activos", "Invitaciones pendientes"]}
                    setTabActive={setTabActive}
                  />
                )}
                <NavbarItem
                  img={{ src: logout, alt: "menu-icon" }}
                  title="Cerrar sesión"
                  notMenu
                  path="/logout"
                  setShow={setShow}
                  history={history}
                  uKey={6}
                />
              </Navbar>
            )}
          </MainData>
        </LeftContainer>
        <RightContainer>
          {alert && (
            <Alert style={{ display: "none" }}>
              <figure>
                <img src={infoWhite} alt="Alert" />
              </figure>
              <p>Verificación de correo electrónico</p>
              <figure id="close">
                <img src={closeWhite} alt="Close" />
              </figure>
            </Alert>
          )}
          {child}
        </RightContainer>
      </MainContainer>
    </Router>
  );
}

function NavbarItem(props) {
  return (
    <li key={props.uKey}>
      <NavLink
        exact
        activeClassName="active"
        to={props.path ? props.path : ""}
        onClick={async (event) => {
          if (props.path === "/logout") {
            props.setShow(true);
          } else {
            props.history.push({ pathname: props.path });
          }
        }}
      >
        <div id="leftContainer">
          <figure>
            <img src={props.img.src} alt={props.img.alt} />
          </figure>
          <p>{props.title}</p>
        </div>
        {props.subMenu &&
          props.subMenu.map((item) => (
            <div className="sub-menu" onClick={() => props.setTabActive(item)}>
              <p>{item}</p>
            </div>
          ))}
      </NavLink>
    </li>
  );
}
