import styled from "styled-components";
import {
  grayS2,
  grayS6,
  grayS7,
  grayS8,
  greenS2,
  greenS3,
  redS2,
  redS3,
} from "../../../variables";

// container de la vista del grid de productos
export const ContainerFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  flex-wrap: nowrap;
  gap: 12px;
  padding: 12px 9px 2px 9px;
  //border: 1px solid blue;

  button {
    flex-grow: 2;
    max-width: 150px;
    padding: 5px 10px;
    border: 2px solid ${grayS7};
    cursor: pointer;
    border-radius: 10px;
    background-color: #fff;
    font-family: "Roboto Medium", "Segoe UI", sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: #262626;
    white-space: pre-wrap;
    line-height: 1.4;
    transition: all 0.2s;

    &:hover {
      border: 2px solid #e33aa9;
      color: white;
      background-color: #e33aa9;
    }

    &.btnRed {
      border: 2px solid ${redS2};
      background-color: ${redS3};

      &:hover {
        background-color: ${redS2};
        color: ${grayS2};
      }
    }

    &.btnGreen {
      border: 2px solid ${greenS2};
      background-color: ${greenS3};

      &:hover {
        background-color: ${greenS2};
        color: ${grayS2};
      }
    }

    &:disabled {
      background-color: ${grayS2} !important;
      border: 2px solid ${grayS6} !important;
      color: ${grayS6} !important;
    }

    // icon loading
    i {
      font-size: 15px;
    }
  }

  /*
	.selectPrueba {
		position: relative;
		display: grid;
		place-items: center;
		border: 1px solid red;

		button {
			font-family: 'Segoe UI' , sans-serif;
			font-size: 18px;
			cursor: pointer;
		}

		.divHiddenSlider {
			display: none;
			position: absolute;
			background-color: white;
			top: calc( 100% + 1px );
			left: 0px;
			padding: 5px;
			border-radius: 10px;
			box-shadow: 0px 0px 5px 2px lightgray;

			&.active {
				display: grid;
			}
		}
	}*/
`;
