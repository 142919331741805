import React from "react";

import {
  ProfileMenuContainer,
  RPSymmary,
  RPHeader,
  ItemsContainer,
  RPItem,
  RPDescription,
} from "./styles";
import addshoppingcart from "../../../assets/IconComponents/addshoppingcart.svg";
import libraryadd from "../../../assets/IconComponents/libraryAdd.svg";
import playlistadd from "../../../assets/IconComponents/playlistAdd.svg";

function ProfileMenu(props) {
  const productDetail = props.productDetail;

  const getDataSheetPrice = (inputs) => {
    if (inputs > 0 && inputs <= 15) {
      return 96;
    } else if (inputs > 15 && inputs <= 45) {
      return 213;
    } else if (inputs > 45) {
      return 320;
    }
  };

  return (
    <ProfileMenuContainer show={props.show}>
      <RPSymmary>
        <RPHeader>
          <h3>Productos Solicitado</h3>
        </RPHeader>
      </RPSymmary>
      <ItemsContainer>
        <RPItem>
          <div className="rp-header">
            <img src={addshoppingcart} alt="" className="rp-icon" />
            <h3>Producto agregado</h3>
          </div>
          <RPDescription>
            <ul>
              <li key={1}>
                <div className="rp-product-details">
                  <p>{productDetail?.name}</p>
                  <p>UPC {productDetail?.upc}</p>
                </div>
                {/* <div className="rp-product-cost">
                  <p>
                    $
                    <span>
                      {getDataSheetPrice(productDetail?.missing?.datasheet) +
                        productDetail?.missing?.description * 278 +
                        productDetail?.missing?.image * 99}
                    </span>
                  </p>
                </div> */}
              </li>
            </ul>
          </RPDescription>
        </RPItem>
        {productDetail?.missing?.datasheet > 0 && (
          <RPItem>
            <div className="rp-header">
              <img src={libraryadd} alt="" className="rp-icon" />
              <h3>Fichas tecnicas agregadas</h3>
            </div>
            <RPDescription>
              <ul>
                <li key={3}>
                  <div className="rp-product-details">
                    <p>{productDetail.name}</p>
                    <p>UPC {productDetail.upc}</p>
                  </div>
                  <div className="rp-product-cost">
                    <p>
                      $
                      <span>
                        {getDataSheetPrice(productDetail?.missing?.datasheet)}
                      </span>
                    </p>
                  </div>
                </li>
              </ul>
            </RPDescription>
          </RPItem>
        )}
        {productDetail?.missing?.description > 0 && (
          <RPItem>
            <div className="rp-header">
              <img src={playlistadd} alt="" className="rp-icon" />
              <h3>Descripciones agregadas</h3>
            </div>
            <RPDescription>
              <ul>
                <li key={4}>
                  <div className="rp-product-details">
                    <p>{productDetail.name}</p>
                    <p>UPC {productDetail.upc}</p>
                  </div>
                  <div className="rp-product-cost">
                    <p>
                      $<span>{productDetail?.missing?.description * 278}</span>
                    </p>
                  </div>
                </li>
              </ul>
            </RPDescription>
          </RPItem>
        )}
        {productDetail?.missing?.image > 0 && (
          <RPItem>
            <div className="rp-header">
              <img src={libraryadd} alt="" className="rp-icon" />
              <h3>Imágenes agregadas</h3>
            </div>
            <RPDescription>
              <ul>
                <li key={5}>
                  <div className="rp-product-details">
                    <p>{productDetail.name}</p>
                    <p>UPC {productDetail.upc}</p>
                  </div>
                  <div className="rp-product-cost">
                    <p>
                      $<span>{productDetail?.missing?.image * 99}</span>
                    </p>
                  </div>
                </li>
              </ul>
            </RPDescription>
          </RPItem>
        )}
      </ItemsContainer>
    </ProfileMenuContainer>
  );
}

export default ProfileMenu;
