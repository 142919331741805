import React, { useState, StrictMode } from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import Main from "./components/main/index";
import Choice from "./components/Login/Choice";
import Login from "./components/Login/Login.js";
import "./index.css";

function App() {
  const [userType, setUserType] = useState(null);
  return (
    <StrictMode>
      <Router>
        <Switch>
          <AuthDetection userType={userType} setUserType={setUserType} />
        </Switch>
      </Router>
    </StrictMode>
  );
}
export default App;

function AuthDetection(props) {
  // TODO: Improve this mechanism to detect authentication
  const authenticated =
    sessionStorage.getItem("auth") === "true" ? true : false;

  return (
    <Route
      render={() =>
        authenticated ? (
          <Main {...props} />
        ) : props.userType === null ? (
          <Choice {...props} />
        ) : (
          <Login {...props} />
        )
      }
    />
  );
}
