import styled from "styled-components";

export const TextUnderImage = styled.div`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "24px")};
  font-family: Raleway;
  color: ${({ color }) => (color ? color : "#fbfbfb")};
  display: flex;
  line-height: 36px;
  justify-content: center;
  align-items: center;

  > p {
    text-align: center;
    width: 90%;
    margin: 0;
  }
`;

export const VersionFooter = styled.div`
  position: fixed;
  top: 97%;
  left: 96%;
  z-index: 15;
  height: 90px;
  width: 100%;
  pointer-events: none;
`;

export const StepsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  > img {
    margin: 1.3%;
  }
`;
export const MainContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
`;
export const GraphicPanel = styled.div`
  width: 50%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .image-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    max-height: 700px;

    figure {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: 0;
    }
  }
  background: ${({ color }) =>
    color ? color : "linear-gradient(180deg, #e33aa9 0%, #3b1366 100%)"};
  /* background: linear-gradient(
      180deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0.887706) 49.27%,
      rgba(255, 255, 255, 0) 100%
    ),
    #d43594; */
`;
export const FormPanel = styled.div`
  width: 50%;
  overflow-y: auto;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .loginLabel {
    width: 92%;
    display: flex;
    justify-content: flex-end;
    color: #b12d84;
    font-family: Avenir Next;
    :hover {
      cursor: pointer;
    }
  }

  > figure {
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    > img {
      height: 62.14px;
      width: 63.8%;
    }
  }
`;
export const FormContainer = styled.form`
  max-width: 460px;
  height: 80%;
  margin: 0 auto;
  font-family: Raleway;

  h3 {
    color: ${(props) => (props.paso === 3 ? "black" : "#707070")};
    font-size: 24px;
    margin-top: 3.8%;
    margin-bottom: 3.8%;
    font-weight: ${(props) => (props.paso === 3 ? "600" : "normal")};
  }

  p {
    color: #707070;
    font-size: 14px;
    margin-top: 3.8%;
    margin-bottom: 3.8%;
    font-weight: normal;
    font-family: "Avenir Next";
  }

  > p:last-child {
    margin-top: 0.6%;
  }
`;
export const Item = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  .checkbox {
    width: 18px;
    height: 18px;
    margin-right: 14px;
  }
  .checkboxLabel {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    color: #503d66;
    :hover {
      cursor: pointer;
    }
  }
  p {
    margin-bottom: 10px;
    width: 100%;
  }
  input {
    height: 30px;
    width: 100%;
    border: 0.5px solid rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    border-radius: 3px;
    padding: 0 11px;
    font-family: Lato;
  }

  label {
    font-size: 11px;
    color: red;
    font-weight: 700;
  }
`;
export const LongItem = styled.div`
  width: 100%;
  p {
    margin-bottom: 10px;
    width: 100%;
  }
  input {
    height: 30px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 11px;
    font-family: Avenir Next;
    color: #817393;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    padding: 10px;
    outline: none;
    resize: none;
    border: none;
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  select {
    width: 100%;
    height: 30px;
  }
  label {
    font-size: 11px;
    color: red;
    font-weight: 700;
  }
  .resendTrue {
    color: green;
  }
  .password {
    & + * {
      margin-top: 20px;
    }
    &:hover {
      border: 1px solid #e33aa9;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    position: relative;
    width: 100%;
    border: 1px solid #f0eef2;
    .icon {
      position: absolute;
      height: 60%;
      position: absolute;
      top: 50%;
      right: 8px;
      transform: translateY(-50%);
      opacity: 0.3;
      cursor: pointer;
    }
    .icon:hover {
      opacity: 0.8;
    }
  }
`;
export const PhoneSelect = styled.div`
  width: 10.7%;
  select {
    height: 30px;
    width: 100%;
    border: 0.5px solid rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    border-radius: 3px;
  }
`;
export const PhoneNumberInput = styled.div`
  width: 83%;
  input {
    height: 30px;
    width: 100%;
    border: 0.5px solid rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    border-radius: 3px;
    font-family: Lato;
  }
`;
export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .changeEmail {
    width: 100%;
    text-align: center;
    font-size: 13px;
    margin-top: 2%;
    font-weight: bold;
    color: #b12d84;
    :hover {
      cursor: pointer;
    }
  }
  .registrationLabel {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-family: Avenir Next;
    margin-top: 3%;
    color: #b12d84;
    :hover {
      cursor: pointer;
    }
  }

  .row {
    display: flex;
    flex-direction: row;

    &:nth-child(2) {
      font-weight: 500;
      margin: 0;
    }
  }

  &.privacy {
    justify-content: initial;
  }

  &:nth-child(3) {
    margin-top: 23px;
  }

  & + .passwod-strength {
    margin-top: 26px;

    & + .privacy {
      margin-top: 49px;
    }
  }

  &.privacy {
    align-items: initial;

    & + .privacy {
      margin-top: 12px;
    }
  }

  input {
    margin: auto 0;

    & + * {
      margin-left: 15px;
    }
  }
`;

export const Button = styled.button`
  font-family: Raleway;
  font-size: 15px;
  background: ${({ background }) => (background ? background : "#b12d84")};
  border-radius: 30px;
  border: none;
  width: 158.54px;
  height: 43px;
  color: ${({ color }) => (color ? color : "white")};
  :hover {
    cursor: pointer;
  }
`;

export const RowButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 44px;
  margin-bottom: 2%;
`;

export const ConfirmationRowButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5%;
  margin-bottom: 2%;
`;
export const StepsRow = styled.div`
  display: flex;
  width: 100%;
`;

export const StepDiv = styled.div`
  width: 100%;
  height: 8px;
  background-color: ${(props) => {
    if (props.pasoActual > props.paso) {
      return "#71DE56";
    } else if (props.pasoActual === props.paso) {
      return "#C4C4C4";
    } else {
      return "#E2E2E2";
    }
  }};
  border: 0.5px solid #e2e2e2;
`;
export const ItemPaso = styled.div`
  width: 100%;
  p {
    margin: 0;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 11px;
  }
`;
//Styled-Components passwordCreation
export const Terms = styled.div`
  font-size: 13px;

  a {
    text-decoration: none;
    font-family: Avenir Next;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 24px;
    color: #817393;
  }
  span {
    font-weight: bold;
  }
  div {
    opacity: 80%;
    line-height: 24px;
  }
  .verificationCode {
    color: #e451ac;
    font-size: 11px;
    text-decoration: underline #e451ac;
    cursor: pointer;
  }
`;
export const PasswordStrenght = styled.div`
  height: 8px;
  width: 100%;
  background-color: ${(props) => {
    switch (props.passwordStrength) {
      case 0:
        return "#F0F0F0";
      case 1:
        if (props.level === 1) {
          return "#E67432";
        } else {
          return "#F0F0F0";
        }
      case 2:
        if (props.level <= 2) {
          return "#EDD34D";
        } else {
          return "#F0F0F0";
        }
      case 3:
        if (props.level <= 3) {
          return "#71DE56";
        } else {
          return "#F0F0F0";
        }
      case 4:
        if (props.level <= 4) {
          return "#71DE56";
        } else {
          return "#F0F0F0";
        }
      default:
        return;
    }
  }};
`;
export const ItemPasswordStrength = styled.div`
  width: 24%;
  position: relative;

  p {
    margin-top: 1.5%;
    font-weight: 700;
    display: flex;
    justify-content: flex-end;
    font-size: 11px;
    position: absolute;
    right: 0;
    top: 100%;
    color: ${(props) => {
      switch (props.paso) {
        case 1:
          return "#E67432";
        case 2:
          return "#EDD34D";
        case 3:
          return "#71DE56";
        case 4:
          return "#71DE56";
        default:
          return "#E67432";
      }
    }};
  }
`;
export const ConfirmationTitle = styled.div`
  font-size: 15px;
  width: 100%;
  margin-top: 4%;
`;
