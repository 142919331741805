import React, { useState } from "react";
import PropTypes from "prop-types"; //especificar de que tipo son las props
import { Container } from "./styles";
import menuVerticalIcon from "../../../assets/IconComponents/menu_vertical.svg";
import editIcon from "../../../assets/IconComponents/edit-circle.svg"; // edit
import TrashIcon from "../../../assets/IconComponents/IconTrash.svg"; // delete
import { getImages } from "../utils/getImages";
import CustomDropDown, {
  classCustomDropDown,
} from "../../custom/CustomDropdown";

/*==========================================================
	Este componente representa el contenedor grid layout 
	en donde estaran ubicadas las tarjetas de cada lista
============================================================*/
const ListCard = ({
  list,
  handlers,
  functions,
  isVisibleModalAlert,
  isVisibleModalInputEdit,
  ...props
}) => {
  const [showSubmenu, setShowSubmenu] = useState(false);

  return (
    <Container
      className={functions.isSelectedList(list.id) ? "selectedList" : ""}
    >
      {/* contenedor de las imagenes */}
      <div className="div-imgs">{getImages(list)}</div>

      {/* div que contiene el submenu y el nombre de la lista */}
      <div className="container-submenu-total">
        {/* nombre de la lista */}
        <div className="label-name">
          <label> {list.name} </label>
          <div className="tooltip">
            <label> {list.name} </label>
          </div>
        </div>

        {/* submenu de opciones */}
        {list.is_editable === 1 && (
          <CustomDropDown
            show={showSubmenu}
            position={classCustomDropDown.POSITION_BOTTOM_START}
            className={"tooltip-submenu"}
            onClickAway={(event) => {
              if (!isVisibleModalAlert && !isVisibleModalInputEdit)
                setShowSubmenu(false);
            }}
            hiddenComponent={
              <ul className="submenu-listCard">
                <li
                  onClick={(event) => {
                    //event.stopPropagation()
                    handlers.onClickEditName(list.id, list.name);
                  }}
                >
                  <img src={editIcon} alt={"editar"} />
                  Actualizar nombre
                </li>
                <li
                  onClick={(event) => {
                    //event.stopPropagation()
                    handlers.onClickDeleteList(list.id);
                  }}
                >
                  <img src={TrashIcon} alt={"eliminar"} />
                  Eliminar lista
                </li>
              </ul>
            }
          >
            <button
              className="btn-submenu"
              onClick={() => {
                setShowSubmenu((prev) => !prev);
              }}
            >
              <img src={menuVerticalIcon} alt={"opciones"} />
            </button>
          </CustomDropDown>
        )}
      </div>

      {/* total de productos */}
      <label className="label-articlesCount">
        {list.articles_count} productos agregados
      </label>

      {/* background gris oscuro */}
      <div
        id="ListCard-divBackground"
        className="divBackground"
        onClick={(e) => {
          handlers.onClickListCard(list.id, list.name);
        }}
      ></div>
      {/*functions.isSelectedList(list.id)? <div  className="divBackground"></div> : null */}
    </Container>
  );
};

// definir el tipo de cada prop
ListCard.propTypes = {
  list: PropTypes.shape({
    id: PropTypes.number.isRequired, // id de la lista
    name: PropTypes.string.isRequired, // nombre de la lista
    articlesCount: PropTypes.number, // total de productos en la lista
    images: PropTypes.arrayOf(PropTypes.string), // imagenes de la lista
  }),
};

// definir los valores por default de cada prop
ListCard.defaultProps = {
  id: -1,
  name: "",
  articlesCount: 0,
  images: [],
};

export default ListCard;
