import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { getProfilePicture } from "../../_utils/helper";
import AWS from "aws-sdk";

// SVG assets
import location from "../../../assets/IconComponents/locationWhite.svg";
import edit from "../../../assets/IconComponents/edit.svg";
import profile from "../../../assets/IconComponents/profileWhite.svg";
import account from "../../../assets/IconComponents/account_circle.svg";
import defaultProfile from "../../../assets/defaultImages/defaultProfile.png";

// Styled components
import { Input, Textarea, BasicData, SubmmitButton } from "./CompanyData";
import ReactImageFallback from "react-image-fallback";
import Select from "../../customInputs/Select";
import Plan from "./planSection/index";

import Loading from "../../../components/general/loading/index";

import checked from "../../../assets/checkBox/checked.svg";
import unchecked from "../../../assets/checkBox/unchecked.svg";

const MainContainer = styled.div`
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Section = styled.div`
  width: 95%;
  border-bottom: 1px solid #e2e2e2;
  padding: 14px 0px;
  p {
    font-family: "Avenir Next";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #707070;

    &.title {
      font-weight: 500;
    }
  }
`;

const Header = styled(Section)`
  display: flex;
  justify-content: space-between;
  div {
    display: flex;
  }
  figure {
    & + p {
      margin-left: 13px;
    }
  }
  p {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #503d66;
  }
  .edit :hover {
    cursor: pointer;
  }
`;

const CheckNotification = styled(Section)`
  .item {
    font-family: "Avenir Next";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #503d66;
  }
  .custom-radio-checkbox {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    font-family: arial;
  }
  .disabled {
    cursor: default;
  }
  .icon-checkbox-show {
    background-image: url(${unchecked});
  }
  .custom-radio-checkbox > .custom-radio-checkbox__input {
    display: none;
  }
  .custom-radio-checkbox > .icon-checkbox {
    display: inline-block;
    width: 17px;
    height: 16px;
    margin-right: 7px;
    background-size: cover;
  }
  .custom-radio-checkbox
    > .custom-radio-checkbox__input:checked
    + .icon-checkbox-show {
    background-image: url(${checked});
  }
`;

const AboutMe = styled(Section)`
  box-sizing: border-box;
  .title {
    font-weight: bold;
    color: #503d66;
  }
`;

const SelectFile = styled.input`
  color: transparent;
  display: block;
  cursor: pointer;
  &::-webkit-file-upload-button {
    visibility: hidden;
  }
  ::before {
    border: 1px solid #e33aa9;
    box-sizing: border-box;
    border-radius: 14px;

    content: "Subir imagen";
    color: #222;
    display: inline-block;
    padding: 6px 40px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #e33aa9;
  }
  :active {
    outline: 0;
  }
  :active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }
`;

const ProfileImage = styled.div`
  width: 83px;
  height: 83px;
  .img {
    border-radius: 50%;
    width: 83px;
    height: 83px;
    object-fit: cover;
  }
`;

const ImageContainer = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  height: 85px;

  .containerSelectFile {
    min-width: 200px;
  }
`;

const ContainerPlan = styled.section`
  display: flex;
  flex-direction:column;
  width: 100%;
`;

const S3_BUCKET = process.env.REACT_APP_IMAGES_PROFILE_BUCKET;
const REGION = "us-east-1";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_KUTS3,
  secretAccessKey: process.env.REACT_APP_AKUTS3,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

export default function PersonalData(props) {
  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem("user")));
  const [userAux, setUserAux] = useState(
    JSON.parse(sessionStorage.getItem("user"))
  );
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageUploaded, setImageUploaded] = useState(user.src);
  const [dataImage, setDataImage] = useState({});
  const [countrySelected, setCountrySelected] = useState(user.country);
  const paises = [
    { id: 1, name: "México" },
    { id: 2, name: "Argentina" },
    { id: 3, name: "Colombia" },
    { id: 4, name: "Costa Rica" },
    { id: 5, name: "Ecuador" },
    { id: 6, name: "Salvador" },
    { id: 7, name: "Honduras" },
    { id: 8, name: "Panama" },
    { id: 9, name: "Perú" },
  ];

  const { history, child } = props;

  const onSubmit = async (e) => {
    e.preventDefault();
    let newValues = {};
    document.querySelector("#nameInput").value !== "" &&
      (newValues.name = document.querySelector("#nameInput").value);
    document.querySelector("#lastNameInput").value !== "" &&
      (newValues.last_name = document.querySelector("#lastNameInput").value);
    document.querySelector("#birthDateInput").value !== "" &&
      (newValues.birth_date = document.querySelector("#birthDateInput").value);
    document.querySelector("#addressInput").value !== "" &&
      (newValues.address = document.querySelector("#addressInput").value);
    document.querySelector("#zipCodeInput").value !== "" &&
      (newValues.zip_code = document.querySelector("#zipCodeInput").value);
    document.querySelector("#telephoneInput").value !== "" &&
      (newValues.telephone = document.querySelector("#telephoneInput").value);
    document.querySelector("#aboutMeInput").value !== "" &&
      (newValues.about_me = document.querySelector("#aboutMeInput").value);
    document.querySelector("#positionInput").value !== "" &&
      (newValues.position = document.querySelector("#positionInput").value);
    document.querySelector("#selectInput").value !== "" &&
      (newValues.country = document.querySelector("#selectInput").value);
    document.querySelector("#check-notify").checked
      ? (newValues.email_notify = 1)
      : (newValues.email_notify = 0);
    setUser(newValues);

    try {
      await axios.put(
        `${process.env.REACT_APP_USER_ENDPOINT}?boolUser=1`,
        newValues,
        {
          headers: {
            Authorization: sessionStorage.getItem("jwt"),
          },
        }
      );
      if (dataImage.src) {
        const file = Buffer.from(
          dataImage.src.replace(/^data:image\/\w+;base64,/, ""),
          "base64"
        );
        const params = {
          Body: file,
          ContentEncoding: "base64",
          Bucket: S3_BUCKET,
          Key: `id-${userAux.id_user}/${user.id_user}.png`,
        };
        setLoading(true);
        await myBucket.putObject(params).promise();
        setLoading(false);
      }

      let response = await axios.get(process.env.REACT_APP_USER_ENDPOINT, {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      });
      // const picture = getProfilePicture(user.id_user, 83, 83);
      const newUserInfo = JSON.parse(response.data.body).data[0];
      newUserInfo.src = `https://${
        process.env.REACT_APP_IMAGES_PROFILE_BUCKET
      }.s3.amazonaws.com/id-${user.id_user}/${
        user.id_user
      }.png?${new Date().getTime()}`;
      sessionStorage.setItem("user", JSON.stringify(newUserInfo));
      setUser(newUserInfo);
      setActive(false);
    } catch (err) {
      console.log(err.message);
    }
  };

  const PreviewImage = () => {
    const file = document.getElementById("profile").files[0];
    if (file) {
      var oFReader = new FileReader();
      oFReader.fileName = file.name;
      oFReader.readAsDataURL(file);
      oFReader.onload = function (oFREvent) {
        setImageUploaded(oFREvent.target.result);
        setDataImage({
          src: oFREvent.target.result,
          name: oFREvent.target.fileName,
        });
      };
    }
  };

  return (
    <>
    <ContainerPlan>
      <Plan history={history} hasChild={child !== null} />
    </ContainerPlan>
    <MainContainer>
      <Header>
        <div>
          <figure>
            <img src={profile} alt="Section icon" />
          </figure>
          <p>Información personal</p>
        </div>
        {!active && (
          <figure onClick={() => setActive(!active)} className="edit">
            <img src={edit} alt="Edit" />
          </figure>
        )}
      </Header>
      <CheckNotification>
        <div>
          {active ? (
            <label className="custom-radio-checkbox">
              <input
                className="custom-radio-checkbox__input"
                id="check-notify"
                type="checkbox"
              />
              <span className="icon-checkbox icon-checkbox-show"></span>
              <span className="item">
                Deseo recibir notificación cuando fábrica de contenido termine
                uno de mis productos
              </span>
            </label>
          ) : (
            <>
              <label className="custom-radio-checkbox disabled">
                <input
                  className="custom-radio-checkbox__input"
                  id="check-notify"
                  type="checkbox"
                  disabled
                />
                <span className="icon-checkbox icon-checkbox-show"></span>
                <span className="item">
                  Deseo recibir notificación cuando fábrica de contenido termine
                  uno de mis productos
                </span>
              </label>
            </>
          )}
        </div>
      </CheckNotification>
      <AboutMe>
        <p className="title">Acerca de mí</p>
        {active ? (
          <Textarea
            id="aboutMeInput"
            placeholder="Agrega una breve descripción sobre ti"
            defaultValue={user.about_me ? user.about_me : ""}
          ></Textarea>
        ) : (
          <p>{user.about_me ? user.about_me : "---"}</p>
        )}
      </AboutMe>
      <BasicData>
        <div>
          <p className="title">Nombre</p>
          {active ? (
            <Input
              id="nameInput"
              placeholder="Nombre"
              defaultValue={user.name ? user.name : ""}
            />
          ) : (
            <p>{user.name ? user.name : "---"}</p>
          )}
        </div>
        <div>
          <p className="title">Apellido</p>
          {active ? (
            <Input
              id="lastNameInput"
              placeholder="Apellidos"
              defaultValue={user.last_name ? user.last_name : ""}
            />
          ) : (
            <p>{user.last_name ? user.last_name : "---"}</p>
          )}
        </div>
        <div>
          <p className="title">Fecha de nacimiento</p>
          {active ? (
            <Input
              placeholder="MM-DD-YYYY"
              type="date"
              id="birthDateInput"
              defaultValue={
                user.birth_Date ? user.birth_Date.split("T")[0] : ""
              }
            />
          ) : (
            <p>{user.birth_Date ? user.birth_Date.split("T")[0] : "---"}</p>
          )}
        </div>
        <div>
          <p className="item">Puesto</p>
          {active ? (
            <Input
              id="positionInput"
              placeholder="Puesto"
              defaultValue={user.position ? `${user.position}` : ""}
            />
          ) : (
            <p>{user.position ? `${user.position}` : "---"}</p>
          )}
        </div>
      </BasicData>
      <BasicData>
        <div>
          <figure>
            <img src={location} alt="Location" />
          </figure>
          <h4>Ubicación</h4>
        </div>
        <div>
          <p className="title">País</p>
          {active ? (
            <Select
              id="selectInput"
              list={paises}
              defaultValue={countrySelected}
              onChange={(value) => {
                setCountrySelected(value.name);
              }}
              left
            />
          ) : (
            <p>{user.country ? user.country : "---"}</p>
          )}
        </div>
        <div>
          <p className="title">Dirección</p>
          {active ? (
            <Input
              id="addressInput"
              placeholder="Agrega tu dirección"
              defaultValue={user.address ? user.address : ""}
            />
          ) : (
            <p>{user.address ? user.address : "---"}</p>
          )}
        </div>
        <div>
          <p className="title">Código postal</p>
          {active ? (
            <Input
              id="zipCodeInput"
              placeholder="Código postal"
              defaultValue={user.zip_code ? user.zip_code : ""}
            />
          ) : (
            <p>{user.zip_code ? user.zip_code : "---"}</p>
          )}
        </div>
        <div>
          <p className="title">Teléfono</p>
          {active ? (
            <Input
              id="telephoneInput"
              placeholder="Teléfono"
              defaultValue={user.telephone ? user.telephone : ""}
            />
          ) : (
            <p>{user.telephone ? user.telephone : "---"}</p>
          )}
        </div>
        <div>
          <p className="title">Correo electrónico</p>
          <p>{JSON.parse(sessionStorage.getItem("user")).email}</p>
        </div>
      </BasicData>
      <BasicData>
        <div>
          <figure>
            <img src={account} alt="Profile" />
          </figure>
          <h4>Imagen de perfil</h4>
        </div>
        <ImageContainer>
          <div className="containerSelectFile">
            {active && (
              <SelectFile
                type="file"
                id="profile"
                name="profile"
                accept="image/png"
                onChange={PreviewImage}
              />
            )}
          </div>
          <ProfileImage>
            <ReactImageFallback
              src={imageUploaded}
              fallbackImage={defaultProfile}
              className="img"
            />
          </ProfileImage>
        </ImageContainer>
      </BasicData>
      {active && (
        <BasicData>
          {!loading && (
            <SubmmitButton
              onClick={(e) => {
                onSubmit(e);
              }}
            >
              <p>Guardar cambios</p>
            </SubmmitButton>
          )}
          {loading && <Loading />}
        </BasicData>
      )}
    </MainContainer>
    </>
    
  );
}
