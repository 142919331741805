import { isEqual } from "lodash";
import { useCallback, useState, useEffect, memo } from "react";
import ReactImageFallback from "react-image-fallback";
import { Tooltip } from "contentoh-components-library";
import { Container, ServiceDetail } from "./styles";
import { getRetailerImage } from "../../_utils/helper";
import defaultProfile from "../../../assets/IconComponents/defaultProfileImage.svg";
import spinner from "../../../assets/defaultImages/Spinner.gif";

const servicesDetail = [
  {
    icon: "dataset",
    service: "datasheet",
    name: "Ficha Técnica",
    short: "atri.",
  },
  {
    icon: "article",
    service: "description",
    name: "Descripción",
    short: "desc.",
  },
  { icon: "image", service: "image", name: "Imágenes", short: "imag." },
];

const RetailerItem = ({ services, retailer, price, ...props }) => {
  const [servicesDisable, setServicesDisable] = useState([]);
  const [servicesSelected, setServicesSelected] = useState([]);
  const [classMIU] = useState("material-icons-outlined");

  const includeService = useCallback(
    (service) => {
      if (servicesDisable.includes(service)) return "disable";
      if (servicesSelected.includes(service)) return "service__select";
      return "service";
    },
    [servicesDisable, servicesSelected]
  );

  const getPrices = (retailerPrices = {}) => {
    let servicesWithService = ["datasheet", "description", "image"];
    Object.entries(retailerPrices).forEach(([service, { price, discount }]) => {
      if (price === 0 && discount === 0) {
        setServicesDisable((prev) => [...prev, service]);
        return;
      }
      servicesWithService = servicesWithService.filter((f) => f !== service);
    });
    setServicesDisable((prev) => [
      ...new Set([...prev, ...servicesWithService]),
    ]);
  };

  useEffect(() => {
    getPrices(price);
    setServicesSelected(services);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [price, services]);

  const updateServiceSelect = (service) => {
    if (!props.onChangeService) return;
    if (servicesSelected.includes(service)) {
      setServicesSelected((prev) => prev.filter((f) => f !== service));
      props.onChangeService(service, false);
    } else {
      setServicesSelected((prev) => [...prev, service]);
      props.onChangeService(service, true);
    }
  };

  return (
    <Container>
      <div className="retailer">
        <div>
          <ReactImageFallback
            src={getRetailerImage(retailer.id)}
            fallbackImage={defaultProfile}
            initialImage={spinner}
            alt={retailer.name}
            className="retailer__img"
            onError={() => {}}
          />
          {retailer.name}
        </div>
        <div>
          {servicesDetail.map(({ icon, service, name, short }) => (
            <Tooltip
              key={service}
              componentTooltip={name}
              children={
                <ServiceDetail>
                  <span
                    onClick={() => updateServiceSelect(service)}
                    className={`${classMIU} ${includeService(service)}`}
                  >
                    {icon}
                  </span>
                  <span className={`${includeService(service)}`}>
                    {price[service]?.inputsReceivable?.length ?? 0} {short}
                  </span>
                </ServiceDetail>
              }
              classNameTooltip="container-tooltip"
              position="topCenter"
            />
          ))}
        </div>
      </div>
    </Container>
  );
};

export default memo(RetailerItem, isEqual);
