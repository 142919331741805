import React, { useState, useEffect } from "react";
import { CustomSelect } from "contentoh-components-library";
import { Container } from "./styles";
import { CustomSlider } from "../customSlider";

export default function ContainerMain(props) {
  const [selectedLists, setSelectedLists] = useState([]);
  const [customLists, setCustomLists] = useState([]);
  const [selectedProviders, setSelectedProviders] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [priceRange, setPriceRange] = useState(props.currentPriceRange);
  const [percentageRange] = useState(props.currentPercentageRange);
  const [isRetailer] = useState(
    JSON.parse(sessionStorage.getItem("user")).is_retailer
  );

  useEffect(() => {
    // seleccionar la lista del redirect
    if (props.locationRedirect?.state) {
      const listID = Number(props.locationRedirect.state.listId);
      if (!isNaN(listID)) {
        setSelectedLists([listID]);
        const lists = props.lists.map((list) => {
          if (list.id === listID) list.isSelected = true;
          else list.isSelected = false;
          return list;
        });
        setCustomLists(lists);
      } else {
        setCustomLists(props.lists.slice());
      }
    } else {
      setCustomLists(props.lists.slice());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.lists]);

  useEffect(() => {
    props.updateFilters("list", selectedLists);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLists]);

  useEffect(() => {
    props.updateFilters("provider", selectedProviders);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProviders]);

  useEffect(() => {
    props.updateFilters("department", selectedDepartments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDepartments]);

  useEffect(() => {
    props.updateFilters("price", priceRange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceRange]);

  useEffect(() => {
    props.updateFilters("percentage", percentageRange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [percentageRange]);

  return (
    <Container className={props.className}>
      {props.componentFilterInput && props.componentFilterInput}

      {/* component select para listas */}
      <CustomSelect
        customSelectId="select-lists"
        selectLabel="Todas las listas"
        showSearchBar
        placeHolder={"Buscar Lista"}
        options={customLists}
        parameterArray={selectedLists}
        setParameterArray={setSelectedLists}
      />
      {/* component select para proveedores */}
      {isRetailer ? (
        <CustomSelect
          customSelectId="select-providers"
          selectLabel="Todos los proveedores"
          showSearchBar
          placeHolder={"Buscar proveedor"}
          defaultOption="Todos los proveedores"
          options={props.providersList}
          parameterArray={selectedProviders}
          setParameterArray={setSelectedProviders}
        />
      ) : null}
      {/* component select para departamentos */}
      <CustomSelect
        customSelectId="select-departments"
        selectLabel="Todos los departamentos"
        showSearchBar
        placeHolder={"Buscar departamento"}
        defaultOption="Todos los departamentos"
        options={props.departmentsList}
        parameterArray={selectedDepartments}
        setParameterArray={setSelectedDepartments}
      />
      {/* component select para precios */}
      <CustomSelect
        customSelectId="select-prices"
        selectLabel="Todos los precios"
        options={[]}
        customOptions={
          <CustomSlider
            className="customSelect-customSlider"
            labelIconLeft={"$"}
            valueMin={props.priceRange.min}
            initialMin={priceRange?.min}
            valueMax={props.priceRange.max}
            initialMax={priceRange?.max}
            step={5}
            distanceMinBetweenValues={0}
            onChange={(min, max) => {
              setPriceRange({ min, max });
            }}
          />
        }
      />

      {props.containerButtons && props.containerButtons}
    </Container>
  );
}
