import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import AWS from "aws-sdk";
import { getOnboarding } from "./utils/getOnboarding";
import { DragAndDropPhases } from "contentoh-components-library/dist/components/organisms/DragAndDropPhases";
import { ButtonV2 } from "contentoh-components-library/dist/components/atoms/ButtonV2";
import { Modal } from "contentoh-components-library/dist/components/organisms/Modal";

// SVG assets
// Styled components

const MainContainer = styled.div`
  border: none;
  box-sizing: border-box;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:20px;
`;

const Header = styled.div`
  width:100%;
  display: flex;
  justify-content: space-between;
  margin-top:10px;
  .button-phase {
    cursor: pointer;
    padding: 9px 18px;
    background-color: #ffffff;
    border-radius: 30px;
    font-family: RobotoMedium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    border: none;
    color: #e33aa9;
    border:1px solid #e33aa9;
  }
  .button-save {
    cursor: pointer;
    font-family: RobotoMedium, sans-serif;
    font-style: normal;
    font-weight: 500;
    border: none;
    button{
      padding: 9px 18px;
    }
  }
  .modal-save{
    width:20%;
    background:transparent;
  }
`;

const FlowsContainer = styled.div`
  width:100%;
`;

export default function Flows(props) {
  const token = sessionStorage.getItem("jwt");
  const [modalData, setModalData] = useState({ 
    show: false,
    title: "Actualización completa",
    message: "",
    icon: "success",
  });

  return (
    <>
    <MainContainer>
      <FlowsContainer>
        <DragAndDropPhases token={token}></DragAndDropPhases>
      </FlowsContainer>
      <Modal {...modalData}
          onClickBtnDefault={() =>
            setModalData((prev) => ({ ...prev, show: false }))
          }></Modal>
    </MainContainer>
    </>
    
  );
}
