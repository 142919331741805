import { SelectCustom, Container, BackgroundEnd } from "./style";

export default function Select(props) {
  const validValue = (v1, v2) => `${v1}` === `${v2}`;
  return (
    <Container width={props.width}>
      <SelectCustom id={props.id} width={props.width} onChange={props.onChange}>
        <option value="" disabled selected>
          {props.placeholder}
        </option>
        {props.options &&
          props.options.map((item, i) => (
            <option
              key={i}
              value={item.value ? item.value : item}
              selected={
                item.value
                  ? validValue(item.value, props.valueSelected) && "selected"
                  : validValue(item, props.valueSelected) && "selected"
              }
            >
              {item.name ? item.name : item}
            </option>
          ))}
      </SelectCustom>
      <BackgroundEnd />
    </Container>
  );
}
