function IconDownloadPDF() {
    return (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path d="M3.86619 7.33969L7.14744 11.0897C7.23651 11.1909 7.36494 11.25 7.49994 11.25C7.63494 11.25 7.76338 11.1909 7.85244 11.0897L11.1337 7.33969C11.2546 7.20094 11.2846 7.005 11.2078 6.83719C11.1318 6.67031 10.9649 6.5625 10.7812 6.5625L8.90619 6.5625L8.90619 0.46875C8.90619 0.21 8.69619 -2.23261e-07 8.43744 -2.45882e-07L6.56244 -4.09799e-07C6.30369 -4.3242e-07 6.09369 0.21 6.09369 0.46875L6.09369 6.5625L4.21869 6.5625C4.03494 6.5625 3.86807 6.66937 3.79213 6.83719C3.71619 7.005 3.74432 7.20187 3.86619 7.33969Z" fill="#817393" />
                <path d="M12.6562 10.3125V13.125H2.34375V10.3125H0.46875V14.0625C0.46875 14.5809 0.88875 15 1.40625 15H13.5938C14.1122 15 14.5312 14.5809 14.5312 14.0625V10.3125H12.6562Z" fill="#817393" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="15" height="15" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
export default IconDownloadPDF;