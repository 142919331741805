import {
  getFilterOptions,
  statusOption,
  getKeyValue,
  getKeyAttr,
} from "./generalUtils";
import moment from "moment";

import { getRetailerFilters } from "../../../_utils/data";
import { filterEstatusOptions } from "../../../_utils/helper";
export const setFilters = async (
  productList,
  productsType,
  setEnabledFilters,
  setFiltersOptions
) => {
  const dataCompany = JSON.parse(sessionStorage.getItem("company"));
  let filtersUniqueValues = {};

  const contentohProductsRequested = productsType === "contentoh";
  const ProviderProductsRequested = productsType === "provider";
  const databaseFilters = await getRetailerFilters();
  let generalFilters = [
    { name: "Categoría", active: false },
    { name: "Compania", active: false },
    { name: "Estatus", active: false },
    { name: "Marca", active: false },
  ];
  if (contentohProductsRequested || ProviderProductsRequested) {
    generalFilters.push({ name: "Orden", active: false });

    filtersUniqueValues.Estatus = statusOption;
    let orderFilterOptions = getFilterOptions(productList, "orderId");
    if (orderFilterOptions.length === 0)
      orderFilterOptions = getFilterOptions(productList, "id_order");
    filtersUniqueValues.Orden = orderFilterOptions;
  }

  filtersUniqueValues.Categoría = databaseFilters.categoryOptions;
  filtersUniqueValues.Compania = databaseFilters.companyOptions;
  filtersUniqueValues.Marca = databaseFilters.brandOptions;
  filtersUniqueValues.Estatus = filterEstatusOptions;

  if (dataCompany?.is_retailer && productsType === "general") {
    let filterRetailer = {
      name: `Alcances`,
      active: false,
    };
    generalFilters.push(filterRetailer);
    const retailersFormat = dataCompany.retailers.map((element) => ({
      name: element.name,
      value: element.id,
    }));
    if (retailersFormat.length > 1)
      filtersUniqueValues.Alcances = retailersFormat;
  }

  if (dataCompany?.is_retailer && productsType === "onboarding") {
    // Fase
    let filterPhase = {
      name: "Fase",
      active: false,
    };
    generalFilters.push(filterPhase);

    const phaseSet = new Set();
    const phaseFormat = productList
      .map((element) => {
        const phaseName = element.article.phaseName;
        const phaseId = element.article.phaseId;

        // Agregar a Set solo si no está presente
        if (!phaseSet.has(phaseId)) {
          phaseSet.add(phaseId);
          return { name: phaseName, value: phaseId };
        }
        return null; // Si ya existe, devolver null para ser filtrado después
      })
      .filter(Boolean);

    if (phaseFormat.length > 0) filtersUniqueValues.Fase = phaseFormat;

    // Proveedor
    let filterProvider = {
      name: "Proveedor",
      active: false,
    };
    generalFilters.push(filterProvider);

    const providerSet = new Set();
    const providerFormat = productList
      .map((element) => {
        const providerName = element.article.company_name;
        const providerId = element.article.company_id;

        if (!providerSet.has(providerId)) {
          providerSet.add(providerId);
          return { name: providerName, value: providerId };
        }
        return null;
      })
      .filter(Boolean);

    if (providerFormat.length > 0)
      filtersUniqueValues.Proveedor = providerFormat;

    // Grupo
    let filterGroup = {
      name: "Grupo",
      active: false,
    };
    generalFilters.push(filterGroup);

    const groupSet = new Set();
    const groupFormat = productList
      .map((element) => {
        const groupName = element.article.groupName;
        const groupId = element.article.groupId;

        if (!groupSet.has(groupId)) {
          groupSet.add(groupId);
          return { name: groupName, value: groupId };
        }
        return null;
      })
      .filter(Boolean);

    if (groupFormat.length > 0) filtersUniqueValues.Grupo = groupFormat;
  }

  setEnabledFilters(generalFilters);
  setFiltersOptions(filtersUniqueValues);
};

export const filterProductList = (
  userCheckedProducts,
  originalProductList,
  filtersItems,
  setStateFlags,
  setArticlesList
) => {
  let listToFilter = userCheckedProducts
    ? originalProductList.slice().map((item) => {
        item.checked = false;
        return item;
      })
    : originalProductList.slice();

  filtersItems?.forEach((filterParams) => {
    const filterKey = getKeyAttr(filterParams.filter);
    listToFilter = listToFilter.filter((product) =>
      filterParams.values.includes(getKeyValue(product, filterKey))
    );
  });
  if (listToFilter.length === 0) {
    setStateFlags((currentFlags) => {
      currentFlags.warningMessage =
        "No se encontraron productos con esas características";
      return currentFlags;
    });
  }
  setArticlesList(listToFilter);
};

export const updateReportFilters = (
  filters,
  setReportFilters,
  setAditionalFilter
) => {
  try {
    const filtersActive = filters;
    let newFilters = {};
    setAditionalFilter();
    filtersActive.forEach((filterItem) => {
      switch (filterItem.filter) {
        case "upc":
          newFilters.filterByUpc = filterItem.values;
          break;
        case "orderId":
          newFilters.filterByOrder = filterItem.values;
          break;
        case "country":
          newFilters.filterByCountry = filterItem.values;
          break;
        case "company_name":
          newFilters.filterByCompany = filterItem.values;
          break;
        case "categories":
          newFilters.filterByCategory = filterItem.values.map((item) => {
            return item.replace(/\|/g, "/");
          });
          break;
        case "status":
          newFilters.filterByEstatus = filterItem.values;
          break;
        case "prio":
          newFilters.filterByPrio = filterItem.values;
          break;
        case "retailers":
          newFilters.filterByRetailer = filterItem.values;
          break;
        case "date":
          newFilters.filterByDate = {
            startDate: moment(filterItem.values.startDate)
              .utc()
              .format("YYYY-MM-DD"),
            endDate: moment(filterItem.values.endDate)
              .utc()
              .format("YYYY-MM-DD"),
          };
          break;
        default:
          break;
      }
    });

    setReportFilters(newFilters);
  } catch (err) {
    console.log(err, "Unable to set filters for report");
  }
};
