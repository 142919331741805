import styled from "styled-components";

// GENERAL COMPONENTS
export const MainContainer = styled.div`
  box-sizing: border-box;
  height: calc(100% - 100px);
  width: 100%;
  margin-right: 1%;
  padding: 0.5%;
  .img {
    height: 20px;
    width: 20px;
  }

  .table-products {
    .table-column {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      box-sizing: border-box;

      &:first-child {
        div {
          width: 30px;
          height: 30px;
          padding: 0;

          input {
            margin: auto;
          }
        }
      }

      & + .table-column {
        border-left: 1px solid #f0eef2;
      }
    }
    .table-row {
      cursor: pointer;

      &:nth-child(odd) {
        background-color: #fafafa;
      }

      &:hover {
        background-color: #d4d1d7;
      }
    }

    .ReactVirtualized__Grid {
      will-change: auto !important;
      overflow:scroll!important;
      padding-bottom:24%;

      .ReactVirtualized__Grid__innerScrollContainer {
        overflow: unset !important;
      }
    }
  }
  .container-grid {
    height:100%;
    display:flex;
    flex-direction:column;
    gap:1rem;
    margin-left:0px;
  }
`;
export const Title = styled.p`
  font-family: Raleway;
  font-size: 30px;
  font-weight: 400;
  color: #b12d84;
  line-height: 35px;
  margin-bottom: 1.3%;
`;

export const SubTitle = styled.p`
  font-family: Avenir Next;
  width: 38%;
  font-size: 18px;
  line-height: 21px;
  color: #817393;
  margin-bottom: 3.2%;
`;

export const LeftTableContainer = styled.div`
  width: 43%;
  max-width: 43%;
  overflow-y: auto;
  overflow-x: auto;
`;

export const RightTableContainer = styled.div`
  width: 57%;
  max-width: 57%;
  overflow-x: auto;
`;

// FILTER UPPER SECTION COMPONENTS
export const FilterDiv = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 72px;
  border-top: 1px solid #f0eef2;
  padding-top: 1.1%;
`;

export const CustomInput = styled.div`
  display: ${(props) => props.tableInput && "flex"};
  justify-content: ${(props) => props.tableInput && "center"};
  width: ${(props) => (props.tableInput ? "100%" : "14%")};
  position: relative;
  margin-left: 5px;
`;

export const TableSelect = styled.select`
  text-align-last: center;
  width: fit-content;
  color: #503d66;
  appearance: none;
  border: none;
  font-size: 14px;
  font-family: Avenir Next;
  font-weight: 600;
  :hover {
    cursor: pointer;
  }
`;
export const CustomInputIcon = styled.div`
  position: absolute;
  top: ${(props) => (props.tableInput ? "1px" : "7px")};
  right: ${(props) => (props.tableInput ? "0" : "8px")};
  pointer-events: none;
  img {
    height: 100%;
  }
`;

export const FilterSelect = styled.select`
  border: 1px solid #ed944d;
  box-sizing: border-box;
  padding: 0 20% 0 5%;
  color: #ed944d;
  font-weight: 500;
  width: 100%;
  height: 30px;
  border-radius: 30px;
  appearance: none;
  :hover {
    cursor: pointer;
  }
  > option {
    color: #ed944d;
  }
`;
export const SearchBar = styled.input`
  border: 1px solid #f0eef2;
  box-sizing: border-box;
  padding: 0 5%;
  width: 100%;
  height: 30px;
  border-radius: 30px;
`;
export const FilterButton = styled.button`
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  border-radius: 60px;
  border: none;
  background-color: #fafafa;
  margin-right: 5px;
  :hover {
    cursor: pointer;
  }
`;

// TABLE COMPONENTS
export const TablesContainer = styled.div`
  display: flex;
  height: calc(100% - 230px);
  width: 100%;
  font-family: Avenir Next;
  overflow: auto;
`;

export const LeftTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  box-sizing: border-box;
  border-right: 1px solid #d4d1d7;
`;

export const RightTable = styled.table`
  box-sizing: border-box;
  width: 100%;
  > figure {
    img {
      width: 20px;
      border-radius: 50%;
    }
  }
`;

export const TableHeader = styled.th`
  background-color: #fff;
  color: #503d66;
  font-weight: 600;
  font-size: 14px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;

  &:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const TableRow = styled.tr`
  box-sizing: border-box;
  height: 30px;
  background-color: ${(props) => (props.index % 2 === 0 ? "#FAFAFA" : "white")};
  width: 100%;

  :hover {
    cursor: pointer;
  }
`;

export const TableCol = styled.td`
  box-sizing: border-box;
  border-left: ${(props) => (props.noBorder ? "none" : "1px solid #F0EEF2")};
  border-right: ${(props) =>
    props.noLastBorder ? "none" : " 1px solid #F0EEF2"};
  vertical-align: middle;
  text-align: center;
  font-size: 13px;
  color: #817393;
  min-width: ${(props) => {
    switch (props.colType) {
      // Left Table
      case "Reg":
        return "46.39px";
      case "Orden":
        return "57.8px";
      case "UPC":
        return "82.17px";
      case "Nombre":
        return "71.42px";
      case "Marca":
        return "81.62px";
      case "Estatus":
        return "107px";
      case "Prio":
        return "39px";
      // Right Table
      case "Categoria":
        return "99px";
      case "Cadenas":
        return "70px";
      case "Datos":
        return "64px";
      case "Asig":
        return "43px";
      case "Stat":
        return "45px";
      default:
        return;
    }
  }};
  > figure {
    img {
      width: 30px;
    }
  }

  &.checkbox-container {
    min-width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const SpanEstatus = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  height: 20px;
  width: 82px;
  font-size: 11px;
  background-color: ${(props) => {
    switch (props.estatus) {
      case "AC":
      case "ACA":
      case "AP":
      case "AA":
        return "#18A0FB";

      case "Terminado":
        return "#18A0FB";
      case "Proceso":
        return "#ED9A4D";
      case "Exportado":
        return "#71DE56";
      case "Actualizar":
        return "#EDD34D";
      case "Por recibir":
        return "#E67432";
      case "Revisión":
        return "#D74DED";
      default:
        return;
    }
  }};
  padding: 2% 7%;
  border-radius: 3px;
  color: ${(props) => (props.estatus === "Actualizar" ? "#969696" : "#FFFFFF")};
`;

export const Stat = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 12px;
  justify-content: center;
  margin: 0 auto;
  height: 20px;
  width: 30px;
  background-color: ${(props) => {
    switch (props.estatus) {
      case "A":
        return "#18A0FB";
      case "AA":
        return "#18A0FB";
      case "AAA":
        return "#18A0FB";
      case "RCC":
        return "#D74DED";
      case "E":
        return "#71DE56";
      case "P":
        return "#ED9A4D";
      case "ES":
        return "#E67432";
      case "L":
        return "#D74DED";
      case "C":
        return "#EFD963";
      default:
        return;
    }
  }};
  padding: 2% 7%;
  border-radius: 30px;
  color: ${(props) => (props.estatus === "Actualizar" ? "#969696" : "#FFFFFF")};
`;

export const Icon = styled.figure`
  & > img {
    width: ${(props) => (props.width ? props.width : "30px")};
    height: ${(props) => (props.width ? props.width : "30px")};
    object-fit: contain;
  }
`;

export const TableWarning = styled.div`
  font-family: Avenir Next;
  box-sizing: border-box;
  font-size: 20px;
  color: #603888;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5%;
`;
