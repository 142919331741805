import React from "react";
import { tableStyle } from "../table_template";
import { Column, Table } from "react-virtualized";
import "react-virtualized/styles.css"; // only needs to be imported once
import { RowColumn, PrincipalGrid, GridRow } from "../styles";
import Assignment from "../../../_utils/TableComponents/Assignment/index";
import Percentage from "../../../_utils/TableComponents/Percentage/index";
import GridView from "../../../_utils/TableComponents/GridView/index.js";

const printMissing = (val) => {
  return val <= 0 ? 0 : val;
};
const GeneralProducts = (props) => {
  if (props.viewProducts === "list") {
    if (props.section === "RetailerProducts") {
      //TABLA PARA CUANDO ES RADIOSHAK CADENA
      return (
        <div style={{ width: "auto", overflowX: "auto" }}>
          <Table
            className="table-products"
            rowClassName="table-row"
            headerStyle={tableStyle}
            onRowClick={(evt) => {
              props.eventsFunctions.rowClicked(props.articlesList[evt.index]);
            }}
            height="720"
            width="2440"
            headerHeight={20}
            rowHeight={30}
            rowCount={props.articlesList.length}
            rowGetter={({ index }) => props.articlesList[index]}
            tableStyle={{
              overflow: "unset",
            }}
            rowStyle={{
              display: "flex",
              overflow: "none !important",
              textAlign: "center",
              width: "100%",
            }}
          >
            <Column
              className="table-column"
              flexGrow={1}
              maxWidth={35}
              minWidth={35}
              dataKey={"version"}
              style={{
                width: "35px",
                textAlign: "center",
                margin: "0",
              }}
              label={
                <input
                  id={"globalHeaderCheckbox"}
                  onClick={(evt) => {
                    evt.stopPropagation();
                    props.eventsFunctions.checkboxClicked(evt.target.checked);
                  }}
                  type="checkbox"
                />
              }
              cellRenderer={({ cellData, rowIndex, dataKey }) => (
                <input
                  key={`checkbox-${rowIndex}-${props.articlesList[rowIndex].checked}`}
                  type="checkbox"
                  defaultChecked={props.articlesList[rowIndex].checked}
                  onClick={(evt) => {
                    evt.stopPropagation();
                    props.eventsFunctions.checkboxClicked(
                      evt.target.checked,
                      props.articlesList[rowIndex]
                    );
                  }}
                />
              )}
            />

            <Column
              className="table-column"
              flexGrow={1}
              width={160}
              minWidth={160}
              label={"UPC"}
              dataKey={"article.upc"}
              style={{
                textAlign: "left",
                maxWidth: "12%",
                minWidth: "160px",
                flexGrow: 1,
                margin: 0,
              }}
              cellRenderer={({ cellData, rowIndex, dataKey }) => (
                <RowColumn>
                  <p>{props.articlesList[rowIndex].article.upc}</p>
                </RowColumn>
              )}
            />
            <Column
              className="table-column"
              flexGrow={1}
              width={270}
              minWidth={270}
              label={"Nombre"}
              dataKey={"article.name"}
              style={{
                textAlign: "left",
                maxWidth: "24%",
                minWidth: "270px",
                flexGrow: 1,
                margin: 0,
              }}
              cellRenderer={({ cellData, rowIndex, dataKey }) => (
                <RowColumn>
                  <p>{props.articlesList[rowIndex].article.name}</p>
                </RowColumn>
              )}
            />
            <Column
              className="table-column"
              flexGrow={1}
              width={150}
              minWidth={150}
              style={{
                textAlign: "left",
                maxWidth: "19%",
                minWidth: "150px",
                flexGrow: 1,
                margin: 0,
              }}
              label={"Proveedor"}
              dataKey={"article.company_name"}
              cellRenderer={({ cellData, rowIndex, dataKey }) => (
                <RowColumn>
                  <p>{props.articlesList[rowIndex].article.company_name}</p>
                </RowColumn>
              )}
            />
            <Column
              className="table-column"
              flexGrow={1}
              width={250}
              minWidth={250}
              label={"Categoría"}
              dataKey={"article.category"}
              style={{
                textAlign: "left",
                width: "8%",
                minWidth: "250px",
                flexGrow: 1,
                margin: 0,
              }}
              cellRenderer={({ cellData, rowIndex, dataKey }) => (
                <RowColumn>
                  <p>{props.articlesList[rowIndex].article.category}</p>
                </RowColumn>
              )}
            />

            <Column
              className="table-column"
              flexGrow={1}
              width={150}
              minWidth={150}
              label={"Fase"}
              dataKey={"article.phase"}
              style={{
                textAlign: "left",
                //width: "8%",
                maxWidth: "150px",
                flexGrow: 1,
                margin: 0,
              }}
              cellRenderer={({ cellData, rowIndex, dataKey }) => (
                <RowColumn>
                  <p>{props.articlesList[rowIndex].article.phaseName}</p>
                </RowColumn>
              )}
            />
            <Column
              className="table-column"
              flexGrow={1}
              width={150}
              minWidth={150}
              label={"Grupo"}
              dataKey={"article.group"}
              style={{
                textAlign: "left",
                width: "8%",
                minWidth: "150px",
                flexGrow: 1,
                margin: 0,
              }}
              cellRenderer={({ cellData, rowIndex, dataKey }) => (
                <RowColumn>
                  <p>{props.articlesList[rowIndex].article.groupName}</p>
                </RowColumn>
              )}
            />
            <Column
              className="table-column"
              flexGrow={1}
              maxWidth={100}
              minWidth={100}
              label={"Avance"}
              dataKey={"article.avance"}
              style={{
                textAlign: "center",
                flexGrow: 1,
                margin: 0,
              }}
              cellRenderer={({ cellData, rowIndex, dataKey }) => (
                <div
                  style={{
                    width: "90%",
                    color: "var(--white-main, #FFF)",
                    "font-family": "Roboto",
                    "font-size": "12px",
                    "font-style": "normal",
                    "font-weight": "400",
                    "line-height": "normal",
                    background: "#2692F6",
                    border: "none",
                    "border-radius": "3px",
                    padding: "3px",
                  }}
                >
                  {props.articlesList[rowIndex].article.review_state}
                </div>
              )}
            />
          </Table>
        </div>
      );
    } else {
      //TABLA PARA CUANDO ES OTRA CADENA
      return (
        <div className="table-container">
          <Table
            className="table-products"
            rowClassName="table-row"
            headerStyle={tableStyle}
            onRowClick={(evt) => {
              props.eventsFunctions.rowClicked(props.articlesList[evt.index]);
            }}
            height={720}
            width={1440}
            headerHeight={20}
            rowHeight={30}
            rowCount={props.articlesList.length}
            rowGetter={({ index }) => props.articlesList[index]}
            tableStyle={{
              overflow: "unset",
              width: "100%",
              maxWidth:"1440px"
            }}
            rowStyle={{
              display: "flex",
              overflow: "unset",
              textAlign: "center",
              width: "100%",
              maxWidth:"1440px"
            }}
          >
            <Column
              className="table-column"
              flexGrow={1}
              maxWidth={35}
              minWidth={35}
              dataKey={"version"}
              style={{
                width: "35px",
                textAlign: "center",
                margin: "0",
              }}
              label={
                <input
                  id={"globalHeaderCheckbox"}
                  onClick={(evt) => {
                    evt.stopPropagation();
                    props.eventsFunctions.checkboxClicked(evt.target.checked);
                  }}
                  type="checkbox"
                />
              }
              cellRenderer={({ cellData, rowIndex, dataKey }) => (
                <input
                  key={`checkbox-${rowIndex}-${props.articlesList[rowIndex].checked}`}
                  type="checkbox"
                  defaultChecked={props.articlesList[rowIndex].checked}
                  onClick={(evt) => {
                    evt.stopPropagation();
                    props.eventsFunctions.checkboxClicked(
                      evt.target.checked,
                      props.articlesList[rowIndex]
                    );
                  }}
                />
              )}
            />
            <Column
              className="table-column"
              flexGrow={1}
              flexShrink={1}
              width={150}
              maxWidth={230}
              style={{
                textAlign: "left",
                maxWidth: "19%",
                minWidth: "150px",
                flexGrow: 1,
                flexShrink: 1,
                margin: 0,
              }}
              label={"Proveedor"}
              dataKey={"article.company_name"}
              cellRenderer={({ cellData, rowIndex, dataKey }) => (
                <RowColumn>
                  <p>{props.articlesList[rowIndex].article.company_name}</p>
                </RowColumn>
              )}
            />
            <Column
              className="table-column"
              flexGrow={1}
              flexShrink={1}
              width={70}
              maxWidth={120}
              style={{
                textAlign: "center",
                maxWidth: "8%",
                minWidth: "120px",
                flexGrow: 1,
                flexShrink: 1,
                margin: 0,
              }}
              label={"UPC"}
              dataKey={"article.upc"}
              cellRenderer={({ cellData, rowIndex, dataKey }) => (
                <RowColumn>
                  <p>{props.articlesList[rowIndex].article.upc}</p>
                </RowColumn>
              )}
            />
            <Column
              className="table-column"
              flexGrow={1}
              flexShrink={1}
              width={270}
              style={{
                textAlign: "left",
                maxWidth: "30%",
                flexGrow: 1,
                flexShrink: 1,
                margin: 0,
              }}
              label={"Nombre"}
              dataKey={"article.name"}
              cellRenderer={({ cellData, rowIndex, dataKey }) => (
                <RowColumn>
                  <p>{props.articlesList[rowIndex].article.name}</p>
                </RowColumn>
              )}
            />
            <Column
              className="table-column"
              flexGrow={1}
              flexShrink={1}
              width={250}
              style={{
                textAlign: "left",
                maxWidth: "36%",
                flexGrow: 1,
                flexShrink: 1,
                margin: 0,
              }}
              label={"Categoría"}
              dataKey={"article.category"}
              cellRenderer={({ cellData, rowIndex, dataKey }) => (
                <RowColumn>
                  <p>{props.articlesList[rowIndex].article.category}</p>
                </RowColumn>
              )}
            />
            <Column
              className="table-column"
              flexGrow={1}
              maxWidth={60}
              minWidth={60}
              style={{
                textAlign: "center",
                maxWidth: "60px",
                minWidth: "60px",
                flexGrow: 1,
                flexShrink: 1,
                margin: 0,
              }}
              label={"%"}
              dataKey={"article.percentage"}
              cellRenderer={({ cellData, rowIndex, dataKey }) => (
                <Percentage
                  percentage={props.articlesList[rowIndex].percentage}
                />
              )}
            />
          </Table>
        </div>
      );
    }
  } else {
    return (
      <PrincipalGrid>
        {props.articlesList.map((e, index) => {
          const listsFavorites =
            props.section !== "RetailerProducts" &&
            props.listsFavorites?.products?.list;

          return (
            <GridRow>
              <GridView
                setGridView={props.setGridView}
                dataProduct={e}
                percentage={props.articlesList[index].percentage}
                listsFavorites={listsFavorites}
                index={index}
                section={props.section}
                eventsFunctions={props.eventsFunctions}
              />
            </GridRow>
          );
        })}
      </PrincipalGrid>
    );
  }
};
export default GeneralProducts;
