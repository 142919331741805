import React, { useEffect, useState } from "react";
import axios from "axios";
import { CustomSelect } from "contentoh-components-library";
import { getOnboarding } from "./utils/getOnboarding";
import { MainContainer, Parrafo, BasicData } from "./utils/groupStyles";

// components
import GenericModal from "../../general/GenericModal";
import { InviteUser } from "./InviteUser";
import Loading from "../../general/loading";
import { Modal } from "contentoh-components-library/dist/components/organisms/Modal";
import { ButtonV2 } from "contentoh-components-library/dist/components/atoms/ButtonV2";
import { EditGroup } from "contentoh-components-library/dist/components/organisms/EditGroup";
import { Multiselect } from "contentoh-components-library/dist/components/atoms/Multiselect";
import { CheckBox } from "contentoh-components-library/dist/components/atoms/CheckBox";
import { CheckboxList } from "../../customInputs/CheckboxList/CheckboxList.jsx";
// SVG assets
import trash from "../../../assets/IconComponents/trashv2.svg";
import searchIcon from "../../../assets/IconComponents/search.svg";
import save from "../../../assets/IconComponents/save.svg";
import edit from "../../../assets/IconComponents/editv2.svg";
import groupEdit from "../../../assets/IconComponents/group_pink.svg";
import personAdd from "../../../assets/IconComponents/person_add.svg";
import close from "../../../assets/IconComponents/close.svg";
import face from "../../../assets/IconComponents/face.svg";

export default function GroupsUsers(props) {
  const [listUsers, setListUsers] = useState([]);
  const [listUsersFilter, setListUsersFilter] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [upgradePlanRedirect, setUpgradePlanRedirect] = useState(false);
  const [modalData, setModalData] = useState({
    show: false,
    title: "Actualización completa",
    message: "",
    icon: "success",
  });
  const [showInvite, setShowInvite] = useState(false);
  const [userName, setUserName] = useState("");
  const [colabLimitReached, setColabLimitReached] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const [userData, setUserData] = useState([]);
  const [allGroups, setAllGroups] = useState([]);
  const [attributesGroups, setAttributesGroups] = useState([]);
  const [groupsSelected, setGroupsSelected] = useState([]);
  const [groupsData, setGroupsData] = useState([]);
  const [showEditGroup, setShowEditGroup] = useState(false);
  const [rowsChecked, setRowsChecked] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [gruposSeleccionados, setGruposSeleccionados] = useState([]);
  const token = sessionStorage.getItem("jwt");

  const fetchData = async (groups = []) => {
    const groupsId = groups && groups.length > 0 ? groups.map(group => parseInt(group, 10)) : [];
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_USER_ENDPOINT}?isOnboarding=1&groupsIds=[${groupsId}]`,
        {
          headers: {
            Authorization: sessionStorage.getItem("jwt"),
          },
        }
      );
      const onboardingData = JSON.parse(response.data.body).data[3];
      if (onboardingData && Array.isArray(onboardingData)) {
        const userDataArray = [];
        const groupsArray = [];
        onboardingData.forEach(({ idUser, userEmail, userName, groups }) => {
          userDataArray.push({idUser, userEmail, userName});
          groups.forEach(({ groupId, groupName, userContentGroup }) => {
            if (userContentGroup===1) {
              groupsArray.push({
                groupId: groupId,
                groupName: groupName || null,
                userContentGroup: userContentGroup || null,
              });
            }
          });
        });
        setUserData(userDataArray);
        setListUsersFilter(onboardingData);
        //setAllGroups(groupsArray);
      //   const newSelectValues = {};
      //  listUsersFilter.forEach((user) => {
      //    newSelectValues[user.idUser] = user.groups
      //      ? user.groups.map((group) => group.groupName)
      //      : [];
      //  });
      // setSelectValues(newSelectValues);
      } else {
        console.error("onboardingData esta indefinido");
      }
    } catch (error) {
      console.error("Error al recuperar onboardingData:", error);
    } finally {
      setLoading(false);
    }
};

useEffect(() => {
  fetchData();
}, []); 

useEffect(() => {
  console.log("listUsersFilter",listUsersFilter)
}, [listUsersFilter]); 

useEffect(() => {
  if(selectedRows>0){
    setIsCheckboxChecked(true);
  }else{
    setIsCheckboxChecked(false);
  }
}, [selectedRows]);

  const onSubmit = async (body) => {
    try {
        const res = await axios.post(
          `${process.env.REACT_APP_INVITE_USER}`,
          body,
          {
            headers: {
              Authorization: sessionStorage.getItem("jwt"),
            },
          }
        );
        if (res.data.statusCode === 200) {
          setLoading(true);
          setModalData({
            img: face,
            message: `Se creó el usuario correctamente`,
            detail: "Se le envió un correo con una contraseña temporal",
            button2: { name: "Aceptar", action: () => setShow(false) },
          });
          setLoading(true);
        } else {
          setModalData({
            message: `Ocurrió un error al crear la cuenta`,
            detail: "Asegúrese que no se encuentra registrado actualmente",
            button2: { name: "Aceptar", action: () => setShow(false) },
          });
        }
        fetchData([]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(
    () => getOnboarding(setLoading, setAllGroups, setAttributesGroups),
    []
  );

    //Filtro Grupos
    useEffect(() => {
      setGroupsData(prev=>{
        return prev.map(item => {
          if(groupsSelected.includes(item.id)) return {...item,isSelected:true}
          return item
        })
      })
      fetchData(groupsSelected);
      setLoading(false)
  
   }, [groupsSelected]);
   
  

  const searchUser = (userName = "") => {
    try {
      const tempArray = userData.slice();
      const filteredUsers =
        userName.length > 0
          ? tempArray.filter((item) => {
              return item?.userName
                ?.toLowerCase()
                .includes(userName.toLowerCase());
            })
          : tempArray;

      setListUsersFilter(filteredUsers);
    } catch (error) {
      console.error("Error al buscar usuarios:", error);
    }
  };

  const onChangeName = (e) => {
    const newUserName = e.target.value;
    setUserName(newUserName);
    searchUser(newUserName);
  };

  useEffect(() => {

    if(groupsSelected.length) return
    
    const grupos = Object.entries(allGroups).map(([groupId, groupName, isSelected]) => ({
      value: groupId,
      label: groupName,
      isSelected: isSelected || false,
    }));

    setGroupsData(grupos);

  }, [allGroups]);

  //Elimina un grupo de usuarios
  const deleteSingleGroup = async (userIds, groupsIds) => {
    try {
      const body = {
        userIds,
        groupsIds,
      };
      console.log("ahi esta tu cochino body", body);
      {
        /*const response = await axios.post(
        `${process.env.REACT_APP_USERS_ONBOARDING_ENDPOINT}/delete`,
        body,
        {
          headers: {
            Authorization: sessionStorage.getItem("jwt"),
          },
        }
      );*/
      }
      setGruposSeleccionados([]);
      setSelectedUsers([]);
      setIsCheckboxChecked(false);
      setSelectedRows([]);
      fetchData();
    } catch (error) {
      console.error("Error al cambiar grupos de usuarios:", error);
      setLoading(false);
    }
  };

  //Elimina todos los grupos de usuarios seleccionados
  const masiveDeleteGroup = async (userIds) => {
    try {
      const body = {
        userIds,
        groupsIds: [],
      };
      const response = await axios.post(
        `${process.env.REACT_APP_USERS_ONBOARDING_ENDPOINT}/delete`,
        body,
        {
          headers: {
            Authorization: sessionStorage.getItem("jwt"),
          },
        }
      );
      console.log("borrado correctamente", response.data.body);
      setGruposSeleccionados([]);
      setSelectedUsers([]);
      setIsCheckboxChecked(false);
      setSelectedRows([]);
      fetchData();
    } catch (error) {
      console.error("Error al cambiar grupos de usuarios:", error);
      setLoading(false);
    }
  };

  const handlerSelectAll = (evt) => {
    if (evt.target.checked) {
      const allRowIds = listUsersFilter.map((rowData) => rowData.idUser);
      setRowsChecked(allRowIds);
      setSelectedRows(allRowIds);
      setIsCheckboxChecked(true);
    } else {
      setRowsChecked([]);
      setSelectedRows([]);
      setIsCheckboxChecked(false);
    }
  };

  const handlerSelectRow = (idUser) => {
    setRowsChecked((prevRowsChecked) => {
      if (prevRowsChecked.includes(idUser)) {
        setIsCheckboxChecked(false);
        setIsSaveButtonEnabled(false);
        return prevRowsChecked.filter((id) => id !== idUser);
      } else {
        setIsCheckboxChecked(true);
        setIsSaveButtonEnabled(true);
        return [...prevRowsChecked, idUser];
      }
    });

    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(idUser)) {
        return prevSelectedRows.filter((id) => id !== idUser);
      } else {
        return [...prevSelectedRows, idUser];
      }
    });
    setSelectedUsers((prevSelectedUsers) => [...prevSelectedUsers, idUser]);
  };

  const handleEditGroupClick = () => {
    setShowEditGroup(true);
  };

  const handleEditGroupClose = () => {
    setShowEditGroup(false);
    setIsCheckboxChecked(false);
    setSelectedRows([]);
    setSelectedUsers([]);
  };

  const handleDeleteGroup = () => {
    if (selectedUsers.length > 0) {
      setModalData({
        show: true,
        className: "modal-eliminar",
        message: "Los usuarios seleccionados serán eliminados.",
        customComponent: (
          <>
            <Parrafo>¿Estás seguro?</Parrafo>
          </>
        ),
        icon: "info",
        buttons: [
          <ButtonV2
            key="btn-Cancelar"
            type="pinkS2"
            label="Cancelar"
            size={12}
            onClick={() => {
              setModalData(false);
            }}
          />,
          <ButtonV2
            key="btn-Eliminar"
            type="red"
            label="Eliminar"
            size={12}
            className="button-users-delete"
            onClick={() => {
              if (selectedUsers.length > 0) {
                masiveDeleteGroup(selectedUsers);
              // } else {
              //   deleteSingleGroup(selectedUsers, gruposSeleccionados);
              }
              setModalData(false);
            }}
          />,
        ],
      });
    }
  };

  const handleSaveChanges = () =>{
    setModalData({
      show: true,
      className: "modal-save",
      title: "",
      message: `El cambio de grupo fue aplicado correctamente.`,
      icon: "successv2",
      buttons: [
        <ButtonV2
          key="btn-Aceptar-guardar"
          type="pink"
          label="Aceptar"
          size={12}
          onClick={() => {
            setModalData(false);
          }}
        />,
      ],
    })
    setGruposSeleccionados([]);
    setSelectedUsers([]);
    setIsCheckboxChecked(false);
    setSelectedRows([]);
    fetchData();
  }

  return loading ? (
    <Loading />
  ) : (
    <>
      <MainContainer>
        <div className="filters-container">
          <div className="filters">
            <div className="search-user-input">
              <img src={searchIcon} alt="search-icon" />
              <input
                type="text"
                placeholder="Buscar usuarios"
                value={userName}
                onChange={onChangeName}
              />
            </div>
            <CheckboxList
              id="group-select"
              items={groupsData}
              name="Grupo"
              defaultSearch=""
              defaultOption="Todos los grupos"
              placeholder="Buscar grupo"
              onChange={(selectedItems) => {
                if (selectedItems.length > 0) {
                  fetchData(selectedItems);
                }else{
                  fetchData(selectedItems)
                }
              }}
            />
          </div>
          <div className="buttons-container">
            {isCheckboxChecked ? (
              <>
                <button
                  className="trash-button"
                  onClick={() => {
                    handleDeleteGroup();
                  }}
                >
                  <img src={trash} alt="trash icon" />
                </button>
              </>
            ) : (
              <>
                <button
                  className="add-users-button circular-button-v2"
                  onClick={() => setShowInvite(true)}
                >
                  <img src={personAdd} alt="add icon" />
                </button>
                <button
                  className="edit-users-button circular-button-v2"
                  onClick={handleEditGroupClick}
                >
                  <img src={groupEdit} alt="edit icon" />
                </button>
              </>
            )}
            {isCheckboxChecked && (
              <button
              className="save-changes circular-button-v2"
              key="btn-Guardar"
              size={12}
              onClick={() => handleSaveChanges()}
            >
               <img src={save} alt="edit icon" />
               </button>
       
            )} 
          </div>
        </div>

        <BasicData>
          <div className="row header th-custom">
            <p className="col row gap-10">
              <CheckBox
                id={"globalHeaderCheckbox"}
                type="checkbox"
                typeColor="green"
                checked={selectedRows.length === listUsersFilter.length}
                onChange={handlerSelectAll}
              />
              Nombre
            </p>
            <p className="col text_center">Correo</p>
            <p className="col">Grupo</p>
          </div>
          {listUsersFilter.length > 0 ? (
          listUsersFilter.map((user) => (
            <div className="row" key={user.idUser}>
              <p className="col flex gap-10">
                <CheckBox
                  id={`checkbox-${user.idUser}`}
                  typeColor="green"
                  onChange={() => handlerSelectRow(user.idUser)}
                  checked={selectedRows.includes(user.idUser)}
                />
                {user.userName}
              </p>
              <p className="col">{user.userEmail}</p>
              <p className="col">
                {isCheckboxChecked ? (
                  <>
                  <Multiselect
                    arrayData={listUsersFilter}
                    userId={user.idUser}
                    token={token}
                    showSearchBar={false}
                    placeHolder="Buscar grupo"
                    textButton="Editar grupos"
                  />
                  </>
                  
                  ) : (
                    user.groups && user.groups.length > 0 ? (
                      user.groups
                        .filter(
                          (group) =>
                            group.groupId !== "Sin id" &&
                            group.userContentGroup === 1 && group.groupName !== "No tiene grupo asignados"
                        )
                        .map(
                          (group, index, array) =>
                            group.groupName + (index < array.length - 1 ? ", " : "")
                        )
                    ) : (
                      "Sin grupos asignados"
                    )
                  )}
              </p>
            </div>
          ))
        ) : (
          <div className="no-users"><p>No se encontraron usuarios asignados para el grupo.</p></div>
        )}
        </BasicData>
        {show && <GenericModal close={() => setShow(false)} {...modalData} />}
        {colabLimitReached && (
          <GenericModal
            close={() => setColabLimitReached(!colabLimitReached)}
            message="Excediste el número máximo de colaboradores permitidos en tu plan"
            button1={{
              name: "Entendido",
              action: () => setColabLimitReached(!colabLimitReached),
            }}
            button2={{
              name: "Mejorar mi plan",
              action: () => {
                setUpgradePlanRedirect(true);
              },
            }}
          />
        )}
        {showInvite && (
          <InviteUser
            setShowInvite={setShowInvite}
            onSubmit={onSubmit}
            inviteMoreUsers={true}
            // loadData={loadData}
          />
        )}
        <Modal
          {...modalData}
          onClickBtnDefault={() =>
            setModalData((prev) => ({ ...prev, show: false }))
          }
        ></Modal>
        <EditGroup
          token={token}
          show={showEditGroup}
          onClose={handleEditGroupClose}
        />
      </MainContainer>
    </>
  );
}
