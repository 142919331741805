import styled from "styled-components";

export const ActivatorDropdown = styled.div`
  font-family: "Roboto Regular", "Segoe UI", sans-serif;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  border-radius: 50px;
  cursor: pointer;

  .icon {
    font-size: 12px;
    padding: 5px;
    border-radius: inherit;
  }

  // selectLabel
  p {
    padding: 10px 15px;
  }

  .arrow-item {
    transform: rotate(-90deg);
    cursor: pointer;
    font-size: 10px;
    margin-left: 10px;
  }
`;
