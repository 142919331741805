import styled from 'styled-components'



export const ContainerGraph = styled.div`
width: 200px;
height: 100px;
position: relative;
color: black;
display: flex;
text-align: center;
letter-spacing: -0.015em;
color: #817393;
align-items: flex-end;
justify-content: center;
overflow: hidden;
box-sizing : border-box;
  &:before{
  content: '';
  width: 200px;
  height: 100px;
  border:25px solid rgba(0,0,0,.15);
  border-bottom: none;
  position: absolute;
  box-sizing : border-box;
  transform-origin: 50%  0%;
  border-radius: 150px 150px 0 0 ;
  left: 0;
  top: 0;
  z-index: -10;
  }

.total-products {
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 36px;
  color: #817393;
}

p {
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 21px;
  color: #817393;
}
` 
export const Graph = styled.div`
width: 200px;
height: 100px;
border:25px solid ${props => props.fill};
border-top: none;
position: absolute;
transform-origin :50%  0% 0;
border-radius: 0 0 150px 150px ;
left: 0;
top: 100%;
z-index: ${props=>props.zIndex};
animation : 1s fillGraphAnimation ease-in;
transform: rotate( calc( 1deg * ( ${props => props.percentage} * 1.8 ) ) );
box-sizing : border-box;
cursor: pointer;
&:after{
  // content: attr(data-name) ;
  content: attr(data-name) ' ' counter(varible) '%';
  counter-reset: varible ${props => props.percentage};
  background: ${props => props.fill} ;
  box-sizing : border-box;
  border-radius : 2px;
  color: #fff;
  font-weight: 200;
  font-size: 12px;
  height: 20px;
  padding: 3px 5px;
  top: 0px;
  position: absolute;
  left: 0;
  transform: rotate(calc( -1deg * ${props => props.percentage} * 1.8 ))  translate(-30px , -15px);
  transition:0.2s ease-in;
  transform-origin: 0 50% 0;
  opacity: 0;
}
&:hover{
    opacity: 0.8;
  &:after{
    opacity: 1;
    left: 30px;
  }
}



@keyframes fillAnimation{
0%{transform : rotate(-45deg);}
50%{transform: rotate(135deg);}
}

@keyframes fillGraphAnimation{
0%{transform: rotate(0deg);}
50%{transform: rotate(180deg);}
}`