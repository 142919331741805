import axios from 'axios';

export const getOnboarding = async (setLoading, setAllGroups, setAttributesGroups) => {
  setLoading(true);
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_GROUP_BY_ATTRIBUTE}/get`,
      { 
        groupsIds: [],
        attributesIds: []
      },
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    const data = JSON.parse(response.data.body);
    const groupsByRetailer = data.groupsByRetailer;
    const attributesByGroups = data.attributesByGroups;
    setAllGroups(groupsByRetailer);
    setAttributesGroups(attributesByGroups);
  } catch (error) {
    console.error("Error al recuperar la data de grupo por atributo", error);
  } finally {
    setLoading(false);
  }
};
