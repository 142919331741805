import styled from "styled-components";

export const Container = styled.div`
  padding: 10px;
  height: calc(100% - 120px);

  * {
    box-sizing: border-box;
  }
`;

export const ContainerMain = styled.div`
  display: flex;
  height: calc(100% - 69px);
`;

export const ContainerMap = styled.div`
  box-sizing: border-box;
  overflow: auto;
  max-height: calc(100% - 10px);
  width: ${(props) => (props.width ? props.width : "calc(100% - 330px)")};
`;
export const ContainerDatos = styled.div`
  width: 40%;
  min-width: 300px;
  padding: 15px;
`;

export const ContainerDetalles = styled.div`
  padding: 10px;
  padding-right: 0;
  overflow: auto;

  &.side-bar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const RowSpaceBetwen = styled.div`
  display: flex;
  justify-content: space-between;
  label {
    font-family: Avenir Text;
    color: #817393;
    font-size: 12px;
  }
`;

export const Text14 = styled.p`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #503d66;

  &.text-map {
    margin-left: 5px;

    & + * {
      margin-top: 4px;
    }
  }

  &.srv,
  &.desc {
    color: #817393;
  }
`;

export const ContainerInfo = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const ContainerSelect = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 0.5rem;
`;

export const CustomSelect = styled.select`
  border: 1px solid #e33aa9;
  border-radius: 15px;
  color: #e33aa9;
  margin-left: 7px;
  height: 20px;
  width: 125px;

  &:focus {
    background: #817393;
    border-radius: 15px;
    color: white;
    border: none;
  }
  & > option {
    background: #d4d1d7;
    border-radius: 10px;
    color: black;
  }
  & > option:hover {
    background: #817393;
    border-radius: 10px;
    color: white;
  }
`;

export const ODTText = styled.p`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.015em;
  color: #817393;

  span {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: #503d66;
    margin-right: 12px;
  }
`;

export const ContainerCalendar = styled.div`
  display: flex;

  .calendarContainer {
    width: 180px;
    box-sizing: border-box;
    padding: 0;
    margin-right: 0;

    .heading {
      .title {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 10.2271px;
        line-height: 15px;
        text-align: center;
        letter-spacing: 0.0730505px;
        color: #817393;
      }

      .prev,
      .next {
        width: 10px;
        height: 26px;
        padding: 0;

        svg {
          line-height: 26px;
        }
      }
    }

    .daysOfWeek {
      div {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 8.76606px;
        line-height: 11px;
      }
    }

    .dayPickerContainer {
      .dayWrapper {
        width: 25px;
        height: 25px;

        button {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 11.6881px;
          line-height: 18px;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
`;

export const ContainerDate = styled.div`
  width: 133px;

  & + * {
    margin-left: 20px;
  }
`;

export const InputDate = styled.input`
  background: #f0eef2;
  border-radius: 15px;
  color: #817393;
  font-size: 12px;
  font-family: Avenir Next;
  height: 20px;
  width: 100%;
  border: none;
  text-align: center;
`;
export const DateButton = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0eef2;
  border-radius: 15px;
  color: #817393;
  font-size: 12px;
  font-family: Avenir Next;
  height: 20px;
  width: 100%;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const Text10 = styled.p`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  color: #817393;
`;

export const Text12 = styled.p`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 21px;
  color: #817393;
  margin-bottom: 10px;

  &.txt-address {
    width: 180px;
  }

  &.office-contact {
    color: #503d66;
  }
`;

export const Text11 = styled.p`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: #817393;

  & + hr {
    margin-top: 34px;
  }
`;

export const BotonEnviarCorreo = styled.button`
  border: 1px solid #d74ded;
  box-sizing: border-box;
  border-radius: 2px;
  color: #d74ded;
  font-family: Lato;
  font-size: 12px;
  background: white;

  & + * {
    margin-top: 22px;
  }
`;

export const InputCalle = styled.input`
  background: #fafafa;
  border-radius: 3px;
  width: 310px;
  height: 19px;
  border: none;
  font-size: 11px;
  &::placeholder {
    color: #d4d1d7;
  }
  margin: 5px;
  padding-left: 7px;
`;
export const InputEstado = styled.input`
  background: #fafafa;
  border-radius: 3px;
  width: 110px;
  height: 19px;
  border: none;
  font-size: 11px;
  &::placeholder {
    color: #d4d1d7;
  }
  margin: 5px;
  padding-left: 7px;
`;
export const InputCP = styled.input`
  background: #fafafa;
  border-radius: 3px;
  width: 110px;
  height: 19px;
  border: none;
  font-size: 11px;
  &::placeholder {
    color: #d4d1d7;
  }
  margin: 5px;
  padding-left: 7px;
`;
export const InputNombre = styled.input`
  background: #fafafa;
  border-radius: 3px;
  width: 182px;
  height: 19px;
  border: none;
  font-size: 11px;
  &::placeholder {
    color: #d4d1d7;
  }
  margin: 5px;
  padding-left: 7px;
`;
export const InputTelefono = styled.input`
  background: #fafafa;
  border-radius: 3px;
  width: 157px;
  height: 19px;
  border: none;
  font-size: 11px;
  &::placeholder {
    color: #d4d1d7;
  }
  margin: 5px;
  padding-left: 7px;
`;
export const Row = styled.div`
  display: flex;
  align-items: center;
  .pNumber {
    margin-top: 10px;
    color: #969696;
    font-family: Avenir Next;
    font-size: 11px;
  }
`;

export const TableProduct = styled.table`
  margin-top: 30px;
  display: block;
  empty-cells: show;
`;
export const TotalTable = styled.thead`
  margin-top: 10px;
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 30px;
  border: 1px solid #f0eef2;
  box-sizing: border-box;
  border-radius: 7px;
  height: 41px;
`;

export const TextTotal = styled.th`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #817393;
  display: block;
  text-align: center;
`;

export const TextTotalBold = styled.th`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #503d66;
  display: block;
  text-align: center;
`;

export const ContainerFooter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding: 24px 10px 10px 20px;
  border-bottom: 1px solid #c4c4c4;
`;

export const HeadTableProd = styled.thead`
  position: relative;
  display: block;
  width: 100%;
`;

export const HeadRowTableProdEnd = styled.th`
  font-family: Avenir Next;
  text-align: center;
  font-size: 12px;
  color: #503d66;
  border-right: solid;
  border-right-width: 1px;
  border-right-color: #d4d1d7;
  display: block;
`;

export const HeadRowTableProd = styled.th`
  font-family: Avenir Next;
  font-size: 12px;
  color: #503d66;
  text-align: center;
  flex-basis: 100%;
  display: block;
`;

export const HeadRowTableProdTitle = styled.th`
  font-family: Avenir Next;
  font-size: 15px;
  color: #503d66;
  text-align: left;
  flex-basis: 100%;
  display: block;
`;
export const HeadRowTableProdIconEnd = styled.th`
  font-family: Avenir Next;
  font-size: 15px;
  color: #503d66;
  text-align: right;
  flex-basis: 100%;
  display: block;
  border-right: solid;
  border-right-width: 1px;
  border-right-color: #d4d1d7;
`;

export const HeadRowTableProdSubitle = styled.th`
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: Avenir Next;
  font-size: 12px;
  color: #817393;
  text-align: left;
  flex-basis: 100%;
  display: block;
`;

export const HeadRowTableProdSubitleEnd = styled.th`
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: Avenir Next;
  font-size: 12px;
  color: #817393;
  text-align: left;
  flex-basis: 100%;
  display: block;
  border-right: solid;
  border-right-width: 1px;
  border-right-color: #d4d1d7;
`;

export const TablesProductRowWhite = styled.tr`
  border: none;
  background-color: #ffffff;
  height: 36px;
  display: flex;
  cursor: pointer;
  &:hover:nth-child(odd) {
    background-color: #d4d1d7;
  }
  &:hover:nth-child(even) {
    background-color: #d4d1d7;
  }
  &:nth-child(odd) {
    background-color: #fafafa;
  }
  &:nth-child(even) {
    background-color: #ffffff;
  }
`;

export const CadenasBody = styled.tr`
  background-color: #f0eef2;
  height: auto;
  display: flex;
`;

export const CadenasHead = styled.tr`
  border-top: solid;
  border-top-width: 1px;
  border-top-color: #e33aa9;
  background-color: #f0eef2;
  border-bottom: solid;
  border-bottom-width: 1px;
  border-bottom-color: #d4d1d7;
  height: auto;
  display: flex;
  .title {
    text-align: left;
    padding-left: 5px;
    padding-bottom: 15px;
  }
`;

export const CadenasFotter = styled.tr`
  border-top: solid;
  border-top-width: 1px;
  border-top-color: #d4d1d7;
  border-bottom: solid;
  border-bottom-width: 1px;
  border-bottom-color: #e33aa9;
  background-color: #f0eef2;
  height: auto;
  display: flex;
  .title {
    text-align: left;
    padding-left: 5px;
    padding-bottom: 15px;
  }
`;
export const CadenaProductD = styled.td`
  font-family: Avenir Next;
  text-align: center;
  font-size: 12px;
  flex-basis: 100%;
  display: block;
  .title {
    margin-top: 5px;
    text-align: left;
    padding-left: 5px;
  }
`;

export const CadenaProductDEnd = styled.td`
  font-family: Avenir Next;
  text-align: center;
  font-size: 12px;
  border-right: solid;
  border-right-width: 1px;
  border-right-color: #d4d1d7;
  flex-basis: 100%;
  display: block;
`;

export const TablesProductD = styled.td`
  padding-top: 13px;
  font-family: Avenir Next;
  text-align: center;
  font-size: 12px;
  flex-basis: 100%;
  display: block;
`;
export const TablesProductDIcons = styled.td`
  font-family: Avenir Next;
  font-size: 12px;
  flex-basis: 100%;
  display: block;
  text-align: right;
`;

export const TablesProductDEnd = styled.td`
  font-family: Avenir Next;
  padding-top: 13px;
  text-align: center;
  font-size: 12px;
  border-right: solid;
  border-right-width: 1px;
  border-right-color: #d4d1d7;
  flex-basis: 100%;
  display: block;
`;

export const InputForm = styled.input`
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 2px;
  height: 21px;
  width: 100%;
  &::placeholder {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 21px;
    /* identical to box height, or 210% */

    letter-spacing: -0.015em;

    color: #c4c4c4;
  }
`;
export const DateText = styled.p`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #503d66;
`;

export const SelectForm = styled.select`
  background: #fafafa;
  border-radius: 2px;

  border: none;
  width: 150px;
  height: 21px;
  & option {
    color: #817393;
    background-color: #f0eef2;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;

export const Header1 = styled.tr`
  display: flex;
  width: calc(100% - 20px);
`;
export const Header2 = styled.tr`
  display: flex;
  width: calc(100% - 17px);
`;
export const TotalRow = styled.tr`
  display: flex;
  width: calc(100% - 17px);
  margin-top: 10px;
`;

export const BodyTable = styled.tbody`
  width: 100%;
  height: 400px;
  overflow-y: scroll;
  display: block;
  clear: both;
`;
