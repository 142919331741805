import ReactDOM from "react-dom";
import App from "./App.jsx";
import Amplify from "aws-amplify";
import "./index.css";
import "material-icons/iconfont/material-icons.css";

ReactDOM.render(<App />, document.getElementById("root"));

Amplify.configure({
  Auth: {
    region: "us-east-1",
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: true,
  },
});
