import styled from "styled-components";

export const Filter = styled.div`
  position: relative;

  .option-selected {
    padding: 4px 5px 4px 14px;
    border: 1px solid #f0eef2;
    box-sizing: border-box;
    border-radius: 20px;
    display: flex;
    align-items: center;

    p {
      font-family: Avenir Next;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 25px;
      color: #d4d1d7;
      white-space: nowrap;

      & + * {
        margin-left: 5px;
      }
    }

    .remove-filter {
      background-color: transparent;
      border: none;
      width: 20px;
      height: 20px;
      padding: 0;
      cursor: pointer;
    }
  }

  .filters-selection {
    border: 1px solid #e33aa9;
    border-radius:20px;
    box-sizing: border-box;
    padding: 3px 30px 3px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;

    label {
      cursor: pointer;
      font-family: Avenir Next;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 25px;
      color: #e33aa9;
      position: relative;

      :before {
        right: calc(100% + 3px);
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
      }
    }
  }
`;

export const FilterPopUp = styled.div`
  position: absolute;
  background: #f0eef2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 15px;
  padding-left: 15px;
  z-index: 5;
  left: 0;
  top: 100%;
  min-width: 100%;
  .grid-container {
    max-height: 170px;
    overflow-y: auto;
    /*${({ display }) =>
      display === "grid"
        ? `display: grid;
          grid-template-columns: repeat(3, 33%);
          gap: 3px 5px;
          grid-auto-flow: row;
          max-width: 590px;
          overflow-x: hidden;`
        : `display: block;
          width: auto;`} */

    & > div {
      margin-left: 25px;

      label {
        font-family: Avenir Next;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 21px;
        color: #817393;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    p {
      font-family: Avenir Next;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 21px;
      color: #817393;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .search-container {
    margin-bottom: 10px;
    min-width: 100px;
    width: 100%;
    input {
      width: 100%;
      font-family: Avenir Next;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #817393;
      border: 1px solid #f0eef2;
      border-radius: 10px;
      padding: 7px 10px;
    }
  }
`;
