import styled from "styled-components";

export const ContainerCard = styled.div`
  cursor: pointer;
  display: flex;
  min-width: 97px;
  height: 120px;
  border: 1px solid ${(props) => props.color};
  box-sizing: border-box;
  border-radius: 7px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;

  & + * {
    margin-left: 28px;
  }
`;

export const TitleCard = styled.div`
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: -0.015em;

  /* Gray S4 */

  color: #817393;
`;

export const Price = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.015em;
  color: #817393;
`;
export const Discount = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 36px;
  /* or 200% */

  text-align: center;
  letter-spacing: -0.015em;

  color: #71de56;
`;
