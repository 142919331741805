import styled from "styled-components";

export const Title = styled.div`
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  color: #b12d84;
`;

export const ContainerButton = styled.div`
  display: flex;
  padding-top: 22px;
  justify-content: flex-end;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  > div {
    width: 144px;
    height: 36px;
    display: flex;
    justify-content: center;
    background: #603888;
    border-radius: 30px;
    font-family: Avenir Next;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    /* identical to box height */
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #ffffff;
  }
`;

export const SelectionMap = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.015em;
  color: #969696;
  padding-bottom: 5px;
`;

export const TitleCompany = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 36px;
  /* or 100% */
  letter-spacing: -0.015em;
  color: #707070;
`;

export const TitleUser = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 36px;
  /* identical to box height, or 200% */
  letter-spacing: -0.015em;
  color: #969696;
`;

export const Subtitle = styled.div`
  padding: 16px 0 27px 0;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */
  letter-spacing: -0.015em;
  /* Gray S4 */
  color: #817393;
`;

export const ContainerMap = styled.div`
  width: 100%;
  height: calc(100vh - 100px);
  overflow-y: auto;
  font-family: Raleway;
  padding: 20px;
  box-sizing: border-box;
`;

export const ContainerQuotes = styled.div`
  width: 30%;
  box-sizing: border-box;
  border-left: 1px solid #969696;
  padding: 5px 15px;
  .TitleQuotes {
    padding: 10px 0;
    border-bottom: 1px solid #969696;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */
    letter-spacing: -0.015em;
    color: #969696;
  }

  .containQuote {
    border-bottom: 1px solid #969696;
    display: flex;
    padding: 21px 0;
    justify-content: space-between;
  }
  .containQuoteL {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    img:nth-child(2) {
      margin-left: 10px;
    }
  }
  .containQuoteR > time {
    display: flex;
    justify-content: flex-end;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    letter-spacing: -0.015em;
    color: #969696;
  }
  .containQuoteR > div:nth-last-child(-n + 2) {
    display: flex;
    justify-content: flex-end;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */
    text-align: right;
    letter-spacing: -0.015em;
    color: #969696;
  }
`;

export const ContainLateral = styled.input`
  width: 70%;
`;

export const InputForm = styled.input`
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 7px;
  height: 31px;
  width: 100%;
`;

export const ButtonQuotes = styled.div`
  width: 112px;
  height: 20px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.015em;
  color: #d74ded;
  border: 1px solid #d74ded;
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
