import React, { useState, useEffect } from "react";
import { Table, Column } from "react-virtualized";
import Status from "../../../_utils/TableComponents/Status";
import {
  allChecked,
  articleChecked,
  rowClicked,
} from "../utils.js/dataFunctions";
import {
  retailerTasksStyle,
  nameColumn,
  skuColumn,
  orderColumn,
  versionColumn,
  categoryColumn,
  brandColumn,
  statusColumn,
  Label,
} from "./styles";

const RetailerTasksTable = (props) => {
  const [chks, setChks] = useState([]);
  const [chkGlobal, setChkGlobal] = useState(null);

  useEffect(() => {
    const checkboxs = document.querySelectorAll(".chk-option");
    const chkGlob = document.getElementById("chk-global");
    setChks(checkboxs);
    setChkGlobal(chkGlob);
  }, [props.articlesList]);

  return (
    <Table
      {...retailerTasksStyle}
      rowCount={props.articlesList.length}
      rowGetter={({ index }) => props.articlesList[index]}
      onRowClick={(evt) => {
        rowClicked(
          props.articlesList[evt.index],
          props.eventFunctions.setRedirect
        );
      }}
    >
      <Column
        {...versionColumn}
        label={
          <Label>
            <input
              type="checkbox"
              id="chk-global"
              onChange={(evt) => {
                chks.forEach((chk) => (chk.checked = evt.target.checked));
                allChecked(
                  props.articlesList,
                  props.eventFunctions.setTaskList,
                  evt.target.checked,
                  props.eventFunctions.setSelectedArticles
                );
              }}
            />
          </Label>
        }
        cellRenderer={({ rowData }) => (
          <Label onClick={(evt) => evt.stopPropagation()}>
            <input
              type="checkbox"
              className="chk-option"
              defaultChecked={rowData.checked}
              onChange={(evt) => {
                chkGlobal.checked = Array.from(chks).every(
                  (chk) => chk.checked
                );
                articleChecked(
                  props.articlesList,
                  evt.target.checked,
                  props.eventFunctions.selectedArticles,
                  props.eventFunctions.setSelectedArticles,
                  props.eventFunctions.setTaskList,
                  rowData
                );
              }}
            />
          </Label>
        )}
      />
      <Column
        label="SKU"
        {...skuColumn}
        cellDataGetter={({ rowData }) => rowData.article.upc}
      />
      <Column
        label="Orden"
        {...orderColumn}
        cellDataGetter={({ rowData }) => rowData.orderId}
      />
      <Column
        label="Nombre"
        {...nameColumn}
        cellDataGetter={({ rowData }) => rowData.article.name}
      />
      <Column
        label="Marca"
        {...brandColumn}
        cellDataGetter={({ rowData }) => rowData.brand ?? "S/M"}
      />
      <Column
        label="Categoría"
        {...categoryColumn}
        cellDataGetter={({ rowData }) => rowData.article.category ?? "S/M"}
      />
      <Column
        label="Version"
        {...versionColumn}
        cellDataGetter={({ rowData }) => rowData.version}
      />
      <Column
        {...statusColumn}
        label={"Estatus"}
        cellRenderer={({ rowData }) => (
          <Status status={rowData.article_status} noHover />
        )}
      />
    </Table>
  );
};

export default RetailerTasksTable;
