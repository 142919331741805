export const rowClicked = (product, setProductSelected, setSidebool) => {
  product.retailersAvailable = product.retailers;
  product.id_article = product.article.id_article;
  product.categoryName = product.article.category;
  product.version_status = product.status;
  sessionStorage.setItem("productSelected", JSON.stringify(product));
  sessionStorage.setItem("version", product.version);
  sessionStorage.setItem(
    "productEdit",
    JSON.stringify({
      ArticleId: product?.article?.id_article,
      idCategory: product?.article?.id_category,
      product,
    })
  );
  setProductSelected(product);
  setSidebool(true);
};
export const checkboxClicked = () => {};
export const checkAll = (checked, productsList, updateProducts) => {
  updateProducts(
    productsList.map((product) => ({ ...product, checked: checked }))
  );
};
