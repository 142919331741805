import styled from "styled-components";

export const TwoColumns = styled.div`
  display: grid;
  width: 100%;
  grid-auto-rows: 1fr;
  grid-template-columns: ${({ left, right }) =>
    left && right ? `${left} ${right}` : "50% 50%"};
  grid-template-rows: 100%;
  gap: 0px 30px;
  grid-template-areas: "first second";
  .products,
  .first {
    grid-area: first;
    display: flex;
    overflow-y: auto;
    font-family: Roboto;
    & > div {
      display: block;
      width: 100%;
      overflow-x: hidden;
    }
    &.margin {
      padding-left: 50px;
    }
    label > p {
      color: #fff;
    }
    label:before {
      width: 12px;
      height: 12px;
      background-size: 12px;
    }
  }
  .header {
    display: flex;
    align-items: center;
    height: 34px;
  }
  .products  {
    max-height: calc(100vh - 300px);
  }
  .first {
    overflow-y: hidden;
  }
  .cards {
    grid-area: second;
    width: 100%;
    max-height: calc(100vh - 300px);
    overflow-y: auto;
  }
`;

export const TotalArticleDetail = styled.div`
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 110px 0 50px;

  & > div:last-child {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    width: 300px;
    & > span {
      text-align: center;
      width: 75px;
    }
  }
`;
