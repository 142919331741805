import React from "react";
import { Container } from "./styles";
import CloseIcon from "../../../assets/IconComponents/Cerrar.svg";

/*==========================================================
	Este componente representa el contenedor grid layout 
	en donde estaran ubicadas las tarjetas de cada lista
============================================================*/
export default function Modal(props) {
  return (
    <Container>
      <div className="div-content-modal">
        <header>
          <div className="div-title">
            {/* icono modal */}
            {props.icon && (
              <div className="icon">
                <img src={props.icon} alt={"icono"} />
              </div>
            )}

            {/* titulo */}
            {props.title && props.title !== "" && <label>{props.title}</label>}
          </div>

          {/* boton x */}
          <img
            className="button-close"
            src={CloseIcon}
            alt={"x"}
            onClick={(event) => {
              props.onClickBtnClose && props.onClickBtnClose(event);
            }}
          />
        </header>

        {/* label detail */}
        {props.message && props.message !== "" && (
          <label className="label-detail">{props.message}</label>
        )}

        {/* custom component */}
        {props.customComponent && (
          <div className="div-customComponent">{props.customComponent}</div>
        )}

        {/* container buttons */}
        <div className="container-buttons">
          {props.buttons && props.buttons}
        </div>
      </div>
    </Container>
  );
}
