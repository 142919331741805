export const features = [
  { id: 1, name: "Ahorra el 10% al contratar de forma anual" },
  { id: 2, name: "Número de productos", info: "Número de productos" },
  { id: 3, name: "Número de usuarios", info: "Número de usuarios" },
  { id: 4, name: "Contratar productos", info: "Contratar productos" },
  { id: 5, name: "Crear producto" },
  { id: 6, name: "Revisión de producto" },
  { id: 7, name: "Descargas ilimitadas" },
  { id: 8, name: "Cadenas ilimitadas" },
  { id: 9, name: "Almacenamiento anual", info: "Almacenamiento anual" },
  { id: 10, name: "Edición de producto", info: "Edición de producto" },
  { id: 11, name: "Validación por sistema", info: "Validación por sistema" },
  { id: 12, name: "Resizing de imágenes", info: "Resizing de imágenes" },
  {
    id: 13,
    name: "Versionamiento de productos",
    info: "Versionamiento de productos",
  },
  { id: 14, name: "Asignar equipo", info: "Asignar equipo" },
  { id: 15, name: "Ejecutivo KAM", info: "Ejecutivo KAM" },
  {
    id: 16,
    name: "Solicitar actualizaciones",
    info: "Solicitar actualizaciones",
  },
  { id: 17, name: "Soporte", info: "Soporte" },
];

export const featuresNotIncluded = [
  { planId: 1, features: [7, 9, 10, 11, 12, 13, 14, 15, 16] },
  { planId: 2, features: [14, 15, 16] },
  { planId: 3, features: [14, 15, 16] },
  { planId: 4, features: [15, 16] },
];
