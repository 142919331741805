import styled from "styled-components";

export const Button = styled.div`
  background: #e33aa9;
  border-radius: 40px;
  color: white;
  width: 160px;
  height: 41px;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const ExportAlert = styled.div`
  display: ${(props) => (props.modalView ? "block" : "none")};
  position: absolute;
  width: 154px;
  background: #281f33;
  border-radius: 3px;
  border-left: 9px solid #edd34d;
  padding: 17px 0;
  bottom: 100%;
  margin-bottom: 10px;
  z-index: 5;
  left: 50%;
  transform: translateX(-50%);

  .close-button {
    position: absolute;
    top: 1px;
    right: 3px;
    border: none;
    padding: 0;
    background-color: transparent;
  }

  p {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #fafafa;
    text-align: left;
    width: 105px;
    margin: auto;
  }
`;

export const ContainerVersion = styled.div`
  position: absolute;
  bottom: 105px;
  border-left: 1px solid #f0eef2;
  right: 0px;
  width: 292px;
  height: calc(100vh - 195px);
  background: #fff;
  overflow-y: scroll;
  .versionEach {
    border-bottom: 1px solid #f0eef2;
    display: flex;
    justify-content: space-between;
    height: 102px;
    box-sizing: border-box;
    padding: 23px 14px 19px 13px;
    .containerIcon {
      margin-bottom: 5px;
    }
    .checkIcon {
      float: right;
    }
    .textVersion {
      p:nth-child(1) {
        font-family: Avenir Next;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: right;
        letter-spacing: -0.015em;
        color: #503d66;
      }
      p:nth-child(n + 2) {
        font-family: Avenir Next;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        /* identical to box height, or 150% */

        text-align: right;
        letter-spacing: -0.015em;

        /* Gray S4 */

        color: #817393;
      }
    }
    .buttonVersion {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      .btn {
        width: 112px;
        height: 20px;
        background: #71de56;
        border-radius: 15px;
        font-family: Avenir Next;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: -0.015em;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .topVersion {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    height: 38px;
    background: #503d66;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.015em;
    color: #fafafa;
    .button {
      cursor: pointer;
      height: 30px;
      width: 30px;
    }
    .button::before,
    .button::after {
      margin-top: 8px;
      right: 18px;
      position: absolute;
      content: "";
      width: 16px;
      height: 1px; /* cross thickness */
      background-color: #c4c4c4;
    }

    .button::before {
      transform: rotate(45deg);
    }

    .button::after {
      transform: rotate(-45deg);
    }
  }
  .versionEach {
    height: 102px;
    display: flex;
  }
  .active {
    background-color: #f0eef2;
  }
`;

export const ContainerPrincipal = styled.div`
  width: 100%;
  height: calc(100vh - 100px);
  padding: 10px 10px 10px 10px;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
`;
export const ImgContentoh = styled.img`
  margin-top: 20px;
`;
export const TitleContentohs = styled.div`
  padding-top: 5px;
  width: 397px;
  height: 59px;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.015em;
  color: #969696;
`;
export const ContainerIcon = styled.button`
  border: none;
  padding: 0;
  background-color: transparent;
  border-radius: 50%;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;

  :hover {
    cursor: pointer;
  }

  & + * {
    margin-left: 8px;
  }
`;
export const ContainerPIcons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-around;

  .search-input-container {
    position: relative;

    input {
      width: 174px;
      height: 30px;
      border: 1px solid #f0eef2;
      box-sizing: border-box;
      border-radius: 30px;
      padding-left: 15px;
    }

    &:before {
      content: "";
      position: absolute;
      display: block;
      background-image: url(${(props) => props.Search});
      width: 17px;
      height: 17px;
      right: 9px;
      top: 50%;
      transform: translateY(-50%);
    }

    & + * {
      margin-left: 8px;
    }
  }

  & + * {
    margin-left: 14px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  height: 3.5rem;
  justify-content: space-between;
`;

export const HeaderImg = styled.img`
  max-width: 100%;
  height: auto;
  width: 35px;
  height: 35px;
  border-radius: 50%;
`;
export const HeaderLogo = styled.a`
  color: #19181b;
  font-weight: 500;
  display: none;
  text-decoration: none;
`;
export const HeaderSearch = styled.div`
  display: flex;
  padding: 0.4rem 0.75rem;
  background-color: #f4f0fa;
  border-radius: 0.25rem;
`;

export const HeaderInput = styled.input`
  width: 100%;
  border: none;
  outline: none;
  background-color: #f4f0fa;
  &::placeholder {
    font-family: "Poppins", sans-serif;
    color: #58555e;
  }
`;
export const HeaderToggle = styled.div`
  color: #19181b;
  cursor: pointer;
  font-size: 1.2rem;
`;

export const NavContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0%;
  height: 93vh;
  width: 200px;
  padding: 1rem 1rem 0;
  background-color: #ffffff;
  z-index: 0;
  transition: 0.4s;
`;

export const Navbar = styled.nav`
  height: 100%;
  display: block;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 3rem;
  overflow: auto;
  border-radius: 20px;
  background-color: #603888;
  scrollbar-width: none;
`;

export const NavLogo = styled.a`
  font-weight: 600;
  padding: 2rem 1rem;
  margin-bottom: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #58555e;
`;

export const NavLogoName = styled.span`
  opacity: 0;
  transition: 0.3s;
  &:hover {
    opacity: 1;
  }
`;
export const NavItems = styled.div`
  row-gap: 1.5rem;
  display: grid;
`;

export const NavList = styled.div`
  row-gap: 2.5rem;
`;
export const NavLinks = styled.div`
  margin: 0 15px 0 26px;
`;
export const NavSubtitle = styled.h3`
  font-size: 0.938rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  color: #a5a1aa;
`;
export const NavLink = styled.a`
  display: flex;
  width: 190px;
  height: 34px;
  align-items: center;
  color: #58555e;
  &:hover {
    border-radius: 18px;
    background-color: #e33aa9;
  }
`;
export const NavName = styled.span`
  font-family: "Raleway";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.015em;
  color: #fcfcfc;
`;

export const NavDropdownCollapse = styled.div`
  background-color: #a5a1aa;
  border-radius: 0.25rem;
  margin-top: 1rem;
`;

export const NavDropdown = styled.div`
  overflow: hidden;
  max-height: 21px;
  transition: 0.4s ease-in-out;
  &:hover {
    max-height: 100rem;
  }
`;

export const NavDropdownContent = styled.div`
  display: grid;
  row-gap: 0.5rem;
  padding: 0.75rem 2.5rem 0.75rem 1.8rem;
`;

export const NavDropdownItem = styled.div`
  font-size: 0.75rem;
  font-weight: 500;
  color: #58555e;
`;

export const ContainerScroll = styled.div`
  height: ${(props) => (props.height ? props.height : "60vh")};
  width: 100%;
  overflow: auto;
`;

export const TablesHeadRows = styled.tr`
  width: 100%;
  height: 40px;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;

  :nth-child(odd) {
    background: #f0eef2;
    height: 40px;
  }

  &:hover {
    cursor: pointer;
    background: #d4d1d7;
  }
`;

export const ContainerChart = styled.div`
  padding-right: 0;
`;
export const TitleOrders = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  color: #707070;
`;

export const Container = styled.div`
  display: flex;
  flex: ${(props) => props.flex};
  width: ${(props) => props.width};
  padding: ${(props) => props.padding};
  margin: 20px 0;
  justify-content: ${(props) => props.justifycontent};
  flex-direction: ${(props) => props.flexdirection};
  flex-wrap: ${(props) => props.flexwrap};
  align-self: ${(props) => props.alignself};
  .imgIcon {
    margin-right: 1rem;
  }

  &.status-color-container {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &.title-container {
    flex-direction: column;
    max-width: 350px;

    & + * {
      margin-left: 50px;
    }
  }

  &.main-container {
    justify-content: space-between;
  }

  &.cards-container {
    width: auto;
    align-items: flex-end;

    & + * {
      margin-left: 10px;
    }
  }

  &.orders-header,
  &.order-date-range {
    width: auto;
  }

  &.orders-header {
    flex: 3;
  }

  &.order-date-range {
    flex: 2;
    display: flex;
    margin-top: 40px;

    .date-range-container {
      min-width: 260px;

      & + * {
        margin-top: 18px;
      }
    }
  }

  &.monthly-billing {
    display: block;
    flex: 1;
  }

  &.order-dates {
    display: block;
    flex: 2;

    & + * {
      margin-left: 10px;
    }
  }

  &.work-orders-container {
    & + table {
      margin-top: 10px;
    }
  }

  &#button-container {
    justify-content: space-between;
    align-items: flex-end;

    .right-side {
      display: flex;
    }
  }

  .export-button-container,
  .update-icon-container,
  .filter-icon-container,
  .trash-icon-container {
    position: relative;
  }

  .btn-product-options {
    & + * {
      margin-left: 10px;
    }
  }

  & + .filter-input {
    margin: 0;
    margin-top: 15px;
    margin-bottom: 15px;
  }
`;
Container.defaultProps = {
  width: "100%",
  padding: "0",
  flexdirection: "row",
  justifycontent: "space-around",
  flexwrap: "nowrap",
};
export const InputFormSearch = styled.div`
  display: flex;
  padding: 0 1rem 0 0;
  align-items: flex-end;
  > input {
    width: 173.2px;
    height: 30px;
    border: 1px solid #f0eef2;
    box-sizing: border-box;
    border-radius: 30px;
  }
`;
export const HeaderEstatus = styled.div`
  display: flex;
  padding: ${(props) => props.padding};
  width: ${(props) => props.width};
  align-items: center;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.015em;
  color: #817393;
  > div {
    margin-right: 1rem;
    background: ${(props) => props.bg};
    width: 10px;
    height: 10px;
  }

  &:nth-child(2) {
    margin-right: 17px;
  }

  &.last-header-status {
    margin-top: 10px;
  }
`;
export const ContainerTitleContentoh = styled.div`
  align-items: flex-start;
  justify-content: flex-start;
`;
export const ContainerHeader = styled.div`
  display: flex;
  padding: 10px 2rem;
  background: #f0eef2;
  border-radius: 7px;
  justify-content: center;
  flex-direction: column;

  div:first-child {
    padding: 0 0 10px 0;
    font-family: Avenir Next;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 11px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #817393;
  }
  p {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #817393;
    white-space: nowrap;
  }
  div:nth-child(3) {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    letter-spacing: -0.015em;
    color: #817393;
  }
`;

export const Title = styled.span`
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 42px;
  color: #503d66;
`;

export const Subtitle = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #817393;
`;

export const rowTable = styled.tr`
  background-color: red;
  &hover {
    cursor: pointer;
  }
`;

export const HeaderTitleR = styled.div`
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  color: #b12d84;
`;

export const HeaderSubtitleR = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.015em;
  color: #817393;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;

  button {
    border: none;
    background-color: transparent;
    padding: 0;
    width: 30px;
    height: 30px;
    cursor: pointer;

    & + button {
      margin-left: 3px;
    }

    & + p {
      margin-left: 13px;
    }
  }

  p {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #817393;
  }

  .spinner-container {
    width: 30px;
    height: 30px;

    img {
      width: 100%;
    }
  }
`;

export const DropDownsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 20px 0 40px;
`;

export const DropDownContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  .choose-version,
  .option-selected p {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 21px;
    color: #d8d7e1;

    & + * {
      margin-left: 9px;
    }
  }

  .dropdown-container {
    position: relative;

    .option-selected {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2px 13px;
      border: 1px solid #b6a1cc;
      box-sizing: border-box;
      border-radius: 15px;
      background-color: transparent;
      min-width: 132px;
    }

    .options {
      position: absolute;
      background-color: #f0eef2;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 9px;
      padding: 4px 10px;
      width: 100%;
      left: 0;
      top: 100%;

      ul {
        li {
          font-family: Lato;
          font-style: normal;
          font-weight: normal;
          font-size: 11px;
          line-height: 21px;
          color: #817393;
        }
      }
    }
  }
`;
