import { ClickAwayListener, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { faEllipsisV as IconMenuV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

export class classCustomDropDown {
  static POSITION_BOTTOM_START = "bottom-start";
  static POSITION_BOTTOM_END = "bottom-end";
  static POSITION_BOTTOM = "bottom";

  static POSITION_TOP_START = "top-start";
  static POSITION_TOP_END = "top-end";
  static POSITION_TOP = "top";
}

export default function CustomDropDown(props) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  return (
    <ClickAwayListener
      onClickAway={(event) => {
        if (props.onClickAway) props.onClickAway(event);
        else if (show) setShow(false);
      }}
    >
      <Tooltip
        open={show}
        placement={props.position ?? classCustomDropDown.POSITION_TOP}
        componentsProps={{
          tooltip: {
            className: props.className,
            onClick: (event) => {
              event.stopPropagation();
            },
          },
          popper: { disablePortal: true },
        }}
        title={props.hiddenComponent ?? <div></div>}
      >
        {props.children ?? (
          <button>
            <i>
              <Icon icon={IconMenuV} />
            </i>
          </button>
        )}
      </Tooltip>
    </ClickAwayListener>
  );
}
