import axios from "axios";
import { Base64 } from "js-base64";
import { v4 as uuidv4 } from "uuid";

// SVG FLAGS
import mexico from "../../assets/IconFlags/mexico.svg";
import argentina from "../../assets/IconFlags/argentina.svg";
import honduras from "../../assets/IconFlags/honduras.svg";
import colombia from "../../assets/IconFlags/colombia.svg";
import costa from "../../assets/IconFlags/costa.svg";
import ecuador from "../../assets/IconFlags/ecuador.svg";
import panama from "../../assets/IconFlags/panama.svg";
import peru from "../../assets/IconFlags/peru.svg";
import salvador from "../../assets/IconFlags/salvador.svg";
import defaultFlag from "../../assets/IconFlags/defaultflag.svg";
import defaultUpdate from "../../assets/defaultImages/defaultUpdate.png";
import teamContentoh from "../../assets/imagesFake/AvatarContentoh.svg";
import EmptyCircleGray from "../../assets/defaultImages/EmptyCircleGray.svg";

//SVG Prio
import greenFlag from "../../assets/IconComponents/greenFlag.svg";
import yellowFlag from "../../assets/IconComponents/yellowFlag.svg";
import redFlag from "../../assets/IconComponents/redFlag.svg";
import emptyFlag from "../../assets/IconComponents/emptyFlag.svg";

export const isElementClicked = (evt, element) => {
  let flyoutElement;
  try {
    flyoutElement = document.getElementById(element);
  } catch (err) {}
  let targetElement = evt.target;
  do {
    if (targetElement === flyoutElement) {
      return true;
    }
    // Go up the DOM
    targetElement = targetElement.parentNode;
  } while (targetElement);
  return false;
};

export const getMetricsProducts = async () => {
  let productArraylist;
  let productCounter = 0;
  await axios({
    method: "get",
    url: !window.location.href.includes("Contentoh")
      ? `${process.env.REACT_APP_ARTICLE_ENDPOINT}`
      : `${process.env.REACT_APP_ARTICLE_ENDPOINT}?contentoh=true`,
    headers: {
      Authorization: sessionStorage.getItem("jwt"),
    },
  }).then(
    (response) => (productArraylist = JSON.parse(response.data.body).data)
  );

  let metricsArray = [];
  let percentArray = [];
  if (Array.isArray(productArraylist)) {
    productArraylist?.forEach((product) => {
      productCounter++;
      if (!metricsArray[getStateFilter(product.status)])
        metricsArray[getStateFilter(product.status)] = 1;
      else metricsArray[getStateFilter(product.status)]++;
    });

    productArraylist?.forEach((product) => {
      percentArray[getStateFilter(product.status)] =
        (metricsArray[getStateFilter(product.status)] / productCounter) * 100;
    });
  }

  return [metricsArray, productCounter, percentArray, productArraylist];
};

export const getCollaborators = async () => {
  const response = await axios({
    method: "get",
    url: process.env.REACT_APP_COLLABORATOR_ENDPOINT,
    headers: {
      Authorization: sessionStorage.getItem("jwt"),
    },
  });
  sessionStorage.setItem(
    "collaborator",
    JSON.stringify(JSON.parse(response.data.body).users)
  );
};

export const getStatusColor = (status) => {
  switch (status) {
    case "NULL":
      return "#D4D1D7";
    case "S/OT":
      return "#000";
    case "R":
    case "PA":
    case "SAC":
      return "#E67432";
    case "AC":
    case "ACA":
    case "AP":
    case "AA":
    case "APPROVED":
    case "AAC":
      return "#18A0FB";

    case "RC":
    case "RA":
    case "RCA":
    case "RP":
    case "RAC":
      return "#D74DED";

    case "CA":
    case "AS":
    case "IE":
      return "#ED9A4D";

    case "NS":
      return "#817393";
    case "Ex":
    case "FAP":
      return "#71DE56";

    default:
      return "#D4D1D7";
  }
};

export const filterEstatusOptions = [
  { name: "R - Recepción", value: "R" },
  { name: "AS - Asignado", value: "AS" },
  { name: "IE - Información Enviada", value: "IE" },
  { name: "CA - Capturando", value: "CA" },
  { name: "AC - Aprobado Coordinador", value: "AC" },
  { name: "RC - Rechazado Coordinador", value: "RC" },
  { name: "AA - Aprobado Auditor", value: "AA" },
  { name: "RA - Rechazado Auditor", value: "RA" },
  { name: "AP - Aprobado Proveedor", value: "AP" },
  { name: "RP - Rechazado Proveedor", value: "RP" },
  { name: "ACA - Aprobado Cadena", value: "ACA" },
  { name: "RCA - Rechazado Cadena", value: "RCA" },
  { name: "Ex - Exportado", value: "Ex" },
];

export const getRetailerStatus = (state) => {
  switch (state) {
    case "AS":
    case "CA":
    case "IE":
      return "uncomplete";

    case "RC":
    case "RA":
    case "RP":
      return "revision";

    case "AC":
    case "AA":
    case "AP":
      return "complete";

    default:
      return "uncomplete";
  }
};

export const getGraphicColor = (state) => {
  switch (state) {
    case "exported":
      return "#71DE56";
    case "inprogress":
      return "#ED9A4D";
    case "inrevision":
      return "#D74DED";
    case "finished":
      return "#18A0FB";
    default:
      return "#EDD34D";
  }
};

export const getStateFilter = (state) => {
  switch (state) {
    case "Ex":
      return "exported";

    case "AS":
    case "CA":
    case "IE":
      return "inprogress";

    case "RC":
    case "RA":
    case "RP":
      return "inrevision";

    case "AC":
    case "AA":
    case "AP":
      return "finished";

    default:
      return "uncomplete";
  }
};

export const getShortStatus = (status) => {
  switch (status) {
    case "NULL":
      return "R";
    default:
      return status;
  }
};

export const getLongStatus = (status) => {
  switch (status) {
    case "CREATED":
      return "Creada";
    case "NULL":
      return "-";
    case "R":
      return "Recibido";
    case "AS":
      return "Asignado";
    case "AC":
      return "Aprobado Coordinador";
    case "RC":
      return "Rechazado Coordinador";
    case "AA":
      return "Aprobado Auditor";
    case "RA":
      return "Rechazado Auditor";
    case "AP":
      return "Aprobado Proveedor";
    case "RP":
      return "Rechazado Proveedor";
    case "ACA":
      return "Aprobado Cadena";
    case "RCA":
      return "Rechazado Cadena";
    case "IE":
      return "QA Coordinador";
    case "CA":
      return "Capturando";
    default:
      return "Capturando";
  }
};

export const getRegionFlag = (region) => {
  switch (region) {
    case "México":
      return mexico;
    case "Argentina":
      return argentina;
    case "Honduras":
      return honduras;
    case "Colombia":
      return colombia;
    case "Costa Rica":
      return costa;
    case "Ecuador":
      return ecuador;
    case "El Salvador":
      return salvador;
    case "Panamá":
      return panama;
    case "Perú":
      return peru;
    default:
      return defaultFlag;
  }
};

export const getImage = (imageGeneral, width, height) => {
  try {
    const image = imageGeneral;
    const ext = image.src.split(".");
    const uuid = ext[0].split("-");
    return {
      id: image.id,
      image_id: image.image_id,
      packing_type: +image.packing_type,
      image_type: +image.image_type,
      name: image.name,
      version: image.id_version,
      width: image.width,
      height: image.height,
      ext: ext[ext.length - 1],
      uuid:
        uuid.reduce((prevVal, currVal, idx) => {
          if (idx < 2) return "";
          else return idx === 2 ? currVal : prevVal + "-" + currVal;
        }, "") || uuidv4(),
      srcDB: image.src,
      src:
        "https://d24s337exbt5zr.cloudfront.net/" +
        Base64.encode(
          JSON.stringify({
            bucket: process.env.REACT_APP_IMAGES_BUCKET,
            key: image.src,
            edits: {
              resize: {
                width: width * 4,
                height: height * 4,
                fit: "contain",
                background: {
                  r: 255,
                  g: 255,
                  b: 255,
                  alpha: 1,
                },
              },
            },
          })
        ),
    };
  } catch (err) {
    console.log("err", err);
    return { src: defaultUpdate };
  }
};

export const renderCompaniesList = (services) => {
  if (sessionStorage.getItem("revision")) {
    return sessionStorage.getItem("revision") && services.length > 0;
  } else if (!sessionStorage.getItem("revision")) {
    return true;
  }
};

export const getRoleUser = (userId) => {
  switch (userId) {
    case 1:
      return "Administrador";
    case 2:
      return "KAM";
    case 3:
      return "Recepcionista";
    case 4:
      return "Coordinador textos";
    case 5:
      return "Coordinador imagenes";
    case 6:
      return "Auditor QA";
    case 7:
      return "Especialista textos";
    case 8:
      return "Especialista edición";
    case 9:
      return "Entregas";
    case 10:
      return "Custom";
    default:
      return "Proveedor";
  }
};

export const getProfilePicture = (userId, width = 24, height = 24) => {
  if (userId === -1) return teamContentoh;
  if (!userId) return EmptyCircleGray;
  try {
    return (
      "https://d24s337exbt5zr.cloudfront.net/" +
      Base64.encode(
        JSON.stringify({
          bucket: process.env.REACT_APP_IMAGES_PROFILE_BUCKET,
          key: `id-${userId}/${userId}.png`,
          edits: {
            resize: {
              width: width,
              height: height,
              fit: "contain",
              background: {
                r: 255,
                g: 255,
                b: 255,
                alpha: 0,
              },
            },
          },
        })
      )
    );
  } catch (err) {
    console.log("err", err);
    return { src: defaultUpdate };
  }
};

export const getGridImage = (src, width = 24, height = 24) => {
  try {
    return (
      "https://d24s337exbt5zr.cloudfront.net/" +
      Base64.encode(
        JSON.stringify({
          bucket: process.env.REACT_APP_IMAGES_BUCKET,
          key: src,
          edits: {
            resize: {
              width: width,
              height: height,
              fit: "contain",
              background: {
                r: 255,
                g: 255,
                b: 255,
                alpha: 1,
              },
            },
          },
        })
      )
    );
  } catch (err) {
    console.log("err", err);
    return { src: defaultUpdate };
  }
};

export const getImagesPrices = (imagesData, retailersSelected) => {
  try {
    const arrayMins = [];
    const mandatories = [];
    let imagesPrice = 0;
    let imagesQty = 0;
    // Check minimum of images required for product
    imagesData?.retailers.forEach((retailer, i) => {
      if (retailersSelected) {
        const item = retailersSelected.find((item) => item.id === retailer.id);
        if (item) {
          arrayMins.push(retailer.min);
          imagesData?.retailerMandatories[i].forEach((requiredImage) => {
            if (!mandatories.find((item) => item === requiredImage.id_image)) {
              mandatories.push(requiredImage.id_image);
            }
          });
        }
      } else if (!retailersSelected) {
        arrayMins.push(retailer.min);
        if (imagesData?.retailerMandatories) {
          imagesData?.retailerMandatories[i]?.forEach((requiredImage) => {
            if (!mandatories.find((item) => item === requiredImage.id_image)) {
              mandatories.push(requiredImage.id_image);
            }
          });
        }
      }
    });

    const MinImages = Math.max(...arrayMins);
    imagesQty = MinImages;
    // set initial price based on max minimum of images required among retailers
    imagesPrice = MinImages * 99;
    if (mandatories.length > MinImages) {
      imagesPrice = mandatories.length * 99;
      imagesQty = mandatories.length;
    }

    return { price: imagesPrice, quantity: imagesQty };
  } catch (err) {
    console.log(err, "unable to get imaging pricing");
    return { price: 0, quantity: 0, err: true };
  }
};

export const getDatasheetPrices = (data, inputsGlobal, retailers) => {
  const arrayGlobal = Object.values(inputsGlobal);
  let sharedInputs = [];
  let missingQty = 0;
  let filledData = 0;
  if (retailers) {
    // Checkout sent retailers
    data.forEach((ret) => {
      // Iterar cada datagroup del retailer y obtener cada input/atributo requerido
      const item = retailers.find((item) => item.id === ret.retailer.id);
      if (item) {
        Object.values(ret.data).forEach((datagroup) => {
          datagroup.inputs.forEach((input) => {
            // check if this input has a value
            let inputFound = arrayGlobal.find((IR) => IR.id === input);
            let registeredInput = sharedInputs.find(
              (item) => item.id === input
            );
            if (inputFound && !registeredInput) {
              // if value exists -> don't add to retailer's missing  attributes
              sharedInputs.push(inputFound);
            }
          });
        });
      }
    });
  } else {
    sharedInputs = arrayGlobal;
  }
  missingQty = sharedInputs.filter((e) => !e.value).length;
  filledData = sharedInputs.filter((e) => e.value).length;

  return {
    missing: missingQty,
    filled: filledData,
    sharedInputs: sharedInputs,
  };
};

export const checkoutDatasheetUpdate = (retailers) => {
  const sharedInputs = [];
  let missingQty = 0;
  let filledData = 0;
  let price = 0;
  retailers.forEach((ret) => {
    ret.forEach((input) => {
      if (!sharedInputs.find((catched) => catched.id === input.id)) {
        sharedInputs.push(input);
      }
    });
  });

  missingQty = sharedInputs.filter((e) => !e.value).length;
  filledData = sharedInputs.filter((e) => e.value).length;

  //Recalculate price based on attributes
  if (sharedInputs.length > 0 && sharedInputs.length <= 15) {
    price = 96;
  } else if (sharedInputs.length > 15 && sharedInputs.length <= 45) {
    price = 213;
  } else if (sharedInputs.length > 45) {
    price = 320;
  }

  return {
    missing: missingQty,
    filled: filledData,
    price: price,
    sharedInputs: sharedInputs,
  };
};

export const getPrio = (prio) => {
  switch (prio) {
    case "none":
      return emptyFlag;
    case "low":
      return greenFlag;
    case "medium":
      return yellowFlag;
    case "high":
      return redFlag;
    default:
      return emptyFlag;
  }
};

export const membershipLimitations = async (validation) => {
  let result = {};

  try {
    const countsPromises = [];
    let prodLimit = 0;
    let colabLimit = 0;
    switch (validation) {
      case "products":
        countsPromises.push(
          axios.get(
            `${process.env.REACT_APP_ARTICLE_ENDPOINT}?countCheck=true`,
            {
              headers: {
                Authorization: sessionStorage.getItem("jwt"),
              },
            }
          )
        );
        // This validation returns a route for the Link "to" prop
        result = "/AddProducts";
        break;
      case "colab":
        // This validation returns boolean and error message in case it is one
        result = true;
        countsPromises.push(
          axios.get(
            `${process.env.REACT_APP_USER_ENDPOINT}?isColaborators=1&countCheck=true`,
            {
              headers: {
                Authorization: sessionStorage.getItem("jwt"),
              },
            }
          )
        );
        break;
      default:
        break;
    }

    const [dataResponses] = await Promise.all(countsPromises);
    const activeItems = JSON.parse(dataResponses.data?.body)?.count;
    const membership = JSON.parse(sessionStorage.getItem("user")).membership;
    switch (membership.planID) {
      case 1:
        prodLimit = 3;
        colabLimit = 1;
        break;
      case 2:
        prodLimit = 10;
        colabLimit = 1;
        break;
      case 3:
        prodLimit = 50;
        colabLimit = 1;
        break;
      case 4:
        prodLimit = 100;
        colabLimit = 3;
        break;
      case 5:
        prodLimit = 500;
        colabLimit = 5;
        break;
      case 6:
        prodLimit = 1000;
        colabLimit = 10;
        break;
      case 7:
        prodLimit = 2500;
        colabLimit = 10;
        break;
      case 8:
        prodLimit = 5000;
        colabLimit = 50;
        break;
      case 9:
        prodLimit = Number.POSITIVE_INFINITY;
        colabLimit = 30;
        break;
      default:
        break;
    }
    if (validation === "products" && activeItems >= prodLimit) {
      result = "maxProductsCreated";
    } else if (validation === "colab" && activeItems >= colabLimit) {
      result = false;
    }
  } catch (err) {
    console.log(err);
  }
  return result;
};
export const priorityOptions = [
  { name: "Sin prioridad", value: "none" },
  { name: "Baja", value: "low" },
  { name: "Media", value: "medium" },
  { name: "Alta", value: "high" },
];

export const getDatasheetCart = (retailersSelected, datasheetData) => {
  if (retailersSelected.length === 0 || !datasheetData)
    return { qty: 0, price: 0 };
  const objAttributes = {};
  datasheetData?.retailers?.forEach((retailer) => {
    if (retailersSelected.find((f) => f === retailer.id) !== undefined) {
      retailer.attributes.forEach((attr) => {
        objAttributes[attr] = datasheetData.attributes[attr];
      });
    }
  });
  const attributes = Object.values(objAttributes);
  let price = 0;
  if (attributes.length > 0 && attributes.length <= 15) {
    price = 96;
  } else if (attributes.length > 15 && attributes.length <= 45) {
    price = 213;
  } else if (attributes.length > 45) {
    price = 320;
  }
  return { qty: attributes.length, price: price };
};

export const getImagesCart = (retailersSelected, imagesData) => {
  if (retailersSelected.length === 0 || !imagesData)
    return { qty: 0, price: 0 };
  const arrayMins = [];
  const mandatories = [];
  const { attributes, retailers } = imagesData;
  // Check minimum of images required for product
  retailers?.forEach((retailer) => {
    if (retailer) {
      if (retailersSelected.find((f) => f === retailer.id)) {
        arrayMins.push(retailer.min_img);
        const newArray = [];
        retailer?.images?.forEach((e) => {
          newArray.push(attributes[e]);
        });

        const mandatoriesRetailer = newArray.filter((f) => f.mandatory);
        mandatories.push(mandatoriesRetailer.length);
      }
    }
  });
  const imagesQty = Math.max(Math.max(...arrayMins), Math.max(...mandatories));
  if (imagesQty === Number.NEGATIVE_INFINITY) return { qty: 0, price: 0 };
  return { price: imagesQty * 99, qty: imagesQty };
};

export const getDiscount = (datasheet, description, images) => {
  const servicesSelected =
    (datasheet.length > 0) + (description.length > 0) + (images.length > 0);
  if (servicesSelected === 3) return 0.8;
  if (servicesSelected === 2) return 0.9;
  return 1;
};

export const getPrices = (params) => {
  const { product, valuesDatasheet, valuesImages } = params;
  const prices = {
    basicPrice: 0,
    aditionalPrice: 0,
    total: 0,
    priceString: "",
  };
  const discount = getDiscount(
    product.datasheet,
    product.description,
    product.image
  );
  prices.basicPrice =
    (valuesDatasheet.price +
      product.description.length * 278 +
      valuesImages.price) *
    discount;
  prices.aditionalPrice = product.attributeTranslations ? 307 : 0;
  prices.aditionalPrice += product.descriptionTranslations ? 430 : 0;
  product.manual?.forEach((e) => {
    if (e === "5") prices.aditionalPrice += 122;
    if (e === "10") prices.aditionalPrice += 182;
    if (e === "15") prices.aditionalPrice += 242;
  });
  product.build?.forEach((e) => {
    if (e === "small") prices.aditionalPrice += 123;
    if (e === "large") prices.aditionalPrice += 184;
  });
  prices.total = prices.basicPrice + prices.aditionalPrice;
  let priceString = `$${prices.total.toFixed(2)}`;
  if (discount === 0.8) priceString += " (-20%)";
  else if (discount === 0.9) priceString += " (-10%)";
  prices.priceString = priceString;
  return prices;
};

/**
 *
 * @param {object} services object from services requested for the product
 * @param {boolean} services.datasheet bool which tells y datasheets were requested
 * @param {boolean} services.descriptions bool which tells y descriptions were requested
 * @param {boolean} services.images bool which tells y images were requested
 * @returns
 */
export const servicesTranslation = (services) => {
  try {
    const servicesKeys = Object.keys(services);
    const filtered = servicesKeys.filter((key) => services[key]);
    replaceKeyNames(filtered);
    const lastService = filtered.pop();
    return filtered.length
      ? `${filtered.join(",")} ${
          lastService === "imágenes" ? "e" : "y"
        } ${lastService}`
      : "---";
  } catch (err) {
    console.log(err, "Error translating services");
  }
};

/**
 *
 * @param {Array<string} filteredServices strings array containin only requested services
 */
const replaceKeyNames = (filteredServices) => {
  replaceKeyString(filteredServices, "datasheets");
  replaceKeyString(filteredServices, "descriptions");
  replaceKeyString(filteredServices, "images");
};

/**
 *
 * @param {Array<string} filteredServices strings array containin only requested services
 * @param {string} serviceName service string to identify which service we need to translate ("datasheets" => "ficha técnica")
 */
const replaceKeyString = (filteredServices, serviceName) => {
  const servicesCatalog = {
    datasheets: "ficha técnica",
    descriptions: "descripciones",
    images: "imágenes",
  };

  if (filteredServices.includes(serviceName)) {
    const indexOfService = filteredServices.indexOf(serviceName);
    indexOfService !== -1 &&
      (filteredServices[indexOfService] = servicesCatalog[serviceName]);
  }
};

/**
 *
 * @param {string} category category string from db (I.E. "CONSUMIBLES|PRODUCTOS DE LIMPIEZA|SUAVIZANTES")
 * @param {string} section section which wants to be returned (I.E department = CONSUMIBLES else = PRODUCTOS DE LIMPIEZA|SUAVIZANTES )
 * @returns
 */
export const handleCategories = (category, section) => {
  try {
    const categories = category.split("|");
    if (section === "department") return categories[0] ?? "---";
    else {
      categories.splice(0, 1);
      return categories ?? "---";
    }
  } catch (err) {
    console.log(err, "unable to split category (tasks)");
    return "---";
  }
};

/**
 *
 * @returns {string} encoded string containing every retailer id that's related to user company(I.E. 58,59 => 58%2C59)
 */
export const getCompanyRetailers = () => {
  const retailersIds = JSON.parse(
    sessionStorage.getItem("company")
  ).retailers.map((ret) => ret.id);

  return encodeURIComponent(retailersIds.join(","));
};

/**
 *
 * @returns {string} name of the unique code used by user company to identify products
 */
export const getUniqueCodeLabel = () => {
  let userIsRetailer;
  if (sessionStorage.getItem("user"))
    userIsRetailer = JSON.parse(sessionStorage.getItem("user")).is_retailer;
  return userIsRetailer ? "SKU" : "UPC";
};

export const getRetailerImage = (id) =>
  `https://content-management-images.s3.amazonaws.com/retailers/${id}.png`;

export const currencyFormatter = (params = {}) =>
  new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
    maximumFractionDigits: 2,
    ...params,
  });

export const dateFormatter = new Intl.DateTimeFormat("es-ES", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
});
