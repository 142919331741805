export const COLORS = {
  magentaOriginal: "#B12D84",
  purpuraOriginal: "#",
  purpuraS2: "#",
  purpuraS3: "#",
  magentaS2: "#",
  purpuraDark: "#",
  grayS5: "#",
  grayS4: "#",
  grayS3: "#",
  grayS2: "#",
  grayS1: "#",
};

export const magentaOriginal = "#B12D84";
export const grayS2 = "#F0EEF2";
export const grayS4 = "#817393";
export const grayS5 = "#503D66";
export const grayS6 = "#808080";
export const grayS7 = "#D4D1D7";
export const grayS8 = "#f1f1f1";

export const rojoS1 = "#DF2828";
export const redS2 = "#a03939";
export const redS3 = "#f0b8b8";

export const greenS1 = "#29452d";
export const greenS2 = "#588a4b";
export const greenS3 = "#d9e9d5";

export const pinkS1 = "#E33AA9";
export const pinkS2 = "#bc308b";
