import { Container } from "../products/productsGeneral/styles";
import IconInvoicingEmail from "../../assets/IconComponents/IconInvoicingEmail.svg";
import IconInvoicingPrint from "../../assets/IconComponents/IconInvoicingPrint.svg";

export default function HeaderInvoicingL() {
  return (
    <Container justifycontent="flex-end">
      <img className="imgIcon" src={IconInvoicingPrint} alt="Icon" />
      <img src={IconInvoicingEmail} alt="Icon" />
    </Container>
  );
}
