import { DateTime } from "luxon";
import { Redirect } from "react-router-dom";
import { useState, useEffect, useReducer, useCallback } from "react";
import { ButtonV2, Button, Modal, Loading } from "contentoh-components-library";
import First from "./steps/First";
import Second from "./steps/Second";
import Third from "./steps/Third";
import DropdownButton from "./DropdownButton";
import {
  Container,
  Header,
  Body,
  Footer,
  InfoCard,
  TotalDetail,
  Center,
  PaymentButtonContainer,
} from "./styles";
import {
  getCart,
  updateToCart,
  createToCart,
  deleteToCart,
} from "../_utils/data";
import { currencyFormatter, dateFormatter } from "../_utils/helper";
import spinner from "../../assets/defaultImages/Spinner.gif";
import deleteForever from "../../assets/IconCheckout/delete-forever.svg";

const calculatePrices = (service, totalItems = null, standardPrices) => {
  if (service === "datasheet") {
    if (totalItems <= 15) return standardPrices.datasheet15;
    if (totalItems <= 45) return standardPrices.datasheet45;
    return standardPrices.datasheet80;
  }
  if (service === "description") return standardPrices[service] * totalItems;
  if (service === "image") {
    let price = 0;
    totalItems.forEach((id) => {
      price += standardPrices[`image${id}`] ?? 0;
    });
    return price;
  }
  return 0;
};

const calcArticlePrice = (article, prices) => {
  const articlePrice = {
    general: 0,
    retailers: {},
    services: {
      datasheet: { price: 0, items: 0 },
      description: { price: 0, items: 0 },
      image: { price: 0, items: [] },
    },
    discount: 0,
  };
  const { category, retailers } = article;
  const { rules, standardPrices } = prices;
  const currentInputs = { datasheet: [], description: [], image: [] };
  const standardServices = ["datasheet", "description", "image"];
  const servicesSelected = [];
  Object.keys(retailers)
    .sort(sortRetailersFunction)
    .reverse()
    .forEach((id) => {
      const retailerId = id.replace(/.*-/, "");
      const retailerServicesSelected = retailers[id];
      articlePrice.retailers[retailerId] = {};
      if (!rules[category]) return;
      if (!rules[category][retailerId]) return;
      // Conteo de productos
      standardServices.forEach((service) => {
        if (!rules[category][retailerId][service]) return;
        const { inputs, price } = rules[category][retailerId][service];
        const currentServiceIsSelected =
          retailerServicesSelected.includes(service);
        let totalItems = 0;
        let inputsReceivable = [];
        if (service === "datasheet") {
          inputsReceivable = inputs.filter(
            ({ attributeId }) => !currentInputs[service].includes(attributeId)
          );
          inputsReceivable = [
            ...new Set(
              inputsReceivable.map(({ attributeId, commercialModel }) => {
                totalItems += commercialModel ?? 0;
                return attributeId;
              })
            ),
          ];
        } else if (service === "image") {
          inputsReceivable = [
            ...new Set(
              inputs.filter((f) => !currentInputs[service].includes(f))
            ),
          ];
          totalItems = inputsReceivable;
        } else {
          totalItems = 1; // description service is 1
          inputsReceivable = inputs.filter(
            (f) => !currentInputs[service].includes(f)
          );
        }
        if (currentServiceIsSelected) {
          currentInputs[service].push(...inputsReceivable);
        }

        const haveItems =
          service === "image" ? totalItems.length > 0 : totalItems > 0;
        if (!haveItems) {
          articlePrice.retailers[retailerId][service] = {
            price: 0,
            discount: price,
            inputs,
            inputsReceivable,
          };
          return;
        }
        const newPrice = calculatePrices(service, totalItems, standardPrices);
        articlePrice.retailers[retailerId][service] = {
          price: newPrice,
          discount: price - newPrice,
          inputs,
          inputsReceivable,
        };
        const hasAlredyBeenAdded = servicesSelected.includes(service);
        if (currentServiceIsSelected) {
          if (service === "image")
            articlePrice.services[service].items.push(...totalItems);
          else articlePrice.services[service].items += totalItems;
          if (!hasAlredyBeenAdded) servicesSelected.push(service);
        }
      });
    });
  // Cálculo de precio
  standardServices.forEach((service) => {
    if (!servicesSelected.includes(service)) return;
    const price = calculatePrices(
      service,
      articlePrice.services[service].items,
      standardPrices
    );

    articlePrice.services[service].price = price;
    articlePrice.general += price;
  });
  articlePrice.full = articlePrice.general;
  if (servicesSelected.length === 2) {
    articlePrice.discount = 10;
    articlePrice.general = articlePrice.general * 0.9;
  } else if (servicesSelected.length === 3) {
    articlePrice.discount = 20;
    articlePrice.general = articlePrice.general * 0.8;
  }
  return articlePrice;
};

const articlesReducer = (state, event) => {
  const temp = { ...state };
  const { action, payload } = event;
  switch (action) {
    case "init":
      return payload;
    case "updateService": {
      const { articleId, retailerId, service, isAdd, prices } = payload;
      const key = Object.keys(temp[articleId].retailers).find((f) =>
        f.endsWith(`-${retailerId}`)
      );
      if (isAdd) {
        temp[articleId].retailers[key].push(service);
      } else {
        temp[articleId].retailers[key] = temp[articleId].retailers[key].filter(
          (f) => f !== service
        );
      }
      const price = calcArticlePrice(temp[articleId], prices);
      temp[articleId].price = price;
      return temp;
    }
    case "updateServiceAllProducts": {
      const { isAdd, service, prices } = payload;
      Object.entries(temp).forEach(([articleId, article]) => {
        Object.keys(article.retailers).forEach((id) => {
          if (isAdd && !temp[articleId].retailers[id].includes(service)) {
            temp[articleId].retailers[id].push(service);
          } else if (!isAdd) {
            temp[articleId].retailers[id] = temp[articleId].retailers[
              id
            ].filter((f) => f !== service);
          }
        });
        const price = calcArticlePrice(temp[articleId], prices);
        temp[articleId].price = price;
      });
      return temp;
    }
    default:
      return temp;
  }
};

const sortRetailersFunction = (a, b) => {
  const [attrA] = a.split("-");
  const [attrB] = b.split("-");
  return attrA - attrB;
};

export const Checkout = (props) => {
  const [currentDate, setCurrentDate] = useState("");
  const [articles, setArticles] = useReducer(articlesReducer, {});
  const [retailers, setRetailers] = useState({});
  const [generalData, setGeneralData] = useState({
    totalArticles: 0,
    retailers: [],
    datasheet: 0,
    description: 0,
    image: 0,
    subtotalPrice: 0,
  });
  const [step, setStep] = useState(1);
  const [bodyStep, setBodyStep] = useState(null);
  const [buttonsStepData, setButtonsStepData] = useState([
    { name: "Confirmar cadenas y servicios" },
    { name: "2" },
    { name: "3" },
  ]);
  const [retailersSelected, setRetailersSelected] = useState({});
  const [articlesSelected, setArticlesSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [reload, setReload] = useState(0);
  const [prices, setPrices] = useState({});
  const [appointment, setAppointment] = useState({ type: "En oficina" });
  const [paymentData, setPaymentData] = useState({});
  const [modalData, setModalData] = useState({
    show: false,
    title: "Actualización completa",
    message: "",
    icon: "success",
  });
  const [createOT, setCreateOT] = useState(false); // trigger
  const [orderBeingCreated, setOrderBeingCreated] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const [canContinue, setCanContinue] = useState(false);

  useEffect(() => {
    const isInOffice = appointment.type === "En oficina";
    const isDefaultStreet =
      appointment.street === "Eugenio Sue #316 Col. Polanco";
    if (isInOffice && !isDefaultStreet) {
      setAppointment({
        street: "Eugenio Sue #316 Col. Polanco", // default CDMX || Mty addres || user value
        city: "CDMX, México", // default CDMX || Mty addres || user value
        zipCode: "11560", // default CDMX || Mty addres || user value
        latlng: { lat: 19.4363992, lng: -99.196269 }, // default CDMX || Mty addres || user value
        date: dateFormatter.format(DateTime.now()), // today DD/MM/YYYY || userDate
        collectedName: "", // user value || empty string default
        collectedPhone: "", // user value || empty string default
        type: "En oficina", // user value || "En oficina" default
        inputType: "", // user value || empty string default})
      });
    }
  }, [appointment]);

  const getCartArticles = useCallback(
    () =>
      getCart({ action: "general" })
        .then(({ articles, retailers }) => {
          setRetailers(retailers);
          // setArticles({ action: "init", payload: articles });
          return articles;
        })
        .catch((err) => console.error(err)),
    []
  );

  const getCartPrices = useCallback(
    () =>
      getCart({ action: "price" })
        .then((prices) => {
          setPrices(prices);
          return prices;
        })
        .catch((err) => console.error(err)),
    []
  );

  const resetPayButton = () => setCreateOT(false);

  const handleSubmit = async (dataExtra = null, key = "") => {
    setOrderBeingCreated(true);
    const { subtotalPrice, totalPrice } = generalData;
    const data = { appointment, paymentData, subtotalPrice, totalPrice };
    if (dataExtra && key !== "") {
      data[key] = { ...data[key], ...dataExtra };
    }
    const res = await createToCart({ data });
    const { statusCode, body } = res?.data;
    const buttonData = {
      key: "btn-Aceptar",
      type: "white",
      borderType: "oval",
      label: "Aceptar",
      size: 12,
    };
    if (statusCode === 200) {
      const { orderId } = JSON.parse(body);
      setModalData((prev) => ({
        ...prev,
        show: true,
        title: "Orden creada con éxito",
        message: `Tu orden es: ${orderId}`,
        icon: "success",
        buttons: [
          <ButtonV2 {...buttonData} onClick={() => setRedirect("products")} />,
        ],
      }));
    } else {
      setModalData((prev) => ({
        ...prev,
        show: true,
        icon: "error",
        title: "Ups, algo salio mal",
        message: "Contacta al equipo de TI para resolver tu caso",
        buttons: [
          <ButtonV2
            {...buttonData}
            onClick={() => {
              setOrderBeingCreated(false);
              resetPayButton();
              setModalData((prev) => ({ ...prev, show: false }));
            }}
          />,
        ],
      }));
    }
  };

  const deleteArticles = () => {
    setIsLoading(true);
    deleteToCart({ data: { articles: articlesSelected } })
      .then(async () => {
        await getDataCart();
        setArticlesSelected([]);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const getDataCart = useCallback(async () => {
    const [articles, prices] = await Promise.all([
      getCartArticles(),
      getCartPrices(),
    ]);
    const pricePayload = {};
    Object.entries(articles ?? {}).forEach(([id, data]) => {
      const price = calcArticlePrice(data, prices);
      articles[id].price = price;
      pricePayload[id] = price.general;
    });
    setArticles({ action: "init", payload: articles });
    if (Object.keys(articles).length > 0) setCanContinue(true);
    updateToCart({ action: "updatePrice", data: { articles: pricePayload } });
    setIsLoading(false);
  }, [getCartArticles, getCartPrices]);

  useEffect(() => {
    setIsLoading(true);
    const dt = DateTime.now();
    const f = { dateStyle: "full", timeStyle: "long" };
    const date = dt
      .setLocale("es-MX")
      .toLocaleString(f)
      .replace(/,(?:.(?!,))+$/, "");
    setCurrentDate(date.charAt(0).toUpperCase() + date.slice(1));
    getDataCart();
    setArticlesSelected([]);
  }, [getDataCart, reload]);

  useEffect(() => {
    const checkoutData = {
      totalArticles: Object.keys(articles ?? {}).length,
      retailers: [],
      datasheet: 0,
      description: 0,
      image: 0,
      subtotalPrice: 0,
      totalPrice: 0,
      IVA: 0,
    };
    Object.values(articles ?? {}).forEach((article) => {
      const { price, retailers } = article;
      checkoutData.subtotalPrice += price.general;
      Object.entries(retailers)
        .reverse()
        .forEach(([id, services]) => {
          const retailerId = id.replace(/.*-/, "");
          if (!price.retailers[retailerId]) return;
          if (!checkoutData.retailers.includes(retailerId)) {
            checkoutData.retailers.push(retailerId);
          }
          services.forEach((service) => {
            if (!price.retailers[retailerId][service]) return;
            if (service === "description") {
              checkoutData[service] += 1;
              return;
            }
            checkoutData[service] +=
              price.retailers[retailerId][service]?.inputsReceivable?.length ??
              0;
          });
        });
    });
    checkoutData.totalPrice = checkoutData.subtotalPrice * 1.16;
    checkoutData.IVA = checkoutData.subtotalPrice * 0.16;
    setGeneralData(checkoutData);
  }, [articles]);

  useEffect(() => {
    switch (step) {
      case 1:
        setButtonsStepData([
          { name: "Confirmar cadenas y servicios" },
          { name: "2" },
          { name: "3" },
        ]);
        setBodyStep(
          <First
            articles={articles}
            setArticles={async (action, payload) => {
              setArticles({
                action,
                payload: { ...payload, prices },
              });
              const res = await updateToCart({ action, data: payload });
              const isMultipleUpdate = action !== "updateService";
              const isSuccessCode = res?.data?.statusCode === 200;
              if (isMultipleUpdate && isSuccessCode) {
                const { message } = JSON.parse(res?.data?.body);
                if (message !== "Ok")
                  setModalData((prev) => ({
                    ...prev,
                    icon: "success",
                    message,
                    show: true,
                  }));
              }
            }}
            retailers={retailers}
            sortRetailersFunction={sortRetailersFunction}
            articlesSelected={articlesSelected}
            setArticlesSelected={setArticlesSelected}
          />
        );
        break;
      case 2:
        const amount = Math.floor(generalData.totalPrice * 1.16 * 100);
        const amount_decimal = generalData.totalPrice * 1.16 * 100 - amount;
        setPaymentData({ amount, amount_decimal });
        setButtonsStepData([
          { name: "1", onClick: () => setStep(1) },
          { name: "Elegir entrega" },
          { name: "3" },
        ]);
        setBodyStep(
          <Second company={props.company} setAppointment={setAppointment} />
        );
        break;
      case 3:
        setButtonsStepData([
          { name: "1", onClick: () => setStep(1) },
          { name: "2", onClick: () => setStep(2) },
          { name: "Forma de pago" },
        ]);
        setBodyStep(
          <Third
            articles={articles}
            retailers={retailers}
            createOT={createOT}
            paymentData={paymentData}
            generalData={generalData}
            setPaymentData={setPaymentData}
            handleSubmit={handleSubmit}
            resetPayButton={resetPayButton}
          />
        );
        break;
      default:
        setBodyStep(null);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    step,
    generalData,
    articles,
    retailers,
    prices,
    articlesSelected,
    createOT,
  ]);

  useEffect(() => {
    const retailersSelected = [];
    articlesSelected.forEach((id) => {
      Object.keys(articles[id].retailers).forEach((id) => {
        const retailerId = id.replace(/.*-/, "");
        if (!retailersSelected.includes(retailerId))
          retailersSelected.push(retailerId);
      });
    });
    setRetailersSelected(retailersSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articlesSelected]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <Container>
      <Modal
        {...modalData}
        onClickBtnDefault={() =>
          setModalData((prev) => ({ ...prev, show: false }))
        }
      />
      <Header>
        <div>
          <h1>{props.company.trade_name}</h1>
          <p className="date">{currentDate}</p>
        </div>
        <div className="button__general button__icon">
          {step === 1 && articlesSelected.length > 0 ? (
            <div className="button__retailer_control">
              <DropdownButton
                retailers={retailers}
                retailersSelected={retailersSelected}
                articlesSelected={articlesSelected}
                setArticlesSelected={setArticlesSelected}
                setReload={setReload}
              />
              <Button
                buttonType="general-transparent-button border_red"
                onClick={() =>
                  setModalData({
                    show: true,
                    message:
                      "¿Seguro que quieres borrar los productos o cadenas seleccionadas?",
                    icon: "info",
                    buttons: [
                      <ButtonV2
                        key="btn-Cancelar"
                        type="white"
                        label="Cancelar"
                        size={12}
                        onClick={() => setModalData({ show: false })}
                      />,
                      <ButtonV2
                        key="btn-Aceptar"
                        type="pink"
                        label="Aceptar"
                        size={12}
                        onClick={() => {
                          deleteArticles();
                          setModalData({ show: false });
                        }}
                      />,
                    ],
                  })
                }
                label={
                  <Center>
                    <img src={deleteForever} alt="Eliminar productos" />
                  </Center>
                }
              />
            </div>
          ) : null}
          <div className="button__steps">
            <div className="links">
              {buttonsStepData.map((data, i) => (
                <ButtonV2
                  key={i}
                  onClick={() => data.onClick && data.onClick()}
                  id={`step${i + 1}`}
                  label={data.name}
                  type={step > i + 1 ? "pink" : "white"}
                />
              ))}
            </div>
          </div>
          <Button
            buttonType="general-transparent-button"
            onClick={() => {}}
            label={
              <Center>
                <span className="material-icons-outlined">close</span>
              </Center>
            }
          />
        </div>
      </Header>
      <Body>{isLoading ? <Loading /> : bodyStep}</Body>
      <Footer>
        <div>
          <InfoCard>
            <p className="title">PRODUCTOS</p>
            <p className="value">{generalData.totalArticles}</p>
          </InfoCard>
          <InfoCard>
            <p className="title">CADENAS</p>
            <p className="value">{generalData.retailers.length}</p>
          </InfoCard>
          <InfoCard>
            <p className="title">FICHAS TÉCNICAS</p>
            <p className="value">{generalData.datasheet}</p>
          </InfoCard>
          <InfoCard>
            <p className="title">DESCRIPCIONES</p>
            <p className="value">{generalData.description}</p>
          </InfoCard>
          <InfoCard>
            <p className="title">IMÁGENES</p>
            <p className="value">{generalData.image}</p>
          </InfoCard>
        </div>
        <div>
          <p className="total">Total</p>
          <InfoCard>
            <p className="value">
              {currencyFormatter().format(generalData.totalPrice)}
            </p>
          </InfoCard>
          <TotalDetail>
            <div className="container">
              <p>Subtotal:</p>
              <p>IVA:</p>
            </div>
            <div className="container">
              <p>{currencyFormatter().format(generalData.subtotalPrice)}</p>
              <p>{currencyFormatter().format(generalData.IVA)}</p>
            </div>
          </TotalDetail>
          <PaymentButtonContainer
            orderBeingCreated={orderBeingCreated === true}
          >
            <Button
              buttonType={
                orderBeingCreated ? "general-button-disabled" : "general"
              }
              onClick={() =>
                canContinue &&
                (step === 3
                  ? setCreateOT(true)
                  : setStep((prev) => (prev += 1)))
              }
              label={
                <Center>
                  {!canContinue ? (
                    "Carrito Vacío"
                  ) : orderBeingCreated ? (
                    <img src={spinner} alt="Creando orden" />
                  ) : step === 3 ? (
                    "Pagar"
                  ) : (
                    <>
                      Continuar
                      <span className="material-icons-outlined">
                        arrow_right_alt
                      </span>
                    </>
                  )}
                </Center>
              }
            />
            {orderBeingCreated ? <p>Esta acción podría demorar</p> : null}
          </PaymentButtonContainer>
        </div>
      </Footer>
    </Container>
  );
};
