import styled from "styled-components";
import checked from "../../../assets/Icons/checked.svg";

export const Container = styled.div`
  .container-def {
    margin-top: 10px;
    box-shadow: 0px 2px 4px #00000040;
    border-radius: 5px;
    background-color: #fff;
    padding-bottom: 10px;
    position: absolute;
    z-index: 10;
    max-height: 500px;
    overflow: auto;
  }

  .custom-select {
    font-family: Raleway;
    font-size: 13px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 10px 15px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-radius: 50px;
    background-color: #fff;
    border: 1px solid #f0f0f0;
    cursor: pointer;
  }

  .search-bar{
    display: flex;
    align-items: center;
    padding: 0px 5px;
    border-bottom: 1px solid #f0f0f0;
  }

  .search {
    border: none;
    height: 34px;
    padding: 5px 10px;
    color: #707070;
  }
  .search::placeholder {
    display: inline;
    color: #707070;
    font-family: Avenir Next;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
  }

  .container-options {
    padding: 5px;
  }

  .default-option {
    cursor: pointer;
    padding: 10px 10px 5px;
    color: #817393;
    font-family: Avenir Next;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
  }

  .options {
    height: 24px;
    gap: 10px;
    display: flex;
    align-items: center;
  }

  .custom-check {
    border: 3px solid red;
    background-color: #D9D9D9;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    width: 300px;
    height: 300px;
    appearance: none;
    display: inline-block;
    vertical-align: middle;
    background-origin: border-box;
    padding: 0;
    user-select: none;
    flex-shrink: 0;
    border-radius: 3px;
  }

  .custom-check {
    cursor: pointer;
    background-color: #D9D9D9;
    width: 16px;
    height: 16px;
    appearance: none;
    border: 1px solid #cbcbcb;
    transition: background-color 0.3s ease-in-out;
  }

  .custom-check:checked {
    background-color: #FFFFFF;
    border: none;
    background-image: url(${checked});
  }

  .custom-check:focus {
    border-color: rgb(198, 198, 198);
  }

  .custom-check:disabled {
    background-color: rgb(198, 198, 198);
    background-image: none;
  }

  .custom-check:disabled:checked {
    background-color: rgb(198, 198, 198);
    background-image: url(${checked});
  }

  .texto {
    display: inline;
    color: #817393;
    font-family: Avenir Next;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
  }

  .search-icon{
    width:13px;
    height: 13px;
  }

  .arrow-item{
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    cursor: pointer;
    font-size: 10px;
    margin-left: 10px;
  }
`;
