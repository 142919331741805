import { handleFilterKey } from "../../../collaborators/utils/handleFiltrKey";

export const filterProducts = (
  filtersObject,
  setTaskList,
  originalTaskList
) => {
  const filters = Object.values(filtersObject);
  const filtersActive = filters.length > 0;
  if (filtersActive) {
    const userCheckedProducts = originalTaskList.filter((item) => item.checked);
    let listToFilter = userCheckedProducts
      ? originalTaskList.slice().map((item) => {
          item.checked = false;
          return item;
        })
      : originalTaskList.slice();

    filters?.forEach((filterParams) => {
      const { filter, values } = filterParams;
      const filterKey = handleFilterKey(filter);
      listToFilter = listToFilter.filter((product) => {
        const defaultValue = product[filterKey] || product.article[filterKey];
        switch (filter) {
          case "Categorías":
            return values.includes(`${product.article.category}`);
          case "retailers":
            return values.some((retId) =>
              product.retailersWithService.includes(`${retId}`)
            );
          case "company":
            return values.some((s) => product.article.company_name.includes(s));
          case "upc":
            return values.some((upc) => defaultValue.startsWith(upc));
          default:
            return filterParams.values.includes(defaultValue);
        }
      });
    });
    setTaskList(listToFilter);
  } else {
    setTaskList(originalTaskList);
  }
};

export const updateFilters = (taskList, setFilters) => {
  let uniqueOrders;
  let uniqueBrands;
  let uniqueRetailers;
  // let uniqueDepartments;
  let uniqueCategories;
  let uniqueCompanies;

  const filtersAvailable = [
    { name: "Orden", active: false },
    { name: "Alcances", active: false },
    { name: "Departamentos", active: false },
    { name: "Categorías", active: false },
  ];

  uniqueOrders = [...new Set(taskList.map((item) => item.orderId))];
  uniqueOrders = uniqueOrders.map((order) => ({
    name: order,
    value: order,
  }));

  const dataCompany = JSON.parse(sessionStorage.getItem("company"));
  uniqueRetailers = [...new Set(dataCompany.retailers.map((item) => item))];
  uniqueRetailers = uniqueRetailers.map((retailer) => ({
    name: retailer.name,
    value: retailer.id,
  }));

  let listCategories = taskList.map((item) => item.article.category.split("|"));

  // uniqueDepartments = [...new Set(listCategories.map((e) => `${e[0]}`))];
  // uniqueDepartments = uniqueDepartments.map((department) => ({
  //   name: department,
  //   value: department,
  // }));

  uniqueCategories = [
    ...new Set(listCategories.map((e) => `${e[0]}|${e[1]}|${e[2]}`)),
  ];
  uniqueCategories = uniqueCategories.map((category) => ({
    name: category,
    value: category,
  }));

  const filtersOptions = {
    Orden: uniqueOrders,
    Alcances: uniqueRetailers,
    // Departamentos: uniqueDepartments,
    Categorías: uniqueCategories,
  };

  uniqueBrands = [
    ...new Set(taskList.filter((f) => f.brand).map((item) => item.brand)),
  ];
  if (uniqueBrands.length > 0) {
    filtersOptions.Marca = uniqueBrands.map((brand) => ({
      name: brand,
      value: brand,
    }));
    filtersAvailable.push({ name: "Marca", active: false });
  }

  uniqueCompanies = [...new Set(taskList.map((e) => e.article.company_name))];
  if (uniqueCompanies?.length > 1) {
    filtersAvailable.push({ name: "Compania", active: false });
    filtersOptions.Compania = uniqueCompanies
      .filter((name) => name !== "")
      .map((name) => ({ name, value: name }));
  }

  if (filtersOptions.Alcances.length <= 1) delete filtersOptions.Alcances;

  setFilters({ filtersOptions, filtersAvailable });
};
