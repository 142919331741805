import styled from "styled-components";

// container de la vista del grid de productos
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 80;
  gap: 12px;
  //border: 1px solid blue;

  .customSelect-customSlider {
    box-shadow: 1px 1px 10px 2px #00000040;
    margin-left: 5px;
    border-radius: 5px;
    background-color: #fff;
    position: absolute;
    z-index: 10;
    //border: 1px solid red !important;
  }

  /*
	.selectPrueba {
		position: relative;
		display: grid;
		place-items: center;
		border: 1px solid red;

		button {
			font-family: 'Segoe UI' , sans-serif;
			font-size: 18px;
			cursor: pointer;
		}

		.divHiddenSlider {
			display: none;
			position: absolute;
			background-color: white;
			top: calc( 100% + 1px );
			left: 0px;
			padding: 5px;
			border-radius: 10px;
			box-shadow: 0px 0px 5px 2px lightgray;

			&.active {
				display: grid;
			}
		}
	}*/
`;
