import styled from "styled-components";

export const Button = styled.div`
  display: flex;
  align-items: center;
  background-color: #f7f7fc;
  border: 1px solid #f7f7fc;
  width: 100%;
  height: 50px;
  text-align: left;
  padding: 5px;
  cursor: pointer;

  img {
    width: 24px;
    height: 24px;
    margin: 0 10px;
  }
`;
