import {
  Container,
  Title,
  MainData,
  DateText,
  ODTText,
  TextConfirm,
  ActiveCircle,
  InactiveCircle,
  Row,
  Separator,
  ButtonContinuar,
  ButtonCancelar,
  Line,
  ContainerClose,
  Flex,
  ContainerMetodosPago,
  ContainerMain,
  ContainerDetalles,
  Text24,
  Block1,
  Hr,
  Text14,
  InputCupon,
  RowSpaceBetwen,
  ContainerFormasPago,
  InfoDiv,
} from "./styles";
import IconClose from "../../../../assets/IconComponents/IconClose";
import IconPadlock from "../../../../assets/IconComponents/IconPadlock";
import IconSharePink from "../../../../assets/IconComponents/IconSharePink";
import IconMailPink from "../../../../assets/IconComponents/IconMailPink";
import IconCheck from "../../../../assets/IconComponents/IconCheck";
import IconMasterdCard from "../../../../assets/IconComponents/IconMasterdCard";
import GenericModal from "../../../../components/general/GenericModal";
import pagadoModalIcon from "../../../../assets/IconComponents/pagadoModalIcon.svg";
import { useState } from "react";

import React from "react";

const PagoTarjeta = () => {
  const [date] = useState(new Date());
  const [showGenericModal, setShowGenericModal] = useState(false);
  const [company, setCompany] = useState(
    JSON.parse(sessionStorage.getItem("company"))
  );
  const order = sessionStorage.getItem("order")
    ? JSON.parse(sessionStorage.getItem("order"))
    : (window.location.href = "/checkout");
  return (
    <Container>
      <MainData>
        <div>
          <Title>
            <b>{company.company_name}</b>
          </Title>
          <DateText>{`${date.getDate()}/${
            date.getMonth() + 1
          }/${date.getFullYear()}`}</DateText>
        </div>
        <Row>
          <InactiveCircle onClick={() => (window.location.href = `/checkout`)}>
            1
          </InactiveCircle>
          <Separator />
          <InactiveCircle onClick={() => (window.location.href = `/StepTwo`)}>
            2
          </InactiveCircle>
          <Separator />
          <TextConfirm>
            Método <br />
            de pago
          </TextConfirm>
          <ActiveCircle>3</ActiveCircle>
          <ContainerClose>
            <div onClick={() => (window.location.href = `/Dashboard`)}>
              <IconClose />
            </div>
          </ContainerClose>
        </Row>
      </MainData>
      <ContainerMain>
        <ContainerMetodosPago>
          <ContainerFormasPago>
            <RowSpaceBetwen>
              <IconCheck />
              <InfoDiv>
                <p className="textFormaPago">Pago Completado</p>
                <div>
                  <RowSpaceBetwen>
                    <p className="text">Nombre del banco</p>
                    <p className="text">Grupo Financiero Banorte</p>
                    <p className="text"></p>
                  </RowSpaceBetwen>
                  <RowSpaceBetwen>
                    <p className="text">Cuenta</p>
                    <p className="text">072180002624585886</p>
                    <p className="text">
                      <IconMasterdCard />
                    </p>
                  </RowSpaceBetwen>
                  <RowSpaceBetwen>
                    <p className="text">Referencia bancaria</p>
                    <p className="text">
                      {`${date.getDate()}/${
                        date.getMonth() + 1
                      }/${date.getFullYear()}`}{" "}
                      {company.company_name}
                    </p>
                    <p className="text"></p>
                  </RowSpaceBetwen>
                </div>
                <br />
                <RowSpaceBetwen>
                  <p className="textFormaPago">Total</p>
                  <p className="textFormaPago">{`$${(
                    order.subtotal * 1.16
                  ).toFixed(1)}`}</p>
                  <p className="textFormaPago"></p>
                </RowSpaceBetwen>
                <RowSpaceBetwen>
                  <p className="textFormaPago">Número de orden</p>
                  <p className="textFormaPago">--</p>
                  <p className="textFormaPago"></p>
                </RowSpaceBetwen>
                <RowSpaceBetwen>
                  <p className="textFormaPago">Recoleccion</p>
                  <p className="textFormaPago">En oficinas</p>
                  <p className="textFormaPago"></p>
                </RowSpaceBetwen>
                <br />
                <br />
                <p className="textProductos">
                  Tus productos se han agregado con éxito, esperamos la entrega
                  de tus productos para comezar con el proceso.
                </p>
                <br />
                <br />
                <p className="text18">¡Gracias por confiar en nosotros!</p>
              </InfoDiv>
              <Row>
                <IconSharePink />
                &nbsp;&nbsp;
                <IconMailPink />
              </Row>
            </RowSpaceBetwen>
          </ContainerFormasPago>
        </ContainerMetodosPago>
        <ContainerDetalles>
          <Text24>Detalles de precio</Text24>
          <Block1>
            <p className="text18">{order.items.length} productos</p>
            <p className="text18">{`$${(order.productServices * 1.16).toFixed(
              1
            )}`}</p>
          </Block1>
          <Block1>
            <Text14>Servicios extra</Text14>
            <Text14>{`$${(order.aditionalServices * 1.16).toFixed(1)}`}</Text14>
          </Block1>
          <Block1>
            <Text14>Entrega del producto</Text14>
            <Text14>NA</Text14>
          </Block1>
          <Hr />
          <Text14>¿Tienes un cupón de descuento?</Text14>
          <InputCupon placeholder="Agregar cupón" />
        </ContainerDetalles>
      </ContainerMain>
      <Flex>
        <p className="total">Total</p>
        <p className="text36">{`$${(order.subtotal * 1.16).toFixed(1)}`}</p>
      </Flex>
      <Flex>
        <Line />
      </Flex>
      <Flex>
        <IconPadlock />
        <p className="text12">
          Seguridad y protección de pago o reembolso total de la compra.
        </p>
      </Flex>
      <Flex>
        <ButtonCancelar onClick={() => (window.location.href = `/Dashboard`)}>
          Cancelar
        </ButtonCancelar>
        <ButtonContinuar>
          <button
            type="submit"
            form="my-form"
            onClick={() => setShowGenericModal(!showGenericModal)}
          >
            Finalizar
          </button>
        </ButtonContinuar>
        {/* onClick={() => {window.location.href = `/ListProducts`; handleSubmit}} */}
      </Flex>
      {showGenericModal && (
        <GenericModal
          close={() => setShowGenericModal(!showGenericModal)}
          detail="Desarrollaremos tus productos con las mejores prácticas para las cadenas a entregar, puedes ver el avance de tus productos en la sección Content-oh!"
          message="Bienvenido a nuestro servicio Content-oh!"
          button1={{
            name: "Continuar",
            action: () => {
              window.location.href = `/Dashboard`;
            },
          }}
          img={pagadoModalIcon}
        />
      )}
    </Container>
  );
};

export default PagoTarjeta;
