import React from "react";
import retailerLogo from "../../assets/Login/retailer_logo.svg";
import providerLogo from "../../assets/Login/provider_logo.svg";
import {
  MainContainer,
  Panel,
  TextUnderImage,
  Title,
  Button,
  VersionFooter,
} from "./selectPlanStyles";

const Index = (props) => {
  return (
    <MainContainer>
      <Panel>
        <div className="image-container">
          <Title color="#FAFAFA">Cadena</Title>
          <figure>
            <img src={retailerLogo} alt="Cadena" />
          </figure>
          <TextUnderImage>
            <p>Elige esta opción si eres una cadena retailer</p>
          </TextUnderImage>
        </div>
        <Button
          background="#FAFAFA"
          color="#603888"
          onClick={() => props.setUserType("retailer")}
        >
          Elegir
        </Button>
      </Panel>
      <Panel color="#FFF">
        <div className="image-container">
          <Title>Proveedor</Title>
          <figure>
            <img src={providerLogo} alt="Proveedor" />
          </figure>
          <TextUnderImage color="#000">
            <p>Elige esta opción si eres un proveedor</p>
          </TextUnderImage>
        </div>
        <Button
          background="#E33AA9"
          onClick={() => props.setUserType("provider")}
        >
          Elegir
        </Button>
      </Panel>
      <VersionFooter>v.2.7</VersionFooter>
    </MainContainer>
  );
};

export default Index;
