import {
  Container,
  Title,
  MainData,
  DateText,
  ODTText,
  TextConfirm,
  ActiveCircle,
  InactiveCircle,
  Row,
  Separator,
  ButtonContinuar,
  ButtonCancelar,
  Line,
  ContainerClose,
  Flex,
  ContainerMetodosPago,
  ContainerMain,
  ContainerDetalles,
  Text24,
  Block1,
  Hr,
  RowDatos,
  Text14,
  InputCupon,
  OptionInput,
  Input,
  InputMonto,
  RowSpaceBetwen,
  ContainerFormasPago,
  ContainerFormaPago,
  ContainerFormaPago2,
  HorizontalCenter,
  BotonComprobante,
  BotonValidar,
} from "./styles";
import IconClose from "../../../../assets/IconComponents/IconClose";
import IconPadlock from "../../../../assets/IconComponents/IconPadlock";
import IconSharePink from "../../../../assets/IconComponents/IconSharePink";
import IconMailPink from "../../../../assets/IconComponents/IconMailPink";
import IconCheck from "../../../../assets/IconComponents/IconCheck";

import { Calendar } from "react-datepicker2";
import { useState } from "react";

const Transferencia = () => {
  const [value, setValue] = useState(new Date());
  const [date] = useState(new Date());
  const [company, setCompany] = useState(
    JSON.parse(sessionStorage.getItem("company"))
  );
  return (
    <Container>
      <MainData>
        <div>
          <Title>
            <b>{company.company_name}</b>
          </Title>
          <DateText>{`${date.getDate()}/${
            date.getMonth() + 1
          }/${date.getFullYear()}`}</DateText>
        </div>
        <Row>
          <InactiveCircle onClick={() => (window.location.href = `/checkout`)}>
            1
          </InactiveCircle>
          <Separator />
          <InactiveCircle onClick={() => (window.location.href = `/StepTwo`)}>
            2
          </InactiveCircle>
          <Separator />
          <TextConfirm>
            Método <br />
            de pago
          </TextConfirm>
          <ActiveCircle>3</ActiveCircle>
          <ContainerClose>
            <div onClick={() => (window.location.href = `/Dashboard`)}>
              <IconClose />
            </div>
          </ContainerClose>
        </Row>
      </MainData>
      <ContainerMain>
        <ContainerMetodosPago>
          <ContainerFormasPago>
            <p className="title">
              {" "}
              <b>Validación de pago</b>
            </p>
            <Row>
              <ContainerFormaPago2 for="contrato">
                <RowSpaceBetwen>
                  <Row>
                    <OptionInput
                      id="contrato"
                      type="radio"
                      name="metodo"
                      value="contrato"
                      checked
                      disabled
                    ></OptionInput>
                    <p className="title">
                      <b>Pago con transferencia</b>
                    </p>
                  </Row>
                  <div>
                    <IconSharePink />
                    &nbsp;&nbsp;
                    <IconMailPink />
                  </div>
                </RowSpaceBetwen>
                <p className="textFormaPago">
                  Nombre del banco &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  Barone LLC.
                </p>
                <p className="textFormaPago">
                  Clave interbancaria &nbsp;&nbsp;&nbsp; 1234567890123456789
                </p>
                <p className="textFormaPago">
                  Referencia bancaria &nbsp;&nbsp;&nbsp; 1233411 Proveedor
                </p>
              </ContainerFormaPago2>
            </Row>
            <Row>
              <ContainerFormaPago>
                <RowSpaceBetwen>
                  <Calendar
                    isGregorian={true}
                    onChange={(value) => {
                      setValue(value._d);
                    }}
                  />
                  <RowDatos>
                    <p className="text">Transferencia realizada por:</p>
                    <Input placeholder="Escribe el nombre del titular" />
                    <p className="text">Número de autorización</p>
                    <Input placeholder="Escribe el número de autorización" />
                    <Row>
                      <p className="text">Monto</p>
                      <InputMonto placeholder="Monto exacto" />
                    </Row>
                    <Row>
                      <p className="text">Comprobante de pago</p>
                      <BotonComprobante>
                        Elige el archivo de comprobante
                      </BotonComprobante>
                    </Row>
                    <BotonValidar>Validar</BotonValidar>
                  </RowDatos>
                  <HorizontalCenter>
                    <IconCheck />
                    <br />
                    <p className="pagoCompletado">Pago Completado</p>
                  </HorizontalCenter>
                </RowSpaceBetwen>
              </ContainerFormaPago>
            </Row>
          </ContainerFormasPago>
        </ContainerMetodosPago>
        <ContainerDetalles>
          <Text24>Detalles de precio</Text24>
          <Block1>
            <p className="text18">7 productos</p>
            <p className="text18">$12,160</p>
          </Block1>
          <Block1>
            <Text14>Servicios extra</Text14>
            <Text14>$680</Text14>
          </Block1>
          <Block1>
            <Text14>Entrega del producto</Text14>
            <Text14>NA</Text14>
          </Block1>
          <Hr />
          <Text14>¿Tienes un cupón de descuento?</Text14>
          <InputCupon placeholder="Agregar cupón" />
        </ContainerDetalles>
      </ContainerMain>
      <Flex>
        <p className="total">Total</p>
        <p className="text36">$12,160</p>
      </Flex>
      <Flex>
        <Line />
      </Flex>
      <Flex>
        <IconPadlock />
        <p className="text12">
          Seguridad y protección de pago o reembolso total de la compra.
        </p>
      </Flex>
      <Flex>
        <ButtonCancelar onClick={() => (window.location.href = `/Dashboard`)}>
          Cancelar
        </ButtonCancelar>
        <ButtonContinuar>
          <button type="submit" form="my-form">
            Finalizar
          </button>
        </ButtonContinuar>
        {/* onClick={() => {window.location.href = `/ListProducts`; handleSubmit}} */}
      </Flex>
    </Container>
  );
};

export default Transferencia;
