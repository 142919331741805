import { DateText, ODTText } from "../products/listProducts/stepTwo/styles";
import { Containers } from "./styles";
import IconEmailQuotes from "../../assets/IconComponents/IconEmailQuotes.svg";
import IconShareQuotes from "../../assets/IconComponents/IconShareQuotes.svg";
import {
  Title,
  ContainerButton,
  ContainerMap,
  ContainerQuotes,
  Subtitle,
  TitleCompany,
  TitleUser,
  ButtonQuotes,
  InputForm,
} from "./quotesStyles";
import MapContainer from "../general/map/index";
import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import Moment from "react-moment";
import Loading from "../general/loading";

const Quotes = () => {
  const [appointmentList, setAppointmentList] = useState([]);
  const [appointmentSelected, setAppointmentSelected] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [reason, setReason] = useState("");
  const [company, setCompany] = useState("");
  const [name, setName] = useState("");
  const getAppointment = async () => {
    try {
      setIsLoading(true);
      const response = await axios({
        method: "get",
        url: process.env.REACT_APP_APPOINTMENT_ENDPOINT,
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      });
      setIsLoading(false);
      const data = JSON.parse(response.data.body).data;
      setAppointmentList(data);
      const selectedId = sessionStorage.getItem("appointmentSelected");
      const redirectedId = sessionStorage.getItem("ordersAppointment")
        ? JSON.parse(sessionStorage.getItem("ordersAppointment"))
        : false;
      if (redirectedId) {
        setAppointmentSelected(data.find((e) => e.id_order === redirectedId));
        sessionStorage.removeItem("ordersAppointment");
      } else if (selectedId) {
        setAppointmentSelected(
          data.find((e) => e.id_cita === parseInt(selectedId))
        );
        sessionStorage.removeItem("appointmentSelected");
      } else {
        data[0] !== undefined && setAppointmentSelected(data[0]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAppointment();
    setCompany(JSON.parse(sessionStorage.getItem("company")).company_name);
    const user = JSON.parse(sessionStorage.getItem("user"));
    setName(`${user.name} ${user.last_name}`);
  }, []);

  const updateAppointment = async () => {
    try {
      if (reason !== "") {
        setIsLoading(true);
        await axios({
          method: "put",
          url: process.env.REACT_APP_APPOINTMENT_ENDPOINT,
          data: {
            appointment: JSON.parse(sessionStorage.getItem("stepTwo")),
            cita: appointmentSelected.id_cita,
            reason: reason,
          },
          headers: {
            Authorization: sessionStorage.getItem("jwt"),
          },
        });
        const response = await axios({
          method: "get",
          url: process.env.REACT_APP_APPOINTMENT_ENDPOINT,
          headers: {
            Authorization: sessionStorage.getItem("jwt"),
          },
        });
        setIsLoading(false);
        setAppointmentList(JSON.parse(response.data.body).data);
        setAppointmentSelected(JSON.parse(response.data.body).data[0]);
      } else {
        alert("Debe llenar el campo de razón para reagendar.");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Containers>
      <ContainerMap>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <Title> Estado de cuenta a facturar </Title>
            <Subtitle>
              Aquí encontrarás todas las facturas pendientes o concluídas con
              Content-oh!
            </Subtitle>
            <TitleCompany>{company}</TitleCompany>
            <TitleUser>{name}</TitleUser>
            {appointmentSelected.id_cita ? (
              <>
                <DateText>
                  <Moment format="DD/MM/YYYY">
                    {moment(appointmentSelected.date)}
                  </Moment>
                </DateText>
                <ODTText> ODT {appointmentSelected.id_cita}</ODTText>

                <MapContainer width="100%" cita={appointmentSelected} />
                {appointmentSelected.reason ? (
                  <p>Ya reagendaste una vez ({appointmentSelected.reason})</p>
                ) : (
                  <>
                    <InputForm
                      placeholder="Escribe el motivo de reagendar"
                      onChange={(e) => setReason(e.target.value)}
                    />
                    <ContainerButton>
                      <div onClick={() => updateAppointment()}>Guardar</div>
                    </ContainerButton>
                  </>
                )}
              </>
            ) : (
              <p>No tiene citas registradas.</p>
            )}
          </>
        )}
      </ContainerMap>

      <ContainerQuotes>
        <div className="TitleQuotes">Siguientes citas</div>
        {isLoading ? (
          <Loading />
        ) : (
          appointmentList.length > 0 &&
          appointmentList.map((element, i) => (
            <div className="containQuote" key={i}>
              <div className="containQuoteL">
                <div>
                  <img src={IconEmailQuotes} alt="" />
                  <img src={IconShareQuotes} alt="" />
                </div>
                <ButtonQuotes onClick={() => setAppointmentSelected(element)}>
                  Reagendar
                </ButtonQuotes>
              </div>
              <div className="containQuoteR">
                <Moment format="DD/MM/YYYY">{element.date}</Moment>
                <div>{element.type}</div>
                {/* <div>DHL</div> */}
              </div>
            </div>
          ))
        )}
      </ContainerQuotes>
    </Containers>
  );
};

export default Quotes;
