import styled from "styled-components";
import { grayS2, grayS4, grayS5 } from "../../variables";
export const ProfileMenuContainer = styled.div`
  //display: ${(props) => (props.show ? "block" : "none")};
  display: block;
  width: 223px;
  max-height: 350px;
  overflow-y: auto;
  background-color: ${grayS2};
  position: absolute;
  top: 70px;
  /* left: 50%; */
  right: 12.5%;
  transform: translate(-50%);
  border-radius: 10px;
  padding: 17px 10px;
  z-index: 100;
  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    bottom: 99%;
    transform: translate(-50%);
    width: 0;
    height: 0;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    border-bottom: 15px solid ${grayS2};
  }
  animation: fadeinout 8s linear forwards;

  @keyframes fadeinout {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
    }
  }
`;

export const RPSymmary = styled.div``;
export const RPHeader = styled.div`
  border-bottom: 1px solid #d4d1d7;
  padding-bottom: 7px;
  h3 {
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: ${grayS5};
    font-family: "Avenir Next", sans-serif;
  }
`;
export const ItemsContainer = styled.div`
  padding: 4px;
`;
export const RPItem = styled.div`
  .rp-header {
    display: flex;
    img {
      & + * {
        margin-left: 5px;
      }
    }
    h3 {
      font-size: 14px;
      line-height: 19px;
      font-family: "Avenir Next", sans-serif;
      color: ${grayS4};
    }
    & + * {
      margin-top: 12px;
    }
  }
  & + * {
    margin-top: 10px;
  }
`;
export const RPDescription = styled.div`
  ul {
    li {
      display: flex;
      justify-content: space-between;
      p {
        font-size: 11px;
        font-family: "Avenir Next", sans-serif;
        color: ${grayS4};
      }
      .rp-product-details {
        padding-left: 20px;
        position: relative;
        &:before {
          content: "";
          display: block;
          background-color: ${grayS4};
          width: 5px;
          height: 5px;
          border-radius: 50%;
          position: absolute;
          left: 5px;
          top: 5px;
        }
      }
    }
  }
`;
