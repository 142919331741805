import React, { useEffect, useState } from "react";
import { Container } from "./styles.js";
import searchIcon from "../../../assets/IconComponents/search.svg";

export const CheckboxList = ({
  id,
  items = [],
  value = [],
  name = "",
  defaultSearch = "",
  defaultOption = "",
  onChange,
  placeholder = "",
}) => {
  const [itemsSelected, setItemsSelected] = useState(value);
  const [search, setSearch] = useState(defaultSearch);
  const [containerVisible, setContainerVisible] = useState(false);
  const itemsFiltered = (() => {
    if (!search) return items;

    return items.filter((item) =>
      item.label.toLowerCase().includes(search.toLowerCase())
    );
  })();

  useEffect(() => {
    onChange && onChange(itemsSelected);
  }, [itemsSelected]);

  return (
    <Container id={id}>
      <button
        className="custom-select"
        onClick={() => setContainerVisible(!containerVisible)}
      >
        {name}
        <div className="arrow-item">&#9664;</div>
      </button>
      {containerVisible && (
      <div className="container-def">
        <div className="search-bar">
          <img src={searchIcon} className="search-icon" alt="search icon" />
          <input
            type="text"
            className="search"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            value={search}
            placeholder={placeholder}
          ></input>
        </div>
        <p className="default-option">{defaultOption}</p>
        {itemsFiltered.map((item, i) => {
          return (
            <div className="container-options">
              <div key={i} className="options">
                <input
                  id="custom-check"
                  className="custom-check"
                  type="checkbox"
                  checked={itemsSelected.includes(item.value)}
                  onChange={(e) => {
                    setItemsSelected((prev) => {
                      const existItem = prev.find((e) => e === item.value);

                      if (existItem) {
                        return [...prev].filter((e) => e !== item.value);
                      }

                      return [...prev, item.value];
                    });
                  }}
                />
                <label htmlFor="" className="texto">
                  {item.label}
                </label>
              </div>
            </div>
          );
        })}
      </div>)}
    </Container>
  );
};
