import styled from "styled-components";
import { grayS2, grayS7, pinkS1 } from "../../variables";

// css del componente <ListCard />
export const Container = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.35);
  display: grid;
  z-index: 1501;
  place-items: center;

  .div-content-modal {
    width: 50%;
    max-width: 450px;
    min-width: 80px;
    min-height: 120px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 13px 18px 13px;
    box-shadow: 0px 0px 4px 1px ${grayS7};
    //border: 1px solid blue;

    header {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-end;
      gap: 6px;
      //border: 1px solid blue;

      .div-title {
        flex-grow: 2;
        display: flex;
        flex-direction: column;
        gap: 14px;
        align-self: center;
        //border: 1px solid red;

        label {
          width: 100%;
          padding: 0px 10px;
          font-family: "Roboto Medium", "Segoe UI", sans-serif;
          font-size: 17px;
          line-height: 1.2;
          font-weight: bold;
          color: #262626;
          text-align: center;
          //border: 1px solid blue;
        }

        .icon {
          width: 100%;
          display: grid;
          place-items: center;
          //border: 1px solid green;

          img {
            width: 85%;
            max-width: 80px;
            min-width: 40px;
          }
        }
      }

      .button-close {
        width: 30px;
        border-radius: 100%;
        padding: 3px;
        cursor: pointer;
        //border: 1px solid red;

        &:hover {
          background-color: ${grayS2};
        }
      }
    }

    .label-detail {
      width: 100%;
      padding: 15px 10px 0px 10px;
      text-align: left;
      font-family: "Roboto Regular", "Segoe UI", sans-serif;
      font-size: 13px;
      color: #707070;
      //border: 1px solid blue;
    }

    .div-customComponent {
      width: 100%;
      max-height: 50vh;
      display: flex;
      flex-direction: column;
      margin: 15px 0px 0px 0px;
      padding: 0px 10px;
      //border: 1px solid red;
    }

    .container-buttons {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 12px;
      padding: 25px 10px 0px 10px;
      //border: 1px solid red;

      button {
        font-family: "Roboto Medium", "Segoe UI", sans-serif;
        font-weight: 600;
        font-size: 12px;
        padding: 6px 18px;
        border-radius: 20px;
        cursor: pointer;
      }

      .btnTransparent {
        background-color: transparent;
        border: 2px solid ${pinkS1};
        color: ${pinkS1};

        &:hover {
          background-color: whitesmoke;
        }
      }

      .btnPink {
        background-color: ${pinkS1};
        border: none;
        color: white;
      }
    }
  }
`;
