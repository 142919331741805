import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PriorityFlag = styled.figure`
  height: ${(props) => (props.height ? props.height : "20px")};
  width: ${(props) => (props.width ? props.width : "20px")};

  img {
    height: ${(props) => (props.height ? props.height : "20px")};
    width: ${(props) => (props.width ? props.width : "20px")};
  }
`;

export const FlagOption = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  > figure {
    margin-right: 5px;
  }
  :hover {
    background-color: lightgray;
  }
`;

export const FlagsList = styled.div`
  position: absolute;
  top: 10px;
  left: 80%;
  z-index: 2;
  white-space: normal;
  font-family: Avenir Next;
  font-style: normal;
  width: 125px;
  & > div {
    display: flex;
    flex-direction: column;
    background: #f0eef2;
    border: 1px solid #d4d1d7;
    box-sizing: border-box;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    margin-bottom: 2px;
    padding: 5px;
    box-sizing: border-box;
  }
`;
