import styled from "styled-components";
import checked from "../assets/checked.svg";
import unchecked from "../assets/unchecked.svg";
import { grayS7, pinkS2 } from "../../../variables";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  //border: 1px solid red;

  .iconLoading {
    width: 30px;
    height: 30px;
    background-color: inherit;
    //border: 1px solid red;
  }

  input {
    display: none;

    &:checked + .label-input {
      &:before {
        background-color: white;
        background-image: url(${checked});
      }
    }
  }

  label {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start !important;
    align-items: center;
    min-height: 16px;
    min-width: 16px;
    background-color: transparent;

    p {
      display: inline;
      color: #817393;
      font-family: "Avenir Next";
      font-weight: 500;
      text-align: left;
      font-size: 13px;
      margin: 0px;

      &.filter-text {
        color: #817393;
        font-size: 13px;
        padding-top: 2px;
      }
    }

    &.label-input p.filter-text {
      margin-left: 23px;
    }

    &.label-loading p.filter-text {
      margin-left: 10px;
    }

    &.label-input {
      cursor: pointer;
    }

    &.label-input:before {
      content: "";
      width: 16px;
      height: 16px;
      position: absolute;
      display: inline-block;
      top: 50%;
      transform: translateY(-50%);
      background-image: url(${unchecked});
    }
  }
`;
