import React, { useEffect, useState, useRef } from "react";
import GeneralProducts from "./tables/GeneralProducts";
import OrderedProducts from "./tables/OrderedProducts";
import ContentohExportModal from "../contentohExportModal";
import ProviderProducts from "./tables/ProviderProducts";
import { Redirect } from "react-router-dom";
import Loading from "../../general/loading";
import { FilterInput } from "../../filterInput/index";
import ProductDetail from "../productDetail/index";
import { Container, TableWarning } from "./styles";
import GenericModal from "../../general/GenericModal";
import { updateReportFilters } from "./utils/filtersHandler";
import {
  getProducts,
  loadMoreProducts,
  getExportables,
  updateSessionStorageProduct,
} from "./utils/productsHandler";
import {
  getFilterName,
  compareCanonicValues,
  formatSelectedProduct,
  removeUncheckedProduct,
  removeUncheckedOrdered,
  handleProductChecks,
  handleOrderedChecks,
} from "./utils/generalUtils";
import {
  getCompanyCollaborators,
  getPercentage,
  updateRetailerAssignations,
} from "../../_utils/data";

import { filterProductList, setFilters } from "./utils/filtersHandler";
export default function RetailerProducts(props) {
  const [listType] = useState(props.type);
  const [reportFilters, setReportFilters] = useState({});
  const [productCount, setProductCount] = useState(0);
  const [articlesList, setArticlesList] = useState([]);
  const [aditionalFilter, setAditionalFilter] = useState();
  const [originalList, setOriginalList] = useState([]);
  const [firstRequest, setFirstRequest] = useState([]);
  const [canonicFilter, setCanonicFilter] = useState();
  const [collaborators, setCollaborators] = useState([]);
  const [productSelected, setProductSelected] = useState([]);
  const [selectedArticles] = useState([]);
  const [productsSelected, setProductsSelected] = useState([]);
  const [filtersOptions, setFiltersOptions] = useState({});
  const [filterArray, setFilterArray] = useState([]);
  const [modalClass, setModalClass] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [showContentohExportModal, setShowContentohExportModal] =
    useState(false);
  const [addedPopUp, setAddedPopUp] = useState(null);
  const wrapperRef = useRef(null);
  const [redirect, setRedirect] = useState();
  const [startIndex, setStartIndex] = useState(0);
  const [stateFlags, setStateFlags] = useState({
    isLoading: true,
    showProductDetail: false,
    loadingExportables: false,
    productsLoaded: false,
    redirectMultipleEdition: false,
    addedToCart: false,
    firstFilterExecuted: false,
    productsFirstLoad: true,
    errorCatched: false,
    NoProductsFound: false,
    FilteredNotFound: false,
    warningMessage: "",
  });
  const [origin, setOrigin] = useState(null);
  /*ESTADO */
  const [gridView, setGridView] = useState("list");
  const [productList, setProductList] = useState();

  const [filterLists, setFilterLists] = useState();
  const [providerSelected, setProviderSelected] = useState([]);
  const [phasesSelected, setPhasesSelected] = useState([]);
  const [groupsSelected, setGroupsSelected] = useState([]);
  const company = JSON.parse(sessionStorage.getItem("company"));
  const hasOnboarding = company?.financedRetailers?.find((item) =>
        [74].includes(item.id));
  const acquiredOnboarding= props.acquired;

  const calcPercentage = async (initialList) => {
    const retailers = company.retailers.map((ret) => ({
      id: ret.id,
      name: ret.name,
    }));
    const ids = retailers.map((ret) => ret.id);
    const copyList = {};
    const data = [];
    initialList.forEach((e) => {
      data.push({
        id_article: e.article.id_article,
        id_category: e.article.id_category,
        version: e.version,
        retailersAvailable: e.retailers
          .filter((f) => ids.includes(f.id))
          .map(({ id }) => id),
      });
      copyList[e.article.id_article] = e;
    });
    if (!hasOnboarding && data.length>0) {
      const [res] = await getPercentage({ data });
      Object.entries(res).forEach(([articleID, percentages]) => {
        copyList[articleID].percentage = percentages.percentageRequired;
      });
    }

    const temp = Object.values(copyList);
    setArticlesList(temp);
    return temp;
  };

  useEffect(() => {
    // Este efecto se ejecuta cuando el componente se inicia
    const initialFetchData = async () => {
      try {
        const paramsListType = hasOnboarding ? (acquiredOnboarding ? "acquired" :"onboarding") : listType;

        let paramsToGetProducts = {
          startIndex: startIndex,
          productsType: paramsListType,
          providerFilter: providerSelected,
          phaseFilter: phasesSelected,
          groupFilter: groupsSelected,
          setFilterLists,
        };

        let initialList = await getProducts(
          paramsToGetProducts,
          stateFlags,
          setStateFlags,
          setProductCount,
          setProductList
        );

        setFilters(
          initialList,
          paramsListType,
          setFilterArray,
          setFiltersOptions
        );

        setArticlesList(initialList);
        setOriginalList(initialList);
        setFirstRequest(initialList);
        if (hasOnboarding !== "onboarding") {
          calcPercentage(initialList);
        }
        setStateFlags((prev) => ({
          ...prev,
          isLoading: false,
          productsLoaded: true,
        }));

        setCollaborators(await getCompanyCollaborators());
      } catch (error) {
        console.error("Error al cargar datos:", error);
      }
    };

    initialFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Este efecto se ejecuta cuando cambian las dependencias [providerSelected, phasesSelected, groupsSelected]
    const fetchDataOnChange = async () => {
      try {
        const paramsListType = hasOnboarding ? (acquiredOnboarding ? "acquired" :"onboarding") : listType;

        let paramsToGetProducts = {
          startIndex: startIndex,
          productsType: paramsListType,
          providerFilter: providerSelected,
          phaseFilter: phasesSelected,
          groupFilter: groupsSelected,
          filterLists: filterLists,
          setFilterLists,
        };

        let updatedList = await getProducts(
          paramsToGetProducts,
          stateFlags,
          setStateFlags,
          setProductCount,
          setProductList
        );
        setFilters(
          updatedList,
          paramsListType,
          setFilterArray,
          setFiltersOptions
        );

        setArticlesList(updatedList);
        setOriginalList(updatedList);
        setFirstRequest(updatedList);
        if (hasOnboarding !== "onboarding") {
          calcPercentage(updatedList);
        }
        setStateFlags((prev) => ({
          ...prev,
          isLoading: false,
          productsLoaded: true,
        }));

        setCollaborators(await getCompanyCollaborators());
      } catch (error) {
        console.error("Error al cargar datos:", error);
      }
    };
    fetchDataOnChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerSelected, phasesSelected, groupsSelected]);

  useEffect(() => {
    setStateFlags((prev) => {
      prev.isLoading = false;
      return prev;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articlesList]);

  useEffect(() => {
    const globalCheckbox = document.getElementById("globalHeaderCheckbox");
    if (globalCheckbox) {
      const allArticlesSelected =
        productsSelected.length === articlesList.length;
      globalCheckbox.checked = allArticlesSelected;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsSelected]);

  useEffect(() => {
    if (stateFlags.showProductDetail) {
      setTimeout(() => {
        setModalClass("modal-active");
        document.addEventListener("click", closeModal, false);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateFlags.showProductDetail]);

  const getCanonicProducts = async (paramsFilter) => {
    setStateFlags((prev) => {
      prev.isLoading = true;
      return prev;
    });

    const filteredProductsFromDB = await getProducts(
      {
        startIndex: 0,
        productsType: hasOnboarding ? (acquiredOnboarding ? "acquired" :"onboarding") : listType,
        providerFilter: providerSelected,
        phaseFilter: phasesSelected,
        groupFilter: groupsSelected,
        canonicFilter: paramsFilter,
        filterLists: filterLists,
        setFilterLists,
      },
      stateFlags,
      setStateFlags,
      setProductCount
    );
    setStartIndex(0);
    if (hasOnboarding !== "onboarding") {
      calcPercentage(filteredProductsFromDB);
    }
    setArticlesList(filteredProductsFromDB);
    // setOriginalList(filteredProductsFromDB);
  };

  const rowClicked = (product) => {
    const formattedProduct = updateSessionStorageProduct(product);
    const providerApproved = product.article_status === "AP";
    const productInAnOrder = product.id_order || product.orderId;
    if (productInAnOrder) setOrigin("RequestWithContentoh");
    else setOrigin("RequestWithoutContentoh");
    setProductSelected(formattedProduct);
    const user = JSON.parse(sessionStorage.getItem("user"));
    if (user.is_retailer && listType !== "general") {
      if (providerApproved) {
        sessionStorage.setItem("revisionRetailer", true);
        setRedirect(`products/${product.id_article}/datasheet`);
      } else {
        setShowAlert(true);
      }
    } else {
      setStateFlags((prev) => ({ ...prev, showProductDetail: true }));
    }
  };

  const filtersReset = () => {
    setCanonicFilter();
    setStartIndex(0);
    setArticlesList(firstRequest);
    setOriginalList(firstRequest);
  };

  const articleSelected = (checked, product) => {
    try {
      const handleAll = product === undefined;
      if (checked) {
        let formattedProducts = formatSelectedProduct(
          product,
          handleAll,
          articlesList
        );

        setProductsSelected((prev) =>
          handleAll ? formattedProducts : [...prev, formattedProducts]
        );
      } else {
        setProductsSelected((prev) => {
          if (listType === "general" || listType === "provider")
            return removeUncheckedProduct(product, prev, handleAll);
          if (listType === "contentoh")
            return removeUncheckedOrdered(product, prev, handleAll);
        });
      }
      setArticlesList((prev) => {
        if (listType === "general" || listType === "provider")
          return handleProductChecks(prev, checked, product, handleAll);
        if (listType === "contentoh")
          return handleOrderedChecks(prev, checked, product, handleAll);
      });
    } catch (err) {
      console.log(
        err,
        "Unable to add clicked article to selected products, please report this to IT"
      );
    }
  };

  const filterInputFunct = (filtersObject) => {
    try {
      const userCheckedProducts = productsSelected.length > 0;
      const filters = Object.values(filtersObject);
      updateReportFilters(filters, setReportFilters, setAditionalFilter);
      const filtersAreActive = filters.length > 0;
      const updatedFilter = filters[0];
      if (filtersAreActive) {
        if (!canonicFilter) {
          triggerCanonicFilter(updatedFilter, setProductCount);
        } else {
          const canonicFilterUpdated = compareCanonicValues(
            canonicFilter,
            updatedFilter
          );

          if (canonicFilterUpdated) {
            triggerCanonicFilter(updatedFilter);
          } else {
            filterProductList(
              userCheckedProducts,
              originalList,
              filters,
              setStateFlags,
              setArticlesList
            );
          }
        }
      } else {
        filtersReset();
      }
      //Clean selected and checked products
      articleSelected(false);
    } catch (err) {
      console.log(err, "Unable to filter data, please repor this to IT");
      setArticlesList(originalList);
    }
  };

  const triggerCanonicFilter = (canonicItem, setProductCount) => {
    const newCanonicFilter = {
      filterName: getFilterName(canonicItem.filter),
      filterValues: encodeURI(JSON.stringify(canonicItem.values)),
    };
    setCanonicFilter(newCanonicFilter);
    getCanonicProducts(newCanonicFilter, setProductCount);
  };

  const exportProducts = () => {
    if (productsSelected.length > 0) {
      setShowContentohExportModal(true);
    }
  };

  const editProducts = () => {
    if (productsSelected.length > 0) {
      const orderedArray = productsSelected.sort(
        (a, b) => a.id_article - b.id_article
      );
      sessionStorage.setItem(
        "multipleEditionList",
        JSON.stringify(orderedArray)
      );
      setStateFlags((prev) => ({ ...prev, redirectMultipleEdition: true }));
    }
  };

  const assignUsers = async (product, userId) => {
    try {
      let data = {
        assigneeId: userId,
      };
      const articlesToAssign = [];

      const selectedCopy = productsSelected.slice();
      const articleIsSelected = selectedCopy.find(
        (selected) => selected.article.id_article === product.article.id_article
      );
      !articleIsSelected && selectedCopy.push(product);
      const articles = articlesList.slice();
      selectedCopy.forEach((selected) => {
        const foundItem = articles.find(
          (prod) => prod.article.id_article === selected.article.id_article
        );
        if (foundItem) {
          const itemIndex = articles.indexOf(foundItem);
          if (itemIndex !== -1) {
            articlesToAssign.push(foundItem.article.id_article);
            articles[itemIndex].asignations = [userId];
          }
        }
      });
      data.articlesToAssign = articlesToAssign;
      updateRetailerAssignations({ data });
      setArticlesList(articles);
    } catch (err) {
      console.log(
        err,
        `unable to assign user to product -  ${
          (product.article.id_article, userId)
        }`
      );
    }
  };

  const renderTable = () => {
    if (articlesList.length === 0)
      return <TableWarning>{stateFlags.warningMessage}</TableWarning>;
    const tableProps = {
      articlesList,
      collaborators,
      eventsFunctions: {
        rowClicked,
        checkboxClicked: articleSelected,
        assignUsers,
      },
      viewProducts: gridView,
    };

    if (listType === "general") {
      tableProps.section = hasOnboarding ? "RetailerProducts" : "general";
      if (gridView === "list") {
        return <GeneralProducts {...tableProps} />;
      } else {
        return <GeneralProducts {...tableProps} />;
      }
    }
    if (listType === "contentoh") return <OrderedProducts {...tableProps} />;
    if (listType === "provider") return <ProviderProducts {...tableProps} />;
  };

  const closeModal = (e) => {
    if (
      (!e.target.closest("#product-detail") && stateFlags.showProductDetail) ||
      e.target.closest(".close-button")
    ) {
      setModalClass(null);
      document.removeEventListener("click", closeModal, false);
      setTimeout(
        () => setStateFlags((prev) => ({ ...prev, showProductDetail: false })),
        500
      );
    }
  };

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: redirect,
          state: { origin: origin },
        }}
      />
    );
  }

  return (
    <Container>
      {stateFlags.addedToCart && addedPopUp}
      <FilterInput
        className="filter-input"
        filterInputFunct={filterInputFunct}
        charged={articlesList?.length}
        total={productCount}
        loadMoreProducts={() =>
          loadMoreProducts(
            startIndex,
            canonicFilter,
            stateFlags,
            setStateFlags,
            setArticlesList,
            setOriginalList,
            setStartIndex,
            originalList,
            listType,
            setProductCount
          )
        }
        editProducts={editProducts}
        exportProducts={exportProducts}
        loadingExportables={stateFlags.loadingExportables}
        download={() =>
          getExportables(setStateFlags, reportFilters, aditionalFilter)
        }
        taskFilter={listType !== "general"}
        filtersArray={filtersOptions}
        filterArray={filterArray}
        setGridView={(gridView) => setGridView(gridView)}
        section={hasOnboarding ? "RetailerProducts" : listType}
        productList={productList}
        filterLists={filterLists}
        setProviderSelected={setProviderSelected}
        setGroupsSelected={setGroupsSelected}
        setPhasesSelected={setPhasesSelected}
        providerSelected={providerSelected}
        phasesSelected={phasesSelected}
        groupsSelected={groupsSelected}
        hideExportButton={false}
      />
      {/* aqui se rendiriza la tabla */}
      {stateFlags.isLoading ? <Loading /> : renderTable()}

      {stateFlags.redirectMultipleEdition && !hasOnboarding && (
        <Redirect
          to={{
            pathname: `/products/multipleEdition`,
          }}
        />
      )}
      {stateFlags.redirectMultipleEdition && hasOnboarding && (
        <Redirect
          to={{
            pathname: `/products/multipleEditionComponent`,
          }}
        />
      )}
      {showContentohExportModal && (
        <ContentohExportModal
          selectedArticlesIds={productsSelected}
          productsList={articlesList}
          products={productsSelected}
          close={setShowContentohExportModal}
          checkboxCounter={selectedArticles.length}
          isUserRetailer={
            JSON.parse(sessionStorage.getItem("company")).is_retailer
          }
        />
      )}
      {stateFlags.showProductDetail && (
        <ProductDetail
          modalRef={wrapperRef}
          selectedArticles={productsSelected}
          option={"Productos"}
          className={modalClass}
          productSelected={productSelected}
          setAddedToCart={(value) =>
            setStateFlags((prev) => ({ ...prev, addedToCart: value }))
          }
          setAddedPopUp={setAddedPopUp}
          setModalClass={setModalClass}
          setSidebool={(value) =>
            setStateFlags((prev) => ({ ...prev, showProductDetail: value }))
          }
          setLoading={(value) =>
            setStateFlags((prev) => ({ ...prev, isLoading: value }))
          }
          getProducts={getProducts}
          section={hasOnboarding ? (acquiredOnboarding ? "acquired" :"onboarding") : listType}
        />
      )}
      {showAlert && (
        <GenericModal
          close={() => setShowAlert(false)}
          message="El producto no está listo"
          detail={`Valida que tu producto aparezca con el estatus aprobado proveedor:`}
          CustomComponentWidth={40}
          button1={{ name: "Entendido", action: () => setShowAlert(false) }}
          status="AP"
        />
      )}
    </Container>
  );
}
