import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";

// components
import GenericModal from "../../general/GenericModal";
import Loading from "../../general/loading";
import { Modal } from "contentoh-components-library/dist/components/organisms/Modal";
import { ButtonV2 } from "contentoh-components-library/dist/components/atoms/ButtonV2";
import { CheckBox } from "contentoh-components-library/dist/components/atoms/CheckBox";
import { MainContainer, BasicData } from "./utils/attributesStyles.js";

// SVG assets
import searchIcon from "../../../assets/IconComponents/search.svg";
import { CheckboxList } from "../../customInputs/CheckboxList/CheckboxList.jsx";

export default function AttributesGroup({ props }) {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [listAttributesFilter, setListAttributesFilter] = useState([]);
  const [listAttributes, setListAttributes] = useState([]);
  const [modalData, setModalData] = useState({
    show: false,
    title: "Actualización completa",
    message: "",
    icon: "success",
  });
  const [attribute, setAttribute] = useState("");
  const [groupsSelected, setGroupsSelected] = useState([]);
  const [attributesSelected, setAttributesSelected] = useState([]);
  const [allGroups, setAllGroups] = useState([]);
  const [attributesGroups, setAttributesGroups] = useState([]);
  const [rowsChecked, setRowsChecked] = useState([]);
  const [columnsChecked, setColumnsChecked] = useState([]);
  const [atributos, setAtributos] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [groupsData, setGroupsData] = useState([]);
  const [attributesData, setAttributesData] = useState([]);
  const [attributesByGroups, setAttributesByGroups] = useState({});

  const updateAttributes = async () => {
    try {
      const body = {
        attributesByGroups,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_GROUP_BY_ATTRIBUTE}/update`,
        body,
        {
          headers: {
            Authorization: sessionStorage.getItem("jwt"),
          },
        }
      );
      setModalData({
        show: true,
        className: "modal-save",
        message: "Cambios aplicados correctamente.",
        icon: "successv2",
        buttons: [
          <ButtonV2
            key="btn-Aceptar"
            type="pink"
            label="Aceptar"
            size={12}
            onClick={() => {
              setModalData((prev) => ({ ...prev, show: false }));
            }}
          />,
        ],
      });
      filterAttributes();

      setLoading(true);
    } catch (error) {
      console.error("Error al actualizar atributos", error);
    } finally {
      setLoading(false);
    }
  };

  const filterAttributes = useCallback(async () => {
    try {
      setLoading(true);
      setListAttributes(attributesGroups);
      setListAttributesFilter(attributesGroups);

      setLoading(false);
    } catch (error) {
      console.error("Error al filtrar atributos:", error);
      setLoading(false);
    }
  }, [attributesGroups]);

  useEffect(() => {
    filterAttributes();
  }, [filterAttributes]);

  const fetchData = async (groups = [], attributes = []) => {
    const groupsId =
      groups && groups.length > 0
        ? groups.map((group) => parseInt(group, 10))
        : [];
    const attributesId =
      attributes && attributes.length > 0
        ? attributes.map((attribute) => parseInt(attribute, 10))
        : [];
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_GROUP_BY_ATTRIBUTE}/get`,
        {
          groupsIds: groupsId,
          attributesIds: attributesId,
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("jwt"),
          },
        }
      );
      const data = JSON.parse(response.data.body);
      const groupsByRetailer = data.groupsByRetailer;
      const attributesByGroups = data.attributesByGroups;
      setAllGroups(groupsByRetailer);
      setAttributesGroups(attributesByGroups);
      initializeSelectedColumns(attributesByGroups);
    } catch (error) {
      console.error("Error al recuperar la data de grupo por atributo", error);
    } finally {
      setLoading(false);
    }
  };

  const allAttributes = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_GROUP_BY_ATTRIBUTE}/get`,
        {
          groupsIds: [],
          attributesIds: [],
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("jwt"),
          },
        }
      );
      const data = JSON.parse(response.data.body);
      const attributesByGroups = data.attributesByGroups;
      const groupsByRetailer = data.groupsByRetailer;
      setAttributesData(attributesByGroups);
      localStorage.setItem(
        "attributesData",
        JSON.stringify(attributesByGroups)
      );
      localStorage.setItem("groupsData", JSON.stringify(groupsByRetailer));
    } catch (error) {
      console.error("Error al recuperar la data de grupo por atributo", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    allAttributes();
  }, []);

  //Filtro Grupos
  useEffect(() => {
    setGroupsData((prev) => {
      return prev.map((item) => {
        if (groupsSelected.includes(item.id))
          return { ...item, isSelected: true };
        return item;
      });
    });
    fetchData(groupsSelected, []);
    setLoading(false);
  }, [groupsSelected]);

  //Filtro Atributos
  useEffect(() => {
    setAtributos((prev) => {
      return prev.map((item) => {
        if (attributesSelected.includes(item.id))
          return { ...item, isSelected: true };
        return item;
      });
    });
    fetchData([], attributesSelected);
    setLoading(false);
  }, [attributesSelected]);

  const searchAttributes = (searchText = "") => {
    try {
      const lowerCaseSearch = searchText.toLowerCase();
      const filteredAttributes = Object.entries(attributesGroups)
        .filter(([attributeName, { attributeId }]) =>
          attributeName.toLowerCase().includes(lowerCaseSearch)
        )
        .reduce((result, [attributeName, { attributeId }]) => {
          result[attributeName] = { attributeId };
          return result;
        }, {});
      setListAttributesFilter(filteredAttributes);
    } catch (error) {
      console.error("Error al buscar atributos:", error);
    }
  };

  const onChangeName = (e) => {
    const text = e.target.value;
    setAttribute(text);
    searchAttributes(text);
  };

  const handlerSelectAll = (evt) => {
    setModalData({
      show: true,
      className: "modal-masive-change",
      message:
        "Estas a punto de hacer una acción masiva para seleccionar grupos.",
      icon: "info",
      customComponent: <p className="texto">¿Estas seguro?</p>,
      buttons: [
        <ButtonV2
          key="btn-Cancelar"
          type="white"
          label="Cancelar"
          size={12}
          onClick={() => {
            setModalData((prev) => ({ ...prev, show: false }));
          }}
        />,
        <ButtonV2
          key="btn-Aceptar"
          type="red"
          label="Aceptar"
          size={12}
          onClick={() => {
            setModalData((prev) => ({ ...prev, show: false }));
            if (!evt.target.checked) {
              handleSelectAll();
            } else {
              handleDeselectAll();
            }
          }}
        />,
      ],
    });
  };

  const handleSelectAll = () => {
    const allRowIds = Object.values(attributesGroups).map(
      (rowData) => rowData.attributeId
    );
    setRowsChecked(allRowIds);
    setSelectedRows(allRowIds);
    setColumnsChecked((prevColumnsChecked) => {
      const allColumnsIds = Object.entries(allGroups).reduce(
        (result, [groupId]) => {
          return result.concat(
            allRowIds.map((attributeId) => `${attributeId}-${groupId}`)
          );
        },
        []
      );
      return allColumnsIds;
    });
    setAttributesByGroups((prevAttributesByGroups) => {
      const updatedAttributesByGroups = { ...prevAttributesByGroups };
      allRowIds.forEach((attributeId) => {
        updatedAttributesByGroups[attributeId] = {
          active: Object.keys(allGroups).map((groupId) =>
            parseInt(groupId, 10)
          ),
          disable: [],
        };
      });
      return updatedAttributesByGroups;
    });
  };

  const handleDeselectAll = () => {
    setRowsChecked([]);
    setSelectedRows([]);
    setColumnsChecked([]);
    setAttributesByGroups((prevAttributesByGroups) => {
      const updatedAttributesByGroups = { ...prevAttributesByGroups };
      selectedRows.forEach((attributeId) => {
        updatedAttributesByGroups[attributeId] = {
          active: [],
          disable: Object.keys(allGroups).map((groupId) =>
            parseInt(groupId, 10)
          ),
        };
      });
      return updatedAttributesByGroups;
    });
  };

  const handleRowCheckboxChange = (evt, attributeId) => {
    const isChecked = evt.target.checked;

    if (isChecked) {
      // Si está marcado, selecciona todos los checks del row
      const newColumnsChecked = Object.keys(allGroups).map(
        (groupId) => `${attributeId}-${groupId}`
      );
      setRowsChecked((prevRowsChecked) => [...prevRowsChecked, attributeId]);
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, attributeId]);
      setColumnsChecked((prevColumnsChecked) => [
        ...prevColumnsChecked,
        ...newColumnsChecked,
      ]);

      setAttributesByGroups((prevAttributesByGroups) => ({
        ...prevAttributesByGroups,
        [attributeId]: {
          active: Object.keys(allGroups).map((groupId) =>
            parseInt(groupId, 10)
          ),
          disable: [],
        },
      }));
    } else {
      // Si no está marcado, deselecciona todos los checks del row
      setRowsChecked((prevRowsChecked) =>
        prevRowsChecked.filter((id) => id !== attributeId)
      );
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((id) => id !== attributeId)
      );
      setColumnsChecked((prevColumnsChecked) =>
        prevColumnsChecked.filter((id) => !id.startsWith(`${attributeId}-`))
      );

      setAttributesByGroups((prevAttributesByGroups) => {
        const activeGroups = prevAttributesByGroups[attributeId]?.active || [];
        const newColumnsChecked = Object.keys(allGroups).map(
          (groupId) => `${attributeId}-${groupId}`
        );
        const disableGroups = activeGroups.filter(
          (group) => !newColumnsChecked.includes(group)
        );

        return {
          ...prevAttributesByGroups,
          [attributeId]: { active: [], disable: disableGroups },
        };
      });
    }
  };

  const handleCheckboxChange = (
    groupId,
    attributeId,
    attributeName,
    isChecked
  ) => {

    setSelectedRows((prevSelectedRows) => {
      const updatedSelectedRows = isChecked
        ? [...prevSelectedRows, `${attributeId}-${groupId}`]
        : prevSelectedRows.filter(
            (rowId) => rowId !== `${attributeId}-${groupId}`
          );

      return updatedSelectedRows;
    });

    setColumnsChecked((prevColumnsChecked) => {
      const columnId = `checkbox-${attributeId}-${groupId}`;
      const updatedColumnsChecked = isChecked
        ? [...prevColumnsChecked, columnId]
        : prevColumnsChecked.filter((id) => id !== columnId);

      return updatedColumnsChecked;
    });

    setAttributesGroups((prevAttributesGroups) => {
      const updatedAttributesGroups = { ...prevAttributesGroups };
      const attribute = updatedAttributesGroups[attributeName];

      if (attribute) {
        const groupIndex = attribute.groups.indexOf(groupId);
        if (groupIndex !== -1) {
          attribute.groups[groupIndex] = isChecked;
        }
      }

      setAttributesByGroups((prevAttributesByGroups) => {
        const updatedAttributesByGroups = { ...prevAttributesByGroups };

        const attributeIdString = attributeId.toString();
        if (!updatedAttributesByGroups[attributeIdString]) {
          updatedAttributesByGroups[attributeIdString] = {
            active: [],
            disable: [],
          };
        }

        const groupIdNumber = parseInt(groupId, 10);
        const groupList = isChecked ? "active" : "disable";
        const oppositeGroupList = isChecked ? "disable" : "active";

        if (
          !updatedAttributesByGroups[attributeIdString][groupList].includes(
            groupIdNumber
          )
        ) {
          updatedAttributesByGroups[attributeIdString][groupList].push(
            groupIdNumber
          );
        }

        // Si isChecked es true, agregar groupId a la lista de disable
        if (isChecked) {
          updatedAttributesByGroups[attributeIdString].disable.push(
            groupIdNumber
          );
        } else {
          // Antes de enviarlo a la lista de disable, quitar el check en ColumnsChecked
          setColumnsChecked((prevColumnsChecked) => {
            return prevColumnsChecked.filter(
              (id) => id !== `${attributeId}-${groupId}`
            );
          });
        }

        // Quita de la lista de grupos opuesta
        updatedAttributesByGroups[attributeIdString][oppositeGroupList] =
          updatedAttributesByGroups[attributeIdString][
            oppositeGroupList
          ].filter((group) => group !== groupIdNumber);
        return updatedAttributesByGroups;
      });

      return updatedAttributesGroups;
    });
  };

  const handleSaveChanges = () => {
    updateAttributes();
  };

  const initializeSelectedColumns = (attributesGroups) => {
    const initialCheckedColumns = Object.entries(attributesGroups).reduce(
      (result, [, { attributeId, groups }]) => {
        const columnsToCheck = Object.values(groups).map(
          ({ groupId, groupName }) => {
            return `${attributeId}-${groupId}`;
          }
        );
        return result.concat(columnsToCheck);
      },
      []
    );

    setColumnsChecked(initialCheckedColumns);
  };

  //FIXME:Cuando haya tiempo saca esto de la session no es lo mas correcto guardarlo aqui
  useEffect(() => {
    const storedAttributesData = JSON.parse(
      localStorage.getItem("attributesData")
    ) || {};
    const storedGroupsData = JSON.parse(localStorage.getItem("groupsData")) || {};
    const atributos = Object.entries(storedAttributesData).map(
      ([attributeName, { attributeId, groups }]) => ({
        label: attributeName,
        value: attributeId,
        groups: groups.map((group) => ({ id: group })),
      })
    );
    const grupos = Object.entries(storedGroupsData).map(
      ([groupId, groupName, isSelected]) => ({
        value: groupId,
        label: groupName,
        isSelected: isSelected || false,
      })
    );
    setAtributos(atributos || []);
    setGroupsData(grupos || []);
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <>
      <MainContainer>
        <div className="filters-container">
          <div className="filters">
            <div className="search-user-input">
              <img src={searchIcon} alt="search-icon" />
              <input
                type="text"
                placeholder="Buscar"
                value={attribute}
                onChange={onChangeName}
              />
            </div>
            <CheckboxList
              id="group-select"
              items={groupsData}
              name="Grupo"
              defaultSearch=""
              defaultOption="Todos los grupos"
              placeholder="Buscar grupo"
              onChange={(selectedItems) => {
                if (selectedItems.length > 0) {
                  setGroupsSelected(selectedItems);
                } else {
                  setGroupsSelected([]);
                }
              }}
            />
            <CheckboxList
              id="group-select"
              items={atributos}
              name="Atributo"
              defaultSearch=""
              defaultOption="Todos los atributos"
              placeholder="Buscar atributo"
              onChange={(selectedItems) => {
                if (selectedItems.length > 0) {
                  setAttributesSelected(selectedItems);
                } else {
                  setAttributesSelected([]);
                }
              }}
            />
          </div>
          <ButtonV2
            key="btn-Guardar"
            className="button-save"
            type="pink"
            label="Guardar Cambios"
            size={12}
            onClick={() => handleSaveChanges()}
          ></ButtonV2>
        </div>
        <div className="table-attributes-container">
          <BasicData>
            <div className="row header th-custom">
              <p className="col row justify-between width-atributo elipsis">
                Atributo
                <CheckBox
                  id={"globalHeaderCheckbox"}
                  type="checkbox"
                  typeColor="pink"
                  checked={
                    selectedRows.length ===
                    Object.entries(attributesGroups).length
                  }
                  onChange={(evt) => handlerSelectAll(evt)}
                />
              </p>
              {Object.entries(allGroups).map(([id, name]) => (
                <p key={id} id={id} className="col text_center elipsis">
                  {name}
                </p>
              ))}
            </div>
            {Object.entries(listAttributesFilter).length > 0 ? (
              Object.entries(listAttributesFilter).map(
                ([attributeName, { attributeId, groups }]) => (
                  <div key={attributeId} className="row" id={attributeId}>
                    <p className="col row justify-between width-atributo">
                      {attributeName}
                      <CheckBox
                        id={`checkbox-${attributeId}`}
                        typeColor="pink"
                        onChange={(evt) =>
                          handleRowCheckboxChange(evt, attributeId)
                        }
                        checked={selectedRows.includes(attributeId)}
                      />
                    </p>
                    {Object.entries(allGroups).map(([groupId, groupName]) => (
                      <p
                        key={`group-column-${groupId}`}
                        className="col text_center"
                      >
                        <CheckBox
                          id={`checkbox-${attributeId}-${groupId}`}
                          typeColor="pink"
                          onChange={(evt) => {
                            handleCheckboxChange(
                              groupId,
                              attributeId,
                              attributeName,
                              evt.target.checked
                            );
                          }}
                          className="selectCheck"
                          checked={
                            selectedRows.includes(
                              `${attributeId}-${groupId}`
                            ) ||
                            columnsChecked.includes(`${attributeId}-${groupId}`)
                          }
                        />
                      </p>
                    ))}
                  </div>
                )
              )
            ) : (
              <div className="no-users">
                <p>No hay atributos con ese grupo.</p>
              </div>
            )}
          </BasicData>
        </div>

        {show && <GenericModal close={() => setShow(false)} {...modalData} />}
        <Modal
          {...modalData}
          onClickBtnDefault={() =>
            setModalData((prev) => ({ ...prev, show: false }))
          }
        ></Modal>
      </MainContainer>
    </>
  );
}
