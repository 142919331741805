import { getRetailerProducts } from "../../../_utils/data";
import { updateFlags } from "./stateUpdates";
import axios from "axios";
import { saveAs } from "file-saver";

export const getProducts = async (
  params,
  currentFlags,
  flagUpdateFunction,
  setProductCount
) => {
  const products = await getRetailerProducts(params, setProductCount);
  const ErrorReadingProducts = products.err;
  const NoProductsFound = products.productList.length === 0;
  const itemList = products.productList;

  if (ErrorReadingProducts) {
    flagUpdateFunction(updateFlags(currentFlags, "RequestError"));
  } else if (NoProductsFound) {
    flagUpdateFunction(updateFlags(currentFlags, "EmptyList"));
  }
  return itemList;
};

export const loadMoreProducts = async (
  startIndex,
  canonicFilter,
  stateFlags,
  setStateFlags,
  setArticlesList,
  setOriginalList,
  setStartIndex,
  originalList,
  listType,
  setProductCount
) => {
  try {
    setStateFlags((prev) => {
      prev.isLoading = true;
      return prev;
    });
    const newStartIndex =
      startIndex +
      parseInt(process.env.REACT_APP_RETAILERS_INCREMENT_LIMIT, 10);

    const params = { startIndex: newStartIndex, productsType: listType };
    if (canonicFilter) {
      params.canonicFilter = canonicFilter;
    }
    setStartIndex(newStartIndex);

    const newProducts = await getProducts(
      params,
      stateFlags,
      setStateFlags,
      setProductCount
    );

    setArticlesList((prev) => [...prev, ...newProducts]);

    setOriginalList((prev) => [...prev, ...newProducts]);
  } catch (err) {
    console.log(err, "unable to load more products,please report this to IT");
    setArticlesList(originalList);
    setOriginalList(originalList);
  }
};

export const getExportables = async (
  flagUpdateFunction,
  reportFilters,
  aditionalFilter
) => {
  flagUpdateFunction((prev) => ({ ...prev, loadingExportables: true }));
  try {
    const defaultRetailers = JSON.parse(
      sessionStorage.getItem("company")
    ).retailers.map((retailer) => retailer.id);

    const email = encodeURIComponent(
      JSON.parse(sessionStorage.getItem("user")).email
    );
    let reportFiltersCopy = reportFilters ?? {
      filterByRetailer: defaultRetailers,
    };
    //if no retailer was selected from filter, get only retailers linked to acc products
    if (!reportFiltersCopy.filterByRetailer) {
      reportFiltersCopy.filterByRetailer = defaultRetailers;
    }
    const filtersForQuery = encodeURIComponent(JSON.stringify(reportFilters));
    const jsFilter = aditionalFilter
      ? `&jsFilter=${encodeURIComponent(JSON.stringify(aditionalFilter))}`
      : "";
    const response = await axios.get(
      `${process.env.REACT_APP_REPORTS}?email=${email}&filters=${filtersForQuery}&reportType=2${jsFilter}`,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    saveAs(JSON.parse(response.data.body).url);
  } catch (error) {
    console.log(error);
  }
  flagUpdateFunction((prev) => ({ ...prev, loadingExportables: false }));
};
export const updateSessionStorageProduct = (product) => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const company = JSON.parse(sessionStorage.getItem("company"));
  let prod = product;
  if (user.is_retailer) {
    const idsRetailers = company.retailers.map((e) => e.id);
    product.retailers = product.retailers.filter((f) =>
      idsRetailers.includes(f.id)
    );
  }
  prod.retailersAvailable = product.retailers;
  prod.upc = product.article.upc;
  prod.name = product.article.name;
  prod.categoryName = product.article.category;
  prod.id_category = product.article.id_category;
  prod.id_article = product.article.id_article;
  prod.asignations = product.asignations ?? [];
  if (!prod.orderId) {
    prod.datasheet_status = product.article_status;
    prod.description_status = product.article_status;
    prod.images_status = product.article_status;
  }
  prod.services = [
    {
      id_order: prod.id_order,
      id_retailer: company.id,
      id_article: prod.id_article,
      service: "datasheet",
      quantity: 1,
      price: 0,
      id_user: user.id_user,
      datasheet_common: null,
      discount: null,
      status: product.article_status,
    },
  ];
  sessionStorage.setItem("version", prod.version);
  sessionStorage.setItem("productSelected", JSON.stringify(prod));
  sessionStorage.setItem(
    "productEdit",
    JSON.stringify({
      ArticleId: prod?.article.id_article,
      idCategory: prod?.article.id_category,
      product: prod,
    })
  );
  return prod;
};
