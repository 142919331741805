import { useState } from "react";
import cartPlusSolid from "../../../../assets/IconComponents/cart-plus-solid.svg";
// import { useCloseModal } from "../../../../hooks/useCloseModal";
import { Container } from "./styles.js";
import OkModalIcon from "../../../../assets/IconComponents/genericModalCheck.svg";
import {
  postAcquiredProducts,
  postNotifyAdcquiredTHD,
} from "../../../_utils/data";
import Modal from "../../../merchantsLists/Modal/Modal";
// import { sendToAudit } from "../../../_utils/data";

export default function DropdownScenary(props) {
  const [showFirstModal, setShowFirstModal] = useState(false);
  // const [right, setRight] = useState(false);
  // const [showScenaries, setShowScenaries] = useCloseModal(
  //   props.id ?? "DropdownScenary"
  // );

  // useEffect(() => {
  //   if (showScenaries) {
  //     const el = document.getElementById(props.id ?? "DropdownScenary");
  //     const vw = Math.max(
  //       document.documentElement.clientWidth || 0,
  //       window.innerWidth || 0
  //     );
  //     const coords = el.getBoundingClientRect();
  //     setRight(coords.right > vw - 115);
  //   }
  // }, [props.id, showScenaries]);

  const closeModal = () => {
    // setShowScenaries(false);
    props.setModalAlert({ show: false });
  };

  const notifyToContentoh = async () => {
    const button1 = {
      type: "btnPink",
      name: "Continuar",
      action: closeModal,
    };
    const paramsModal = [
      "Gracias",
      "Content-oh! se hace cargo de esto a partir de aquí",
      closeModal,
      button1,
    ];
    await postNotifyAdcquiredTHD();
    props.showModalAlert(...paramsModal);
  };

  const modalBaseInfo = {
    seller: {
      button1: {
        type: "btnPink",
        name: "Aceptar",
        action: () => {
          closeModal();
        },
      },
      message:
        "Los productos seleccionados fueron enviados al checkout del vendor.",
      icon: OkModalIcon,
    },
    retailer: {
      button1: {
        type: "btnPink",
        name: "Seguir en mis productos",
        action: closeModal,
      },
      button2: {
        type: "btnTransparent",
        name: "Generar orden",
        action: () => notifyToContentoh(),
      },
      title: "Productos añadidos a tu carrito",
      message:
        "¿Quieres continuar con el proceso de generación de la orden a Content-oh! o seguir revisando tus productos?",
    },
  };

  const acquireProducts = async (stage) => {
    const { button1, button2, title, message, icon } = modalBaseInfo[stage];
    const paramsModal = [title, message, closeModal, button1, button2, icon];
    let articles = [];
    const productsSelected = props.articlesList?.filter((item) => {
      const articleId = item.article.id_article;
      return !item.stage && props.productsSelected.includes(articleId);
    });
    if (productsSelected.length > 0) {
      props.articlesList?.forEach((item) => {
        const articleId = item.article.id_article;
        const { version, stage } = item;
        if (!stage && props.productsSelected.includes(articleId)) {
          articles.push({ articleId, version });
        }
      });
    } else if (props.articleId) {
      const { version } = props.articlesList.find(
        ({ article }) => article.id_article === props.articleId
      );
      articles = [{ articleId: props.articleId, version }];
    } else {
      button1.name = "Continuar";
      paramsModal[0] = "No hay productos seleccionados";
      paramsModal[1] = undefined;
      paramsModal[4] = undefined;
    }

    const params = {
      data: { articles, arrayRetailers: [58], stage },
    };
    if (articles.length > 0) {
      await postAcquiredProducts(params);
      props.updateScenary(
        articles.map(({ articleId }) => articleId),
        stage
      );
      props.setProductsSelected([]);
    }
    props.showModalAlert(...paramsModal);
  };

  /* Se pospone el flujo de auditoría hasta terminar de definirlo
  const sentProductsToAudit = async (articles) => {
    const params = {
      query: { isDropship: 1 },
      body: { articles },
    };
    await sendToAudit(params);
    closeModal();
  };

  const addToAudit = async () => {
    const button1 = {
      type: "btnPink",
      name: "Enviar a auditoria",
      action: closeModal,
    };
    const button2 = {
      type: "btnTransparent",
      name: "Cancelar",
      action: closeModal,
    };
    const alertParams = [
      "Auditar productos",
      "¿Te gustaría auditar los productos seleccionados?",
      closeModal,
      button1,
      button2,
      undefined,
    ];
    if (props.productsSelected.length > 0) {
      const productList = props.articlesList
        .filter(({ article }) =>
          props.productsSelected.includes(article.id_article)
        )
        .map(({ article, version }) => ({
          id: article.id_article,
          version,
          retailers: [58],
        }));
      button1.action = () => sentProductsToAudit(productList);
    } else if (props.articleId) {
      const { article, version } = props.articlesList.find(
        ({ article }) => props.articleId === article.id_article
      );
      const productList = [
        { id: article.id_article, version, retailers: [58] },
      ];
      button1.action = () => sentProductsToAudit(productList);
    } else {
      button1.name = "Continuar";
      alertParams[0] = "No hay productos seleccionados";
      alertParams[1] = undefined;
      alertParams[4] = undefined;
    }
    props.showModalAlert(...alertParams);
  }; */

  return (
    <Container
      sizeIcon={props.sizeIcon ?? 34}
      id={props.id ?? "DropdownScenary"}
      // right={right}
    >
      <button className="buttonIcon" onClick={() => setShowFirstModal(true)}>
        <img
          className="icon-cart"
          src={cartPlusSolid}
          alt="Adquirir op"
          // onClick={() => setShowScenaries(!showScenaries)}
        />
      </button>
      {/* showScenaries && (
        <ul className="list">
          <li onClick={() => acquireProducts("seller")}>Pagar Vendor/Seller</li>
          <li onClick={() => acquireProducts("retailer")}>Paga THD</li>
          <li onClick={addToAudit}>Auditoría Content-oh!</li>f
        </ul>
      ) */}
      {showFirstModal ? (
        <Modal
          onClickBtnClose={(event) => {
            event.stopPropagation();
            setShowFirstModal(false);
          }}
          title="¿Deseas adquirir estos productos?"
          buttons={[
            <button
              key="modalAlert-btn1"
              className="btnTransparent"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setShowFirstModal(false);
              }}
            >
              Cancelar
            </button>,
            <button
              key="modalAlert-btn2"
              className="btnPink"
              onClick={async (event) => {
                event.preventDefault();
                event.stopPropagation();
                acquireProducts("retailer");
                setShowFirstModal(false);
              }}
            >
              Adquirir
            </button>,
          ]}
        />
      ) : null}
    </Container>
  );
}
