import React, { useEffect, useState } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { Dashboard } from "contentoh-components-library";
import Profile from "../components/main/profile/index";
import AddProducts from "../components/products/addProducts/Add";
import NotFound from "../components/notfound/index";
import taskList from "../components/collaborators/tasks/index.js";
import Orders from "../components/contentoh/Orders";
import OrdersView from "../components/contentoh/orders/index";
import PagoTransferencia from "../components/products/listProducts/pagoTransferencia/index";
import PagoTarjeta from "../components/products/listProducts/pagoTarjeta/index";
import Quotes from "../components/contentoh/quotes";
import { MultipleEdition } from "../components/products/multipleEdition";
import { MultipleEditionComponent } from "../components/products/multipleEditionComponent";
import UpgradePlan from "../components/main/profile/upgradePlan/index";
import { changeHeader } from "./changeHeader";
import { Checkout } from "../components/Checkout";
import { ContentohProducts } from "../components/contentoh/ContentohProducts/index";
import { GeneralProducts } from "../components/products/productsGeneral/generalProducts";
import RetailerProducts from "../components/products/retailerProducts/index";
import MerchantsProducts from "../components/products/merchants/index";
import RetailerTasks from "../components/retailer/tasks/index";
import ListProducts from "../components/merchantsLists/index";
import Acquired from "../components/products/merchants/Acquired/index";
import HOCProviderProductEdition from "../components/products/HOCProviderProductEdition";
import { ThdDashboard } from "../components/thddasboard/index";
import { addToCart } from "../components/_utils/data";

export const DashboardRouters = withRouter((props) => {
  const [user] = useState(JSON.parse(sessionStorage.getItem("user")));
  const [company] = useState(JSON.parse(sessionStorage.getItem("company")));
  const productToEdit = JSON.parse(sessionStorage.getItem("productEdit"));
  const productSelected = JSON.parse(sessionStorage.getItem("productSelected"));
  const jwt = sessionStorage.getItem("jwt");
  const setShowNotification = props.setShowNotification;
  const tabsSections = {
    Descripción: true,
    "Ficha técnica": false,
    Imágenes: false,
  };
  const [location, setLocation] = useState();

  useEffect(() => {
    const temp = changeHeader(props.location.pathname);
    props.setDocTitle(temp.title);
    props.setDescription(temp.info);
  }, [props]);

  useEffect(() => {
    setLocation(props.location);
  }, [props.location]);

  return [
    <Switch>
      <Route key={props.location.pathname} exact path="/products">
        {user.is_retailer || user.is_onboarding===1 ? (
          <RetailerProducts type={"general"} />
        ) : (
          <GeneralProducts titles="Global" option="Productos" />
        )}
      </Route>

      <Route key={props.location.pathname} exact path="/allacquired">
      {user.is_retailer || user.is_onboarding===1 ? (
          <RetailerProducts type={"general"} acquired={true}/>
        ) : (
          <GeneralProducts titles="Global" option="Productos" />
        )}
      </Route>

      <Route key={props.location.pathname} exact path="/merchants">
        <MerchantsProducts
          type={"general"}
          {...location}
          setLocation={setLocation}
        />
      </Route>
      <Route key={props.location.pathname} exact path="/merchantsLists">
        <ListProducts type={"general"} />
      </Route>

      <Route exact path="/Contentoh">
        <GeneralProducts titles="Contentoh" option="ProductosContento" />
      </Route>

      {/*Edit products (Regular-Billing-Notification redirect)*/}
      <Route exact path="/orders">
        <OrdersView headerl="Global" headtable="Global" headerr="Global" />
      </Route>
      <Route exact path="/orders/billing">
        <Orders headerl="Contentoh" headtable="Contentoh" headerr="Contentoh" />
      </Route>
      <Route exact path="/orders/:orderId">
        <Orders headerl="Contentoh" headtable="Global" headerr="Global" />
      </Route>

      <Route path="/Profile" component={Profile} />
      <Route exact path="/AddProducts">
        <AddProducts setShowNotification={setShowNotification} />
      </Route>
      <Route exact path="/Quotes" component={Quotes} />
      <Route exact path="/checkout">
        <Checkout company={company} />
      </Route>
      <Route exact path="/PagoTransferencia" component={PagoTransferencia} />
      <Route exact path="/PagoTarjeta" component={PagoTarjeta} />

      {/*Edit products (Regular edition-Notification redirect)*/}
      <Route path="/EditProducts">
        <HOCProviderProductEdition
          productSelected={productSelected}
          productToEdit={productToEdit}
          user={user}
          company={company}
          token={jwt}
          tabsSections={tabsSections}
          location={location}
          addToCart={addToCart}
        />
      </Route>
      <Route path="/products/:productId/:conceptAssigned">
        <HOCProviderProductEdition
          productSelected={productSelected}
          productToEdit={productToEdit}
          user={user}
          company={company}
          token={jwt}
          tabsSections={tabsSections}
          location={location}
          addToCart={addToCart}
        />
      </Route>
      <Route
        exact
        path="/products/multipleEdition"
        component={MultipleEdition}
      />
      <Route
        exact
        path="/products/multipleEditionComponent"
        component={MultipleEditionComponent}
      />

      <Route exact path="/Dashboard">
        <Dashboard
          jwt={sessionStorage.getItem("jwt")}
          user={user}
          company={company}
        />
      </Route>
      <Route exact path="/DashboardContentoh">
        <Dashboard
          jwt={sessionStorage.getItem("jwt")}
          user={user}
          company={company}
        />
      </Route>
      <Route exact path="/Thddashboard">
        <ThdDashboard
          jwt={sessionStorage.getItem("jwt")}
          user={user}
          company={company}
        />
      </Route>

      <Route exact path="/upgradeplan" component={UpgradePlan} />
      <Route key={props.location.pathname} exact path="/ContentohProducts">
        {user.is_retailer ? (
          <RetailerProducts type={"contentoh"} />
        ) : (
          <ContentohProducts />
        )}
      </Route>

      <Route exact path="/tasks" component={taskList} />
      <Route exact path="/tasks/:companyId/" component={RetailerTasks} />
      <Route exact path="/acquired" component={Acquired} />

      <Route component={NotFound} />
    </Switch>,
  ];
});
