import styled from "styled-components";

export const RequestModal = styled.div`
  width: 100%;
  height: 100vh;
  background-color: rgba(59, 59, 59, 0.53);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  backdrop-filter: blur(4px);
  z-index: 10;

  &.modal-number {
    min-height: 0;
  }
`;

export const ProductsReceived = styled.div`
  display: ${(props) => (props.modalView ? "block" : "none")};
  position: absolute;
  width: 590px;
  height: 300px;
  background: #281f33;
  border-radius: 39px;
  z-index: 5;
  right: 100px;

  .volver-button {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #f0eef2;
    box-sizing: border-box;
    border-radius: 30px;
    color: #f0eef2;
    background: transparent;
    width: 282px;
    height: 41px;
    margin-top: 50px;
  }

  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    border: none;
    padding: 0;
    background-color: transparent;
  }

  .img {
    width: 91px;
    margin-top: 30px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  p {
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: center;
    font-feature-settings: "pnum" on, "lnum" on;

    color: #f7f7f7;
    display: block;
    margin-top: 70px;
    margin-left: auto;
    margin-right: auto;
  }
`;
export const ModalContainerProduct = styled.div`
  box-sizing: border-box;
  background-color: #503d66;
  max-width: 638px;
  height: fit-content;
  border-radius: 30px;
  padding: 35px 52px 83px 52px;
  position: relative;
  .donut {
    margin: 0 auto;
    position: relative;
    width: 152px;
    height: 152px;
  }
  .instalment4 .donut-case {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #d74ded;
    position: absolute;
    top: 0;
    left: 0;
    background-clip: border-box;
    overflow: hidden;
  }

  .donut-text {
    top: 16px;
    left: 16px;
    width: 118px;
    height: 118px;
    background: #503d66;
    position: absolute;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
  }

  .donut-text span {
    color: #48b2c1;
    font-family: Avenir Next;
    font-style: normal;
    font-weight: 500;
    font-size: 96px;
    line-height: 131px;
    text-align: center;
    letter-spacing: -0.015em;

    color: #ededed;
  }
`;
export const ModalContainer = styled.div`
  box-sizing: border-box;
  background-color: #503d66;
  max-width: 638px;
  height: 100vh;
  max-height: 740px;
  border-radius: 30px;
  padding: 44px 52px 35px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  .product-delivered-icon {
    img {
      display: block;
      margin: auto;
    }
  }
  &.close-button {
    color: #d4d1d7;
    background-color: #e33aa900;
    border: 1px solid #817393;
    box-sizing: border-box;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 47px;
  top: 54px;

  button {
    border: 1px solid #ededed;
    background-color: transparent;
    color: #ededed;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
  }
  button::before,
  button::after {
    right: 3px;
    position: absolute;
    content: "";
    width: 80%;
    height: 1px; /* cross thickness */
    background-color: #ededed;
  }

  button::before {
    transform: rotate(45deg);
  }

  button::after {
    transform: rotate(-45deg);
  }
`;

export const ModalImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 150%;
    height: 150%;
    border-radius: 50%;
  }

  .image-container {
    border: 16px solid #d74ded;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      display: block;
      font-family: Avenir Next;
      font-style: normal;
      font-weight: 500;
      font-size: 96px;
      text-align: center;
      text-align: center;
      color: #ededed;
      max-height: 80px;
    }
  }

  &.percent-circle {
    width: 280px;
    height: 280px;
    background: ${(props) =>
      `conic-gradient( #EDD34D 0% ${
        props.percent ? props.percent : "0"
      }%, #18A0FB ${props.percent ? props.percent : "0"}% 100%)`};
    border-radius: 50%;
    margin: auto;

    .image-container2 {
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      background-color: #503d66;
      border-radius: 50%;
      width: 230px;
      height: 230px;

      p {
        color: white;
        font-family: Avenir Next;
        font-style: normal;
        font-weight: 500;
        font-size: 144px;
        line-height: 197px;
        height: 160px;
      }
    }
  }
`;
export const TitleProduct = styled.div`
  margin-top: 15px;
  margin: auto;

  color: #fbfbfb;
  font-family: Raleway;
  font-style: normal;
  h2 {
    margin-top: 15px;
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
    font-weight: normal;
    line-height: 43px;
    span {
      font-weight: 600;
    }
    & + p {
      margin-top: 11px;
    }
  }
  p {
    margin: 0;
    width: 100%;
    text-align: center;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
  }
`;
export const Title = styled.div`
  max-width: 480px;
  margin: 0 auto;
  color: #fbfbfb;
  font-family: Raleway;
  font-style: normal;

  h2 {
    font-size: 36px;
    text-align: center;
    margin: 0;
    font-weight: normal;
    line-height: 43px;
    span {
      font-weight: 600;
    }
    & + p {
      margin-top: 16px;
    }
  }
  p {
    margin: 0;
    width: 100%;
    text-align: center;
    font-size: 24px;
    line-height: 28px;
  }
`;
export const FormActiveContainerProduct = styled.div`
  form {
    padding-top: 5px;
    p {
      margin-bottom: 5px;
      font-family: Avenir Next;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      color: #cdcdcd;

      & + .checkbox-container {
        margin-top: 10px;
      }
    }
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  border-radius: 7px;
  background-color: #6a5880;
  box-sizing: border-box;
  overflow: auto;
  &.active-options {
    justify-content: space-evenly;
    align-items: center;
    min-height: 38px;
    max-width: 317px;
    margin: auto;
  }

  &.linked-companies {
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
    max-width: 535px;
    max-height: 140px;
    height: 140px;

    .check-item {
      flex: 25%;
      max-width: 25%;
      display: flex;
      align-items: center;
      overflow: hidden;

      label {
        white-space: nowrap;
      }
    }

    & + * {
      margin-top: 14px;
    }
  }

  .check-item {
    display: flex;
    flex-wrap: nowrap;

    label {
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 22px;
      color: #d8d7e1;
      position: relative;
      padding-left: 25px;

      &:before {
        content: "";
        display: block;
        width: 17px;
        height: 17px;
        background-image: url(${(props) => props.unchecked});
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    input {
      display: none;

      &:checked {
        & + label:before {
          width: 17px;
          height: 17px;
          background-image: url(${(props) => props.checked});
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }

    * {
      box-sizing: border-box;
    }
  }

  & + * {
    margin-top: 45px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    display: block;
    font-family: Avenir Next;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    max-width: 25%;
    flex: 25%;
    text-align: center;
    color: white;
    margin: 10px;
  }
  label {
    display: block;
    flex: 25%;
    max-width: 25%;
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: white;
    white-space: nowrap;
    overflow: hidden;

    & + * {
      margin-left: 5px;
    }
  }

  & + .btn-container {
    margin-top: 50px;
  }

  & + * {
    margin-top: 3px;
  }
`;

export const Status = styled.div`
  display: block;
  max-width: 25%;
  height: 20px;
  flex: 25%;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: white;
  background: ${(props) => (props.backColor ? props.backColor : "#EDD34D")};
  border-radius: 3px;
`;

export const Completed = styled.div`
  display: block;
  flex: 33%;
  text-align: center;
  color: white;
  margin: 3px;
  background: #18a0fb;
  border-radius: 3px;
`;
export const Label = styled.label`
  color: #fafafa;
  font-family: Lato;
  font-size: 11px;
  line-height: 21px;
  position: relative;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  justify-content: space-evenly;

  button {
    background-color: transparent;
    border: 1px solid #f0eef2;
    color: #f0eef2;
    line-height: 25px;
    border-radius: 30px;
    padding: 7px 22px;
    font-size: 18px;
    min-width: 222px;
    height: 40px;
    cursor: pointer;

    &.add-button {
      background-color: #e33aa9;
      border: none;
    }
  }
`;

export const FormActiveContainer = styled.div`
  .products-container {
    max-height: 120px;
    overflow: auto;

    & + * {
      margin-top: 50px;
    }
  }
  p {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    color: #fff;
    text-align: center;
    margin: 0;

    &.brands {
      margin-top: 14px;
    }

    & + .active-options,
    & + .linked-companies {
      margin-top: 10px;
    }
  }

  .header-text-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    p {
      margin: 0;
    }

    label {
      display: flex;
      font-family: Avenir Next;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 25px;
      color: #fff;
      text-align: center;
      align-items: center;

      &:before {
        margin-right: 5px;
      }
    }
  }
`;

export const ButtonQuoteInvoicing = styled.div`
  border: 1px solid #603888;
  box-sizing: border-box;
  border-radius: 12px;
  background: #fbfbfb;
  color: #603888;
  font-family: Raleway;
  padding: 0 10px;
  width: 128px;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const ButtonQuoteInvoicing2 = styled.div`
  border: 1px solid #603888;
  box-sizing: border-box;
  border-radius: 30px;
  background: #fbfbfb;
  font-family: Raleway;
  height: 30px;
  padding: 0 10px;
  width: 198px;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  color: #503d66;
`;

export const ContainProgress = styled.div`
  width: 103px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.015em;
  height: 20px;
  color: #ffffff;
  background: #ed9a4d;
  border-radius: 15px;
`;

export const Container = styled.div`
  display: flex;
  flex: ${(props) => props.flex};
  width: ${(props) => props.width};
  padding: ${(props) => props.padding};
  justify-content: ${(props) => props.justifycontent};
  flex-direction: ${(props) => props.flexdirection};
  align-items: ${(props) => props.align};
  flex-wrap: ${(props) => props.flexwrap};

  .table {
    input {
      display: none;
    }
  }

  &.image-container-2 {
    display: block;
    width: initial;

    img {
      max-width: 100%;
    }

    & + * {
      margin-left: 10px;
    }
  }
`;

export const Container2 = styled.div`
  display: flex;
  flex: ${(props) => props.flex};
  width: ${(props) => props.width};
  padding: ${(props) => props.padding};
  justify-content: space-between;
  flex-direction: ${(props) => props.flexdirection};
  align-items: ${(props) => props.align};
  flex-wrap: ${(props) => props.flexwrap};
`;

export const ContainerPrincipalSide = styled.div`
  width: 592px;
  height: calc(100% - 100px);
  position: fixed;
  right: 0;
  top: 100px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #fafafa;
  padding: 10px;
  > div {
    display: flex;
  }
  .table {
    height: calc(100% - 360px);
    overflow-y: scroll;
  }
  .footer {
    position: absolute;
    bottom: 87px;
  }
  .footerBotton {
    position: absolute;
    bottom: 10px;
  }
  .btnClose {
    padding: 13px 20px 20px 20px;
    margin-left: 400px;
    div {
      font-family: Avenir Next;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.015em;
      display: flex;
      justify-content: center;
      color: #ffffff;
      background: #603888;
      border-radius: 30px;
      width: 102px;
      height: 44px;
    }
  }
`;

export const HeaderTitle = styled.div`
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height, or 150% */

  letter-spacing: -0.015em;

  /* Magenta Original */

  color: #b12d84;
  float: left;
`;

export const RowStatus2 = styled.div`
  background-color: ${(props) => props.bg};
  font-family: Lato;
  width: 48px;
  height: 20px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height, or 138% */

  letter-spacing: -0.015em;

  color: #ffffff;
  border-radius: 3px;
`;

export const Subtitle = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */
  text-align: center;
  letter-spacing: -0.015em;
  color: #503d66;
`;

export const TableInvoicing = styled.table`
  width: 100%;
  display: block;
  border: none;

  thead {
    width: 100%;
    display: block;

    .tr-row-detail {
      border: none;
      font-family: Avenir Next;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      letter-spacing: -0.015em;
      color: #503d66;
      text-align: left;
      padding: 10px 0;
      display: flex;
      justify-content: space-around;

      th {
        flex: 0%;
        display: block;
      }
    }
  }

  tbody {
    display: block;

    input {
      display: none;
    }

    tr {
      border-top: 1px solid #f0eef2;
      min-height: 20px;
      display: flex;
      position: relative;

      td {
        border: none;
        font-family: Avenir Next;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        text-align: left;
        color: #817393;
        display: flex;
        align-items: center;
        flex: 0%;
      }

      &:last-child {
        border-bottom: 1px solid #f0eef2;
      }
    }
  }
`;

export const Switcher = styled.td`
  width: 11px;
  right: 10px;
  background-color: ${(props) => (props.chkState ? "#E33AA9" : "")};
  height: 100%;
  text-align: center !important;
  border: 1px solid #e33aa9;
  position: absolute;

  img {
    width: 7px;
    margin: auto;
    height: 100%;
  }
`;

export const ContainerFooter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding: 17px 16px 25px 20px;
  border-bottom: 1px solid #c4c4c4;
  .ContainSpan {
    display: flex;
    flex-direction: column;
    padding-right: 10px;
    span {
      font-family: Raleway;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: -0.015em;

      /* Gray S5 */

      color: #503d66;
    }
  }
`;

export const ContainerFooters = styled.div`
  display: flex;
  flex: 2;
  height: 60px;
  box-sizing: border-box;
  justify-content: space-between;
  /* Gray S2 */
  align-items: center;
  padding: 5px 0;
  background: #f0eef2;
  border-radius: 7px;
  align-items: center;
  div {
    border-right: 1px solid #d4d1d7;
    justify-content: center;
    width: 100%;
  }
  div:last-child {
    border: none;
  }
`;

export const NumberTitle = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 11px;
  /* identical to box height, or 100% */

  text-align: center;
  letter-spacing: -0.015em;

  /* Gray S4 */

  color: #817393;
`;

export const ContainerNumber = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  /* identical to box height, or 150% */

  text-align: center;
  letter-spacing: -0.015em;

  /* Gray S5 */

  color: #503d66;
`;

export const HidedTr = styled.tr`
  display: block !important;
  overflow-y: auto;
  padding: 14px 0;
  box-sizing: border-box;
  z-index: 9;
`;
