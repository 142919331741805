import { Button } from "contentoh-components-library";
import { FormContainer } from "../styles";

const InOffice = ({ sede, baseDirs }) => {
  return (
    <FormContainer>
      <p className="subTitle">Dirección de nuestras oficinas</p>
      <p className="mb-10">{baseDirs[sede].address}</p>
      <Button
        buttonType="default-button-general fixButton mb-10"
        onClick={() => {}}
        label={
          <>
            <span className="material-icons-outlined">local_shipping</span>
            Enviar por correo
          </>
        }
      />
      <p className="subTitle">Contacto</p>
      <p className="mb-10">
        {baseDirs[sede].cel1}
        {baseDirs[sede].cel2 ? (
          <>
            <br /> {baseDirs[sede].cel2}
          </>
        ) : null}
      </p>
    </FormContainer>
  );
};

export default InOffice;
