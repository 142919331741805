import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import Plan from "./planSection/index";

// SVG assets
import location from "../../../assets/IconComponents/locationWhite.svg";
import edit from "../../../assets/IconComponents/edit.svg";
import companyIcon from "../../../assets/IconComponents/company.svg";
import documentIcon from "../../../assets/IconComponents/document.svg";

const MainContainer = styled.div`
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
`;

const Section = styled.div`
  width: 95%;
  border-bottom: 1px solid #e2e2e2;
  padding: 14px 0px;
  p {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #707070;
  }
`;

const Header = styled(Section)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  p {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #503d66;
  }
`;

const AboutCompany = styled(Section)`
  padding: 13px 8px;
  box-sizing: border-box;
  .title {
    font-weight: bold;
    color: #503d66;
  }
`;

export const BasicData = styled(Section)`
  div {
    height: 28px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    .title,
    .item {
      width: 35%;
      font-family: "Avenir Next";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.015em;
      color: #503d66;
      & + p {
        color: #817393;
      }
    }

    h4 {
      font-family: "Avenir Next";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: -0.015em;
      color: #503d66;
    }

    figure {
      margin: 0;
      & + * {
        margin-left: 13px;
      }
    }
  }

  #inputSelect div {
    display: flex;
    flex-direction: column;
    left: -15px;
    height: auto;
  }
`;

const Icon = styled.figure`
  margin: 0 10px;
  align-items: center;
`;

const TitleIcon = styled.figure`
  margin: 0 15px 0 0;
  align-items: center;
`;

export const Textarea = styled.textarea`
  width: 100%;
  height: 28px;
  padding: 0 7px;
  display: felx;
  align-items: center;
  border: 1px solid #d4d1d7;
  padding: 7px;
  box-sizing: border-box;
  border-radius: 6px;
  ::placeholder {
    font-family: "Avenir Next";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #d4d1d7;
  }
`;

export const Input = styled.input`
  width: 190px;
  height: 28px;
  margin-bottom: 4;
  display: flex;
  align-items: center;
  border: 1px solid #d4d1d7;
  padding: 7px;
  box-sizing: border-box;
  border-radius: 6px;
  ::placeholder {
    font-family: "Avenir Next";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #d4d1d7;
  }
`;

export const SubmmitButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 60px;
  > p {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 34px;
    background: #d43594;
    border-radius: 30px;
    color: #fff;
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`;

const ContainerPlan = styled.section`
  display: flex;
  flex-direction:column;
  width: 100%;
`;

export default function CompanyData(props) {
  const [canEdit, setCanEdit] = useState(false);
  const [company, setCompany] = useState(
    sessionStorage.getItem("company")
      ? JSON.parse(sessionStorage.getItem("company"))
      : {}
  );

  const { history, child } = props;
  const getCompany = async () => {
    const response = await axios.get(process.env.REACT_APP_USER_ENDPOINT, {
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    });
    sessionStorage.setItem(
      "company",
      JSON.stringify(JSON.parse(response.data.body).data[1])
    );
    setCompany(JSON.parse(response.data.body).data[1]);
  };

  useEffect(() => getCompany(), []);

  const onSubmit = async () => {
    let data = {
      about_company: document.getElementById("about_company").value,
      adress: document.getElementById("address").value,
      email: document.getElementById("email").value,
      social_link: document.getElementById("social_link").value,
      telephone: document.getElementById("telephone").value,
      web_site: document.getElementById("web_site").value,
      zip_code: document.getElementById("zip_code").value,
    };
    try {
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_USER_ENDPOINT}?boolCompany=1`,
        data: data,
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      });
      setCanEdit(false);
      getCompany();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
     <ContainerPlan>
          <Plan history={history} hasChild={child !== null} />
      </ContainerPlan>
      <MainContainer>
        <Header>
          <div>
            <TitleIcon>
              <img src={companyIcon} alt="Section icon" />
            </TitleIcon>
            <p>Información de la empresa</p>
          </div>
          {!canEdit && (
            <TitleIcon onClick={() => setCanEdit(!canEdit)}>
              <img src={edit} alt="Edit" />
            </TitleIcon>
          )}
        </Header>
        <AboutCompany>
          <p className="title">Acerca de la empresa.</p>
          {canEdit ? (
            <Textarea
              id="about_company"
              placeholder="Acerca de la empresa"
              defaultValue={company.about_company ? company.about_company : ""}
            />
          ) : (
            <p>{company.about_company ? company.about_company : "---"}</p>
          )}
        </AboutCompany>
        <BasicData>
          <div>
            <p className="item">Nombre comercial</p>
            <p>{company.trade_name ? `${company.trade_name}` : "---"}</p>
          </div>
          <div>
            <p className="item">Fecha de fundación</p>
            <p>{"---"}</p>
          </div>
        </BasicData>
        <BasicData>
          <div>
            <TitleIcon>
              <img src={location} alt="Location" />
            </TitleIcon>
            <h4>Ubicación</h4>
          </div>
          <div>
            <p className="item">Dirección</p>
            {canEdit ? (
              <Input
                id="address"
                placeholder="Dirección"
                defaultValue={company.adress ? company.adress : ""}
              />
            ) : (
              <p>{company.adress ? company.adress : "---"}</p>
            )}
          </div>
          <div>
            <p className="item">Código postal</p>
            {canEdit ? (
              <Input
                id="zip_code"
                placeholder="Código postal"
                defaultValue={company.zip_code ? company.zip_code : ""}
              />
            ) : (
              <p>{company.zip_code ? company.zip_code : "---"}</p>
            )}
          </div>
          <div>
            <p className="item">Teléfono</p>
            {canEdit ? (
              <Input
                id="telephone"
                placeholder="Teléfono"
                defaultValue={company.telephone ? company.telephone : ""}
              />
            ) : (
              <p>{company.telephone ? company.telephone : "---"}</p>
            )}
          </div>
          <div>
            <p className="item">Correo electrónico</p>
            {canEdit ? (
              <Input
                id="email"
                placeholder="Correo eletrónico"
                defaultValue={company.email ? company.email : ""}
              />
            ) : (
              <p>{company.email ? company.email : "---"}</p>
            )}
          </div>
        </BasicData>
        <BasicData>
          <div>
            <p className="item">Sitio web</p>
            {canEdit ? (
              <Input
                id="web_site"
                placeholder="Página web"
                defaultValue={company.web_site ? company.web_site : ""}
              />
            ) : (
              <p>{company.web_site ? company.web_site : "---"}</p>
            )}
          </div>
        </BasicData>
        <BasicData>
          <div>
            <div className="item">
              <Icon>
                <img src={documentIcon} alt="Location" />
              </Icon>
              <p>LinkedIn</p>
            </div>
            {canEdit ? (
              <Input
                id="social_link"
                placeholder="Peril de LinkedIn"
                defaultValue={company.social_link ? company.social_link : ""}
              />
            ) : (
              <p>{company.social_link ? company.social_link : "---"}</p>
            )}
          </div>
        </BasicData>
        {canEdit && (
          <BasicData>
            <SubmmitButton>
              <p onClick={() => onSubmit()}>Guardar cambios</p>
            </SubmmitButton>
          </BasicData>
        )}
      </MainContainer>
    </>
    
  );
}
