import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";

// components
import GenericModal from "../../general/GenericModal";
import { InviteUser } from "./InviteUser";
import Loading from "../../general/loading";
import { CheckboxCustom } from "../../customInputs/checkboxCustom";

// SVG assets
import trash from "../../../assets/IconComponents/trash.svg";
import face from "../../../assets/IconComponents/face.svg";
import searchIcon from "../../../assets/IconComponents/search.svg";

const MainContainer = styled.div`
  box-sizing: border-box;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > .filters-container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .filters {
      display: flex;
      align-items: center;

      .search-user-input {
        display: flex;
        align-items: center;
        border: 1px solid #f0f0f0;
        border-radius: 50px;
        padding: 9px;

        img {
          width: 14px;
          height: 14px;
          & + * {
            margin-left: 15px;
          }
        }
        input {
          font-family: Roboto;
          font-size: 12px;
          outline: none;
          border: none;
        }
      }

      & > * + * {
        margin-left: 20px;
      }
    }

    .buttons-container {
      display: flex;

      button {
        background-color: transparent;

        &.trash-icon {
          img {
            width: 32px;
          }
        }

        &.circular-button {
          border-radius: 50%;
          overflow: hidden;
          width: 34px;
          height: 34px;
          border: 1px solid #f0f0f0;

          & + * {
            margin-left: 5px;
          }
        }

        &.add-users-button {
          background-color: #e33aa9;
          border-radius: 50px;
          color: #ffffff;
          font-family: Roboto;
          font-size: 12px;
          padding: 10px 20px;
          border: none;
        }
      }
    }
  }
`;

const Section = styled.div`
  width: 95%;
  border-bottom: 1px solid #e2e2e2;
  padding: 24px 0px;
  :last-child {
    border-bottom: 0px;
  }
  p {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #707070;
  }
`;

export const BasicData = styled(Section)`
  font-family: "Avenir Next";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.015em;
  color: #817393;
  width: 100%;

  .text_center {
    text-align: center;
  }
  .space {
    justify-content: space-between;
  }
  .align_end {
    justify-content: flex-end;
  }
  .margin_row {
    .col.chk-cont {
      display: flex;
      justify-content: flex-start;
      & > div {
        & + * {
          margin-left: 10px;
        }
      }
    }
    .col + * {
      margin-left: 10px;
    }
  }
  .column {
    display: flex;
    flex-direction: column;

    p {
      color: #817393;

      & + * {
        margin-top: 5px;
      }
    }
  }
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    .col {
      flex: 1;
      overflow: hidden;
      align-items: center;

      figure {
        & + * {
          margin-left: 10px;
        }
      }

      &:first-child {
        padding: 10px;
        align-items: center;
        overflow: inherit;
      }
    }

    &.header {
      background-color: #f0f0f0;
      height: 34px;

      .col {
        color: #262626;
        text-align: left;
      }
    }
  }
  .center {
    justify-content: center;
    align-items: center;
  }
  input[type="email"] {
    width: 360px;
    height: 30px;
    border: 1px solid #f0eef2;
    box-sizing: border-box;
    border-radius: 7px;
    margin-right: 20px;
    ::placeholder {
      color: #d4d1d7;
    }
  }
  input[type="checkbox"]:checked {
    width: 75px;
    background-color: #e33aa9;
    color: green;
  }
  select {
    width: 116px;
    height: 30px;
    padding: 0 8px;
    border: 1px solid #e33aa9;
    border-radius: 7px;
    box-sizing: border-box;
    background: #fff;
  }
  button {
    width: 130px;
    height: 30px;
    font-size: 14px;
    line-height: 19px;
    color: #e33aa9;
    background: #fff;
    border: 1px solid #e33aa9;
    box-sizing: border-box;
    border-radius: 30px;
    :hover {
      cursor: pointer;
    }
  }
`;

export default function InviteProviders(props) {
  const [listUsers, setListUsers] = useState([]);
  const [listUsersFilter, setListUsersFilter] = useState([]);
  const [show, setShow] = useState(false);
  const [usersSelected, setUsersSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [upgradePlanRedirect, setUpgradePlanRedirect] = useState(false);
  const [modalData, setModalData] = useState({});
  const [showInvite, setShowInvite] = useState(false);
  const [userName, setUserName] = useState("");

  const getUsers = async () => {
    setLoading(true);
    const response = await axios.get(
      `${process.env.REACT_APP_PENDING_INVITATIONS}`,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    const collaborators = JSON.parse(response.data.body).data;
    setListUsers(
      props.tabActive === "Proveedores activos"
        ? collaborators.usersFinanced
        : collaborators.mailsPendings
    );
    setListUsersFilter(
      props.tabActive === "Proveedores activos"
        ? collaborators.usersFinanced
        : collaborators.mailsPendings
    );
    sessionStorage.setItem("collaborators", JSON.stringify(collaborators));
  };

  const searchUser = (userName = "") => {
    const tempArray = listUsers.slice();
    if (userName.length > 0) {
      setListUsersFilter(
        props.tabActive === "Proveedores activos"
          ? tempArray.filter((item) =>
              item?.trade_name?.toLowerCase().includes(userName.toLowerCase())
            )
          : tempArray.filter((item) =>
              item?.guest_user_name
                ?.toLowerCase()
                .includes(userName.toLowerCase())
            )
      );
    } else {
      setListUsersFilter(tempArray);
    }
  };

  const loadData = async () => {
    await getUsers();
    setLoading(false);
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tabActive]);

  useEffect(() => {
    if (upgradePlanRedirect) {
      props.history.push({ pathname: `/upgradeplan` });
      setUpgradePlanRedirect(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upgradePlanRedirect]);

  const deleteCollaborator = async () => {
    try {
      const responseArray = [];
      usersSelected.forEach((userId) => {
        responseArray.push(
          axios({
            method: "delete",
            url: process.env.REACT_APP_USER_ENDPOINT,
            data: { idUser: userId },
            headers: {
              Authorization: sessionStorage.getItem("jwt"),
            },
          })
        );
      });
      await Promise.all(responseArray);
      getUsers();
      setModalData({
        img: face,
        message: `Se eliminó correctamente`,
        button2: { name: "Aceptar", action: () => setShow(false) },
      });
      setShow(true);
    } catch (err) {
      console.log(err);
    }
  };

  const chkOnChange = (e, id) => {
    const tempArray = usersSelected.slice();
    if (e.target.checked) {
      tempArray.push(id);
      setUsersSelected(tempArray);
    } else {
      setUsersSelected(tempArray.filter((item) => item !== id));
    }
  };

  const onChangeName = (e) => {
    setUserName(e.target.value);
    searchUser(e.target.value);
  };

  return loading ? (
    <Loading />
  ) : (
    <MainContainer>
      <div className="filters-container">
        <div className="filters">
          <div className="search-user-input">
            <img src={searchIcon} alt="search-icon" />
            <input
              type="text"
              placeholder="Buscar usuarios"
              value={userName}
              onChange={onChangeName}
            />
          </div>
        </div>
        <div className="buttons-container">
          {props.user.is_retailer !== 1 && (
            <button
              className="trash-icon circular-button"
              onClick={() => {
                if (usersSelected.length > 0) {
                  setModalData({
                    message: `¿Estás seguro que quieres eliminar estos usuarios?`,
                    button1: {
                      name: "Cancelar",
                      action: () => setShow(false),
                    },
                    button2: {
                      name: "Confirmar",
                      action: () => {
                        setShow(false);
                        deleteCollaborator();
                      },
                    },
                  });
                  setShow(true);
                }
              }}
            >
              <img src={trash} alt="trash icon" />
            </button>
          )}
          <button
            className="add-users-button"
            onClick={() => setShowInvite(true)}
          >
            Invitar Proveedor
          </button>
        </div>
      </div>

      <BasicData>
        <div className="row header">
          <p className="col">Nombre</p>
          <p className="col text_center">Correo</p>
        </div>
        {listUsersFilter.length > 0 &&
          listUsersFilter.map((e, i) => (
            <div className="row margin_row" key={i}>
              <div className="col chk-cont">
                <CheckboxCustom
                  id={"chk-" + i}
                  htmlFor={"chk-" + i}
                  onChange={(evt) => chkOnChange(evt, e.id_user || e.id)}
                />
                <p>{`${e.trade_name || e.guest_user_name}` || "---"}</p>
              </div>
              <p className="col">{e.email || e.guest_user_email || "---"}</p>
            </div>
          ))}
      </BasicData>
      {show && <GenericModal close={() => setShow(false)} {...modalData} />}
      {showInvite && (
        <InviteUser
          setShowInvite={setShowInvite}
          inviteProviders={true}
          loadData={loadData}
        />
      )}
    </MainContainer>
  );
}
