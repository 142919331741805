import React from "react";
import {
  FormPanel,
  FormContainer,
  StepsRow,
  StepDiv,
  ItemPaso,
  Row,
} from "./styles";
import contentoh from "../../assets/IconComponents/contentoh.svg";

export default function BaseForm(props) {
  const { paso, instruccion, PasoRegistro, setPaso } = props;
  return (
    <FormPanel>
      <div className="login-container">
        <figure>
          <img src={contentoh} alt="" />
        </figure>
        <FormContainer paso={paso}>
          <h3>{instruccion}</h3>
          <form id="formRegister">
            {PasoRegistro}
            {paso <= 3 && (
              <StepsRow>
                <ItemPaso>
                  <StepDiv paso={1} pasoActual={paso}></StepDiv>
                  {paso >= 1 && <p>Paso 1</p>}
                </ItemPaso>
                <ItemPaso>
                  <StepDiv paso={2} pasoActual={paso}></StepDiv>
                  {paso >= 2 && <p>Paso 2</p>}
                </ItemPaso>
                <ItemPaso>
                  <StepDiv paso={3} pasoActual={paso}></StepDiv>
                  {paso >= 3 && <p>Paso 3</p>}
                </ItemPaso>
              </StepsRow>
            )}
          </form>
        </FormContainer>
        {paso <= 3 && (
          <Row>
            <label
              className="loginLabel"
              onClick={() => {
                setPaso(7);
                sessionStorage.removeItem("nuevoRegistro");
                sessionStorage.removeItem("countryCode");
              }}
            >
              ¿Ya tienes una cuenta? Inicia sesión
            </label>
          </Row>
        )}
      </div>
    </FormPanel>
  );
}
