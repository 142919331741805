import { useEffect, useState } from "react";

const useDescripionPage = (desc) => {
  const [description, setDescription] = useState(desc ? desc : "");

  useEffect(() => {
    sessionStorage.setItem("description", description);
  }, [description]);

  return [description, setDescription];
};

export { useDescripionPage };
