import React from "react";
import { AssignToStyles } from "./styles";
import AvatarProd from "../../../assets/imagesFake/AvatarProduct.svg";
import CloseButton from "../../../assets/IconComponents/pinkAssignToCloseButton.svg";

export default function AssignTo(props) {
  return (
    <AssignToStyles showAssign={props.showAssign}>
      <div className="search-container">
        <div className="circle-image">
          <img src={AvatarProd} alt="" />
        </div>
        <input type="text" />
        <button
          className="close-button"
          onClick={() => props.setShowAssign(false)}
        >
          <img src={CloseButton} alt="" />
        </button>
      </div>
      <div className="search-results">
        <ul>
          <li key={1}>
            <div className="result-item">
              <div className="result-img">
                <img src={AvatarProd} alt="" />
              </div>
              <p>Jenny Wilson</p>
            </div>
          </li>
          <li key={2}>
            <div className="result-item">
              <div className="result-img">
                <img src={AvatarProd} alt="" />
              </div>
              <p>Jenny Wilson</p>
            </div>
          </li>
          <li key={3}>
            <div className="result-item">
              <div className="result-img">
                <img src={AvatarProd} alt="" />
              </div>
              <p>Jenny Wilson</p>
            </div>
          </li>
          <li key={4}>
            <div className="result-item">
              <div className="result-img">
                <img src={AvatarProd} alt="" />
              </div>
              <p>Jenny Wilson</p>
            </div>
          </li>
        </ul>
      </div>
    </AssignToStyles>
  );
}
