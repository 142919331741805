import React from "react";
import ValIcon from "../../../assets/IconComponents/ValIcon.svg";
import InvalidIcon from "../../../assets/IconComponents/InvalidIcon.svg";
import PendingIcon from "../../../assets/IconComponents/PendingIcon.svg";
import { MainContainer, IconContainer } from "./styles";

const ValidatedProduct = ({ status }) => {
  const retailerStates = {
    ACA: ValIcon,
    RCA: InvalidIcon,
    AP: PendingIcon,
    NS: PendingIcon,
  };
  const providerStates = {
    AP: ValIcon,
    RP: InvalidIcon,
    AA: PendingIcon,
    NS: PendingIcon,
  };

  const getIcon = () => {
    const isUserRetailer =
      JSON.parse(sessionStorage.getItem("user")).is_retailer === 1;

    if (isUserRetailer) {
      return retailerStates[status];
    } else {
      return providerStates[status];
    }
  };

  return (
    <MainContainer>
      <IconContainer>
        <img src={getIcon() ?? PendingIcon} alt="" />
      </IconContainer>
    </MainContainer>
  );
};

export default ValidatedProduct;
