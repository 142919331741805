import axios from "axios";

export async function getData(category, idArticle, endpoint, version) {
  try {
    switch (endpoint) {
      case "Descriptions":
        const descriptions = await axios.get(
          `${process.env.REACT_APP_ARTICLE_DATA_DESCRIPTION_ENDPOINT}?idCategory=${category}&articleId=${idArticle}&version=${version}`,
          {
            headers: {
              Authorization: sessionStorage.getItem("jwt"),
            },
          }
        );
        return Object.values(JSON.parse(descriptions.data.body).data);
      case "RetailerData":
        const retailersResponse = await axios.post(
          `${process.env.REACT_APP_ARTICLE_DATA_DATASHEET_ENDPOINT}?idCategory=${category}&articleId=${idArticle}&version=${version}`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: sessionStorage.getItem("jwt"),
            },
          }
        );
        return Object.values(JSON.parse(retailersResponse.data.body).data);
      case "ImagesData":
        const imagesResponse = await axios.post(
          `${process.env.REACT_APP_ARTICLE_DATA_IMAGES_ENDPOINT}?articleId=${idArticle}&version=${version}`,
          {
            category: category,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: sessionStorage.getItem("jwt"),
            },
          }
        );
        return JSON.parse(imagesResponse.data.body).data;
      default:
        break;
    }
  } catch (err) {
    console.log(err, `Unable to retrieve ${endpoint} data`);
    return [];
  }
}

export default getData;
