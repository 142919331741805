import { Tooltip } from "contentoh-components-library";
export const icons = (key) => {
  const elements = {
    datasheet: {
      name: "Ficha Técnica",
      componet: (
        <span key="datasheet" className="material-icons small">
          &#xf8ee;
        </span>
      ),
    },
    description: {
      name: "Descripción",
      componet: (
        <span key="description" className="material-icons small">
          &#xef42;
        </span>
      ),
    },
    images: {
      name: "Imágenes",
      componet: (
        <span key="images" className="material-icons small">
          &#xe3f4;
        </span>
      ),
    },
    translate: {
      name: "Traducción de ficha técnica",
      componet: (
        <span key="datasheet" className="material-icons small">
          &#xe8e2;
        </span>
      ),
    },
    build: {
      name: "Construcción de manuales",
      componet: (
        <span key="build" className="material-icons small">
          &#xe1bd;
        </span>
      ),
    },
    search: {
      name: "Búsqueda",
      componet: (
        <span key="search" className="material-icons small">
          &#xe8b6;
        </span>
      ),
    },
  };
  return (
    <Tooltip
      componentTooltip={elements[key].name}
      children={elements[key].componet}
      classNameTooltip="container-tooltip"
      position="rightCenter"
      addArrow={false}
      followCursor
    />
  );
};
