import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

export const RowStatus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  > div {
    background-color: ${(props) => props.bg};
    font-family: Lato;
    width: ${(props) => (props.width ? `${props.width}px` : "50%")};
    height: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.015em;
    color: #ffffff;
    border-radius: 3px;
    text-align: center;
  }
`;

export const StatusTab = styled.div`
  position: absolute;
  top: 10px;
  right: 80%;
  z-index: 2;
  width: 320px;
  box-sizing: border-box;
  background: #281f33;
  padding: 8px;
  white-space: normal;
  font-family: Avenir Next;
  font-style: normal;
  border-radius: 5px;
  > div {
    background-color: #503d66;
    margin-bottom: 8px;
    color: #f0eef2;
    box-sizing: border-box;
    padding: 10px;
    text-align: left;
    > span {
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
    }
    > p {
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
    }
  }
  > ul {
    color: #f0eef2;
  }
`;

const background = (status) => {
  switch (status) {
    case "AS":
    case "CA":
    case "IE":
      return "#ed9a4d";
    case "S/OT":
      return "#000";
    case "R":
    case "PA":
      return "#e57432";
    case "AA":
    case "AP":
    case "ACA":
      return "#18a0fb";
    case "RP":
    case "RCA":
    case "RC":
    case "RA":
      return "#D74DED";
    case "Ex":
      return "#71de56";
    default:
      return "#d4d1d7";
  }
};

export const Item = styled.li`
  display: flex;
  width: 100%;
  font-weight: 500;
  font-size: 13px;
  line-height: 21px;
  margin-bottom: 7px;
  > div {
    height: 20px;
    width: 50px;
    flex-direction: row;
    margin-right: 5px;
    background: ${({ status }) => background(status)};
    border-radius: 3px;
    text-align: center;
  }
`;
