export const initError = {
  Error: true,
  message: "Hubo un problema al obtener las tareas pendientes",
};
export const noResultsError = {
  Error: true,
  message: "No se encontraron tareas con esas características",
};
export const noResultsFromDB = {
  Error: true,
  message: "No se encontraron tareas pendientes para ti",
};
export const filterError = {
  Error: true,
  message: "Hubo un problema al filtrar por esa última caracteristica",
};
export const noError = {};
