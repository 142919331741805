import React, { useState, useEffect } from "react";
import { MainContainer, ErrorMessage } from "./styles";
import { FilterInput } from "../../filterInput";
import { filterProducts } from "./utils.js/filterFunctions";
import { initTasks } from "./utils.js/dataFunctions";
import { updateFilters } from "./utils.js/filterFunctions";
import RetailerTasksTable from "./Table/RetailerTasksTable";
import Loading from "../../general/loading";
import { filterError } from "../../_utils/errorTemplates";
import { Redirect } from "react-router-dom";

const RetailerTasks = () => {
  const [taskList, setTaskList] = useState([]);
  const [originalTaskList, setOriginalTaskList] = useState([]);
  const [error, setError] = useState({});
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedArticles, setSelectedArticles] = useState([]);
  const [redirect, setRedirect] = useState(null);
  const [redirectMultipleEdition, setRedirectMultipleEdition] = useState(false);
  useEffect(() => {
    try {
      initTasks(setError, setTaskList, setOriginalTaskList, setLoading);
    } catch (err) {
      console.log("Unable to set initial tasks");
      setLoading(false);
    }
  }, []);

  const editProducts = () => {
    if (selectedArticles.length > 0) {
      let orderedArray = selectedArticles.sort(
        (a, b) => a.article.id_article - b.article.id_article
      );
      sessionStorage.setItem(
        "multipleEditionList",
        JSON.stringify(orderedArray)
      );
      sessionStorage.setItem("task", true);
      setRedirectMultipleEdition(true);
    }
  };

  useEffect(() => {
    try {
      updateFilters(originalTaskList, setFilters);
    } catch (err) {
      console.log("unable to update new filter options", err);
    }
  }, [originalTaskList]);

  const filterInputFunct = (filtersObject) => {
    try {
      filterProducts(filtersObject, setTaskList, originalTaskList);
    } catch (err) {
      console.log("Unable to filter products");
      setTaskList(originalTaskList);
      setError(filterError);
    }
  };

  return (
    <MainContainer>
      <FilterInput
        taskFilter
        total={originalTaskList.length}
        charged={taskList.length}
        filtersArray={filters.filtersOptions}
        filterArray={filters.filtersAvailable}
        filterInputFunct={filterInputFunct}
        editProducts={editProducts}
      />
      {loading && <Loading />}
      {!loading && !error.Error && (
        <RetailerTasksTable
          articlesList={taskList}
          eventFunctions={{
            selectedArticles,
            setSelectedArticles,
            setTaskList,
            setRedirect,
          }}
        />
      )}
      {error.Error && <ErrorMessage>{error.message}</ErrorMessage>}
      {redirect && (
        <Redirect
          to={{
            pathname: redirect.path,
            state: { origin: redirect.origin },
          }}
        />
      )}
      {redirectMultipleEdition && (
        <Redirect
          to={{
            pathname: `/products/multipleEdition`,
          }}
        />
      )}
    </MainContainer>
  );
};

export default RetailerTasks;
