import {
  RetailerCatalog,
  //   UserCatalog,
  //   Status,
} from "contentoh-components-library";
import moment from "moment";

export const renderColumns = () => {
  const columnsArray = [
    { name: "UPC", flex: 1, minWidth: 100 },
    { name: "Nombre", flex: 2, minWidth: 200 },
    { name: "Categoría", flex: 2, minWidth: 200 },
    { name: "Cadenas", flex: 1, minWidth: 100 },
    { name: "Porcentaje", flex: 1, minWidth: 60 },
    { name: "Versión", flex: 1, minWidth: 60 },
    { name: "Fecha", flex: 1, minWidth: 60 },
  ];

  return columnsArray;
};

export const getProductsToTable = (products = [], images) => {
  const productsTableArray = [];

  products.forEach((product) => {
    const { upc, version, percentage, timestamp, retailersAvailable } = product;
    const id = product?.id_article + "-" + upc;
    const imgArray = images[product?.id_article + "-" + version];
    const element = {
      id,
      cols: [
        { name: upc || "-", flex: 1, minWidth: 100 },
        { name: <p>{product?.name}</p> || "-", flex: 1, minWidth: 200 },
        { name: <p>{product?.categoryName}</p> || "-", flex: 2, minWidth: 200 },
        {
          name:
            (
              <RetailerCatalog
                id={"retailers-" + id}
                limit={2}
                article={product}
                retailers={retailersAvailable}
              />
            ) || "-",
          flex: 1,
          minWidth: 100,
        },
        { name: percentage, flex: 1, minWidth: 100 },
        { name: version, flex: 1, minWidth: 100 },
        {
          name: moment(timestamp).format("DD/MM/YYYY"),
          flex: 1,
          minWidth: 100,
        },
      ],
      gridElement: {
        images: imgArray,
        info: [
          { title: "name", value: product?.name || "-" },
          { title: "category", value: product?.categoryName || "-" },
          { title: "order", value: upc || "-" },
        ],
        status: [{ title: "Porcentaje", percent: percentage || "-" }],
        catalogs: {
          leftSide:
            (
              <RetailerCatalog
                id={"retailers-" + id}
                limit={2}
                article={product}
                retailers={retailersAvailable}
              />
            ) || "-",
        },
      },
      showBottom: true,
      product,
    };

    productsTableArray.push(element);
  });

  return productsTableArray;
};

export const chkOnChange = (e, product, state, setState) => {
  const tempArray = state.slice();
  if (e.target.checked) {
    tempArray.push(product);
    setState(tempArray);
  } else {
    const filteredArray = tempArray.filter(
      (item) =>
        !(item.upc === product.upc && item.id_article === product.id_article)
    );
    setState(filteredArray);
  }
};

export const chkChecked = (product, state) => {
  const productSelected = [];
  state.forEach((prod) => {
    prod.upc === product.upc &&
      prod.id_article === product.id_article &&
      productSelected.push(prod);
  });
  return productSelected?.length > 0;
};

export const checkAll = (e, setState, products) => {
  const chks = document.querySelectorAll(".chk-item > input");
  e.target.checked ? setState(products) : setState([]);
  chks?.forEach((chk) => (chk.checked = e.target.checked));
};

export const chkCheckedAll = (selected, products) => {
  return selected.length === products.length;
};
