//custom components
import { NavContainer } from "./styles";

//images
import closeButton from "../../../../assets/IconComponents/IconCancel.svg";

export const NavigationBar = (props) => {
  const setNavSelected = (path) => {
    props.setNavSelected(path);
  };

  return (
    <NavContainer>
      <div className="buttons-container">
        <div className="button-container">
          <input
            id="data-sheets"
            type="radio"
            name="radio-group"
            defaultChecked={true}
            onChange={() => setNavSelected("datasheet")}
          />
          <label htmlFor="data-sheets">Fichas técnicas</label>
        </div>
        <div className="button-container">
          <input
            id="descriptions"
            type="radio"
            name="radio-group"
            onChange={() => setNavSelected("description")}
          />
          <label htmlFor="descriptions">Descripciones</label>
        </div>
        <div className="button-container">
          <input
            id="images"
            type="radio"
            name="radio-group"
            onChange={() => setNavSelected("images")}
          />
          <label htmlFor="images">Imágenes</label>
        </div>
      </div>

      <div className="btn-close-container">
        <button
          onClick={() => {
            props.setExitModal(true);
          }}
        >
          <img src={closeButton} alt="Close button" />
        </button>
      </div>
    </NavContainer>
  );
};
