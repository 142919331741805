import { useState, useEffect } from "react";
import { getGraphicColor, getMetricsProducts } from "../../../_utils/helper";

import {
  Container,
  Title,
  Subtitle,
  ContainerChart,
} from "../../../products/productsGeneral/styles";
import ChartCircle from "../../../general/charts/index";
import CardPrice from "../../../general/cardPrice";

export const MetricsBar = () => {
  const retailer = sessionStorage.getItem("retailerSelected")
    ? JSON.parse(sessionStorage.getItem("retailerSelected"))
    : null;
  const [loading, setLoading] = useState(true);
  const [percentMetricsArray, setPercentMetricsArray] = useState([]);
  const [productsMetricsCounter, setProductsMetricsCounter] = useState([]);
  const [productsListCounter, setProductListCounter] = useState(0);

  const percent = (key) => {
    let metrics = productsMetricsCounter[key];
    let percentage = (metrics / productsListCounter) * 100;
    percentage = percentage.toString().includes(".")
      ? percentage.toFixed(2)
      : percentage;
    return percentage ? percentage : 0;
  };

  const getMetrics = async () => {
    const response = await getMetricsProducts();
    let percentArray = response[2];
    let colorPercentArray = [];

    Object.keys(percentArray).forEach((key) => {
      let element = {
        color: "",
        value: 0,
      };

      element.color = getGraphicColor(key);
      element.value = percentArray[key];
      colorPercentArray.push(element);
    });

    colorPercentArray.sort((a, b) => (a.value > b.value ? 1 : -1));

    setProductsMetricsCounter(response[0]);
    setProductListCounter(response[1]);
    setPercentMetricsArray(colorPercentArray);
  };

  useEffect(() => {
    getMetrics();
    setLoading(false);
  }, []);

  return (
    <Container className="title-container">
      <Title>{retailer ? retailer.name : "Lista de productos"}</Title>
      <Subtitle>
        Visualiza de forma general todos los productos, da click sobre alguno
        para ver el detalle o selecciona varios y trabaja en multiedición.
      </Subtitle>
    </Container>
  );
};
