import React, { useEffect, useState } from "react";
import {
  MainContainer,
  SectionOne,
  Container75,
  Container25,
  Flex,
  FlexCard,
  SectionTwo,
  SectionThree,
} from "./styles";

export const ThdDashboard = () => {
  return (
    <MainContainer>
      <div
        style={{
          position: "relative",
          paddingBottom: "65%",
          height: 0,
          overflow: "hidden",
        }}
      >
        <iframe
          src="https://contentoh.retool.com/embedded/public/cd9154c5-8385-4b46-83af-f693489a742a"
          title="Iframe Ajustable"
          frameBorder="0"
          style={{ position: "absolute", width: "100%", height: "100%" }}
          allowFullScreen
        ></iframe>
      </div>
    </MainContainer>
  );
};
