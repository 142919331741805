export const changeHeader = (location) => {
  const data = { title: "", info: "" };
  const regExpProduct =
    /\/products\/([0-9]+)\/(datasheet|description|images|final-revision)/;
  const regExpTareas = /\/tasks\/([0-9]+)/;
  let matchProduct = location.toLowerCase().match(regExpProduct);
  let matchTarea = location.toLowerCase().match(regExpTareas);
  matchProduct = matchProduct === null ? "" : matchProduct[0];
  matchTarea = matchTarea === null ? "" : matchTarea[0];
  switch (location.toLowerCase()) {
    case "/upgradeplan":
      const planName = JSON.parse(sessionStorage.getItem("user")).membership
        .name;
      data.title = "Mi cuenta";
      data.info = planName;
      break;
    case "/acquired":
      data.title = "Adquiridos";
      break;
    case "/products":
      data.title = "Productos";
      data.info = "Lista de productos";
      break;
    case "/allacquired":
      data.title = "Productos";
      data.info = "Lista de productos";
      break;
    case "/merchants":
      data.title = "Productos Onboarding";
      data.info = "Lista de productos";
      break;
    case "/products/multipleedition":
      data.title = "Productos";
      data.info = `Edición de productos`;
      break;
    case "/products/multipleeditioncomponent":
      data.title = "Productos";
      data.info = `Edición de productos`;
      break;
    case "/editproducts":
    case matchProduct:
      data.title = "Productos";
      data.info = `Lista de productos > ${
        JSON.parse(sessionStorage.getItem("productSelected")).name
      }`;
      break;
    case matchTarea:
      data.title = "Tareas";
      data.info = `Lista de productos asignados`;
      break;
    case "/contentoh":
      data.title = "Content-oh!";
      data.info = "Productos";
      break;
    case "/orders":
      data.title = "Content-oh!";
      data.info = "Órdenes de trabajo";
      break;
    case "/orders/billing":
      data.title = "Content-oh!";
      data.info = "Facturación";
      break;
    case "/profile":
      data.title = "Mi Cuenta";
      data.info = "Información del usuario";
      break;
    case "/profile/company-data":
      data.title = "Mi Cuenta";
      data.info = "Información del usuario";
      break;
    case "/profile/billing-data":
      data.title = "Mi Cuenta";
      data.info = "Información del usuario";
      break;
    case "/profile/change-pass":
      data.title = "Mi Cuenta";
      data.info = "Información del usuario";
      break;
    case "/profile/add-people":
      data.title = "Mi Cuenta";
      data.info = "Información del usuario";
      break;
    case "/profile/invite-providers":
      data.title = "Mi Cuenta";
      data.info = "Información del usuario";
      break;
    case "/profile/groups-users":
      data.title = "Grupos y usuarios";
      data.info = "Información del usuario";
      break;
    case "/profile/attributes-group":
      data.title = "Atributos por grupo";
      data.info = "Información del usuario";
      break;
    case "/profile/flows":
      data.title = "Flujos de revisión";
      data.info = "Información del usuario";
      break;
    case "/addproducts":
      data.title = "Productos";
      data.info = "Proveedor";
      break;
    case "/productstoadd":
      data.title = "Productos";
      data.info = "";
      break;
    case "/providers":
      data.title = "Productos";
      data.info = "Productos solicitados a proveedor";
      break;
    case "/quotes":
      data.title = "Content-oh!";
      data.info = "Citas para entrega de producto";
      break;
    case "/checkout":
      data.title = "Checkout";
      data.info = "Proveedor";
      break;
    case "/pagotransferencia":
      data.title = "Dashboard";
      data.info = "Proveedor";
      break;
    case "/pagotarjeta":
      data.title = "Dashboard";
      data.info = "Proveedor";
      break;
    case "/retailers":
      data.title = "Cadena";
      data.info = "Overview";
      break;
    case "/dashboard":
      data.title = "Dashboard";
      data.info = JSON.parse(sessionStorage.getItem("company")).company_name;
      break;
    case "/dashboardcontentoh":
      data.title = "Content-oh!";
      data.info = "Proveedor";
      break;
    case "/thddashboard":
      data.title = "THD Dashboard";
      data.info = JSON.parse(sessionStorage.getItem("company")).company_name;
      break;
    case "/editionmultiple":
      data.title = "Productos";
      data.info = "Lista de productos > Edición multiple";
      break;
    case "/tasks":
      data.title = "Tarea";
      data.info = "Tablero de tareas";
      break;
    case "/contentohproducts":
      data.title = "Content-oh!";
      data.info = "Productos";
      break;
    case "/merchantslists":
      data.title = "Listas";
      data.info = "Listas de productos merchants";
      break;
    default:
      data.title = "Error 404";
      data.info = "";
      break;
  }
  return data;
};
