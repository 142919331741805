import styled from "styled-components";
import { grayS4, grayS5 } from "../../variables";

export const RSContainer = styled.div`
  border: none;
  box-sizing: border-box;
  background-color: #fff;
  width: 600px;
  height: 100%;
  position: fixed;
  right: 0;
  bottom: 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  z-index: 10;
  border: 1px solid #e6e6e6;

  &#request-product-modal {
    right: -600px;
    transition: right 0.5s;

    &.focus {
      right: 0;
    }
  }
`;

export const RSHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #f0eef2;
`;

export const RSShoppingBag = styled.div`
  margin-top: 4px;
`;

export const RSTextContainer = styled.div`
  flex: 0%;
  text-align: left;

  h2 {
    font-family: Raleway;
    font-weight: 500;
    font-size: 24px;
    color: #262626;
    line-height: 36px;
  }

  p {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 21px;
    color: ${grayS5};

    span {
      color: ${grayS4};
    }

    &.date {
      font-family: Avenir Next;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

export const RSCloseButton = styled.button`
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;

  img {
    width: 100%;
    height: auto;
    border-radius: 50%;
    border: 1px solid #f0f0f0;
  }
`;

export const RSShoppingTable = styled.div`
  flex: 1 0 0%;
  border-bottom: 1px solid #f0eef2;
  overflow: auto;
  width: 100%;
  padding: 20px;
  height: calc(100vh - 255px);

  table {
    width: 100%;

    tr {
      display: flex;
      justify-content: space-between;
      padding: 10px 20px;

      th,
      td {
        flex: calc(60% / 4);
        color: ${grayS5};
        font-family: Avenir Next;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 21px;
        text-align: center;

        &:first-child {
          text-align: left;
          flex: 40%;
        }

        &:last-child {
          flex: 15%;
          text-align: right;
        }

        &.retailers-images {
          padding: 0 10px;
          border-radius: 50%;
          display: flex;
          flex-direction: row-reverse;
          justify-content: center;

          img {
            cursor: pointer;
            overflow: hidden;
            width: 18px;
            border: 1px solid #fff;
            border-radius: 50%;
            background-color: #fff;
            & + * {
              margin-left: -5px;
            }
          }
        }

        &.services-icons {
          span {
            font-size: 14px;
          }
        }
      }

      td {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #707070;
        white-space: nowrap;

        &:nth-child(1) {
          overflow-x: hidden;
          text-overflow: ellipsis;
        }
      }

      border-bottom: 1px solid #f0f0f0;
    }

    thead {
      display: block;
      padding: 10px 20px;
      background-color: #f0f0f0;
      color: #262626;
      border-radius: 5px;

      tr {
        padding: 0;
        th {
          color: #262626;
          font-family: Roboto;
        }
      }
    }

    tbody {
      height: 100%;
    }
  }
`;

export const RSCheckout = styled.div`
  padding: 20px 0;
  width: 100%;

  p {
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
    font-family: "Avenir Next";
    font-size: 12px;
    line-height: 12px;
    color: #262626;

    &.description-text {
      border-top: 1px solid #f0eef2;
      border-bottom: 1px solid #f0eef2;
      padding: 8px 0;
    }
  }
`;

export const RSSummary = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 20px;
`;

export const RSSumaryItem = styled.div`
  text-align: center;
  background-color: #f7f7fc;
  flex: 1;
  border-radius: 10px;
  padding: 10px 20px;
  white-space: nowrap;

  p,
  h3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 13px;
    color: #808080;
  }

  h3 {
    font-size: 15px;
    line-height: 20px;
    color: #262626;
  }

  & + * {
    margin-left: 10px;
  }
`;

export const RSTotalPrice = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid #f7f7fc;
  padding: 0 20px;
  padding-bottom: 10px;

  p,
  h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    text-align: right;
    color: #262626;
    margin: 0;
  }

  p + h2 {
    margin-left: 10px;
  }

  h2 {
    background-color: #f7f7fc;
    padding: 10px 20px;
    border-radius: 10px;
  }

  & + * {
    margin-top: 20px;
  }
`;

export const RSCheckoutButton = styled.div`
  text-align: right;

  button {
    border: none;
    border-radius: 50px;
    font-family: "Avenir Next";
    font-weight: 500;
    font-size: 12px;
    color: #fff;
    background-color: #e33aa9;
    padding: 8px 22px;
    cursor: pointer;
  }

  & + * {
    margin-left: 10px;
  }
`;
