import styled from "styled-components";

export const retailerTasksStyle = {
  className: "table-products",
  rowClassName: "table-row",
  headerStyle: {
    display: "flex",
    justifyContent: "center",
    fontFamily: "Avenir Next",
    fontWeight: 600,
    fontSize: "12px",
    color: "#503D66",
    margin: 0,
  },
  height: "670",
  width: "1440",
  headerHeight: 20,
  rowHeight: 30,

  tableStyle: {
    overflow: "unset",
  },
  rowStyle: {
    display: "flex",
    overflow: "unset",
    textAlign: "center",
    fontFamily: "Avenir Next",
    fontSize: 13,
    width: "100%",
    color: "#817393",
  },
};

export const nameColumn = {
  className: "table-column",
  flexGrow: 1,
  maxWidth: "20%",
  minWidth: "20%",
  dataKey: "status",
  style: {
    margin: "0",
  },
};
export const categoryColumn = {
  className: "table-column",
  flexGrow: 1,
  maxWidth: "30%",
  minWidth: "30%",
  dataKey: "status",
  style: {
    margin: "0",
  },
};

export const versionColumn = {
  className: "table-column",
  flexGrow: 1,
  maxWidth: "5%",
  minWidth: "5%",
  dataKey: "status",
  style: {
    justifyContent: "center",
    margin: "0",
  },
};

export const skuColumn = {
  className: "table-column",
  flexGrow: 1,
  maxWidth: "10%",
  minWidth: "10%",
  dataKey: "upc",
  style: {
    justifyContent: "center",
    margin: "0",
    overflow: "unset",
  },
};

export const brandColumn = {
  className: "table-column",
  flexGrow: 1,
  maxWidth: "12%",
  minWidth: "12%",
  dataKey: "upc",
  style: {
    justifyContent: "center",
    margin: "0",
    overflow: "unset",
  },
};

export const orderColumn = {
  className: "table-column",
  flexGrow: 1,
  maxWidth: "8%",
  minWidth: "8%",
  dataKey: "upc",
  style: {
    justifyContent: "center",
    margin: "0",
  },
};

export const statusColumn = {
  className: "table-column",
  flexGrow: 1,
  maxWidth: "11%",
  minWidth: "11%",
  dataKey: "upc",
  style: {
    justifyContent: "center",
    margin: "0",
  },
};

export const Label = styled.label`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  input {
    width: 15px;
    height: 15px;
  }
`;
