import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Panel,
  TextContainer,
  Title,
  Button,
  ContainerGeneral,
  BoxOption,
  Figure,
  RowSpaceBetwen,
  Row,
  InputCardNombre,
  ContainerCards,
} from "./selectPlanStyles";
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import ArrowBack from "./../../assets/IconComponents/arrow_back.svg";
import IconMasterdCard from "./../../assets/IconComponents/IconMasterdCard";

const stripePromise = loadStripe(`${process.env.REACT_APP_KEY_STRIPE}`);

export const LeftChoiceProviders = () => {
  const beneficios = [
    "Crea proveedores y agregalos a tu cadena",
    "Solicita actualización a proveedores",
    "Valida los proveedores",
    "Analiza el número de productos totales",
  ];
  return (
    <Panel justifyContent="center">
      <Title color="#FFFFFF">Beneficios de cadenas</Title>
      <TextContainer>
        <ul>
          {beneficios.map((e, i) => (
            <li key={i}>{e}</li>
          ))}
        </ul>
      </TextContainer>
    </Panel>
  );
};

export const RightChoiceProviders = (props) => {
  const [plan, setPlanSe] = useState(null);
  const plans = [
    { id: 1, qty: 300 },
    { id: 2, qty: 1500 },
    { id: 3, qty: 3000 },
    { id: 4, qty: "On demand" },
  ];
  const setPlan = (option) => {
    const old = JSON.parse(sessionStorage.getItem("nuevoRegistro"));
    old.plan = option;
    setPlanSe(option);
    sessionStorage.setItem("nuevoRegistro", JSON.stringify(old));
  };
  return (
    <Panel color="#FFFFFF">
      <div>
        <Title color="#603888" fontSize="24px" lineHeight="28px">
          Elige el número de proveedores
        </Title>
        <TextContainer
          fontSize="14px"
          lineHeight="19px"
          color="#817393"
          margin="20px 0 0 0"
        >
          <p>
            Bienvido a nuestra plataforma de cadena, para continuar elige el
            número de proveedores que tiene tu cadena.
          </p>
        </TextContainer>
        <ContainerGeneral>
          {plans.map(
            (e, i) =>
              i < plans.length - 1 && (
                <BoxOption
                  key={e.id}
                  isSelected={plan?.id === e.id}
                  onClick={() => setPlan(e)}
                >
                  <p>{e.qty}</p>
                  <p>Proveedores</p>
                </BoxOption>
              )
          )}
        </ContainerGeneral>
        <ContainerGeneral>
          <BoxOption
            isSelected={plan?.id === 4}
            width="100%"
            padding="15px"
            onClick={() => setPlan({ id: 4, qty: "On demand" })}
          >
            <p>On demand</p>
            <p>Agrega proveedores a tu forma</p>
          </BoxOption>
        </ContainerGeneral>
      </div>
      <Button
        type="button"
        isActive={plan?.id}
        canDisabled
        onClick={() => plan?.id && props.setPaso(13)}
      >
        Continuar
      </Button>
    </Panel>
  );
};

export const LeftChoiceProducts = () => {
  const beneficios = [
    "Crea productos",
    "Solicita actualización",
    "Revisa, valida o rechaza productos",
    "Comparte con colaboradores",
  ];
  return (
    <Panel justifyContent="center">
      <Title color="#FFFFFF">Benficios de crear productos</Title>
      <TextContainer>
        <ul>
          {beneficios.map((e, i) => (
            <li key={i}>{e}</li>
          ))}
        </ul>
      </TextContainer>
    </Panel>
  );
};

export const RightChoiceProducts = (props) => {
  const [plan, setPlanSe] = useState(null);
  const plans = [
    { id: 1, qty: "10,000" },
    { id: 2, qty: "50,000" },
    { id: 3, qty: "+100,000" },
  ];
  const setPlan = (option) => {
    const old = JSON.parse(sessionStorage.getItem("nuevoRegistro"));
    old.planProducts = option;
    setPlanSe(option);
    sessionStorage.setItem("nuevoRegistro", JSON.stringify(old));
  };
  return (
    <Panel color="#FFFFFF">
      <Figure onClick={() => props.setPaso(12)}>
        <img src={ArrowBack} alt="" />
      </Figure>
      <div>
        <Title color="#603888" fontSize="24px" lineHeight="28px">
          Elige el número de productos que tiene tu cadena
        </Title>
        <TextContainer
          fontSize="14px"
          lineHeight="19px"
          color="#817393"
          margin="20px 0 0 0"
        >
          <p>
            Continua con un número aproximado de productos que maneja tu cadena.
          </p>
        </TextContainer>
        <ContainerGeneral>
          {plans.map((e, i) => (
            <BoxOption
              key={e.id}
              isSelected={plan?.id === e.id}
              onClick={() => setPlan(e)}
            >
              <p>{e.qty}</p>
              <p>Productos</p>
            </BoxOption>
          ))}
        </ContainerGeneral>
      </div>
      <Button
        type="button"
        isActive={plan?.id}
        canDisabled
        onClick={() => plan?.id && props.setPaso(14)}
      >
        Continuar
      </Button>
    </Panel>
  );
};

export const LeftChoiceCard = () => {
  const beneficios = [];
  return (
    <Panel justifyContent="center">
      <Title color="#FFFFFF">Que incluye la plataforma</Title>
      <TextContainer>
        <ul>
          {beneficios.map((e, i) => (
            <li key={i}>{e}</li>
          ))}
        </ul>
      </TextContainer>
    </Panel>
  );
};

export const RightChoiceCard = (props) => {
  const [cardValid, setCardValid] = useState({ CardNumber: false });
  const CheckoutForm = (props) => {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (e) => {
      e.preventDefault();
      let token = "";
      token = await stripe.createToken(elements.getElement(CardNumberElement));

      if (token.token) {
        const old = JSON.parse(sessionStorage.getItem("nuevoRegistro"));
        old.paymentMethod = token;
        sessionStorage.setItem("nuevoRegistro", JSON.stringify(old));
        props.setPaso(4);
      } else {
        console.log("Error de método de pago", token);
      }

      const { paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: document.getElementById("nameCard").value,
        },
      });

      if (!paymentMethod) {
        console.log("Error de pago");
      } else {
        const old = JSON.parse(sessionStorage.getItem("nuevoRegistro"));
        old.paymentMethod = paymentMethod;
        sessionStorage.setItem("nuevoRegistro", JSON.stringify(old));
        props.setPaso(4);
      }
    };

    return (
      <form onSubmit={handleSubmit} id="my-form">
        <RowSpaceBetwen>
          <div>
            <p className="title2">Agregar tarjeta de crédito</p>
            <label className="label">Número de tarjeta de crédito</label>
          </div>
          <IconMasterdCard />
        </RowSpaceBetwen>
        <CardNumberElement className="inputCardNumber" />
        <Row className="label-heading">
          <label className="labelName">Nombre del titular</label>
          <label className="labelExpira">Expira</label>
          <label className="labelCVV">CVV</label>
        </Row>
        <Row>
          <InputCardNombre id="nameCard" placeholder="Brooklyn Simmons" />
          <CardExpiryElement className="inputCardExpira" />
          <CardCvcElement className="inputCardCVV" />
        </Row>
      </form>
    );
  };

  return (
    <Panel color="#FFFFFF">
      <Figure onClick={() => props.setPaso(12)}>
        <img src={ArrowBack} alt="" />
      </Figure>
      <div>
        <Title color="#603888" fontSize="24px" lineHeight="28px">
          Prueba de 7 días
        </Title>
        <TextContainer
          fontSize="14px"
          lineHeight="19px"
          color="#817393"
          margin="20px 0 0 0"
        >
          <p>
            Para continuar te solicitamos el método de pago. Tienes un plazo de
            7 días para crear tus productos con base en el plan elegido.
          </p>
        </TextContainer>
        <ContainerGeneral>
          <ContainerCards>
            <Elements stripe={stripePromise}>
              <CheckoutForm
                setCardValid={setCardValid}
                cardValid={cardValid}
                setPaso={props.setPaso}
              />
            </Elements>
          </ContainerCards>
        </ContainerGeneral>
      </div>
      <Button type="submit" isActive={() => {}} canDisabled form="my-form">
        Continuar
      </Button>
    </Panel>
  );
};
