import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const ContainerHeader = styled.header`
  width: 100%;
  justify-content: space-around;
  align-items: center;
  background-color: #ffffff;
  display: flex;
  box-shadow: 0 1px 0 rgba(22, 8, 43, 0.1);
  height: 100px;
  flex-shrink: 0;
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
`;

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 15px;
`;
export const ContainerInfoRight = styled.div`
  display: flex;
  width: 156px;
  flex-direction: column;
  justify-content: flex-end;
`;

export const ContainerInfoTitle = styled.span`
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  width: 100%;
  text-align: right;
  letter-spacing: -0.015em;
  color: #b12d84;
`;

export const ContainerInfoSubtitle = styled.span`
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 8px;
  text-align: right;
  color: #817393;
`;

export const UserInfo = styled.div`
  width: 230px;
  display: flex;
  align-items: center;
  padding: 5px 15px 5px 10px;
  height: auto;
  background: #f0eef2;
  border-radius: 25px;
  justify-content: space-between;
  position: relative;
`;

export const ProfileMenu = styled.button`
  border: none;
  display: block;
  position: absolute;
  padding: 20px;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f0eef2;
  margin-top: 10px;
  border-radius: 15px;
  z-index: 10;

  .title-role {
    background-color: #603888;
    width: 168px;
    height: 25px;
    font-family: Avenir Next;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 25px;
    color: #ededed;
    text-align: center;
    border-radius: 15px;
  }

  .account-item {
    display: flex;
    margin-left: 30px;
    text-decoration: none;

    .icon-container {
      img {
        height: 100%;
      }

      & + * {
        margin-left: 12px;
      }
    }

    p {
      font-family: Avenir Next;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: #817393;
    }
  }

  * + a {
    margin-top: 10px;
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  flex-direction: column;
`;

export const HeaderTitle = styled.span`
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.015em;
  color: #b12d84;
`;
export const HeaderInfo = styled.span`
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 8px;
  color: #817393;
`;
export const ContainIcon = styled.div`
  height: 35px;
  position: relative;
`;

export const HeaderSearch = styled.div`
  display: flex;
  width: 40%;
  background-color: #ffffff;
  height: 30px;
  padding: 0 15px 0 0;
`;

export const PulsatingCircle = styled.div`
  position: relative;
  right: -95%;
  top: -30px;
  transform: translateX(-50%) translateY(-50%);
  width: 15px;
  height: ${(props) => (props.notifications ? "15px" : "0px")};

  &:before {
    content: "";
    position: relative;
    right: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #ff009a;
    border-radius: 15px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  }
  @keyframes pulse-ring {
    0% {
      transform: scale(0.33);
    }
    80%,
    100% {
      opacity: 0;
    }
  }
  @keyframes pulse-dot {
    0% {
      transform: scale(0.8);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.8);
    }
  }
`;

export const ProfileNavLink = styled(NavLink)`
  display: flex;
  margin-left: 30px;
  text-decoration: none;

  .icon-container {
    img {
      height: 100%;
    }

    & + * {
      margin-left: 12px;
    }
  }

  p {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #817393;
  }
`;
