import styled from "styled-components";

export const Container = styled.div`
  position: relative;
`;

export const Center = styled.p`
  display: flex;
  align-items: center;
  font-family: Roboto;
  font-size: 14px;
  & > span {
    margin-left: 10px;
  }
`;

export const Menu = styled.ul`
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  z-index: 2;
  background-color: #fff;
  font-family: Roboto;
  box-shadow: 0px 2px 4px #00000040;
  border-radius: 5px;
  width: 150px;
  color: #262626;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  li {
    padding: 10px 0 10px 20px;
    width: 100%;
    margin: 0;
  }
  li:hover {
    cursor: pointer;
    background: #00000007;
  }
`;

export const ContainerCustomModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Roboto;
  p {
    font-size: 12px;
    color: #707070;
    margin-bottom: 10px;
  }
  ul {
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    margin-bottom: 10px;
  }
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #707070;
    font-size: 10px;
    padding: 3px 6px 4px 10px;
    background: #f7f7fc;
    margin-bottom: 10px;
    label {
      display: flex;
      align-items: center;
    }
  }
  .delete {
    color: #df2828;
    font-size: 19px;
    cursor: pointer;
  }

  #retailer-select {
    width: 100%;
    .button-list {
      border-radius: 3px;
      background-color: #0000000d;
      height: 20px;
      padding: 0 10px;
      justify-content: flex-start;
      & > p {
        margin: 0;
        line-height: 20px;
        font-size: 10px;
        margin-right: 3px;
      }
      .arrow-item {
        margin-left: auto;
      }
    }
  }
`;
