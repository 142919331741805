import styled from "styled-components";

export const MainContainer = styled.div`
  height: 100%;
  width: 100%;
  margin-top: 50px;

  .table-products {
    .table-column {
      padding: 0 10px;
      display: flex;
      align-items: center;
      height: 30px;
      box-sizing: border-box;

      &:first-child {
        div {
          width: 30px;
          height: 30px;
          padding: 0;

          input {
            margin: auto;
          }
        }
      }

      & + .table-column {
        border-left: 1px solid #f0eef2;
      }
    }
    .table-row {
      cursor: pointer;

      &:nth-child(odd) {
        background-color: #fafafa;
      }

      &:hover {
        background-color: #d4d1d7;
      }
    }

    .ReactVirtualized__Grid {
      will-change: auto !important;

      .ReactVirtualized__Grid__innerScrollContainer {
        overflow: unset !important;
      }
    }
  }
`;

export const ErrorMessage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-top: 100px;
  font-family: "Avenir Next";
  color: #817393;
  font-size: 22px;
`;
