import { useEffect } from "react";
import { DateTime } from "luxon";
import { FormContainer, Input } from "../styles";
import { dateFormatter } from "../../../../_utils/helper";

const DefaultForm = (props) => {
  useEffect(() => {
    props.setAppointment((prev) => ({
      ...prev,
      street: "",
      city: "",
      zipCode: "",
      latlng: { lat: 19.4363992, lng: -99.196269 },
      date: dateFormatter.format(DateTime.now()),
      collectedName: "",
      collectedPhone: "",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnChange = (evt) => {
    const { name, value } = evt.target;
    props.setAppointment((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <FormContainer>
      <p className="subTitle">Escribe tu dirección o selecciona en el mapa</p>
      <Input
        name="street"
        placeholder="Calle y colonia"
        onChange={handleOnChange}
      />
      <Input
        name="city"
        placeholder="Estado"
        maxWidth="73%"
        className="mr-5 mb-12"
        onChange={handleOnChange}
      />
      <Input
        name="zipCode"
        placeholder="C.P."
        maxWidth="25%"
        onChange={handleOnChange}
      />
      <p className="subTitle">Contacto de recolección</p>
      <Input
        name="collectedName"
        placeholder="Nombre"
        onChange={handleOnChange}
      />
      +52{" "}
      <Input
        name="collectedPhone"
        placeholder="Teléfono"
        maxWidth="92%"
        className="ml-10 mb-12"
        onChange={handleOnChange}
      />
    </FormContainer>
  );
};

export default DefaultForm;
