import * as XLSX from "xlsx";
import axios from "axios";
import { saveAs } from "file-saver";
import { UploadFiles, ListContainer } from "./Add/styles";
import uploadWithoutBorder from "../../../assets/IconComponents/uploadWithoutBorder.svg";
import { checkDuplicateUPC } from "../../_utils/data";

const validRetailers = async (obj, isRetailer) => {
  const arrayCat = Object.values(obj).map((e) => e.category);
  const config = {
    headers: { Authorization: sessionStorage.getItem("jwt") },
  };
  const catRes = await axios.get(
    process.env.REACT_APP_CATEGORY_ENDPOINT,
    config
  );
  const categories = JSON.parse(catRes.data.body).data;
  const arrayId = [];
  const keys = Object.keys(obj);
  arrayCat.forEach((e, i) => {
    if (e) {
      const split = e.split("|");
      const id =
        categories[split[0]].sub_category[split[1]].sub_category[split[2]]
          .id_category;
      arrayId.push(id);
      obj[keys[i]].idCategory = id;
    }
  });
  if (!isRetailer) {
    const resReq = await axios.get(
      `${
        process.env.REACT_APP_REQ_PER_CATEGORY
      }?categories=${encodeURIComponent(JSON.stringify(arrayId))}`
    );
    const req = JSON.parse(resReq.data.body).data;
    Object.values(obj).forEach((e) => {
      const retailersCat = req[e.idCategory];
      const retailersValid = [];
      e.provider.forEach((r) => {
        if (retailersCat[r]) retailersValid.push(r);
      });
      e.provider = retailersValid;
    });
  }
  return obj;
};

const setProviderRetailer = (isRetailer, lists, value) => {
  if (isRetailer) {
    const providerData = lists.providers.find((f) => f.name === value);
    return `${providerData.value}`;
  }
  return JSON.parse(`[${value}]`);
};

export const onLoad = async (args) => {
  const { evt, setArticles, lists, setShowGenericModal, setModalData } = args;
  const bstr = evt.target.result;
  const wb = XLSX.read(bstr, { type: "binary" });
  const wsname = wb.SheetNames[0];
  const ws = wb.Sheets[wsname];
  const rows = XLSX.utils.sheet_to_json(ws, { header: 1 });
  const obj = {};
  const values = [];
  const withError = [];
  const isRetailer = JSON.parse(sessionStorage.getItem("user")).is_retailer;
  const company = JSON.parse(sessionStorage.getItem("company"));
  const userFinanced = company?.financedRetailers?.length > 0;
  const ids = company.retailers.map(({ id }) => id);
  const isMerchants = company?.financedRetailers
    ?.map((e) => e.id)
    ?.includes(68);

  const isRadioShack = company?.financedRetailers
    ?.map((e) => e.id)
    ?.includes(74);
  const isTHDFinanced = [58, 59, 60, 61].some((id) => ids.includes(id));
  const isBodegaFinanced = [70].some((id) => ids.includes(id));
  const relNameKeys = {
    SKU: "sku",
    UPC: "upc",
    "Nombre de Producto": "name",
    Categoría: "category",
    Cadenas: "provider",
    Compañias: "provider",
  };
  const [headers] = rows.splice(0, 1);
  let isValid = true;
  let columnsBase = -1;
  const callbackColIsValid = (e, i) => {
    if (e !== headers[i]) isValid = false;
  };
  if (userFinanced) {
    if (isTHDFinanced) {
      ["SKU", "UPC", "Nombre de Producto", "Categoría", "Cadenas"].forEach(
        callbackColIsValid
      );
      columnsBase = 5;
    } else if (isBodegaFinanced) {
      ["UPC", "Nombre de Producto", "Categoría", "Cadenas"].forEach(
        callbackColIsValid
      );
      columnsBase = 4;
    } else {
      ["UPC", "Nombre de Producto", "Categoría"].forEach(callbackColIsValid);
      columnsBase = 3;
    }
  } else {
    const baseProvider = ["UPC", "Nombre de Producto", "Categoría"];
    baseProvider.push(isRetailer ? "Compañias" : "Cadenas");
    baseProvider.forEach(callbackColIsValid);
    columnsBase = 4;
  }

  if (!isValid) {
    setModalData({
      message: "Error en archivo de plantilla",
      detail:
        "Puede que la plantilla que estás intentando subir no sea la correcta para esta tabla, intenta volverla a descargar",
      customComponent: (
        <ErrorFileComponent setShowGenericModal={setShowGenericModal} />
      ),
    });
    setShowGenericModal(true);
    return;
  }

  const attrKeys = {
    Modelo: 12802,
    Alto: 40035,
    Largo: 40028,
    Ancho: 40021,
    Garantía: 40027,
    Peso: 16958,
    Color: 40974,
    Material: 40029,
    Costo: 43467,
    Disponibilidad: 43468,
  };

  rows.forEach((row) => {
    if (!row || row.length === 0) return;
    const article = {};
    const departmentList = Object.keys(lists.categories);
    row.forEach((col, i) => {
      if (i < columnsBase) {
        const key = relNameKeys[headers[i]];
        switch (key) {
          case "category":
            obj[article.upc].category = col;
            const [department, category, type] = col.split("|");
            const categoryList = Object.keys(
              lists.categories[department].sub_category
            );
            const typeList = Object.keys(
              lists.categories[department].sub_category[category].sub_category
            );
            article.depot = department;
            article.category = category;
            article.type = type;
            article.lists = {
              depot: departmentList,
              category: categoryList,
              type: typeList,
            };
            break;
          case "provider":
            const provider = setProviderRetailer(isRetailer, lists, col);
            obj[article.upc].provider = provider;
            article.provider = provider;
            break;
          default:
            if (key === "upc") obj[col] = {};
            article[key] = col ?? "";
            break;
        }
      } else {
        if (!article.attrs) article.attrs = {};
        const attributeId = attrKeys[headers[i]];
        if (attributeId) article.attrs[attributeId] = col;
      }
    });
    if (!article.provider && isMerchants) {
      article.provider = [68];
      obj[article.upc].provider = [68];
    } else if (!article.provider && isRadioShack) {
      article.provider = [74];
      obj[article.upc].provider = [74];
    }
    values.push(article);
  });
  setShowGenericModal(false);
  const retailers = await validRetailers(obj, isRetailer);
  Object.values(retailers).forEach((e, i) => {
    values[i].provider = e.provider;
  });
  const duplicatesPromises = values.map((article) =>
    checkDuplicateUPC({ upc: article.upc })
  );
  const duplicatesResults = await Promise.all(duplicatesPromises);
  values.push(...withError);
  setArticles({
    action: "addByExcel",
    isDuplicate: duplicatesResults,
    values,
  });
};
export const ErrorFileComponent = (props) => {
  return (
    <UploadFiles>
      <div className="btn">
        <button type="button" onClick={() => props.setShowGenericModal(false)}>
          Salir
        </button>
      </div>
    </UploadFiles>
  );
};
export const UploadFileComponent = (props) => {
  const retailerTHDTemplateURL =
    "https://content-management-images-prod.s3.amazonaws.com/templates/_creacion_de_productos_cadena.xlsx";
  const retailerBodegaTemplateURL =
    "https://content-management-images-prod.s3.amazonaws.com/templates/plantilla_creacion_de_productos_cadena_bodega.xlsx";
  const providerBodegaTemplateURL =
    "https://content-management-images-prod.s3.amazonaws.com/templates/plantilla_creacion_de_productos_financiados_proveedor_bodega.xlsx";
  const providerTemplateURL =
    "https://content-management-images-prod.s3.amazonaws.com/templates/plantilla_creacion_de_productos.xlsx";
  const providerFinancedTemplateURL =
    "https://content-management-images-prod.s3.amazonaws.com/templates/plantilla_creacion_de_productos_financiados.xlsx";
  const providerMerchantsTemplateURL =
    "https://content-management-images-prod.s3.amazonaws.com/templates/plantilla_merchants.xlsx";
  const isRetailer = JSON.parse(sessionStorage.getItem("user")).is_retailer;
  const userFinanced = JSON.parse(
    sessionStorage.getItem("company")
  ).hasOwnProperty("financedRetailers");
  const ids =
    JSON.parse(sessionStorage.getItem("company"))?.retailers?.map(
      ({ id }) => id
    ) ?? [];
  const isTHDFinanced =
    userFinanced && [58, 59, 60, 61].some((id) => ids?.includes(id));
  const isDropshipFinanced = userFinanced && ids?.includes(68);
  const isBodegaFinanced = userFinanced && ids?.includes(70);

  const downloadTempleate = () => {
    let URL = providerTemplateURL;
    if (isRetailer && isTHDFinanced) URL = retailerTHDTemplateURL;
    else if (isRetailer && isBodegaFinanced) URL = retailerBodegaTemplateURL;
    else if (userFinanced && isTHDFinanced) URL = providerFinancedTemplateURL;
    else if (userFinanced && isBodegaFinanced) URL = providerBodegaTemplateURL;
    else if (userFinanced && isDropshipFinanced)
      URL = providerMerchantsTemplateURL;
    return URL;
  };

  return (
    <UploadFiles>
      <div className="label">
        <label htmlFor="file-input">
          <img src={uploadWithoutBorder} alt="Subir excel con productos" />
          Subir
        </label>
        <input
          id="file-input"
          type="file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          onChange={props.onChange}
        />
      </div>
      <div className="btn">
        <button
          type="button"
          onClick={() => {
            saveAs(downloadTempleate(), "plantilla.xlsx");
          }}
        >
          Descargar plantilla
        </button>
      </div>
    </UploadFiles>
  );
};

export const UPCList = (props) => {
  return (
    <ListContainer>
      <ul>
        {props.upcExisting.map((upc) => (
          <li key={upc}>{upc}</li>
        ))}
      </ul>
      <p>Estos UPC se encontraban en plataforma.</p>
      {props.isOrder && (
        <p>Para la orden se utilizarán los UPC ya existentes</p>
      )}
      <UploadFiles>
        <div className="btn">
          <button
            type="button"
            onClick={() => {
              if (props.isOrder) props.createOrder();
              else props.setRedirect("products");
            }}
          >
            Continuar
          </button>
        </div>
      </UploadFiles>
    </ListContainer>
  );
};

/**
 *
 * VALUES:
 * @param {string} valueUpc UPC to check for duplicates in database
 * @param {string} valueCompany idcompany to check for duplicates in database (retailers only)
 * @param {boolean} isRetailer identify if the user is retailer or not. If is true the search will be by upc and provider, otherwise only upc
 * @param {number} index index of the product within the creation list
 *
 * STATES:
 * @param {function} alertDuplicate Controls the alerts that notify the user if the upc is duplicated, the states handled are: setAlertUpc,props.setArticles
 * @param {function} setArticles reducer handler to execute our action obj
 */
export const validateUPC = (parameters) => {
  const {
    valueUpc,
    valueCompany,
    isRetailer,
    index,
    alertDuplicate,
    setNewArticle,
  } = parameters;

  let params = {
    upc: valueUpc,
  };
  let reducerAction = {
    index,
    value: valueUpc,
  };
  if (isRetailer) {
    params.company = valueCompany;
    reducerAction.provider = valueCompany;
    reducerAction.isRetailer = isRetailer;
  }

  if (index === undefined) {
    checkDuplicateUPC(params).then((isDuplicate) => {
      alertDuplicate(isDuplicate);
      if (setNewArticle !== undefined) {
        setNewArticle((prev) => ({
          ...prev,
          upc: valueUpc,
          company: valueCompany,
          error: isDuplicate,
        }));
      }
    });
  } else {
    checkDuplicateUPC(params).then((isDuplicate) => {
      if (isDuplicate) {
        reducerAction.action = "DbDuplicateUPC";
        reducerAction.error = true;
        alertDuplicate(reducerAction);
      } else {
        reducerAction.action = "upc";
        alertDuplicate(reducerAction);
      }
    });
  }
};
