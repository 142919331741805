import styled from "styled-components";
import { grayS2, grayS7, pinkS1 } from "../../../variables";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 15px;

  .inputs-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    //border: 1px solid red;

    .div-input {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px 3px;
      border-radius: 3px;
      gap: 3px;
      border: 1px solid ${grayS7};

      > label {
        font-family: "Segoe UI", sans-serif;
        font-size: 16px;
        font-weight: 600;
      }

      input {
        width: 75px;
        border: none;
        outline: none !important;
        font-family: "Roboto Regular", "Segoe UI", sans-serif;
        font-size: 13px;
        line-height: 1.7;
      }
    }

    .label-separator {
      font-size: 20px;
      font-weight: 700;
      padding-bottom: 1px;
    }
  }

  .div-slider {
    width: 100%;
    padding: 0px 8px;
    //border: 1px solid red;

    .MuiSlider-root {
      color: ${pinkS1}; //color de la linea
      height: 5px; // grosor de la linea

      // linea del rango
      .MuiSlider-track {
        height: 6px;
      }

      // selectores circulares
      .MuiSlider-thumb {
        background-color: white;
        border: 1px solid currentColor;

        // cada una de las 3 lineas internas
        .thumb-line {
          height: 50%;
          width: 1px;
          background-color: currentColor;
          opacity: 0.5;
          margin: 0px 1px 0px 1px;
        }
      }

      // label value
      .MuiSlider-valueLabel {
        line-height: 1.1;
        font-size: 15px;
        font-weight: 600;
        background: unset;
        background-color: ${pinkS1};
        padding: 8px 11px;
        border-radius: 18%;
        transform-origin: bottom center;
        transform: translate(0%, calc(100% + 1px)) scale(0);

        &:before {
          display: none;
        }

        &.MuiSlider-valueLabelOpen {
          transform: translate(0%, calc(100% + 1px)) scale(1);
        }
      }
    }
  }
`;
