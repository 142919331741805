import {
  ContainerPrincipalSide,
  Container,
  Container2,
  TableInvoicing,
  Title,
  ContainProgress,
  Subtitle,
  ButtonQuoteInvoicing,
  ButtonQuoteInvoicing2,
  HeaderTitle,
  RowStatus2,
  ArticleDetails,
  Switcher,
} from "./styles";

import IconCalendar from "../../assets/IconComponents/IconCalendar.svg";
import {
  ContainerFooter,
  ContainerFooters,
  NumberTitle,
  ContainerNumber,
} from "../products/addProducts/EditionMultiple/styles";

import Loading from "../general/loading/index";

import AddIconCancel from "../../assets/IconComponents/IconCancel.svg";
import { getStatusColor, getShortStatus } from "../_utils/helper";
import { useEffect, useState, useRef } from "react";
import axios from "axios";

import ProductDetail from "../../components/products/productDetail/index";

/* Icons DownAndUp*/
import arrowUp from "../../assets/IconComponents/retailersArrowUp.svg";
import arrowDown from "../../assets/IconComponents/retailersArrowDown.svg";

export default function InvoicingDetail(props) {
  const [articles, setArticles] = useState([]);
  const [content, setContent] = useState({});
  const [loading, setLoading] = useState(false);

  /***** ProductDetails ******/
  const [showDetails, setShowDetails] = useState(false);
  const [productData, setProductData] = useState({});

  useEffect(() => {
    const getArticlesContent = async () => {
      setLoading(true);
      let ordersArticles = [];
      let ordersContent = {};

      for (const index in props.orders) {
        const orderServicesResponse = await axios.get(
          `${process.env.REACT_APP_ORDERED_SERVICES_ENDPOINT}?orderId=${props.orders[index]}`,
          {
            headers: {
              Authorization: sessionStorage.getItem("jwt"),
            },
          }
        );

        const item = JSON.parse(orderServicesResponse.data.body).data;

        let sumAttribProvider = item.reduce(
          (sum, valor) => sum + (valor?.article?.attributes_provider || 0),
          0
        );
        let sumDescriProvider = item.reduce(
          (sum, valor) => sum + (valor?.article?.descriptions_provider || 0),
          0
        );
        let sumImagesProvider = item.reduce(
          (sum, valor) => sum + (valor?.article?.images_provider || 0),
          0
        );

        ordersContent.datasheets = sumAttribProvider;
        ordersContent.descriptions = sumDescriProvider;
        ordersContent.images = sumImagesProvider;

        item.forEach((itemData) => {
          ordersArticles.push({
            product: itemData.article,
            retailers: itemData.retailers,
          });
          ordersContent.datasheets += itemData.services.datasheets;
          ordersContent.descriptions += itemData.services.descriptions;
          ordersContent.images += itemData.services.images;
        });
      }

      setArticles(ordersArticles);
      setContent(ordersContent);
      setLoading(false);
    };
    getArticlesContent();
  }, [props.orders]);

  const setName = () => {
    let ordersTitle = "";

    props.orders.forEach((orderId, i) => {
      if (props.orders.length === i + 1) {
        ordersTitle += orderId;
      } else {
        ordersTitle += `${orderId},`;
      }
    });
    return ordersTitle;
  };

  const mainContainerSlide = useRef();

  useEffect(() => {
    if (mainContainerSlide.current) mainContainerSlide.current.focus();
  }, [mainContainerSlide]);

  const viewProductDetail = (article) => {
    setShowDetails(
      showDetails === article.product.id_article
        ? false
        : article.product.id_article
    );

    article.retailersAvailable = article.retailers;

    if (!article?.product?.attributes_provider) {
      article.product.attributes_provider = 0;
    }

    if (!article?.product?.descriptions_provider) {
      article.product.descriptions_provider = 0;
    }

    if (!article?.product?.images_provider) {
      article.product.images_provider = 0;
    }

    const missingInfo = {
      datasheet: article.product.attributes_provider,
      description: article.product.descriptions_provider,
      image: article.product.images_provider,
    };

    article.product.missing = missingInfo;
    article.product.asignations = [];
    article.product.retailersAvailable = article.retailers;
    article.product.version = article?.product?.version_id;

    setProductData(article.product);
  };

  return (
    <ContainerPrincipalSide
      id="order-detail"
      className={props.modalClass ? props.modalClass : ""}
      ref={mainContainerSlide}
    >
      <Container2 width="100%">
        <HeaderTitle>Orden de trabajo</HeaderTitle>
        <button className="btn-close-modal">
          <img src={AddIconCancel} alt="Cerrar" />
        </button>
      </Container2>
      {props.orders.length === 1 ? (
        <Title>{props.orders[0]}</Title>
      ) : (
        <Title>{setName()}</Title>
      )}
      <Container justifycontent="space-between" padding="15px 0 0 0">
        <Container flex="1" justifycontent="space-between">
          <Subtitle>Estatus</Subtitle>
          <ContainProgress> En Proceso</ContainProgress>
        </Container>
        <Container flex="2" justifycontent="flex-end">
          <Subtitle>Entrega pick up</Subtitle>
        </Container>
      </Container>
      <Container justifycontent="space-between" padding="5px 0">
        <Container flex="1" justifycontent="space-between">
          <Subtitle>Fecha de entrega</Subtitle>
          <Subtitle>21/06/2021</Subtitle>
        </Container>
        <Container flex="2" justifycontent="flex-end">
          {props.orders.length === 1 ? (
            <ButtonQuoteInvoicing
              onClick={() => {
                sessionStorage.setItem(
                  "ordersAppointment",
                  JSON.stringify(props.orders[0])
                );
                window.location.href = `/Quotes`;
              }}
            >
              <img src={IconCalendar} alt="" />
              Reagendar cita
            </ButtonQuoteInvoicing>
          ) : (
            <ButtonQuoteInvoicing>
              <img src={IconCalendar} alt="" />
              Reagendar cita
            </ButtonQuoteInvoicing>
          )}
        </Container>
      </Container>
      <Container
        padding="10px 0"
        justifycontent="space-between"
        className="table"
      >
        {articles.length > 0 && !loading ? (
          <TableInvoicing>
            <thead>
              <tr className="tr-row-detail" on>
                <th scope="col">UPC</th>
                <th scope="col">Nombre</th>
                <th scope="col">Estatus</th>
                <th scope="col">Cadena</th>
                <th scope="col">Categoría</th>
                <th scope="col">Versión</th>
                <th scope="col" style={{ maxWidth: "10px" }}></th>
              </tr>
            </thead>
            <tbody>
              {articles.map((article, i) => (
                <>
                  <tr
                    key={i}
                    onClick={() => {
                      viewProductDetail(article);
                    }}
                  >
                    <td>{article.product.upc}</td>
                    <td className="article-name">{article.product.name}</td>
                    <td>
                      <RowStatus2
                        className="modal-status"
                        bg={getStatusColor("CA")}
                      >
                        {getShortStatus("CA")}
                      </RowStatus2>
                    </td>
                    <td>{article.retailers.length}</td>
                    <td>{article.product.id_category.split("|")[0]}</td>
                    <td>1</td>
                    <Switcher
                      showDetails={
                        showDetails === article.product.id_article
                          ? false
                          : article.product.id_article
                      }
                    >
                      <img
                        src={
                          showDetails === article.product.id_article
                            ? arrowUp
                            : arrowDown
                        }
                        alt="arrow"
                      />
                    </Switcher>
                  </tr>
                  <ArticleDetails>
                    {showDetails === article.product.id_article && (
                      <>
                        <div id="containerDetail">
                          <ProductDetail
                            option={"Products"}
                            productSelected={productData}
                            setAddedToCart={""}
                            setAddedPopUp={""}
                            className={"modal-active"}
                            setModalClass={""}
                            setSidebool={""}
                            featuresOfOrders
                          />
                        </div>
                      </>
                    )}
                  </ArticleDetails>
                </>
              ))}
            </tbody>
          </TableInvoicing>
        ) : articles.length !== 0 ? (
          <Loading />
        ) : null}
      </Container>
      <ContainerFooter className="footer">
        <div className="ContainSpan">
          <span>Contenido</span>
          <span>Total</span>
        </div>
        {!loading ? (
          <ContainerFooters>
            <div>
              <NumberTitle>Productos</NumberTitle>
              <ContainerNumber>{articles.length}</ContainerNumber>
            </div>
            <div>
              <NumberTitle>Fichas técnicas</NumberTitle>
              <ContainerNumber>{content.datasheets}</ContainerNumber>
            </div>
            <div>
              <NumberTitle>Descripciones</NumberTitle>
              <ContainerNumber>{content.descriptions}</ContainerNumber>
            </div>
            <div>
              <NumberTitle>Imágenes</NumberTitle>
              <ContainerNumber>{content.images}</ContainerNumber>
            </div>
          </ContainerFooters>
        ) : (
          <ContainerFooters>
            <Loading />
          </ContainerFooters>
        )}
      </ContainerFooter>
      <Container2 className="footerBotton">
        <ButtonQuoteInvoicing2>Validar pago de la orden</ButtonQuoteInvoicing2>
        <div className="btnClose">
          <div>Cerrar</div>
        </div>
      </Container2>
    </ContainerPrincipalSide>
  );
}
