import InfoIcon from "../../assets/sliderToolTip/infoIcon.svg";
import Slide1 from "../../assets/sliderToolTip/slide1.svg";
import Slide2 from "../../assets/sliderToolTip/slide2.svg";
import Slide3 from "../../assets/sliderToolTip/slide3.svg";
import Slide4 from "../../assets/sliderToolTip/slide4.svg";
import Slide5 from "../../assets/sliderToolTip/slide5.svg";
import { Container, Slider } from "./styles";
import { useEffect, useState } from "react";

export default function SliderToolTip() {
  const [showMenu, setShowMenu] = useState(false);
  const closeMenu = (e) => {
    if (!e.target.closest("#div-slider") && showMenu) {
      document.removeEventListener("click", closeMenu, false);
      setShowMenu(false);
    }
  };
  useEffect(() => {
    if (showMenu) {
      document.addEventListener("click", closeMenu, false);
    }
  }, [showMenu]);
  const color = (id) => {
    for (let i = 1; i < 6; i++) {
      if (id === `slidea${i}`) {
        document.getElementById(`slidea${i}`).style.backgroundColor = "#E33AA9";
        if (id === "slidea5") {
          document.getElementById("button-slide").style.display = "flex";
        }
      } else if (id > `slidea${i}`) {
        document.getElementById(`slidea${i}`).style.backgroundColor = "#FAFAFA";
      } else if (id < `slidea${i}`) {
        document.getElementById(`slidea${i}`).style.backgroundColor = "#C4C4C4";
      }
    }
  };
  return (
      <Container className="container-slider">
        <img
          src={InfoIcon}
          alt="info icon"
          onClick={(e) => {
            setShowMenu(!showMenu);
            if (showMenu) {
              document.removeEventListener("click", closeMenu, false);
            }
          }}
        />
        {showMenu && (
          <Slider id="div-slider">
            <ul className="slider">
              <li id="slide1">
                <div>
                  <img src={Slide1} />
                </div>
                <p>
                  Creamos tu contenido, y todo lo que haga falta para tu
                  producto, cada sección puedes socilitar contenido enriquecido,
                  y nosotros lo haremos por ti.
                </p>
              </li>
              <li id="slide2">
                <img src={Slide2} />
                <p>
                  Puedes agregar solicitudes de contenido enriquecido a tu
                  carrito de compra las veces que sean necesarias. Una vez
                  completada tu lista con los servicios necesitados sigue el
                  proceso de compra en checkout.
                </p>
              </li>
              <li id="slide3">
                <img src={Slide3} />
                <p>
                  Revisa el contenido que deseas solicilitar, valida y tambien
                  puedes agregar contenido extra, traducciones, o simplemente
                  completar el checkout.
                </p>
              </li>
              <li id="slide4">
                <img src={Slide4} />
                <p>
                  Elige el tipo de entrega de tu producto, puedes ser
                  recolección en el lugar o por el contrario puedes dejarlo en
                  nuestras oficinas.
                </p>
              </li>
              <li id="slide5">
                <div>
                  <img src={Slide5} />
                </div>
                <div className="lema-end">
                  <p>
                    Finalmente elige la forma de pago que más te guste, procede
                    con el pago y listo.
                  </p>
                  <span>Bienvenido al modo Content-oh!</span>
                </div>
                <button
                  id="button-slide"
                  onClick={(e) => {
                    document.removeEventListener("click", closeMenu, false);
                    setShowMenu(false);
                  }}
                >
                  Cerrar
                </button>
              </li>
            </ul>
            <ul className="menu">
              <li>
                <a
                  href="#slide1"
                  id="slidea1"
                  onClick={(e) => {
                    color("slidea1");
                  }}
                ></a>
              </li>
              <li>
                <a
                  href="#slide2"
                  id="slidea2"
                  onClick={(e) => {
                    color("slidea2");
                  }}
                ></a>
              </li>
              <li>
                <a
                  href="#slide3"
                  id="slidea3"
                  onClick={(e) => {
                    color("slidea3");
                  }}
                ></a>
              </li>
              <li>
                <a
                  href="#slide4"
                  id="slidea4"
                  onClick={(e) => {
                    color("slidea4");
                  }}
                ></a>
              </li>
              <li>
                <a
                  href="#slide5"
                  id="slidea5"
                  onClick={(e) => {
                    color("slidea5");
                  }}
                ></a>
              </li>
            </ul>
          </Slider>
        )}
      </Container>
  );
}