import { Status, Tooltip } from "contentoh-components-library";
import ReactImageFallback from "react-image-fallback";
import { Center, ProfileContainer } from "./styles.js";
import { getProfilePicture } from "../../../_utils/helper.js";
import defaultProfile from "../../../../assets/defaultImages/defaultProfile.png";
import spinner from "../../../../assets/defaultImages/Spinner.gif";

export const getStatusComponent = (status) => {
  const statusList = {
    PA: "Por Asignar",
    SAC: "Solicitud Auditoría a Content-oh!",
    AS: "Asignado",
    R: "Recibido",
    CA: "Capturando",
    IE: "Información Enviada",
    RC: "Rechazado Coordinador",
    AC: "Aprobado Coordinador",
    RA: "Rechazado Auditor",
    AA: "Aprobado Auditor",
    RP: "Rechazado Proveedor",
    AP: "Aprobado Proveedor",
    ACA: "Aprobado Cadena",
    AAC: "Aprobado Auditoría de Content-oh!",
    RCA: "Rechazado Cadena",
    RAC: "Rechazado Auditoría de Content-oh!",
    Ex: "Exportado",
    FAP: "Finalizado Auditoría por Proveedor",
  };
  const tooltip = <p>{status ? statusList[status] : "No Solicitado"}</p>;
  return (
    <Center>
      <Tooltip
        componentTooltip={tooltip}
        children={<Status statusType={status} />}
        position="right"
        followCursor
        addArrow={false}
      />
    </Center>
  );
};

export const getUserComponent = (user) => {
  const { userId, userName } = user;
  return (
    <ProfileContainer>
      <ReactImageFallback
        src={getProfilePicture(userId ?? 0)}
        fallbackImage={defaultProfile}
        initialImage={spinner}
        className="img"
      />
      {userName}
    </ProfileContainer>
  );
};

export const updateSKU = (value, articleId, versionId, setModal) => {
  return (
    <p
      className={setModal ? "pointer" : ""}
      onClick={() => setModal && setModal({ show: true, articleId, versionId })}
      key={`${articleId}-${versionId}-${value}`}
    >
      {value}
    </p>
  );
};
