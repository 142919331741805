import styled from "styled-components";

export const Header = styled.div`
  color: #ffffff;
  font-size: 12px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  & > span {
    margin-right: 10px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: 20px;
  & > div {
    margin-right: 5px;
  }
  span {
    font-family: Roboto;
    color: #707070;
    font-size: 10px;
  }
`;

export const Content = styled.div`
  padding: 20px;
  color: #707070;
  font-family: Roboto;
  font-size: 12px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > p {
    margin-bottom: 25px;
  }
  & > img {
    width: 330px;
    height: 215px;
  }
`;

export const Container = styled.div`
  & > div {
    grid-template-rows: 34px 1fr 40px !important;
  }
  margin-bottom: 20px;
`;
