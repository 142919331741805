import styled from "styled-components";

export const CheckboxCustomContainer = styled.div`
display: flex;
align-items: center;
  input {
    display: none;

    &:checked + label {
      &::before {
        background-image: url(${(props) => props.checked});
      }
    }
  }

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    &::before {
      margin-right: 5px;
      content: "";
      display: inline-block;
      width: ${(props) => (props.size ? props.size : "13")}px;
      height: ${(props) => (props.size ? props.size : "13")}px;
      background-image: url(${(props) => props.unchecked});
      background-repeat: no-repeat;
      background-size: 100%;
    }
  }

  * {
    box-sizing: border-box;
  }
`;
