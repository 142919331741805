import { InputContainer } from "./styles";
import searchIcon from "../iconsFolder/searchIcon.svg";
import { useEffect, useState } from "react";
import { FilterOption } from "../filterOption";
import { FiltersInput } from "../inputFilter";
import { CustomSelect } from "contentoh-components-library";

export const InputSection = (props) => {
  const [showInput, setShowInput] = useState(false);

  const [providerOptions, setProviderOptions] = useState([]);
  const [phasesOptions, setPhasesOptions] = useState([]);
  const [groupsOptions, setGroupsOptions] = useState([]);

  const filtersOptions = props.filterArray;

  const showInputFilter = (e) => {
    if (!e.target.closest("#input-filter") && showInput) {
      document.removeEventListener("click", showInputFilter, false);
      setShowInput(false);
    }
  };

  useEffect(() => {
    //props.applyFilters();
    if (showInput) {
      document.addEventListener("click", showInputFilter, false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showInput]);

  useEffect(() => {
    if (props.filterLists && props.filterLists.providers) {
      const uniqueProviders = new Set();
      const mapProviderFilter = props.filterLists.providers.reduce(
        (uniqueProvidersArray, e) => {
          if (!uniqueProviders.has(e.id_company)) {
            uniqueProviders.add(e.id_company);
            uniqueProvidersArray.push({
              id: e.id_company,
              name: e.companyProviderName,
            });
          }
          return uniqueProvidersArray;
        },
        []
      );
      setProviderOptions(mapProviderFilter);
    }
    if (props.filterLists && props.filterLists.phases) {
      setPhasesOptions(props.filterLists.phases);
    }
    if (props.filterLists && props.filterLists.groups) {
      setGroupsOptions(props.filterLists.groups);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filterLists]);
  
  return (
    <InputContainer className={props.className}>
      <div className="input-container" onClick={() => setShowInput(true)}>
        <div className="fake-input-text">
          <img src={searchIcon} alt="search icon" />
          <p>{props.keyword}</p>
        </div>

        {/* contenedor de la etiquetas de filtros activos */}
        <div className="chosen-filters">
          {Object.keys(props.globalFilters)
            ?.reverse()
            ?.map(
              (filterActive, index) =>
                index < 2 && (
                  <FilterOption
                    key={"filter-option-" + index}
                    label={filterActive}
                    optionSelected={true}
                    index={index}
                    mainFilter={props.mainFilter}
                    globalFilters={props.globalFilters}
                    setGlobalFilters={props.setGlobalFilters}
                    setKeyword={props.setKeyword}
                  />
                )
            )}

          {Object.keys(props.globalFilters).length > 2 && (
            <div className="extra-filters">
              <p>+{Object.keys(props.globalFilters).length - 2}...</p>
            </div>
          )}
        </div>
      </div>

      {/* label de productos cargados */}
      {!props.hiddenLabelProductsLoaded &&
        props.section !== "RetailerProducts" && (
          <div className="products-loaded">
            <p>
              {props.charged} de {props.total} cargados{" "}
            </p>
          </div>
        )}

      {showInput && (
        <FiltersInput
          filters={props.filters}
          keyWords={props.keyword}
          placeHolderInput={props.placeHolderInput}
          mainFilter={props.mainFilter}
          setKeyword={props.setKeyword}
          filtersOptions={filtersOptions}
          filterInputFunct={props.filterInputFunct}
          globalFilters={props.globalFilters}
          setGlobalFilters={props.setGlobalFilters}
          section={props.section}
          //activeFilters={activeFilters}
        />
      )}
      {props.section === "RetailerProducts" && ( //RetailerProduct=Onboarding
        <>
          <CustomSelect
            options={providerOptions}
            showSearchBar
            placeHolder={"Buscar proveedor"}
            selectLabel={"Proveedor"}
            customSelectId={"provider-select"}
            defaultOption={"Todos los proveedores"}
            parameterArray={props.providerSelected || []}
            setParameterArray={props.setProviderSelected}
          />
          <CustomSelect
            options={phasesOptions}
            showSearchBar
            placeHolder={"Buscar fase"}
            selectLabel={"Fase"}
            customSelectId={"phase-select"}
            defaultOption={"Todos las fases"}
            parameterArray={props.phasesSelected || []}
            setParameterArray={props.setPhasesSelected}
          />
          <CustomSelect
            options={groupsOptions}
            showSearchBar
            placeHolder={"Buscar grupo"}
            selectLabel={"Grupo"}
            customSelectId={"group-select"}
            defaultOption={"Todos los grupos"}
            parameterArray={props.groupsSelected || []}
            setParameterArray={props.setGroupsSelected}
          />
        </>
      )}
    </InputContainer>
  );
};
