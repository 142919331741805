import { MultipleEditionContainer } from "../multipleEdition/styles";
import { MultipleEdition } from "contentoh-components-library";
import { useEffect, useState } from "react";

export const MultipleEditionComponent = () => {
  const storedList = sessionStorage.getItem("multipleEditionList");
  const [productsList, setProductsList] = useState(
    storedList ? JSON.parse(storedList) : []
  );

  let arrayIdArticles = [];
  let arrayArticlesVersion = [];
  productsList.forEach((product) => {
    arrayIdArticles.push(product.id_article);
    arrayArticlesVersion.push(product.version);
    
  }
);

  
  

  return (
    <MultipleEditionContainer>
      <MultipleEdition
        articles={arrayIdArticles}
        versions={arrayArticlesVersion}
        token={sessionStorage.getItem("jwt")}
      />
    </MultipleEditionContainer>
  );
};
