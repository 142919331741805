import React, { useEffect, useState } from "react";
import axios from "axios";
import { PopUpContainer } from "./style";

const PopUpEditRetailers = (props) => {
  const { category, retailers } = props;
  const [retailerList, setRetailerList] = useState([]);
  const [financedRetailers] = useState(
    JSON.parse(sessionStorage.getItem("company"))?.financedRetailers
  );
  useEffect(() => {
    if (financedRetailers?.length === 0) {
      const encode = encodeURIComponent(category);
      axios
        .get(`${process.env.REACT_APP_RETAILER_ENDPOINT}?category=${encode}`, {
          headers: {
            Authorization: sessionStorage.getItem("jwt"),
          },
        })
        .then((result) => {
          setRetailerList(Object.values(JSON.parse(result.data.body).data));
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  useEffect(() => {
    if (financedRetailers?.length > 0) setRetailerList(financedRetailers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PopUpContainer className="chk-inputs">
      <div className="elements-container">
        {retailerList && (
          <div className="retailers-container">
            {retailerList.map((retailer, index) => (
              <div
                key={`${props.row}-${retailer.id}`}
                className="retailer-container"
              >
                <input
                  type="checkbox"
                  id={props.prefix + "chk" + retailer.id}
                  name={props.prefix + "chk" + retailer.id}
                  defaultChecked={retailers.includes(retailer.id)}
                  onChange={(evt) => props.updateCadena(evt, retailer.id)}
                />
                <label htmlFor={props.prefix + "chk" + retailer.id}>
                  {retailer.name}
                </label>
              </div>
            ))}
          </div>
        )}
      </div>
    </PopUpContainer>
  );
};

export default PopUpEditRetailers;
