import styled from "styled-components";

export const Container = styled.div`
  padding: 20px;
  overflow-y: scroll;
  .inputCardNumber {
    background: #fafafa;
    border-radius: 3px;
    width: 320px;
    height: 23px;
    border: none;
    &::placeholder {
      color: #d4d1d7;
    }
    margin: 5px;
    padding-left: 7px;
    padding-top: 7px;
  }
  .inputCardExpira {
    background: #fafafa;
    border-radius: 3px;
    width: 71px;
    height: 23px;
    border: none;
    &::placeholder {
      color: #d4d1d7;
    }
    margin: 5px;
    padding-left: 7px;
    padding-top: 7px;
  }
  .inputCardCVV {
    background: #fafafa;
    border-radius: 3px;
    width: 40px;
    height: 23px;
    border: none;
    &::placeholder {
      color: #d4d1d7;
    }
    margin: 5px;
    padding-left: 7px;
    padding-top: 7px;
  }
`;

export const ContainerMain = styled.div`
  display: flex;
`;
export const Text24 = styled.div`
  font-family: Avenir Next;
  color: #817393;
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const ContainerMetodosPago = styled.div`
  width: 100%;
`;

export const ContainerDetalles = styled.div`
  width: 400px;
  padding: 10px;
`;
export const Hr = styled.hr`
  margin-top: 80px;
  width: 100%;
  height: 1px;
  background-color: #ccc;
  border: none;
`;

export const Block1 = styled.div`
  background: #f0eef2;
  border-radius: 7px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 10px;
  .text18 {
    color: #503d66;
    font-size: 18px;
  }
`;
export const Text14 = styled.p`
  font-family: Avenir Next;
  color: #817393;
  font-size: 14px;
`;

export const InputCupon = styled.input`
  border: 1px solid #f0eef2;
  box-sizing: border-box;
  border-radius: 7px;
  width: 100%;
  height: 48px;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  &::placeholder {
    color: #817393;
  }
`;

export const Title = styled.p`
  font-family: Raleway;
  color: #d43594;
  font-size: 36px;
`;
export const MainData = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const ODTText = styled.p`
  font-family: Lato;
  color: #503d66;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
`;
export const Row = styled.div`
  display: flex;
`;

export const RowDatos = styled.div`
  width: calc(100% - 320px);
  padding-top: 30px;
`;

export const ContainerMethods = styled.div`
  border: 1px solid #f0eef2;
  box-sizing: border-box;
  border-radius: 7px;
  width: 340px;
  height: 70px;
  padding: 5px;
  .text {
    font-family: Avenir Next;
    color: #503d66;
    font-size: 14px;
  }
`;

export const SelectGroup = styled.div``;
export const OptionInput = styled.input``;
export const BotonComprobante = styled.button`
  border: 1px solid #d74ded;
  box-sizing: border-box;
  border-radius: 3px;
  color: #d74ded;
  width: 178px;
  height: 20px;
  background-color: transparent;
  font-size: 10px;
  cursor: pointer;
  margin-top: 7px;
  margin-left: 13px;
`;

export const BotonValidar = styled.button`
  background: #d74ded;
  border-radius: 4px;
  width: 100px;
  height: 25px;
  margin-top: 60px;
  color: white;
  font-size: 10px;
  border: none;
`;

export const Input = styled.input`
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 3px;
  width: 310px;
  height: 20px;
  &::placeholder {
    color: #c4c4c4;
  }
  margin-top: 10px;
`;
export const InputMonto = styled.input`
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 3px;
  width: 268px;
  height: 20px;
  &::placeholder {
    color: #c4c4c4;
  }
  margin-top: 7px;
  margin-left: 5px;
`;

export const InputCardNumber = styled.input`
  background: #fafafa;
  border-radius: 3px;
  width: 320px;
  height: 30px;
  border: none;
  &::placeholder {
    color: #d4d1d7;
  }
  margin: 5px;
  padding-left: 7px;
`;
export const InputFormasPago = styled.input`
  background: #fafafa;
  border-radius: 3px;
  width: 320px;
  height: 30px;
  border: none;
  &::placeholder {
    color: #d4d1d7;
  }
  padding-left: 7px;
`;

export const ContainerFormasPago = styled.div`
  border: 1px solid #f0eef2;
  box-sizing: border-box;
  border-radius: 7px;
  width: 830px;
  height: 395px;
  margin-top: 10px;
  padding: 15px;
  .title {
    font-family: Avenir Next;
    color: #503d66;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .text {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #817393;
    width: 200px;
  }
  .textFormaPago {
    font-family: Avenir Next;
    font-style: normal;
    color: #503d66;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 15px;
    width: 200px;
  }
  .pagoCompletado {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
  }
  .text18 {
    color: #503d66;
    font-family: Avenir Next;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
  }
  .textProductos {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #817393;
  }
`;

export const InfoDiv = styled.div`
  width: calc(100% - 200px);
`;
export const RowSpaceBetwen = styled.div`
  display: flex;
  justify-content: space-between;
  height: 30px;
`;

export const InputCardExpira = styled.input`
  background: #fafafa;
  border-radius: 3px;
  width: 71px;
  height: 30px;
  border: none;
  &::placeholder {
    color: #d4d1d7;
  }
  margin: 5px;
  padding-left: 7px;
`;

export const InputCardCVV = styled.input`
  background: #fafafa;
  border-radius: 3px;
  width: 40px;
  height: 30px;
  border: none;
  &::placeholder {
    color: #d4d1d7;
  }
  margin: 5px;
  padding-left: 7px;
`;

export const InputCardNombre = styled.input`
  background: #fafafa;
  border-radius: 3px;
  width: 190px;
  height: 30px;
  border: none;
  &::placeholder {
    color: #d4d1d7;
  }
  margin: 5px;
  padding-left: 7px;
`;

export const TextCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
`;
export const ContainerFormaPago = styled.div`
  border: 1px solid #f0eef2;
  box-sizing: border-box;
  border-radius: 7px;
  width: 806px;
  height: auto;
  padding: 15px;
  margin-top: 20px;
`;

export const ContainerFormaPago2 = styled.label`
  border: 1px solid #f0eef2;
  box-sizing: border-box;
  border-radius: 7px;
  width: 806px;
  height: 121px;
  &:hover {
    background: #f0eef2;
  }
  padding: 15px;
  margin-top: 20px;
`;

export const ContainerOption = styled.label`
  background: #fafafa;
  border-radius: 15px;
  width: 326px;
  height: 30px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  font-family: Avenir Next;
  color: #707070;
  font-size: 14px;
  &:hover {
    background: #f0eef2;
  }
`;

export const ContainerCards = styled.div`
  margin-top: 25px;
  border: 1px solid #f0eef2;
  box-sizing: border-box;
  border-radius: 7px;
  width: 370px;
  height: 160px;
  padding: 15px;
  margin-right: 30px;
  .title {
    font-family: Avenir Next;
    color: #503d66;
    font-size: 14px;
    margin-bottom: 30px;
  }
  .title2 {
    font-family: Avenir Next;
    color: #503d66;
    font-size: 14px;
    margin-bottom: 5px;
  }
  .label {
    font-family: Avenir Next;
    color: #817393;
    font-size: 12px;
    margin-left: 7px;
  }
  .labelName {
    font-family: Avenir Next;
    color: #817393;
    font-size: 12px;
    width: 200px;
    margin-left: 7px;
  }
  .labelExpira {
    font-family: Avenir Next;
    color: #817393;
    font-size: 12px;
    width: 85px;
  }
  .labelCVV {
    font-family: Avenir Next;
    color: #817393;
    font-size: 12px;
    width: 45px;
  }
`;

export const MethodsBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Method = styled.div`
  background: #fafafa;
  border-radius: 20px;
  text-align: center;
  width: 156.07px;
  height: 30px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HorizontalCenter = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ActiveCircle = styled.div`
  width: 58px;
  height: 58px;
  background: #e33aa9;
  font-family: Avenir Next;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  color: white;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
`;

export const Separator = styled.div`
  width: 36px;
  height: 2px;
  margin-top: 29px;
  background-color: #f0eef2;
  align-items: center;
  justify-content: center;
  font-size: 36px;
`;

export const InactiveCircle = styled.div`
  width: 58px;
  height: 58px;
  border: 1px solid #f0eef2;
  box-sizing: border-box;
  font-family: Avenir Next;
  border-radius: 50%;
  display: flex;
  color: #817393;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
`;

export const DateText = styled.p`
  font-family: Avenir Next;
  color: #503d66;
  font-size: 18px;
  margin-top: 10px;
`;
export const TextConfirm = styled.p`
  font-family: Avenir Next;
  color: #603888;
  font-size: 14px;
  margin-top: 15px;
  text-align: right;
`;

export const TableProduct = styled.table`
  margin-top: 30px;
  display: block;
  empty-cells: show;
`;
export const TotalTable = styled.thead`
  margin-top: 10px;
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 30px;
  border: 1px solid #f0eef2;
  box-sizing: border-box;
  border-radius: 7px;
  height: 41px;
`;

export const ButtonCancelar = styled.thead`
  border: 1px solid #817393;
  box-sizing: border-box;
  border-radius: 50px;
  width: 130px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Avenir Next;
  font-size: 18px;
  margin-right: 35px;
  cursor: pointer;
`;

export const Line = styled.p`
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #f0eef2;
  width: 360px;
`;

export const ButtonContinuar = styled.thead`
  background: #e33aa9;
  border-radius: 40px;
  width: 185px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: Avenir Next;
  font-size: 18px;
  cursor: pointer;
  button {
    background-color: transparent;
    border: none;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: Avenir Next;
    font-size: 18px;
    cursor: pointer;
    width: 185px;
    height: 58px;
    border-radius: 40px;
  }
`;

export const TextTotal = styled.th`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #817393;
  display: block;
  text-align: center;
`;

export const TextTotalBold = styled.th`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #503d66;
  display: block;
  text-align: center;
`;

export const ContainerFooter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding: 24px 10px 10px 20px;
  border-bottom: 1px solid #c4c4c4;
`;

export const HeadTableProd = styled.thead`
  position: relative;
  display: block;
  width: 100%;
`;

export const HeadRowTableProdEnd = styled.th`
  font-family: Avenir Next;
  text-align: center;
  font-size: 12px;
  color: #503d66;
  border-right: solid;
  border-right-width: 1px;
  border-right-color: #d4d1d7;
  display: block;
`;

export const HeadRowTableProd = styled.th`
  font-family: Avenir Next;
  font-size: 12px;
  color: #503d66;
  text-align: center;
  flex-basis: 100%;
  display: block;
`;

export const HeadRowTableProdTitle = styled.th`
  font-family: Avenir Next;
  font-size: 15px;
  color: #503d66;
  text-align: left;
  flex-basis: 100%;
  display: block;
`;
export const HeadRowTableProdIconEnd = styled.th`
  font-family: Avenir Next;
  font-size: 15px;
  color: #503d66;
  text-align: right;
  flex-basis: 100%;
  display: block;
  border-right: solid;
  border-right-width: 1px;
  border-right-color: #d4d1d7;
`;

export const HeadRowTableProdSubitle = styled.th`
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: Avenir Next;
  font-size: 12px;
  color: #817393;
  text-align: left;
  flex-basis: 100%;
  display: block;
`;

export const HeadRowTableProdSubitleEnd = styled.th`
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: Avenir Next;
  font-size: 12px;
  color: #817393;
  text-align: left;
  flex-basis: 100%;
  display: block;
  border-right: solid;
  border-right-width: 1px;
  border-right-color: #d4d1d7;
`;

export const TablesProductRowWhite = styled.tr`
  border: none;
  background-color: #ffffff;
  height: 36px;
  display: flex;
  cursor: pointer;
  &:hover:nth-child(odd) {
    background-color: #d4d1d7;
  }
  &:hover:nth-child(even) {
    background-color: #d4d1d7;
  }
  &:nth-child(odd) {
    background-color: #fafafa;
  }
  &:nth-child(even) {
    background-color: #ffffff;
  }
`;

export const CadenasBody = styled.tr`
  background-color: #f0eef2;
  height: auto;
  display: flex;
`;

export const CadenasHead = styled.tr`
  border-top: solid;
  border-top-width: 1px;
  border-top-color: #e33aa9;
  background-color: #f0eef2;
  border-bottom: solid;
  border-bottom-width: 1px;
  border-bottom-color: #d4d1d7;
  height: auto;
  display: flex;
  .title {
    text-align: left;
    padding-left: 5px;
    padding-bottom: 15px;
  }
`;

export const CadenasFotter = styled.tr`
  border-top: solid;
  border-top-width: 1px;
  border-top-color: #d4d1d7;
  border-bottom: solid;
  border-bottom-width: 1px;
  border-bottom-color: #e33aa9;
  background-color: #f0eef2;
  height: auto;
  display: flex;
  .title {
    text-align: left;
    padding-left: 5px;
    padding-bottom: 15px;
  }
`;
export const CadenaProductD = styled.td`
  font-family: Avenir Next;
  text-align: center;
  font-size: 12px;
  flex-basis: 100%;
  display: block;
  .title {
    margin-top: 5px;
    text-align: left;
    padding-left: 5px;
  }
`;

export const CadenaProductDEnd = styled.td`
  font-family: Avenir Next;
  text-align: center;
  font-size: 12px;
  border-right: solid;
  border-right-width: 1px;
  border-right-color: #d4d1d7;
  flex-basis: 100%;
  display: block;
`;

export const TablesProductD = styled.td`
  padding-top: 13px;
  font-family: Avenir Next;
  text-align: center;
  font-size: 12px;
  flex-basis: 100%;
  display: block;
`;
export const TablesProductDIcons = styled.td`
  font-family: Avenir Next;
  font-size: 12px;
  flex-basis: 100%;
  display: block;
  text-align: right;
`;

export const TablesProductDEnd = styled.td`
  font-family: Avenir Next;
  padding-top: 13px;
  text-align: center;
  font-size: 12px;
  border-right: solid;
  border-right-width: 1px;
  border-right-color: #d4d1d7;
  flex-basis: 100%;
  display: block;
`;

export const InputForm = styled.input`
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 2px;
  height: 21px;
  width: 100%;
  &::placeholder {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 21px;
    /* identical to box height, or 210% */

    letter-spacing: -0.015em;

    color: #c4c4c4;
  }
`;

export const SelectForm = styled.select`
  background: #fafafa;
  border-radius: 2px;

  border: none;
  width: 150px;
  height: 21px;
  & option {
    color: #817393;
    background-color: #f0eef2;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;

export const ContainerClose = styled.div`
  margin-left: 40px;
`;
export const Header1 = styled.tr`
  display: flex;
  width: calc(100% - 20px);
`;
export const Header2 = styled.tr`
  display: flex;
  width: calc(100% - 17px);
`;
export const TotalRow = styled.tr`
  display: flex;
  width: calc(100% - 17px);
  margin-top: 10px;
`;

export const BodyTable = styled.tbody`
  width: 100%;
  height: 400px;
  overflow-y: scroll;
  display: block;
  clear: both;
`;

export const Flex = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  .total {
    font-family: Avenir Next;
    color: #817393;
    font-size: 36px;
    margin-right: 140px;
    padding: 10px;
  }
  .text36 {
    font-family: Avenir Next;
    color: #503d66;
    font-size: 36px;
    background: #f0eef2;
    border-radius: 9px;
    padding: 10px;
  }
  .text12 {
    font-family: Lato;
    color: #969696;
    font-size: 12px;
    padding: 3px;
    padding-left: 10px;
  }
`;
