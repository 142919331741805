export const icons = (key) => {
  const elements = {
    datasheet: (
      <span key="datasheet" className={`material-icons small`}>
        &#xf8ee;
      </span>
    ),
    description: (
      <span key="description" className={`material-icons small`}>
        &#xe873;
      </span>
    ),
    images: (
      <span key="images" className={`material-icons small`}>
        &#xe3f4;
      </span>
    ),
    translate: (
      <span key="translate" className="material-icons small">
        &#xe8e2;
      </span>
    ),
    build: (
      <span key="build" className="material-icons small">
        &#xe1bd;
      </span>
    ),
    search: <span className="material-icons small">&#xe8b6;</span>,
  };
  return elements[key];
};
