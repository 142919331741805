import { useEffect, useState } from "react";
import CustomSelectItemsLoad from "../../../custom/CustomSelectItemsLoad";
import { faStar as IconStarFill } from "@fortawesome/free-solid-svg-icons";
import { faStar as IconStarStroke } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

export default function CustomSelectLists({
  idSelect, // posible id unico
  articleID, // ID del articulo
  articleLists = [], // listas del articulo
  allLists = [], // todas las listas merchants
  onClickItem /* cuando se hace click en un item
	(
		isChecked,
		articleID,
		listID,
		setIsSeletedItem( listID , isSelected )=> {},
		unique
	) => {}
	*/,
  componentFooter,
  isShowModal,
  ...props
}) {
  const [customLists, setCustomlists] = useState([]); // fusion de allLists con articleLists
  const [valueInput, setValueInput] = useState(""); // valor del inputSearch del select
  const [optionItems, setOptionItems] = useState([]); // items que seran mostrados en el select

  const [icon, setIcon] = useState({
    icon: <Icon icon={IconStarStroke} />,
    className: "iconNormal",
  });
  const [countN, setCountN] = useState(0);

  // cada vez que cambien las listas del articulo
  useEffect(() => {
    // comparar si es la misma lista
    //if (lastArticlelists && articleLists.length ===lastArticlelists.length) return;
    //else setLastArticlelists(articleLists);
    // realizar fusion de listas
    const articleL = articleLists.map((l) => ({ ...l })) ?? [];
    const listsId_article = articleL.map((list) => list.id);
    let lists = allLists.map((list) => ({ ...list })) ?? [];
    lists.forEach((list) => {
      if (listsId_article.includes(list.id ?? -1)) {
        list.isSelected = true;
      } else list.isSelected = false;
    });
    setCustomlists(lists);
    setOptionItems(lists);
    setValueInput("");

    //evaluar tipo icon
    let typeIcon = {
      icon: <Icon icon={IconStarStroke} />,
      className: "iconNormal",
    };
    if (articleL.length > 0)
      typeIcon = {
        icon: <Icon icon={IconStarFill} />,
        className: "iconSelected",
      };
    setIcon(typeIcon);
  }, [articleLists, allLists]);

  // realizar filtro cada vez que cambia el inputSearch
  const onChangeValueInput = (newValue) => {
    // filtrar por input
    let itemsListFiltered = customLists.map((l) => ({ ...l }));
    if (newValue.trim().length > 0) {
      itemsListFiltered = itemsListFiltered.filter((list) =>
        list.name.toLowerCase().includes(newValue.trim().toLocaleLowerCase())
      );
    }
    setValueInput(newValue);
    setOptionItems(itemsListFiltered);
  };

  // funcion que cambia el estado del checkbox de una lista
  const setIsSeletedItem = (listId, isSelected) => {
    customLists.forEach((list) => {
      if (list.id === listId) list.isSelected = isSelected;
    });
    // filtrar por input search
    let itemsListFiltered = customLists.map((l) => ({ ...l }));
    if (valueInput.trim().length > 0) {
      itemsListFiltered = itemsListFiltered.filter((list) =>
        list.name.toLowerCase().includes(valueInput.trim().toLocaleLowerCase())
      );
    }
    setCustomlists(customLists.map((l) => ({ ...l })));
    setOptionItems(itemsListFiltered);
  };

  // evento click en el chexbox de una lista
  const onClickItemList = async (isChecked, listId) => {
    let itemLoading = customLists.some((list) => list.isSelected === undefined);
    if (itemLoading) return;
    const unique = countN + 1;
    setCountN(unique);
    setIsSeletedItem(listId, undefined); //checkbox dejarlo como loading
    onClickItem(isChecked, articleID, listId, setIsSeletedItem, unique);
  };

  const isShowSelect = (show) => {
    if (show) {
      setIcon({ icon: <Icon icon={IconStarFill} />, className: "iconClick" });
    } else {
      //evaluar tipo icon
      let typeIcon = {
        icon: <Icon icon={IconStarStroke} />,
        className: "iconNormal",
      };
      if (articleLists?.length > 0)
        typeIcon = {
          icon: <Icon icon={IconStarFill} />,
          className: "iconSelected",
        };
      setIcon(typeIcon);
    }
  };

  return (
    <CustomSelectItemsLoad
      options={optionItems}
      showSearchBar
      placeHolder="Buscar lista"
      valueInputSearch={valueInput}
      onChangeValueInputSearch={onChangeValueInput}
      icon={icon.icon}
      iconClassName={icon.className}
      isShowSelect={isShowSelect}
      customSelectId={idSelect}
      onClickItem={onClickItemList}
      componentFooter={componentFooter}
      isShowModal={isShowModal}
    />
  );
}
