import React from "react";

import { ContainerBar, Bar } from "./styles";

export default function ProgressBar(props) {
  return (
    <ContainerBar>
      <Bar color={props.color} percentage={props}>
        {props.percentage}%
      </Bar>
    </ContainerBar>
  );
}
