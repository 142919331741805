import React, { useState, useEffect } from "react";

//Icon imports
import fs from "../../../assets/IconComponents/fullscreen.svg";
import roundedchecked from "../../../assets/IconComponents/rounded-checked.svg";
import { getProfilePicture } from "../../_utils/helper";
import {
  MainContainer,
  ScrollerImg,
  ImageItem,
  ImageContainer,
} from "./styles";

import ValidationMenu from "./ValidationMenu";

export default function CarrouselImg({
  images,
  seleccionado,
  hideTopBar,
  setDataImages,
  namesList,
  assignations,
  setImgFullScreen,
}) {
  const [showValidation, setShowValidation] = useState(false);

  return (
    <MainContainer>
      {!hideTopBar && (
        <>
          <div className="topBar">
            <div className="asig-container">
              {assignations?.map((assignation) => (
                <figure className="rounded-photo">
                  <img src={getProfilePicture(assignation.id_user)} alt="" />
                </figure>
              ))}

              <p className="short-text">Asig.</p>

              <figure
                className="checked-icon"
                onClick={() => setShowValidation(!showValidation)}
              >
                <img src={roundedchecked} alt="" />
                {showValidation && (
                  <ValidationMenu className="checked-iamges" />
                )}
              </figure>
            </div>

            <figure>
              <img src={fs} alt="" onClick={() => setImgFullScreen(true)} />
            </figure>
          </div>
        </>
      )}

      <ScrollerImg>
        <ImageList
          seleccionado={seleccionado}
          images={images}
          setDataImages={setDataImages}
          namesList={namesList}
        ></ImageList>
      </ScrollerImg>
    </MainContainer>
  );
}

const ImageList = ({ images, seleccionado, setDataImages, namesList }) => {
  const [data, setData] = useState([]);

  const onLoad = (item) => {
    const img = new Image();
    img.src = item.src;
    const name =
      namesList && namesList.find((e) => e.id === item.image_id).name;
    setData((prev) => [
      ...prev,
      { name, dimension: `${item.width}x${item.height}` },
    ]);
  };

  useEffect(() => {
    if (data.length > 0) {
      if (setDataImages) {
        setDataImages(data);
      }
    }
  }, [data, setDataImages]);

  const renderImage = (image, index) => {
    return (
      <div key={index}>
        <div>
          <ImageItem onClick={() => seleccionado(image)}>
            <img alt="img" src={image.src} onLoad={() => onLoad(image)} />
          </ImageItem>
        </div>
      </div>
    );
  };

  return <ImageContainer>{images?.map(renderImage)}</ImageContainer>;
};
