import styled, { keyframes } from "styled-components";
import { grayS7, pinkS1 } from "../variables";

const bounce = keyframes`
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
`;

export const FeaturesContainer = styled.div`
  font-family: Raleway;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    text-align: center;

    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -0.015em;
      color: ${pinkS1};
    }
    p {
      font-size: 14px;
    }
    label {
      font-size: 12px;
    }
  }
  .checkbox-container {
    display: flex;
    gap: 5px;
    align-self: center;
  }

  .checkbox-container input {
    cursor: pointer;
    appearance: none;
    width: 14px;
    height: 14px;
    border: 1px solid ${grayS7};
    border-radius: 3px;
    transition: background 0.3s;
    position: relative;
    &:checked,
    &:hover {
      border-color: ${pinkS1};
    }

    &:checked::after {
      content: "✓";
      color: ${pinkS1};
      font-size: 12px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .modal-content .grid {
    pointer-events: none;
  }

  .button-accept {
    background: ${pinkS1};
    color: #fff;
    padding: 0.5rem 0.2rem;
    border-radius: 17px;
    cursor: pointer;
    min-width: 90px;
    border: 1px solid ${pinkS1};

    &:hover {
      background: #fff;
      color: ${pinkS1};
    }
  }
  .animation-children{
    animation: ${bounce} 1s infinite;
  }
`;
